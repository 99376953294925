
import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import axios from 'axios';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import 
  {
    createSelectStyle, handleErrorResponse,
    lmTransports, lmURISchemes, lmSIPRegAuthScheme,
  } from '../../../routes/lm.js';

export const LMAPATServerConfig = ({onConfigChange}) =>  {
  const [state, setState] = useState({
    server: '127.0.0.2', port: 8888, transport: 0,   
  });

  let transportsObj = lmTransports();

  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    let newState = { ...state };
    newState[name] = value; 
    setState(newState);
    onConfigChange(newState);
  };
  
  const handleSelectInputChange=(e)=> {
    const {name,value}=e.target;
    let newState = {...state};

    /* 
    * This block of code is very important.
    * When onChange triggers for a select, we need to keep track of id of item 
    * selected in state object. The id may not be like 0,1,2.. but could be like
    * 0.10,20,35,56 etc..
    * 
    * So for 2nd case where id is montonic, that time we must set the id (through data-id)
    * in JSX and retrieve here, set the id value to state object's corresponding member.
    */
    for (let node of e.target.children) {
      if (node.value === e.target.value) {
        newState[name] = Math.round(node.getAttribute('data-id'));
        break;
      }
    }
    
    setState(newState);
    onConfigChange(newState);
  }

  function justUpdateState() {
    let newState = { ...state };
    setState(newState);
    onConfigChange(newState);
  }

  //Hook to get data from backend server
  useEffect ( () => {
     justUpdateState();
  }, []);    

  return(
    <div key="serverconfig">
      <br/><br/><h3>Server configuration</h3>
      <table key='tserverconfig' className="statstbl">
        <tbody key='tserverconfigbd'>
          
          <tr key='tr1'>

            <td>
              Server IP
              <Tooltip title="Enter server IP address. Use local IP addresses to reduce bandwidth cost." arrow>
                <span className="round">?</span>
              </Tooltip><br/>
              <input required name="server" value={state.server} style={{width: "100%"}} onChange={(e)=>handleInputTextChange(e)}/>
            </td>

            <td>
              Server port
              <Tooltip title="Enter server listen port." arrow>
                <span className="round">?</span>
              </Tooltip><br/>
              <input required name="port" value={state.port} style={{width: "100%"}} onChange={(e)=>handleInputTextChange(e)}/>
            </td>

            <td>
              Transport
              <Tooltip title="Select transport. Here ws / wss refers to websocket / secured websocket transport." arrow>
                <span className="round">?</span>
              </Tooltip><br/>

              <select style={{width: "100%"}} value={transportsObj[state.transport]} 
                name="transport" onChange={handleSelectInputChange}>
                {Object.keys(transportsObj).map(item => 
                  <option key={`sip${item}`} data-id = {`${item}`}>
                  {transportsObj[item]}
                  </option>
                )}
              </select>                        

            </td>
          </tr>
        </tbody>
      </table>
    
    </div>
  );
}
