
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGMultiplierInstallation = () => {

  setPageTitle('Multiplier Installation');
  
  const element = (
    <div className="userguide">
      <h1>Multiplier Installation</h1>
      <br/>
      Please follow below steps to install the LM Tools <b>Multiplier</b> component.<br/><br/> 
      <ol>
        <li>First arrange a machine with <b>Ubuntu 22.04 LTS</b>  OS, 6GB RAM, 50GB HDD and 2.4+ GHz CPU. For higher load, you may have to go for high end machines.</li>
        <li>Download the Multiplier installation zip file from <a href="/userguide/download">here</a>.</li>
        <li>Create a new directory say mylm-installation in home path. 
        The path looks as <b>/home/lm/mylm-installation</b>.</li>
        <li>Unzip the downloaded zip file to /home/lm/mylm-installation path.
        The directory looks as below.
        <br/><img className="borderimg" src={process.env.PUBLIC_URL + '/userguide/dirlistings-1.png'} alt="dir listings"/>
        </li>

        <li>Go to lm-5.00-multiplier folder and you can see the directory content as below.
        <br/><img className="borderimg" src={process.env.PUBLIC_URL + '/userguide/dirlistings-files-1.png'} alt="dir listings"/>
        </li>

        <li>Run the ./install.py as root user. Make sure the machine is connected to internet without any restriction
        as it need to update Ubuntu system packages first, next it will do the Multiplier installation.
        You need to enter three input and those are system ipaddress, username and 1 for selecting Multiplier installation as shown in below diagram.
        Next hit Enter and proceed with the Multiplier installation.
        <br/><img className="borderimg" src={process.env.PUBLIC_URL + '/userguide/input-1.png'} alt="dir listings"/>
        </li>
        
        <li>
        If everything goes fine, you should see "Multiplier installation successful" message at the end of the installation.
        </li>

        <li>
        Now reboot the machine. That's all. You can use the machine as multiplier machine in setup.
        </li>
        
      </ol>
      <br/>
      With this, Multiplier installation is complete, now you should proceed to configure 
      the test and execute.
     
      <br/>
      <br/>
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/multiplier/machineinfo"}>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/quickstart"}>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGMultiplierInstallation}

