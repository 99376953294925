
const Img1 = require('./img_snow_wide.jpg');
const Img2 = require('./img_nature_wide.jpg');
const Img3 = require('./img_mountains_wide.jpg');
const Img4 = require('./img_lights_wide.jpg');

export const ImageData = [
    {
        ImageNo:'',
        ImageName: 'HTTP Testing',
        ImageSrc: Img1
    },
    {
        ImageNo: '',
        ImageName: 'SIP/IMS Testing',
        ImageSrc: Img2
    },
    {
        ImageNo: '',
        ImageName: 'WebRTC Testing',
        ImageSrc: Img3
    },
    {
        ImageNo: '',
        ImageName: 'Proprietary Testing',
        ImageSrc: Img4
    }
];
