/*
* Please note the attribs are indented from start of line intentionally.
* Do not do normal formating else sip test blocks will have additional spaces.
*/
import 
  {
    lmTransports, lmURISchemes, 
    lmMediaProfiles, lmCryptoSuites, 
    lmAudioCodecs, lmVideoCodecs,
    getLmBlockTypeId,
  } from '../../../routes/lm.js';

export function sipCreateInstanceAttribs(serverConfig, pluginId, frequency, reportId) {

  let transportsObj = lmTransports();
  let transport = transportsObj[serverConfig.transport];

  let attribs = 
`sip ${serverConfig.server} ${serverConfig.port} ${transport} sip1
max-session-time 4000
max-endpoints 1000`;

  return ({
    tcname:"create-instance",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: getLmBlockTypeId('Initialize'),
    freq: frequency,
    report: reportId,
    attribs: attribs,
  });
}

export function sipVariablesAttribs(pluginId, frequency, reportId) {
  let attribs = 
`global label=[p-random-hex-string]
global totag=[p-random-hex-string:5]`;

  return ({
    tcname:"variables",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: getLmBlockTypeId('Variables'),
    freq: frequency,
    report: reportId,
    attribs: attribs,
  });
}

export function sipConfigInstance(userConfig, callConfig, serverConfig, pluginId, typeId, frequency, reportId) {
  let video = 0;
  
  let uriSchemesObj = lmURISchemes();
  let mediaprofilesObj = lmMediaProfiles();
  let cryptoSuitesObj = lmCryptoSuites();
  
  let urischeme = uriSchemesObj[serverConfig.urischeme];
  let mediaprofile = mediaprofilesObj[callConfig.mediaprofile];
  let cryptosuite = cryptoSuitesObj[callConfig.cryptosuite].toUpperCase();

  if(callConfig.vcodec > 0) {
    video = 1;
  }
  
  let attribs = 
`sip start
server New SIP LM Server 5.0
uri-scheme ${urischeme}
private-user-prefix ${userConfig.uprefix}
video ${video}
media-transport ${mediaprofile}
crypto-suite ${cryptosuite}
media-ep-mode client
rtcp-mux ${callConfig.rtcpmux}
av-bundle ${callConfig.avbundle}
media-enable 1
media-write 0
sip end`;

  return ({
    tcname:"config-instance",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs,
  });
}

export function sipConfigSDPAttribs(callConfig, pluginId, typeId, frequency, reportId) {

  let videofile = '';
  let acodecnumber = 0;
  let vcodecnumber = 0;

  let audioCodecsObj = lmAudioCodecs();
  let videoCodecsObj = lmVideoCodecs();
  
  let acodec = audioCodecsObj[callConfig.acodec].toUpperCase();
  let vcodec = videoCodecsObj[callConfig.vcodec].toUpperCase();
  
  if(acodec == 'PCMA') {
    acodecnumber = 8;
  } else if(acodec == 'AMR') {
    acodecnumber = 101;
  } else if(acodec == 'OPUS') {
    acodecnumber = 102;
  }
  
  if(vcodec == 'VP8') {
    videofile = 'in-vp8.mlt';
    vcodecnumber = 98;
  } else if(vcodec == 'H264') {
    videofile = 'in-h264.mlt';
    //vcodecnumber = 99;
    vcodecnumber = 98;
  }
  
  let attribs = 
`session-config start
version 0
origin lmcloud
session lmcloud
audio-codec ${acodec}
audio-ptime 20
audio-codec-number ${acodecnumber}
audio-sampling-rate 8000`;

if(vcodecnumber > 0) {
attribs += `
video-codec ${vcodec}
video-fps 24
video-file ${videofile}
video-sampling-rate 90000
video-codec-number ${vcodecnumber}`;
}

attribs += `
session-config end
rps 960`;

  return ({
    tcname:"config-sdp",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs,
  });
}

export function sipRegisterAttribs(pluginId, typeId, frequency, reportId) {
  let attribs = 
`sip start
send [t-tx-register-200]
sip end`;

  return ({
    tcname:"register",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs,
  });
}

export function sipUnRegisterAttribs(pluginId, typeId, frequency, reportId) {
  let attribs = 
`sip start
send [t-tx-unregister-200]
sip end`;

  return ({
    tcname:"unregister",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs,
  });
}

export function sipInviteAttribs(pluginId, typeId, frequency, reportId) {
  let attribs = 
`sip start
send [t-tx-invite-100]
media-init
session-init
media-init
send-with-blen [t-tx-invite]
sip end`;

  return ({
    tcname:"invite",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs,
  });
}

export function sipInvite180Attribs(pluginId, typeId, frequency, reportId) {
  let attribs = 
`sip start
switch session
send [t-tx-invite-180]
sip end`;

  return ({
    tcname:"invite180",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs,
  });
}

export function sipInvite200Attribs(callConfig, pluginId, typeId, frequency, reportId) {
  let attribs = 
`sip start
send [t-tx-ack]
switch session
send-with-blen [t-tx-invite-200]
switch session
set-audio-codec [remote-acodec-name]
set-audio-codec-number [remote-acodec-number]
set-remote-ip [remote-audio-ip]
set-remote-audio-rtp-port [remote-audio-rtp-port]
set-video-codec [remote-vcodec-name]
set-video-codec-number [remote-vcodec-number]
set-remote-video-rtp-port [remote-video-rtp-port]
media-ready timer 60000
media-link
media-sendrecv timer ${callConfig.mediatime}
media-inactive
media-deinit
sip end`;

  return ({
    tcname:"invite200",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs,
  });
}


export function sipAckAttribs(callConfig, pluginId, typeId, frequency, reportId) {
  let attribs = 
`sip start
set-audio-codec [remote-acodec-name]
set-audio-codec-number [remote-acodec-number]
set-remote-ip [remote-audio-ip]
set-remote-audio-rtp-port [remote-audio-rtp-port]
set-video-codec [remote-vcodec-name]
set-video-codec-number [remote-vcodec-number]
set-remote-video-rtp-port [remote-video-rtp-port]
media-ready timer 60000
media-link
media-sendrecv timer ${callConfig.mediatime}
media-inactive
media-deinit
sip end`;

  return ({
    tcname:"ack",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs,
  });
}


export function sipByeAttribs(pluginId, typeId, frequency, reportId) {
  let attribs = 
`sip start
send [t-tx-bye-200]
switch session
send [t-tx-bye]
sip end`;

  return ({
    tcname:"bye",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs,
  });
}

export function sipBye200Attribs(pluginId, typeId, frequency, reportId) {
  let attribs = 
`sip start
sip end`;

  return ({
    tcname:"bye200",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs,
  });
}

export function sipExecuteServerAttribs(tpname, pluginId, typeId, frequency, reportId) {
  let attribs = 
`set-default-text-templates ${tpname}
on sip1 register [t-rx-unregister] [i-unregister]
on sip1 register [t-rx-register] [i-register]
on sip1 invite [t-rx-invite] [i-invite]
on sip1 invite:180 [t-rx-invite-180] [i-invite180]
on sip1 invite:200 [t-rx-invite-200] [i-invite200]
on sip1 ack [t-rx-ack] [i-ack]
on sip1 bye [t-rx-bye] [i-bye]
on sip1 bye:200 [t-rx-bye-200] [i-bye200]
daemon sip1 start`;

  return ({
    tcname:"execute-server",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs,
  });
}
