
import { menuItems } from '../menuItems';
import MenuItems from './MenuItems';
import {getUserRoleId} from '../routes/lm.js';

const Navbar = () => {

  let user = JSON.parse(localStorage.getItem('user'));

  if(user == null) {
    let role = 'anonymous';
    user = {role: role, rid: getUserRoleId(role)};
  }
  
  return (
    <nav>
      <ul className="menus">
        {menuItems.map((menu, index) => {
          const depthLevel = 0;
          
          let userrid = user.rid;  
          let menurid = getUserRoleId(menu.allowed);
  
          if(menurid >= userrid) {
            
            return (
              <MenuItems
                items={menu}
                key={index}
                depthLevel={depthLevel}
              />
            );
          }
          
        })}
      </ul>
    </nav>
  );
};

export default Navbar;