
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

import 
  {
    ugc_variables_syntax, ugc_variables_syntax_desc,
    ugc_plugin_syntax, ugc_plugin_syntax_desc, 
    ugc_rxpkt_expected_length_syntax, ugc_rxpkt_expected_length_syntax_desc,
    ugc_instructions_syntax, ugc_instructions_syntax_desc,
    ugc_text_template_syntax, ugc_text_template_syntax_desc,
    ugc_binary_template_syntax, ugc_binary_template_syntax_desc
  } from './ugcommon.js'

function b2baapat_createinstance_syntax()
{
  return(
    <>
    <b>create-instance</b><br/>
    apat 192.168.1.216 5060 udp apat1<br/>
    http 192.168.1.216 http1<br/>
    max-endpoints 1000<br/>
    max-sessions 1000<br/>
    max-session-time 10<br/>
    ipversion 6<br/>
    automation 1<br/>
    </>
   );
}

function b2baapat_createinstance_syntax_desc()
{
  return(
    <>
    The <b>create-instance</b> command creates B2BA. This test case name is 
    fixed, you can not use any arbitrary name. Various attributes used in create 
    instance are explained below.
    <br/><br/>
    <b>apat</b> - APAT entity is created using <b>apat</b> synntax. See in left column
    for example. User need to mention IP address, port and transport protocol for APAT
    entity. The last parameter is name of APAT entity.
    
    <br/><br/>
    <b>http</b> - User can associate a HTTP client with B2BA APAT entity using 
    <b>http</b> syntax. Just IP address and a name for HTTP client needs to be
    given for HTTP client.
    
    <br/><br/>
    <b>max-endpoints</b> - You can specify how many maximum endpoints can be handled 
    by B2BA APAT using <b>max-endpoints</b>.
    
    <br/><br/>
    <b>max-sessions</b> - You can specify how many maximum sessions can be handled 
    by B2BA APAT using <b>max-sessions</b>.
    
    <br/><br/>
    <b>max-session-time</b> - You can specify maximum session time for a session in 
    B2BA APAT using <b>max-session-time</b>.

    <br/><br/>
    <b>ipversion</b> - Set the ipversion preference IPv6 / IPv4 by setting this to 6 / 4.

    <br/><br/>
    <b>automation</b> - To view the test result (Overall stats) on basis of number of test cases instead of number of virtual users set it to 1.
    
    <br/><br/>
    <b>ep-status</b> - Endpoint status is written into log file in every few 
    seconds. Three possible value are given below.
    
    <ul>
      <br/><li><b>none</b> - Endpoint status is not be written into log file. 
      During heavy load testing this is the preferred option.
      </li>
      
      <br/><li><b>resource</b> - Endpoint status, only resource usage portion 
      (like size of linked lists, timer counts etc) is written to log file.
      </li>
      
      <br/><li><b>details</b> -  Endpoint status in full is written into log file. 
      This helps to debug which particular endpoint is not returned from current 
      command execution. Once you know which endpoint is not returned, filter log 
      for that endpoint and it helps to analyze the issue further.
      </li>
    </ul>
    </>
   );
}


function b2baapat_configinstance_syntax()
{
  return(
    <>
      <b>config-instance</b><br/>
      ws-hs-template template<br/>
      ws-ahh headers<br/>
      ws-req-uri requri<br/>
      video 1<br/>
      media-transport rtp-udp<br/>    
    </>
  ); 
}

function b2baapat_configinstance_syntax_desc()
{
  return(
    <>
      The <b>config-instance</b> command is used to configure the entity. This test case name is 
      fixed, you can not use any arbitrary test case name for this. 
      Various attributes used in config instance are explained below.
      <br/><br/>
      <b>ws-hs-template</b> - The template that is used for websocket handshake. 
      During websocket handshake, multiplier sends handshake (http GET) message. 
      You can customise the handshake GET message using template given here. 

      <br/><br/>
      <b>ws-ahh</b> - You can add additional headers in websocket handshake GET message.
      The additional headers are provided using <b>ws-ahh</b> configuration.

      <br/><br/>
      <b>ws-req-uri</b> - You can specify request uri in GET message of websocket 
      handshake GET message. 

      <br/><br/>
      <b>video</b> - To disable / enable video set it to 0 / 1.

      <br/><br/> 
      <b>media-transport</b> - Media transport profiles. Possible values are rtp-udp, rtp-tcp, rtp-tls, srtp-udp and srtp-dtls.
      
      
      <br/><br/><b>media-transport</b> - Media transport profiles. Possible values are rtp-udp, rtp-tcp, rtp-tls, srtp-udp and srtp-dtls.
      <ul>
        <li><b>rtp-udp</b>: RTP packets exchange over UDP</li>
        <br/><li><b>rtp-tcp</b>: RTP packets exchange over TCP</li>
        <br/><li><b>rtp-tls</b>: RTP packets exchange over TLS</li>
        <br/><li><b>srtp-udp</b>: SRTP packets exchange over UDP</li>
        <br/><li><b>srtp-dtls</b>: SRTP packets exchange over UDP, key is extracted from DTLS handshake.</li>
      </ul>
    
    </>
  ); 
}

const UGAPATB2BASyntax = () => {

  setPageTitle('APAT B2BA Syntax');
  
  let index = 0;
  let modulename = "apatb2ba";
  const element = (
    <div className="userguide">
      <h1>APAT B2BA Syntax</h1>
      
      <br/>

      <table className="statstbl">
        <thead>
          <tr>
            <th style={{width: "5%"}}>
              Sl
            </th>
            
            <th style={{width: "45%"}}>
              Test block
            </th>
            
            <th style={{width: "50%"}}>
              Syntax
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              {++index} 
            </td>
            
            <td>
              {b2baapat_createinstance_syntax()}
            </td>
            
            <td>
              {b2baapat_createinstance_syntax_desc()}
            </td>
          </tr>

          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {b2baapat_configinstance_syntax()}
            </td>
            
            <td>
              {b2baapat_configinstance_syntax_desc()}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {ugc_variables_syntax()}
            </td>
            
            <td>
              {ugc_variables_syntax_desc(modulename)}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {ugc_rxpkt_expected_length_syntax()}
            </td>
            
            <td>
              {ugc_rxpkt_expected_length_syntax_desc()}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {ugc_plugin_syntax()}
            </td>
            
            <td>
              {ugc_plugin_syntax_desc(modulename)}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {ugc_instructions_syntax()}
            </td>
            
            <td>
              {ugc_instructions_syntax_desc(modulename)}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {ugc_text_template_syntax()}
            </td>
            
            <td>
              {ugc_text_template_syntax_desc()}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {ugc_binary_template_syntax()}
            </td>
            
            <td>
              {ugc_binary_template_syntax_desc()}
            </td>
          </tr>

        </tbody>
      </table>

      <br/>
      
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/apatb2bavariables" }>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/apatb2bainstructions" }>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGAPATB2BASyntax}

