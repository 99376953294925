import React, { useState } from 'react';
import { Child } from './child';
import { LMCallConfig } from '../utils/lmcallconfig'
import { LMUserConfig } from '../utils/lmuserconfig'
import { ScenarioHeader } from '../utils/scenarioheader'
import { LMSIPServerConfig } from '../utils/lmsipserverconfig'
import { LMAPATServerConfig } from '../utils/lmapatserverconfig'

export const Parent = () => {
  const [states, setStates] = useState([]);
  const [callconfig, setCallConfig] = useState(null);
  const [userconfig, setUserConfig] = useState(null);
  const [headerconfig, setHeaderConfig] = useState(null);
  const [sipserverconfig, setSIPServerConfig] = useState(null);
  const [apatserverconfig, setAPATServerConfig] = useState(null);

  const handleChildNameChange = (updatedState, index) => {
  const newStates = [...states];
  newStates[index] = updatedState;
  setStates(newStates);
};

 const handleCallConfigChange = (updatedState) => {
   setCallConfig(updatedState);
 }
 
 const handleUserConfigChange = (updatedState) => {
   setUserConfig(updatedState);
 }
 
 const handleScenarioHeadChange = (updatedState) => {
   setHeaderConfig(updatedState);
 }
 
 const handleLMSIPServerConfigChange = (updatedState) => {
   setSIPServerConfig(updatedState);
 }

 const handleLMAPATServerConfigChange = (updatedState) => {
   setAPATServerConfig(updatedState);
 }

  let configObj = {
    baseid: true, 
    uprefix: true, pprefix: true, 
    transport: true, urischeme: true,
  };
 
 return (
   <div key="div1">

    <form name="myformname" id="myformid">
      <Child onNameChange={(updatedState) => 
              handleChildNameChange(updatedState, 0)} />
      <Child onNameChange={(updatedState) => 
              handleChildNameChange(updatedState, 1)} />
      
      <ScenarioHeader scenarioName={'sip-call'} onConfigChange={(updatedState) => 
        handleScenarioHeadChange(updatedState)} />
      
      <LMCallConfig onConfigChange={(updatedState) => 
        handleCallConfigChange(updatedState)} />

      <LMUserConfig configObj={configObj} onConfigChange={(updatedState) => 
        handleUserConfigChange(updatedState)} />

      <LMSIPServerConfig onConfigChange={(updatedState) => 
        handleLMSIPServerConfigChange(updatedState)} />

      <LMAPATServerConfig onConfigChange={(updatedState) => 
        handleLMAPATServerConfigChange(updatedState)} />
        
    </form>

    <br/>
    {(states.length >= 2) && `1st child name ${states[0].name} and 2nd child name is ${states[1].name}`}

    <br/>
    {(callconfig) && `audio codec = ${callconfig.acodec}, video codec = ${callconfig.vcodec}, 
    media profile = ${callconfig.mediaprofile}, crypto suites = ${callconfig.cryptosuite}, 
    rtcp mux = ${callconfig.rtcpmux}, av bundle = ${callconfig.avbundle},
    media time (in ms) = ${callconfig.mediatime}`}
 
     <br/>
    {(userconfig) && `local ip = ${userconfig.localip}, base id = ${userconfig.baseid}, 
    transport = ${userconfig.transport}, urischeme = ${userconfig.urischeme}, 
    user prefix = ${userconfig.uprefix}, password prefix = ${userconfig.pprefix}`}

     <br/>
    {(sipserverconfig) && `server ip = ${sipserverconfig.ip}, port = ${sipserverconfig.port}, 
    transport = ${sipserverconfig.transport}, urischeme = ${sipserverconfig.urischeme}, 
    auth scheme = ${sipserverconfig.auth}`}

     <br/>
    {(apatserverconfig) && `server ip = ${apatserverconfig.ip}, port = ${apatserverconfig.port}, 
    transport = ${apatserverconfig.transport}`}
    
     <br/>
    {(headerconfig) && `name = ${headerconfig.name}, desc = ${headerconfig.desc}, 
    clabel = ${headerconfig.clabel}, cvalue = ${headerconfig.cvalue}, 
    mlabel = ${headerconfig.mlabel}, mvalue = ${headerconfig.mvalue}`} 
    
   </div>
 );
};