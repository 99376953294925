
import React, {useState, useEffect} from 'react';
import {Line} from 'rc-progress';
import axios from 'axios';
import {setPageTitle, getURLQueryVariable, handleErrorResponse} from '../../routes/lm.js';

const Scenarios = () => 
{
  setPageTitle('Create scenario');
  
  const [moduleInfos, setModuleInfos] = 
    useState(
      [
        {
          module: 'Apat', 
          desc: 'Apat stands for any product any test. You can use this module to access one or more URLs. You can format and send any text/binary messages over any transport protocol. It can receive the messages, parse and remember values and reuse it down the line.',
          links: ['/components/scenarios/create?scid=access-single-url', '/components/scenarios/create?scid=access-multi-url'],
          names: ['Access single URL', 'Access multiple URLs'],
        },
        
        {
          module: 'ApatWebRTC', 
          desc: 'This module is used to test products based on WebRTC. You can use this module to simulate one-to-one WebRTC call, conference calls. You can format and send any text/binary messages over any transport protocol. It can receive the messages, parse and remember values and reuse it down the line.',
          links: ['/components/scenarios/create?scid=apat-webrtc'],
          names: ['Apat WebRTC call'],

        },

        {
          module: 'FlexiSIP', 
          desc: 'You can use this module to test SIP based products. You can use LM Tools to simulate SIP clients for SIP / IMS calls. Create a scenario close to your test requirement, and the do minor modifications to test your product.',
          links: ['/components/scenarios/create?scid=sip-call'],
          names: ['SIP call'],
        },

        {
          module: 'FlexiSIPWebRTC', 
          desc: 'You can use this module to test SIP based products. You can use LM Tools to simulate SIP clients for SIP / IMS based WebRTC calls. You can test ICE, STUN, TURN scenarios with SIP signalling. Create a scenario close to your test requirement, and the do minor modifications to test your product.',
          links: ['/components/scenarios/create?scid=sip-webrtc-call'],
          names: ['SIP WebRTC call'],
        },

        {
          module: 'JsonProxy', 
          desc: 'You can use this module to test Generic WebRTC call flows. You can use LM Tools to simulate JSON based proxy server to route generic JSON messages across clients.',
          links: ['/components/scenarios/create?scid=json-proxy'],
          names: ['JSON proxy'],
        },

        {
          module: 'Server', 
          desc: 'You can use this Server module to create servers for SIP, HTTP and APAT (any product any testing).', 
          links: [
            '/components/scenarios/create?scid=server-sip-proxy',
            '/components/scenarios/create?scid=sip-b2ba',
            '/components/scenarios/create?scid=http-proxy',
            '/components/scenarios/create?scid=apat-proxy',
          ],
          names: [
            'SIP proxy server',
            'SIP B2BA',
            'HTTP proxy server',
            'APAT proxy server',
          ],
        },
      ],
    );
  
  const user = JSON.parse(localStorage.getItem('user'));
  
  if(user) {
   
      return (
        <div key="scmain" className="small-text">
          <div key="scmain2" className="center" >
              <br/><h3>Create the scenario</h3>

            <div className="statstbl">
              <table key="sctable">
                <tbody key="sctblbd">

                  <tr key="sctblrow1">
                    <th style={{width: "5%"}}>
                      Serial
                    </th>
                    
                    <th style={{width: "15%"}}>
                      Module
                    </th>
                    
                    <th style={{width: "40%"}}>
                      Description
                    </th>

                    <th style={{width: "30%"}}>
                      Scenarios
                    </th>
                  
                  </tr>
                  
                  
                  {
                    moduleInfos.map((val,tindex)=>
                      <tr key={"sctblrow2" + tindex}>
                        <td style={{width: "5%"}}>
                        {tindex+1}
                        </td>
                        
                        <td style={{width: "15%"}}>
                          {val.module}
                        </td>
                        
                        <td style={{width: "45%"}}>
                          {val.desc}
                        </td>

                        <td style={{width: "35%"}}>
                        {
                          val.links.map((val2, tindex2) =>
                            <div key={'link'+tindex2}>
                              <a href={val2}>{val.names[tindex2]}</a><br/>
                            </div>
                          )
                        }
                        </td>
                        
                      </tr>
                    )
                  }

                </tbody>
              </table>
              </div>
          </div>
        </div>
      );
 
  } else { 
    return(
      <div key="scmain" className="medium-text">
        <div id="scmain2" className="center" >
          You are not allowed to access this page.
        </div>
      </div>
    );
  }
}

const ViewTheScenario = () => {
  const [status, setStatus] = useState(null);
  
  const [testScenario, setTestScenario] = useState({id: getURLQueryVariable("id"), name: ""});

  const [testBed, setTestBed] = useState({id: 0, name: ""});
  const [testSuites, setTestSuites] = useState([{id: 0, name: ""}]);
  const [testTemplates, setTestTemplates] = useState([{id: 0, name: ""}]);
  
  setPageTitle('Scenario ' + testScenario.name);
  
  const user = JSON.parse(localStorage.getItem('user'));
  
  useEffect ( () => {
     fetchScenario();
  }, []);  

  const fetchScenario = async () => {
    let scId = testScenario.id;
    
    if(user != null) {

      try {
        const res = await axios.post('/api/fetch/scenario/data', 
        {uid: user.uid, email: user.email, scid: scId} ,
        {headers: {
          'Authorization': user.jwt,
        }}).then( 
        function (res) {
          if(res.status === 200) {

            setTestScenario(res.data.sc);
            setTestBed(res.data.tb);
            setTestSuites(res.data.tsarr);
            setTestTemplates(res.data.tparr);
          
          }else {
            setStatus({
              type: 'error',
              message: 'Error in displaying data',
            });  
          }
        });
      }catch(err) {
        setStatus({
          type: 'error',
          message: err.message,
        });  
        handleErrorResponse(err);
      }
      
    } else {
      setStatus({
        type: 'error',
        message: "User not logged in",
      });  
    }
  }
  
  let tindex = 1;
  if(user) {
    return(
      <div className="medium-text">
        <div className="center">
          <h3><p align="center">{`Scenario [${testScenario.name}]`}</p></h3>
          
          <table className="tealtbl" style={{width: "100%"}}>
          
            <thead>
              <tr>
                <th>
                  Serial
                </th>
                
                <th>
                  Type
                </th>
                
                <th>
                  Name
                </th>
              </tr>
            </thead> 
               
            <tbody>
              <tr>
              
                <td>
                  {tindex++}
                </td>
              
                <td>
                  Scenario
                </td>
                
                <td>
                  <a href={`/components/view/the/scenario?id=${testScenario.id}`}>{testScenario.name}</a>
                </td>
              </tr>
              <tr>
                <td>
                  {tindex++}
                </td>
              
                <td>
                  Test Bed
                </td>
                
                <td>
                  <a href={`/components/editsingletestbeds?id=${testBed.id}`}>{testBed.name}</a>
                </td>              
              </tr>
              
              {
                testSuites.map((val, tsIndex) => 
                  <tr key={`ts-${tsIndex}`}>
                    <td>
                      {tindex++}
                    </td>
                  
                    <td>
                      Test Suite
                    </td>
                    
                    <td>
                      <a href={`/components/editsingletestsuites?id=${val.id}`}>{val.name}</a>
                    </td>              
                  </tr>
                )
              }

              {
                testTemplates.map((val, tpIndex) => 
                  <tr key={`tp-${tpIndex}`}>
                    <td>
                      {tindex++}
                    </td>
                  
                    <td>
                      Template
                    </td>
                    
                    <td>
                      <a href={`/components/editsingletemplates?id=${val.id}`}>{val.name}</a>
                    </td>              
                  </tr>
                )
              }
              
            </tbody>
          </table>
        </div>
        <br/>
        <div className="inlinecenter small-text">
          {status && <div className={`alert ${status.type}`}>{status.message}</div>}
        </div>
      </div>
    );
  } 
}

const ViewScenarios = () => {
  const [state, setState] = useState([{id: 0, name: "", checked: false}]);
  const [status, setStatus] = useState(null);
  const [selectAllBtnText, setSelectAllBtnText] = useState("Select All");
  
  const user = JSON.parse(localStorage.getItem('user'));
  setPageTitle('Scenarios');
  const fetchScenarios = async () => {
    setStatus(null);
    if(user != null) {
      try {
        const res = await axios.post('/api/fetch/scenarios/nameid', 
        {uid: user.uid, email: user.email} ,
        {headers: {
          'Authorization': user.jwt,
        }}).then( 
        function (res) {
          if(res.status === 200) {
            //This also works, keeping it as reference
            //const newState = res.data.map(obj => ({...obj, checked: false}));
            //console.log(res.data);
            res.data.forEach(element => (element.checked = false));

            setState(res.data);
          }else {
            setStatus({
              type: 'error',
              message: 'Error in displaying data',
            });  
          }
        });
      }catch(err) {
        setStatus({
          type: 'error',
          message: err.message,
        });  
        handleErrorResponse(err);
      }
    } else {
      setStatus({
        type: 'error',
        message: "User not logged in",
      });  
    }
  }

  useEffect ( () => {
   fetchScenarios();
  }, []);
  
  function scRowCBClicked(tindex) {
    const newState = [...state];
    newState[tindex].checked = !(state[tindex].checked);
    setState(newState);
  }

  const handleScenariosDelete= async (e)=> {
    e.preventDefault();
      
    if(state.length > 0) {

      let delIds = [];
      let newState = [];
      
      state.forEach(function (element) {
        if(element.checked) {
          delIds.push(element.id);
        } else {
          newState.push(element);
        }
      });
      
      if(delIds.length > 0) {
        if (window.confirm("Press OK to delete " + delIds.length  + " selected scenario(s)")) {
          try {
            const res = await axios.post('/api/scenarios/data/delete', 
            {uid: user.uid, email: user.email, data: JSON.stringify(delIds) } ,
            {headers: {
              'Authorization': user.jwt,
            }}).then( 
            function (res) {
              if(res.status === 200) {
                setState(newState);
              }else {
                setStatus({
                  type: 'error',
                  message: 'Error in displaying data',
                });  
              }
            });
          }catch(err) {
            setStatus({
              type: 'error',
              message: err.message,
            });  
            handleErrorResponse(err);
          } 
        }
      }
    }
  }
  
  function makeScenarioRowChecked(obj) {
    obj.checked = true;
    return obj;
  }

  function makeScenarioRowUnChecked(obj) {
    obj.checked = false;
    return obj;
  }
  
  const handleEditScenarioSelectAll=(e)=> {
    e.preventDefault();
    
    if(state.length > 0) {
      if(e.target.innerText === "Select All") {
        setSelectAllBtnText("Deselect All");
        const newState = state.map(makeScenarioRowChecked);
        setState(newState);
      } else {
        setSelectAllBtnText("Select All");
        const newState = state.map(makeScenarioRowUnChecked);
        setState(newState);
      }
    } 
  }
  
  if(user === null) {
    window.location.href = "/authrequired";
  } else {
    
    let tindex = 0;
    return (
      <div className="medium-text">
        <div className="center">
          <h3><p align="center">Scenarios</p></h3>
          
          <table className="tealtbl">
          
            <thead>
              <tr>
                <th>Serial</th>
                <th>scenario</th>
                <th>Select</th>
              </tr>
            </thead>                
            <tbody>
              {state.map((item, index) => (
                <tr key={`row${index}`}>
                  <td>{(++tindex)}</td>
                  <td><a href={`/components/view/the/scenario?id=${item.id}`}>{item.name}</a></td>
                  <td>
                    <input type="checkbox" id={`chk{index}`} 
                      onChange={()=>{scRowCBClicked(index)}} 
                      checked={item.checked} 
                    />
                  
                  </td>
                </tr>
              ))}
            </tbody>
          </table> 
        </div>

        <br/>
        <div className="inlinecenter small-text">
        {status && <div className={`alert ${status.type}`}>{status.message}</div>}
          <button onClick={handleScenariosDelete}>Delete</button>
          <button onClick={handleEditScenarioSelectAll}>{selectAllBtnText}</button>
        </div>

      </div>
    );
  }
  
}

export {Scenarios, ViewScenarios, ViewTheScenario};
