import react, {useState, useEffect} from 'react';
import axios from 'axios';
const lm = require("./lm.js")


const Profile = () => {
    lm.setPageTitle('Profile');
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [skype, setSkype] = useState(null);
    const [mobile, setMobile] = useState(null);
    const [dob, setDob] = useState(null);
    const [created, setCreated] = useState(null);
    const [gender, setGender] = useState(null);
    const [role, setRole] = useState(null);
    const [blocked, setBlocked] = useState(false);

    const fetchProfile = async () => {
       var user = null; 
       const userData = localStorage.getItem('user');
       
       if(userData) {
           user = JSON.parse(userData);
       
           try {
               await axios.post('/api/profile', {uid: user.uid, email: user.email}, {
                   headers: {
                     'Authorization': user.jwt,
                   }
               }).then(function (res) {
                 
                  let obj = JSON.parse(res.data);
                  setFirstName(obj.firstname);
                  setLastName(obj.lastname);
                  setMobile(obj.mobile);
                  setEmail(obj.email);
                  setSkype(obj.skype);
                  setDob(new Date(obj.dob).toLocaleDateString());
                  setCreated(new Date(obj.created).toLocaleDateString());
                  setGender(lm.getGender(obj.gender));
                  setBlocked(obj.blocked);
                  setRole(obj.role);
                  
                })
           }catch(err) {
             lm.handleErrorResponse(err);
           }
       }else {
           console.log("User not logged in");
       }
    }

    useEffect ( () => {
       fetchProfile();
    }, []);
    
    return (
        <div className="medium-text">
          <div className="center">
              <h2>User profile</h2>
              <table id="tbl">
                  <tbody>
                      <tr>
                        <th>Fields</th>
                        <th>Values</th>
                      </tr>                        
                      {firstName && <tr><td>First name</td><td>{firstName}</td></tr>}
                      {lastName && <tr><td>Last name</td><td>{lastName}</td></tr>}
                      {email && <tr><td>Email Id</td><td>{email}</td></tr>}
                      {mobile && <tr><td>Mobile</td><td>{mobile}</td></tr>}
                      {skype && <tr><td>Skype</td><td>{skype}</td></tr>}
                      {dob && <tr><td>Date of birth</td><td>{dob}</td></tr>}
                      {created && <tr><td>Created on</td><td>{created}</td></tr>}
                      {gender && <tr><td>Gender</td><td>{gender}</td></tr>}
                      {role && <tr><td>Role</td><td>{role}</td></tr>}
                      {<tr><td>Status</td><td>{blocked ? "Blocked" : "Active"}</td></tr>}
                      
                      {email && <tr><td>Password</td><td><a href="/user/changepasswd">Change password</a></td></tr>}
                      
                  </tbody>
              </table>
          </div>
        </div>
    );
}

export default Profile;
