import React, {useState, useEffect, useRef} from 'react';
import { useBeforeUnload } from "react-router-dom";
import { Link } from 'react-router-dom';
import axios from 'axios';
import {setPageTitle, getURLQueryVariable, getTimeDiff, getISOFormatedLocaleDate, handleErrorResponse, testResults, formatBytes, isNumeric} from '../../routes/lm.js';
import download from 'downloadjs';

let mindex = 1;
const TEST_STATS_REFRESH_TIME = 7000;

function getTblBodyKey(uniqid) {
  let key = uniqid + mindex; 
  mindex++;
  return key;
}

const TestStatsDetails = () => {

  setPageTitle("Detail test stats");
  
  const [status, setStatus] = useState(null);
  const [result, setResult] = useState([
    { tid: 0, tcname: "none", start: 0, end: 0, 
      time: 0, crps: 0, trps: 0, rrps: 0, pass: 0, 
      fail: 0, timeout: 0, ip: 0, pp: 0, total: 0  
    }
  ]);
  
  const user = JSON.parse(localStorage.getItem('user'));

  //Hook to get data from backend server
  useEffect ( () => {
     fetchTestStatsDetail();
  }, []);  

  const fetchTestStatsDetail = async () => {
    setStatus(null);
    if(user != null) {
      try {
        let tid = getURLQueryVariable("tid");
        const res = await axios.post('/api/test/detail/stats/data', 
        {uid: user.uid, email: user.email, tid: tid} ,
        {headers: {
          'Authorization': user.jwt,
        }}).then( 
        function (res) {
          if(res.status === 200) {
            
            const newResult = [];
            let index, index1, index2;
            let arr1 = res.data.data.split('\n');
            
            index = 0;
            for(index1 = 0; index1 <  arr1.length; index1++) {
              
              let arr2 = arr1[index1].split('!');
              if(arr2[0] == 'undefined') {}
              else if (arr2[0] === null) {}
              else if(arr2[0].length === 0) {}
              else {
                let total = parseInt(arr2[3]) + parseInt(arr2[4]) + parseInt(arr2[5]) + parseInt(arr2[6]) + parseInt(arr2[7]);
                let trps = parseInt(arr2[9]);
                let rps = Math.round(total / ((parseInt(arr2[11])) / 1000));
                
                if(rps > trps) {
                  rps = trps;
                }
                
                let repeat = ''; 
                let repeatCnt = parseInt(arr2[10]);
                if(repeatCnt > 1) {
                  repeat = `(${repeatCnt})`;
                }
                
                let obj = {
                  tid: tid, 
                  tcname: (arr2[0] + repeat),
                  start: new Date(parseInt(arr2[1])).toString().substring(4,25) ,
                  end: new Date(parseInt(arr2[2])).toString().substring(4,25), 
                  time: getTimeDiff(parseInt(arr2[2]), parseInt(arr2[1])),
                  crps: parseInt(arr2[8]), 
                  trps: parseInt(arr2[9]), 
                  rrps: rps, 
                  pass: parseInt(arr2[3]), 
                  fail: parseInt(arr2[4]), 
                  timeout: parseInt(arr2[5]), 
                  ip: parseInt(arr2[7]), 
                  pp: parseInt(arr2[6]), 
                  total: total
                };
                
                newResult[index] = obj;
                index++;
              }
            }

            setResult(newResult);
          }else {
            setStatus({
              type: 'error',
              message: 'Error in displaying data',
            });  
          }
        });
      }catch(err) {
        setStatus({
          type: 'error',
          message: err.message,
        }); 
        handleErrorResponse(err);
      }
    } else {
      setStatus({
        type: 'error',
        message: "User not logged in",
      });  
    }
  }
  
  if(user) {
    
    return (
      <div className="small-text">
         <div className="statstbl">
          <h3><p align="center">Test Stats</p></h3>
          
          <table style={{ tableLayout: 'fixed', width: '100%' }}>
            
            <thead>
              
              <tr key={"head"+mindex}>
                <th style={{width: "5%"}}>
                  Sl
                </th>
                
                <th style={{width: "15%"}}>
                  Test Case
                </th>
                
                <th style={{width: "22%"}}>
                  Start time<br/>
                  End time<br/>
                  Diff. time<br/>
                </th>
                
                <th style={{width: "10%"}}>
                  CRPS<br/>
                  TRPS<br/>
                  RRPS
                </th>

                <th style={{width: "15%"}}>
                  Pass<br/>
                  Fail<br/>
                  Timeout
                </th>

                <th style={{width: "15%"}}>
                  Inprogress<br/>
                  Partial pass
                </th>

                <th style={{width: "18%"}}>
                  Total
                </th>

              </tr>
              
            </thead>
            
            <tbody>
              {result.map((val,tindex) => (
                <tr key={"row" + tindex}>
                  <td>
                    {tindex+1}
                  </td>
                  
                  <td>
                  {
                    (val.fail > 0) ? 
                    (<div className={`red`}>{val.tcname}</div>) : 
                    (<div className={`normal`}>{val.tcname}</div>)
                  }
                  </td>
                  
                  <td>
                    {val.start}<br/>
                    {val.end}<br/>
                    {val.time}
                  </td>
                  
                  <td>
                    {val.crps}<br/>
                    {val.trps}<br/>
                    {val.rrps}
                  </td>
                                        
                  <td>
                    {val.pass}<br/>
                    {val.fail}<br/>
                    {val.timeout}
                  </td>
                     
                  <td>
                    {val.ip}<br/>
                    {val.pp}
                  </td>
                                        
                  <td>
                    {val.total}
                  </td>
                                        
                </tr>
              ))}
              
            </tbody>
          </table>
          
        </div>
        
        <br/>
        <div className="inlinecenter small-text">
          {status && <div className={`alert ${status.type}`}>{status.message}</div>}
        </div>
        
      </div>
    );
  }  
}

const TestStats = () => {
  
  const intervalIDRef = useRef(null);
  const [status, setStatus] = useState(null);
  const [result, setResult] = useState([
    { tid: 0, testname: "none", pass: 0, fail: 0, timeout: 0, partialpass: 0, starttime: 0, endtime: 0, inprogress: 0, execstate: 0, exec: "none", tbname: "",  tbid: 0, controller: ""}
  ]);
 
  setPageTitle("Test stats");
  
  const miscSummary = (index) => {
    let obj = result[index];
    return (
      <div className="medium-text">
        <table className="resulttbl">
          <tbody key={'miscblbd' + index}>
            <tr key={'op' + index}>
              <td>
                
                <a href={'#'}>
                  <span onClick={() => restartTheTest(obj.tid)}>Restart</span>
                </a>                
                
              </td>
              <td>
                <a href={'#'}>
                  <span onClick={() => stopTheTest(obj.tid)}>Stop</span>
                </a>
              </td>
            </tr>

            <tr key={'stat' + index}>
              <td>

                <a href={'#'}>
                  <span onClick={() => viewTheDetails(obj.tid)}>Deails</span>
                </a>                
                
              </td>
              <td>
                <a href={'#'}>
                  <span onClick={() => viewTheCurrent(obj.tid)}>Current</span>
                </a>
              </td>
            </tr>

            <tr key={'exec' + index}>
              <td>
                  <span onClick={() => viewTheErrors(obj.tid)}>Errors</span>
              </td>
              <td>

                <a href={'#'}>
                  <span onClick={() => viewTheLogs(obj.tid)}>Logs</span>
                </a>                
                
              </td>
            </tr>

            <tr key={'graph' + index}>
              <td>
                  <span onClick={() => viewTheCharts(obj.tid)}>Charts</span>
              </td>
              <td>
                  <span onClick={() => viewTheFiles(obj.tid)}>Files</span>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    );
  }

  const execSummary = (index) => {
    let obj = result[index];
    
    if(obj.execstate == 1) {
      return obj.exec.split('.');
    }
    
    return ['Completed'];
  }
  
  const resultSummary = (index) => {
    let obj = result[index];
    return (
      <div className="medium-text">
        <table className="resulttbl">
          <tbody key={'restblbd' + index}>
            <tr key={'pass' + index}>
              <td>
                Pass
              </td>
              <td>
                {obj.pass}
              </td>
            </tr>

            <tr key={'fail' + index}>
              <td>
                Fail
              </td>
              <td>
                {obj.fail}
              </td>
            </tr>

            <tr key={'timeout' + index}>
              <td>
                Timeout
              </td>
              <td>
                {obj.timeout}
              </td>
            </tr>

            <tr key={'pp' + index}>
              <td>
                Unattempt
              </td>
              <td>
                {obj.partialpass}
              </td>
            </tr>

            <tr key={'ip' + index}>
              <td>
                Inprogress
              </td>
              <td>
                {obj.inprogress}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
 
  const user = JSON.parse(localStorage.getItem('user'));
  
  const fetchTestStats = async () => {
    setStatus(null);
    if(user != null) {
      try {
        const res = await axios.post('/api/test/current/active/data', 
        {uid: user.uid, email: user.email} ,
        {headers: {
          'Authorization': user.jwt,
        }}).then( 
        function (res) {
          if(res.status === 200) {
            setResult(res.data);
          }else {
            setStatus({
              type: 'error',
              message: 'Error in displaying data',
            });  
          }
        });
      }catch(err) {
        clearInterval(intervalIDRef.current);
        setStatus({
          type: 'error',
          message: err.message,
        });  
        handleErrorResponse(err, false);
      }
    } else {
      setStatus({
        type: 'error',
        message: "User not logged in",
      });  
    }
  }

  //Hook to get data from backend server
  function refreshPageInterval(interval) {
    intervalIDRef.current = setInterval(() => {
     window.location.reload()
    }, interval);
  }

  useEffect ( () => {
    fetchTestStats();
    refreshPageInterval(TEST_STATS_REFRESH_TIME);
    return () => clearInterval(intervalIDRef.current);
  }, []);
  
  function stopTheTest(tid) {
    clearInterval(intervalIDRef.current);
    if(window.confirm(`You are about to stop the test, press OK to confirm.`)) {
      window.location.href = `/exec/stop/test?tid=${tid}`;
    } else {
      /* Quickly refresh the page, hence 100 millisecond time is given */
      refreshPageInterval(100);
    }
  }

  function restartTheTest(tid) {
    if(window.confirm(`You are about to restart the test, press OK to confirm.`)) {
      window.location.href = `/exec/restart/test?tid=${tid}`;
    }
  }
  
  function viewTheDetails(tid) {
    window.location.href = `/components/view/test/stats/details?tid=${tid}`;
  }

  function viewTheCurrent(tid) {
    window.location.href = `/components/view/test/stats/current?tid=${tid}`;
  }

  function viewTheErrors(tid) {
    window.location.href = `/components/view/test/all/errors?tid=${tid}`;
  }

  function viewTheLogs(tid) {
    window.location.href = `/components/view/test/multiplier/logs?tid=${tid}`;
  }
  
  function viewTheCharts(tid) {
    window.location.href = `/components/charts/create?tid=${tid}`;
  }

  function viewTheFiles(tid) {
    window.location.href = `/components/view/test/multipliers/testsuites?args=op,access,tid,${tid}`;
  }
  
  if(user) {
    
    if(result.length == 1) {
      if(result[0].tid == 0) {
        return("No active tests found.");
      }
    }
        
    return (
      <div className="small-text">
         <div className="statstbl">
          <h3><p align="center">Test Stats</p></h3>
          
          <table key={`tbuppertbl` + mindex} style={{ tableLayout: 'fixed', width: '100%' }}>
            <tbody key={getTblBodyKey('summary')}>
              <tr key={"head"+mindex}>
                <th style={{width: "5%"}}>
                  Sl
                </th>
                
                <th style={{width: "22%"}}>
                  Test
                </th>
                
                <th style={{width: "31%"}}>
                  Execution
                </th>
                
                <th style={{width: "20%"}}>
                  Result
                </th>

                <th style={{width: "21%"}}>
                  Miscellaneous
                </th>
              </tr>
              
              {
                result.map((val,tindex)=>
                    <tr key={"data" + tindex}>
                      <td key={"ts" + tindex}>
                        {tindex+1}
                      </td>
                      
                      <td key={"name" + tindex}>
                        {<a href={"/components/editsingletestbeds?id=" + val.tbid}>{val.testname}</a>}
                      </td>
                      
                      <td key={"exec" + tindex}>
                        {
                          execSummary(tindex).map((eval2, eindex) => 
                            <>{eval2}<br/></>
                          )
                        }
                      </td>
                      
                      <td key={"summ" + tindex}>
                        {resultSummary(tindex)}
                      </td>
                      
                      <td key={"misc" + tindex}>
                        {miscSummary(tindex)}
                      </td>
                                            
                    </tr>
                    
                )
              }
              
            </tbody>
          </table>
          
        </div>
        
        <br/>
        <div className="inlinecenter small-text">
          {status && <div className={`alert ${status.type}`}>{status.message}</div>}
        </div>
        
      </div>
    );
  }  
}

const TestLogs = () => {
  setPageTitle("Logs");
  
  const [status, setStatus] = useState(null);
  const [testId, setTestId] = useState(0);
  const [multipliers, setMultipliers] = useState([{mid: 0, name: '', ipaddr: ''}]); 

  const user = JSON.parse(localStorage.getItem('user'));

  //Hook to get data from backend server
  useEffect ( () => {
     fetchTestLogs();
  }, []);  

  const fetchTestLogs = async () => {
    setStatus(null);
    if(user != null) {
      try {
        let tid = getURLQueryVariable("tid");
        setTestId(tid);
        const res = await axios.post('/api/test/multipliers/ids', 
        {uid: user.uid, email: user.email, tid: tid} ,
        {headers: {
          'Authorization': user.jwt,
        }}).then( 
        function (res) {
          if(res.status === 200) {
            setMultipliers(res.data);
          }else {
            setStatus({
              type: 'error',
              message: 'Error in displaying data',
            });  
          }
        });
      }catch(err) {
        setStatus({
          type: 'error',
          message: err.message,
        }); 
        handleErrorResponse(err);
      }
    } else {
      setStatus({
        type: 'error',
        message: "User not logged in",
      });  
    }
  }

  async function downloadLogFile(mid, mname, mipaddr) {
    try {
      const res = await fetch(
        '/api/test/multiplier/log/file', {
          method: 'post',
          headers: { 'Content-Type': 'application/json',  'Authorization': user.jwt,},
          body: JSON.stringify({
            uid: user.uid, 
            email: user.email, 
            tid: testId, 
            mid: mid,
          }),
        },
      );
      
      const blob = await res.blob();
      download(blob, mname + '-log.zip');

    } catch(err) {
      console.log(err);
    }
  }
  
  let tindex = 0;
  return (
    <div className="medium-text">
      <div className="center">
        <h3><p align="center">Logs</p></h3>
        <table className="tealtbl">
          <thead>
            <tr>
              <th>Serial</th>
              <th>Multiplier</th>
              <th>IP address</th>
              <th>Logs</th>
            </tr>
          </thead>                
          <tbody>
            {multipliers.map((item, index) => (
              <tr key={`row${index}`}>
                <td>{(++tindex)}</td>
                <td><a href={`/components/editsinglemultiplier?id=${item.mid}`}>{item.name}</a></td>
                <td>{item.ipaddr}</td>
                <td><a href="#"><span onClick={() => downloadLogFile(item.mid, item.name, item.ipaddr).finally(() => {}) }>{item.name}-log.zip</span></a></td>
              </tr>
            ))}
          </tbody>
        </table> 

      <br/>
      {status && <div className={`alert ${status.type}`}>{status.message}</div>}

      </div>

    </div>
  );
}

const TestMTS = () => {
  let op = "";
  let tid = -1;
  let mid = -1;
  let device = -1;
  let file = '';
  let endpoints = 0;
  
  let startIndex = 0;
  let rowsPerPage = 0;
  let indexesPerPage = 0;
  
  let argstext = getURLQueryVariable("args");
  let args = argstext.split(",");
  
  if(args.length >= 4) {
    if(args[0] === "op") {
      op = args[1];
      if(args[2] === "tid") {
        tid = Math.round(args[3]);
      }
    }
  }

  if(tid < 0) {
    return ("Invalid arguments");
  } else {
    if(op === "access") {

      return TestMTSAccess(tid);

    } else if(op === "view") {
      
      if(args[4] === "endpoints") {
        endpoints = Math.round(args[5]);
      }

      if(args[6] === "mid") {
        mid = Math.round(args[7]);
      }

      if(args[8] === "rows") {
        startIndex = Math.round(args[9]);
        rowsPerPage = Math.round(args[10]);
        indexesPerPage = Math.round(args[11]);
      }

      return TestMTSFilesView(tid, mid, endpoints, startIndex, indexesPerPage, rowsPerPage);

    } else if(op === "download") {

      if(args[4] === "device") {
        device = Math.round(args[5]);
      }

      if(args[6] === "file") {
        file = args[7];
      }

      if(args[8] === "mid") {
        mid = Math.round(args[9]);
      }
      
      return TestMTSFilesDownload(tid, mid, device, file);
    }
  }
  
}

const TestMTSFilesDownload = (tid,mid,device,file) => {
  const [status, setStatus] = useState(null);  
  setPageTitle("Download " + file);
  const user = JSON.parse(localStorage.getItem('user'));

  //Hook to get data from backend server
  useEffect ( () => {
     downloadMediaFile(tid,mid,device,file)
  }, []);  
  
  const downloadMediaFile = async (tid,mid,device,file) => {
    setStatus(null);
    
    if(user) {
      try {
        
        const res = await fetch('/api/download/binary/file/', {
          
          method: 'post',
          headers: {
            'Content-Type':'application/json',
            'Authorization': user.jwt,
          },
            
          body: JSON.stringify({
            "uid": user.uid,
            "email": user.email,
            "tid": tid,
            "mid": mid,
            "device": device,
            "file": file
            }),
          } 
        );
        
        if(res.status === 200) {
          
          let newFile = '';
          let tarr = file.split('.');
          let extension = tarr.pop();
          let absfile = file.substring(0, (file.length - extension.length -1)); //-1 for .
          
          tarr = absfile.split('-');
          let token = tarr.pop(); 
          
          if(extension === "mlt") {
            if(token === "vp8") {
              newFile = absfile + ".webm";
            } else if(token === "h264") {
              newFile = absfile + ".mp4";
            }
          }
          
          file = ((newFile === '') ? file : newFile) + '.zip';
          const blob = await res.blob();
          download(blob, file);

          setStatus({
            type: 'success',
            message: `File ${file} downloaded successfully`,
          }); 
          
        } else {

          setStatus({
            type: 'error',
            message: 'File download failed',
          }); 
          
        } 
       
      } catch (err) {

        setStatus({
          type: 'error',
          message: 'File download error',
        }); 
        
      }    
    }
    
  }
  
  return (
    <div className="inlinecenter">
      Downloading file <b>{file}</b>. <br/>Please wait ...
      {
        status && <div className={`alert ${status.type}`}>{status.message}</div>
      }
    </div>
  );
}

const TestMTSFilesView = (tid, mid, endpoints, startIndex, indexesPerPage, rowsPerPage) => {
  const [status, setStatus] = useState(null);
  
  const [pagingInfo, setPagingInfo] = useState({baseid: 0, offset: 0, endpoints: endpoints}); 
  const [pagingStartIndexes, setPagingStartIndexes] = useState([0, 10]); 
  
  const [outputFiles, setOutputFiles] = useState([
    {devicenum: -1, name: '', size: 0, date: ''},
  ]);
  
  setPageTitle("Output files");

  const user = JSON.parse(localStorage.getItem('user'));

  //Hook to get data from backend server
  useEffect ( () => {
     fetchTestMTSFiles();
  }, []);  

  const fetchTestMTSFiles = async () => {
    setStatus(null);
    if(user != null) {
      try {
        const res = await axios.post('/api/test/mts/files', 
        {
          uid: user.uid, email: user.email, tid: tid, endpoints: endpoints, 
          mid: mid, rowstart: startIndex, rowcount: rowsPerPage
        },
        
        {headers: {
          'Authorization': user.jwt,
        }}).then( 
        function (res) {
          if(res.status === 200) {
            let data = res.data;
            let newPagingInfo = {baseid: res.data.baseid, offset: res.data.offset, endpoints: endpoints};
            setPagingInfo(newPagingInfo);
            setOutputFiles(data.arr);
            
            //Now populate the pagingStartIndexes array
            let newIndexes = [{index: "Start", linkindex: 0},];

            let myLinkIndex = (startIndex / rowsPerPage) / res.data.offset;
            let indexDiff = myLinkIndex - (indexesPerPage/2);
            
            if(indexDiff < 0) {
              myLinkIndex = 0;
            }
            else {
              myLinkIndex = indexDiff + 1;
            }
            
            let myIndexesPerPage = indexesPerPage + myLinkIndex;
            let index = 0;
            
            for(index=myLinkIndex; index < myIndexesPerPage; index++) {
              let newIndex = (index * rowsPerPage);
              let linkIndex = res.data.offset * newIndex;
              if(linkIndex > endpoints) {
                break;
              }
              newIndexes.push({index: index, linkindex: linkIndex});
            }

            //Last link
            newIndexes.push({index: " Last", linkindex: (endpoints - (endpoints % res.data.offset))});
                        
            setPagingStartIndexes(newIndexes);
            
          }else {
            setStatus({
              type: 'error',
              message: 'Error in displaying data',
            });  
          }
        });
      }catch(err) {
        setStatus({
          type: 'error',
          message: err.message,
        }); 
        handleErrorResponse(err);
      }
    } else {
      setStatus({
        type: 'error',
        message: "User not logged in",
      });  
    }
  }

  let tindex = 0;
  
  return (
    <div className="medium-text">
      <div className="center">
        <h3><p align="center">Output files</p></h3>
        
        <table className="tealtbl">
          <thead>
            <tr>
              <th>Serial</th>
              <th>Device</th>
              <th>Files</th>
              <th>Size</th>
              <th>Date</th>
            </tr>
          </thead>
          
          <tbody>
            {outputFiles.map((item, index) => (
              <tr key={`row${index}`}>
                <td>{(++tindex)}</td>
                <td>{item.devicenum}</td>
                <td><a href={`/components/view/test/multipliers/testsuites?args=op,download,tid,${tid},device,${item.devicenum},file,${item.name},mid,${mid}`}>{item.name}</a></td>
                <td>{formatBytes(item.size)}</td>
                <td>{item.date}</td>
              </tr>
            ))}
          </tbody>
          
        </table> 
      </div>

      <div className="page-index">
      {
        pagingStartIndexes.map((item, index) => (
            <a key={`lnk${index}`} href={`/components/view/test/multipliers/testsuites?args=op,view,tid,${tid},endpoints,${endpoints},mid,${mid},rows,${item.linkindex},${indexesPerPage},${rowsPerPage}`}> { isNumeric(item.index) ? (item.index + 1) : item.index} &nbsp;&nbsp;&nbsp; </a>
        ))
        
      }
      </div>

      <div className="inlinecenter small-text">
      {
        status && <div className={`alert ${status.type}`}>{status.message}</div>
      }
      </div>
    </div>
  );
}  

const TestMTSAccess = (tid) => {
  
  setPageTitle("Test Multipliers / Test Suites");
  
  const [status, setStatus] = useState(null);
  const [mts, setMts] = useState([
    {endpoints: 0, mid: 0, mname: "", tsid: 0, tsname: ""},
  ]);
  
  const user = JSON.parse(localStorage.getItem('user'));

  //Hook to get data from backend server
  useEffect ( () => {
     fetchTestMTSAccess();
  }, []);  

  const fetchTestMTSAccess = async () => {
    setStatus(null);
    if(user != null) {
      try {
        const res = await axios.post('/api/test/multipliers/testsuites', 
        {uid: user.uid, email: user.email, tid: tid} ,
        {headers: {
          'Authorization': user.jwt,
        }}).then( 
        function (res) {
          if(res.status === 200) {
            let data = res.data;

            console.log('Rcvd 200 OK');
            console.log(data);
    
            //let newMts = [...data];
            setMts(data);
          }else {
            setStatus({
              type: 'error',
              message: 'Error in displaying data',
            });  
          }
        });
      }catch(err) {
        setStatus({
          type: 'error',
          message: err.message,
        }); 
        handleErrorResponse(err);
      }
    } else {
      setStatus({
        type: 'error',
        message: "User not logged in",
      });  
    }
  }

  let tindex = 0;
  
  return (
    <div className="medium-text">
      <div className="center">
        <h3><p align="center">Output files</p></h3>
        
        <table className="tealtbl">
          <thead>
            <tr>
              <th>Serial</th>
              <th>Multiplier</th>
              <th>Test Suite</th>
              <th>Endpoints</th>
              <th>Files</th>
            </tr>
          </thead>
          
          <tbody>
            {mts.map((item, index) => (
              <tr key={`row${index}`}>
                <td>{(++tindex)}</td>
                <td><a href={`/components/editsinglemultiplier?id=${item.mid}`}>{item.mname}</a></td>
                <td><a href={`/components/editsingletestsuites?id=${item.tsid}`}>{item.tsname}</a></td>
                <td>{item.endpoints}</td>
                <td><a href={`/components/view/test/multipliers/testsuites?args=op,view,tid,${tid},endpoints,${item.endpoints},mid,${item.mid},rows,0,10,10`}>Files</a></td>
              </tr>
            ))}
          </tbody>
          
        </table> 
      </div>

      <div className="inlinecenter small-text">
        {status && 
          <div className={`alert ${status.type}`}>{status.message}</div>
        }
      </div>
    </div>
  );
}

  
const TestAllErrors = () => {

  setPageTitle("All errors");
  
  const [status, setStatus] = useState(null);
  const [errors, setErrors] = useState([
    { tc: '', ep: 0, result: '', time: 0, error: '' },
  ]);
  
  const user = JSON.parse(localStorage.getItem('user'));

  //Hook to get data from backend server
  useEffect ( () => {
     fetchTestAllErrors();
  }, []);  

  const fetchTestAllErrors = async () => {
    setStatus(null);
    if(user != null) {
      try {
        let tid = getURLQueryVariable("tid");
        const res = await axios.post('/api/test/detail/all/errors', 
        {uid: user.uid, email: user.email, tid: tid} ,
        {headers: {
          'Authorization': user.jwt,
        }}).then( 
        function (res) {
          if(res.status === 200) {
            let data = res.data.data;
            let tcResults = testResults();
            
            let count = 0;
            const newErrors = [];
            
            for(let index=0; index < data.length; index++) {
              let fileObj = data[index];
              let arr1 = fileObj.data.split('\n');
              
              for(let index1=0; index1 < arr1.length; index1+=2) {
                if(arr1[index1].trim() !== "") {
                  let arr2 = arr1[index1].split(':');
                  let arr3 = arr1[index1+1].split(':');
                  let obj = {
                    tc: arr2[1] + " >> " + arr2[2] + " >> " + arr2[3],
                    ep: arr3[1],
                    result: tcResults[Math.round(arr3[0])],
                    time: new Date(Math.round(arr2[0])).toLocaleString() ,
                    error: arr3[2],
                  };
                
                  newErrors[count++] = obj;
                }
              }
            }
            
            setErrors(newErrors);

          }else {
            setStatus({
              type: 'error',
              message: 'Error in displaying data',
            });  
          }
        });
      }catch(err) {
        setStatus({
          type: 'error',
          message: err.message,
        }); 
        handleErrorResponse(err);
      }
    } else {
      setStatus({
        type: 'error',
        message: "User not logged in",
      });  
    }
  }
  
  
  
  if(user) {

    return (
      <div className="small-text">
         <div className="statstbl">
          <h3><p align="center">All Errors</p></h3>
          
          <table key={`tbuppertbl` + mindex} style={{ tableLayout: 'fixed', width: '100%' }}>
            <thead>

              <tr key={"head"+mindex}>
                <th style={{width: "5%"}}>
                  Sl
                </th>
                
                <th style={{width: "25%"}}>
                  Test Case
                </th>
                
                <th style={{width: "10%"}}>
                  Endpoint
                </th>
                
                <th style={{width: "10%"}}>
                  Result
                </th>

                <th style={{width: "15%"}}>
                  Time
                </th>

                <th style={{width: "35%"}}>
                  Error
                </th>

              </tr>
            </thead>
            
            <tbody>
              {errors.map((val,tindex)=> (
                  <tr key={"rows" + tindex}>
                    <td>
                      {tindex+1}
                    </td>
                    
                    <td>
                      {val.tc}
                    </td>

                    <td>
                      {val.ep}
                    </td>
                    
                    <td>
                      {val.result}
                    </td>
                    
                    <td>
                      {val.time}
                    </td>
                    
                    <td>
                      {val.error}
                    </td>

                  </tr>
              ))
            }
            </tbody>
            
          </table>
        </div>
        
        <br/>
        <div className="inlinecenter small-text">
          {status && <div className={`alert ${status.type}`}>{status.message}</div>}
        </div>
        
      </div>
    );
  }  
}

const SampleTestStats = () => {

  let SAMPLE_TEST_STATS_REFRESH_TIME = 3000;
  const [result, setResult] = useState([{ tid: 0, tcname: "none"},]);
  const intervalIDRef = React.useRef(null);

  const user = JSON.parse(localStorage.getItem('user'));
    
  const fetchTestStats = async () => {
    try {
      const res = await axios.post('/api/test/current/active/data', 
      {uid: user.uid, email: user.email} ,
      {headers: {
        'Authorization': user.jwt,
      }}).then( 
      function (res) {
        if(res.status === 200) {
          let newResult = {tid: res.data[0].tid, tcname: res.data[0].testname};
          setResult([newResult]);
        }
      });
    }catch(err) {
    }
  }

  useEffect ( () => {
    fetchTestStats();
    refreshPageInterval();
    return () => clearInterval(intervalIDRef.current);
  }, []); 
  
  function refreshPageInterval() {
    intervalIDRef.current =  setInterval(() => {
     window.location.reload()
    }, SAMPLE_TEST_STATS_REFRESH_TIME);
  }

  function stopTest(tid) {
    clearInterval(intervalIDRef.current);
    if(window.confirm(`You are about to stop the test, press OK to confirm.`)) {
      window.location.href = '/components/testbeds';
    } else {
      refreshPageInterval();
    }
  }
  
  return (
    <div className="center"> My simple refresh test page, test id = {result[0].tid} 
     and test name = ${result[0].tcname}.<br/> 
    Stop the test <a href={'#'}>
    <span onClick={() => stopTest(result[0].tid)}>here</span>
    </a> now.</div> 
  );
}

const TestHistory = () => {
  const [status, setStatus] = useState(null);
  
  const [result, setResult] = useState([{ 
    tid: 0, tbid: 0, testname: '', tbname: '', cid: 0, controller: '', 
    starttime: 0, endtime: 0, pass: 0, fail: 0, timeout: 0, partialpass: 0, 
    inprogress: 0},
  ]);
  
  const user = JSON.parse(localStorage.getItem('user'));
  
  setPageTitle("Test history");
  const fetchTestHistory = async () => {
    try {
      const res = await axios.post('/api/test/detail/history/data', 
      {uid: user.uid, email: user.email} ,
      {headers: {
        'Authorization': user.jwt,
      }}).then( 
      function (res) {
        if(res.status === 200) {
          setResult(res.data);
        }
      });
    }catch(err) {
    }
  }

  useEffect ( () => {
    fetchTestHistory();
  }, []); 
  

  function getTbTestName(val) {
    if(val.tbname === val.testname) {
      return <a href={'/components/editsingletestbeds?id=' + val.tbid}>{val.tbname}</a>;
    } 
    
    return (<><a href={'/components/editsingletestbeds?id=' + val.tbid}>{val.tbname}</a> <br/><br/>  {val.testname}</>);
  }

  function viewTheCharts(tid) {
    window.location.href = `/components/charts/create?tid=${tid}`;
  }
  
  return (
      <div className="small-text">
         <div className="statstbl">
          <h3><p align="center">Test History</p></h3>
          
          <table key={`history` + mindex} style={{ tableLayout: 'fixed', width: '100%' }}>
            <tbody key={getTblBodyKey('summary')}>
              <tr key={"head"+mindex}>
              
                <th style={{width: "5%"}}>
                  Sl
                </th>
                
                <th style={{width: "30%"}}>
                  Testbed<br/>
                  Testname
                </th>
                
                <th style={{width: "15%"}}>
                  Controller
                </th>
                
                <th style={{width: "28%"}}>
                  Start time<br/>
                  Refresh time
                </th>

                <th style={{width: "21%"}}>
                  Results
                </th>

                <th style={{width: "21%"}}>
                  Stats
                </th>
                
              </tr>
              
              {
                result.map((val,tindex)=>
                    <tr key={"data" + tindex}>
                      <td>
                        {tindex+1}
                      </td>
                      
                      <td>
                        {getTbTestName(val)}
                      </td>
                      
                      <td>
                        {val.controller}
                      </td>
                      
                      <td>
                        {new Date(parseInt(val.starttime)).toString().substring(4,25)}<br/>
                        {new Date(parseInt(val.endtime)).toString().substring(4,25)}
                      </td>
                      
                      <td>
                        Pass: {val.pass}<br/>
                        Fail: {val.fail}<br/>
                        Timeout: {val.timeout}<br/>
                        Partial: {val.partialpass}<br/>
                        Inprogress: {val.inprogress}
                      </td>

                      <td>
                      <a href={`/components/view/test/stats/details?tid=${val.tid}`}>Details</a><br/>
                      <a href={`/components/charts/create?tid=${val.tid}`}>Charts</a><br/>
                      <a href={`/components/view/test/multipliers/testsuites?args=op,access,tid,${val.tid}`}>Files</a><br/>
                      <a href={`/components/view/test/all/errors?tid=${val.tid}`}>All errors</a>
                      </td>
                                            
                    </tr>
                )
              }
              
            </tbody>
          </table>
          
        </div>
        
        <br/>
        <div className="inlinecenter small-text">
          {status && <div className={`alert ${status.type}`}>{status.message}</div>}
        </div>
        
      </div>
  );
}


const TestStatsCurrent = () => {

  setPageTitle("Current stats");
  
  const [status, setStatus] = useState(null);
  const [currStats, setCurrStats] = 
    useState([{mult: '', multstats: [{ tc: '', time: 0, repeat: '', pass: 0, fail: 0, timeout: 0, notry: 0, inprogress: 0},]},]);

  /*
  const [currStats, setCurrStats] = useState(
    [
      {
        mult: 'm1', thrd: 0, multstats: 
          [
            { tc: 'tc1', time: 1717895103114, repeat: '4/1', pass: 100, fail: 10, timeout: 20, notry: 30, inprogress: 40},
            { tc: 'tc2', time: 1717895104114, repeat: '4/2', pass: 110, fail: 11, timeout: 21, notry: 31, inprogress: 41},
            { tc: 'tc3', time: 1717895105114, repeat: '4/3', pass: 120, fail: 12, timeout: 22, notry: 32, inprogress: 42},
          ]
      },
      
      {
        mult: 'm2', thrd: 0, multstats: 
          [
            { tc: 'tc1', time: 1717895103114, repeat: '4/1', pass: 100, fail: 10, timeout: 20, notry: 30, inprogress: 40},
            { tc: 'tc2', time: 1717895104114, repeat: '4/2', pass: 110, fail: 11, timeout: 21, notry: 31, inprogress: 41},
            { tc: 'tc3', time: 1717895105114, repeat: '4/3', pass: 120, fail: 12, timeout: 22, notry: 32, inprogress: 42},
          ]
      },

      {
        mult: 'm2', thrd: 1, multstats: 
          [
            { tc: 'tc1', time: 1717895103114, repeat: '4/1', pass: 100, fail: 10, timeout: 20, notry: 30, inprogress: 40},
            { tc: 'tc2', time: 1717895104114, repeat: '4/2', pass: 110, fail: 11, timeout: 21, notry: 31, inprogress: 41},
            { tc: 'tc3', time: 1717895105114, repeat: '4/3', pass: 120, fail: 12, timeout: 22, notry: 32, inprogress: 42},
          ]
      },
      
      {
        mult: 'm3', thrd: 0, multstats: 
          [
            { tc: 'tc1', time: 1717895103114, repeat: '4/1', pass: 100, fail: 10, timeout: 20, notry: 30, inprogress: 40},
            { tc: 'tc2', time: 1717895104114, repeat: '4/2', pass: 110, fail: 11, timeout: 21, notry: 31, inprogress: 41},
            { tc: 'tc3', time: 1717895105114, repeat: '4/3', pass: 120, fail: 12, timeout: 22, notry: 32, inprogress: 42},
          ]
      },

      {
        mult: 'm4', thrd: 0, multstats: 
          [
            { tc: 'tc1', time: 1717895103114, repeat: '4/1', pass: 100, fail: 10, timeout: 20, notry: 30, inprogress: 40},
            { tc: 'tc2', time: 1717895104114, repeat: '4/2', pass: 110, fail: 11, timeout: 21, notry: 31, inprogress: 41},
            { tc: 'tc3', time: 1717895105114, repeat: '4/3', pass: 120, fail: 12, timeout: 22, notry: 32, inprogress: 42},
          ]
      },
      
    ],
  );
  */
  
  const user = JSON.parse(localStorage.getItem('user'));

   //Hook to get data from backend server
  useEffect ( () => {
     fetchTestCurrStats();
  }, []);  

  const fetchTestCurrStats = async () => {
    setStatus(null);
    if(user != null) {
      try {
        let tid = getURLQueryVariable("tid");
        const res = await axios.post('/api/test/curr/stats/data', 
        {uid: user.uid, email: user.email, tid: tid} ,
        {headers: {
          'Authorization': user.jwt,
        }}).then( 
        function (res) {
          if(res.status === 200) {
            let data = res.data.data;
            let count = 0;
            let testStatsObj = null;
            let newCurrTestStats = [];
            
            for(let index=0; index < data.length; index++) {
              let fileObj = data[index];
              let arr1 = fileObj.data.split('\n');
              let mCurrStats = [];

              for(let index1=0; index1 < arr1.length; index1++) {
                if(arr1[index1].trim() !== "") {
                  let arr2 = arr1[index1].split(':');
                  let obj = {
                    tc: arr2[0],
                    time: new Date(Math.round(arr2[7])).toLocaleString(),
                    repeat: arr2[1],
                    pass: arr2[2],
                    fail: arr2[5],
                    timeout: arr2[6],
                    notry: arr2[4],
                    inprogress: arr2[3],
                  };
                
                  mCurrStats[index1] = obj;
                }
              }
              
              let arr3 = fileObj.filename.split('.');
              console.log(arr3);
              testStatsObj = {mult: arr3[0], thrd: Math.round(arr3[2]), multstats: mCurrStats};
              newCurrTestStats[count++] = testStatsObj;
            }
            setCurrStats(newCurrTestStats);

          }else {
            setStatus({
              type: 'error',
              message: 'Error in displaying data',
            });  
          }
        });
      }catch(err) {
        setStatus({
          type: 'error',
          message: err.message,
        }); 
        handleErrorResponse(err);
      }
    } else {
      setStatus({
        type: 'error',
        message: "User not logged in",
      });  
    }
  }
  
  let mindex=0;
  
  return(
    <div className="small-text">
      {currStats.map((val,tindex)=> 
        (
          <div key={mindex++} className="statstbl">
            <table key={`tcurr` + mindex} style={{ tableLayout: 'fixed', width: '100%' }}>
              {val.mult ? (<caption>{val.mult}/{(val.thrd + 1)}</caption>) : ''}
              <thead key={`thead` + mindex}>
                <tr key={`thdrow` + mindex}>
                  
                  <th style={{width: "5%"}}>
                    Sl
                  </th>

                  <th style={{width: "20%"}}>
                    Test Case
                  </th>

                  <th style={{width: "15%"}}>
                    Time
                  </th>

                  <th style={{width: "10%"}}>
                    Repeat
                  </th>

                  <th style={{width: "10%"}}>
                    Pass
                  </th>

                  <th style={{width: "10%"}}>
                    Fail
                  </th>

                  <th style={{width: "10%"}}>
                    Timeout
                  </th>

                  <th style={{width: "10%"}}>
                    Not tried
                  </th>

                  <th style={{width: "10%"}}>
                    Inprogress
                  </th>
                  
                </tr>
              </thead>
              
              <tbody key={`tbody` + mindex}>
                {val.multstats.map((mval,mtindex)=>
                  (
                    <tr key={`tbdrow` + mindex + ':' + tindex + ':' + mtindex}>
                      <td>
                        {mtindex+1}
                      </td>
                      
                      <td>
                        {mval.tc}
                      </td>
                      
                      <td>
                        {mval.time}
                      </td>
                      
                      <td>
                        {mval.repeat}
                      </td>

                      <td>
                        {mval.pass}
                      </td>
                      
                      <td>
                        {mval.fail}
                      </td>
                      
                      <td>
                        {mval.timeout}
                      </td>
                      
                      <td>
                        {mval.notry}
                      </td>
                      
                      <td>
                        {mval.inprogress}
                      </td>
                      
                    </tr>
                  )
                )}
              </tbody>
              
            </table>
          </div>
        )
      )}
      
    </div>
  );
}



export {TestStats, TestStatsDetails, TestAllErrors, SampleTestStats, TestHistory, TestStatsCurrent, TestLogs, TestMTS};