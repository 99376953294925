
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGHTTPDB2BAVariables = () => {

  setPageTitle('HTTPD B2BA Variables');
  
  const element = (
    <div className="userguide">
      <h1>HTTPD B2BA Variables</h1>
      <br/>
        

        Multiplier plugins are dependent on variables to large extent. There are 
        different kind of variables like normal variable, system variable, plugin 
        variable and relative variables. Here normal variables and plugin variables are discussed. 
        
        <br/>
        <h3>B2BA HTTPD Normal Variables</h3>
        The normal variables starts with <i>[</i>  and ends with <i>]</i>. 
        For example [count] is a normal variable, variable name is count. 
        Each variable has name, type and value. The string type variables has 
        additionally length as well. You can use normal variables to store data, 
        extract data and fetch data.

        <br/>
        <h3>B2BA HTTPD Plugin Variables</h3>
        B2BA plugin provides internal variables to user known as <i><b>plugin variables</b></i> 
        to enable to fetch plugin internal values. The plugin variables are written in a 
        special way, start with <i>[p-</i> and ends with <i>]</i>. For example <i>[p-random-string]</i>
        is a plugin variable that gives a random digit-only string.

        <ol>
        <br/><li><div className="code">[p-linkarg:index]</div>
        This returns URL link argument at a given position identified index as shown above.
        </li>
        
        <br/><li><div className="code">[p-content-type]</div>
        This returns content type depending on link. If link looks like image link,
        this returns corresponding content type. Otherwise it returns content type
        for text <i><b>text/html; charset=utf-8</b></i>.
        </li>

        <br/><li><div className="code">[p-upload-file:n]</div>
        This returns upload file name. If <b>n</b> is provided, then corresponding upload file name
        is returned. If <b>n</b> is not provided, by default it is considered 1.
        <br/><b>Examples</b>
        <ul>

        <li>[p-upload-file]
        </li>
        
        <li>[p-upload-file:2]
        </li>
        
        <li>[p-upload-file:10]
         </li>

       </ul>
       
        When a HTTP message is received in B2BA HTTPD, it may have 1 or 2 or n number of files.
        Using this plugin variable, one can get filename of corresponding file uploaded by 
        HTTP client / browser.
        </li>

        </ol>
        
        
      <br/>
      
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/apatb2bainstructions" }>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/httpdb2basyntax" }>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGHTTPDB2BAVariables}

