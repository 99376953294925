
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGTestbed = () => {

  setPageTitle('Testbed');
  
  const element = (
    <div className="userguide">
      <h1>LM Tools - Testbed</h1>
      <br/>
      Test bed associates all entities namely controller, multipliers, test suites, templates (through test suites) and 
      also sets certain test properties like log level, test suite repeat count, threads, endpoints etc. 
      Lets see how a testbed looks. Below image shows a fresh test bed.
      <br/><br/>
      The upper block represents testbed details and below block is shows  
      test suites and corresponding multipliers. 
      
      <br/>
      <br/><img className="" src={process.env.PUBLIC_URL + '/userguide/testbed-ui-header.png'} alt="Testbed header" width='100%'/>
      <br/><img className="" src={process.env.PUBLIC_URL + '/userguide/testbed-ui-body.png'} alt="Testbed body" width='100%'/>
      <br/>
      Lets explore the various configuration parameters in testbed.
      <ul>
        <li>
          <b>Name</b> - The unique name of testbed.
        </li>
        <li>
          <b>Controller</b> - Its the name of controller associated with testbed.
        </li>
        <li>
          <b>Log level</b> - Log level of controller configured in testbed.
        </li>
        <li>
          <b>Repeat</b> - The repeat count of testbed. If you want test to run continiously 
          set repeat to <i>infinite</i>.
        </li>
        <li>
          <b>Valgrind</b> - Keep it disabled. This is for developers who want to see memory 
          leaks of tool during test execution.
        </li>
      </ul>
      Now lets see the configuration parameters of multipliers.
      <ul>
        <li>
          <b>Test suite</b> - The test suite which will be executed during test execution.
        </li>
        <li>
          <b>Multiplier</b> - The Multiplier machine that actually executes the test suite selected here.
        </li>
        <li>
          <b>Threads</b> - Number of threads used by Multipler to execute the test. 
          Check <a href="/userguide/multiplier/threadsendpointsrps">here</a> to understand more on threads.
        </li>
        <li>
          <b>Endpoints</b> - Number of endpoints to be simulated for all threads, please note this is for all threads not per thread. 
          Check <a href="/userguide/multiplier/threadsendpointsrps">here</a> to understand more on endpoints.
        </li>
        <li>
          <b>State</b> - Select the state the enable/disable a particular test suite.
        </li>
        <li>
          <b>Log level</b> - Log level of multiplier configured here.
        </li>
        <li>
          <b>Valgrind</b> - Keep it disabled. This is for developers who want to see memory 
          leaks of tool during test execution.
        </li>
      </ul>
      <br/>
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/multiplier"}>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/testsuite"}>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGTestbed}

