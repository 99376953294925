
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';
import {ugc_invoke_url, ugc_invoke_headless, ugc_file_graphs_sip, ugc_file_graphs_media} from './ugcommon.js';

const UGFlexiSIPInstructions = () => {

  setPageTitle('FlexiSIP Instructions');
  
  const element = (
    <div className="userguide">
    <h1>FlexiSIP Instructions</h1>
    <br/>
        
    Instructions are means to instruct certain operations to be executed in multiplier test execution. Instructions can be in instruction blocks or in native APIs those starts with <b><i>execute-</i></b> key. Multiplier supports various types of instructions listed below. 
    <ul>
    <li>format instruction</li>
    <li>invoke instructions</li>
    <li>send instruction</li>
    <li>send-with-blength instruction</li>
    <li>send-data instruction</li>
    <li>receive instruction</li>
    <li>on instruction</li>
    <li>execute instruction</li>
    <li>rps instruction</li>
    </ul>

    <b>Note</b> - Expressions inside angular brackets are just place holders, need to be replace when writing actual instructions. 
    For example &lt;variable&gt; means it can be any variable in multiplier plugin.

    <h3>Format Instructions</h3>
    Format instruction is used when user wants to format a particular string. Sometimes you need to send some data out. You can do so by a simple instruction <i>send [t-template-name]</i>. However in real cases, you may need to modify string after formatting. One such case is setting <i>Content-length</i> properly after complete SIP message is prepared. This achieved by making use of <i>format</i> and <i>send-with-blength</i> Instructions.
    <ul>
    <li><b>format [t-invite-template] &lt;buffer&gt;</b>
    <div className="code">
    <b>Examples</b>
    <br/>format [t-invite-template] buffer
    <br/>format [t-ack-template] buffer1
    <br/>format [t-bye-template] buffer2
    </div>			
    Using above syntax you can format <i>invite-template</i> and save the resultant string in system with a key as <i>buffer</i>. Before this instruction is actually executed, invite-template must have been loaded to system (later see <i>invoke set-text-templates</i>). Since the formatted string is saved with key, later you can access this string using <i>[buffer]</i>.
    </li>
    </ul>

    <h3>Invoke Instructions</h3>
    Invoke type instructions invokes certain operations. There are quite a number of invoke instructions. Lets go through each of them in detail.
    <ul>
    <li><b>invoke set-session-id &lt;session-id&gt;</b>
    <br/>You can set active session using <i>invoke set-session-id</i> instruction. Using session-init native API test case, session are created. Each session is associated with session-id. Assuming if you have multiple sessions, and you want to change active session from session 1 to session 2 you can do so using this instruction.  
    <div className="code">
    <b>Examples</b>
    <br/>invoke set-session-id session-id-1
    <br/>invoke set-session-id session-id-2
    <br/>invoke set-session-id session-id-3
    </div>
    </li>
    
    <br/><li><b>invoke increment var &lt;value&gt; </b>
    <br/>You can increment a variable using above increment syntax. 
    You need to provide variable name and value to be incremented by. If you do not
    specify value, by default it will be incremented by 1.

    <div className="code">
    <b>Examples</b>
    <br/>invoke increment var1 [value1]
    <br/>invoke increment var2
    </div>

    <br/>Additionally it is used to increment local audio rtp sequence number, corresponding example given below..
    <div className="code">
    <b>Example</b>
    <br/>invoke increment local-audio-seq-num
    </div>
    </li>
    
    <br/><li><b>invoke log &lt;string&gt;</b>
    <br/>You can log certain data to a log file using this instruction. Just after <i>invoke log</i> you can put constant string, variable, plugin variable or function. During run time multiplier evaluates the entire expression and log it to corresponding log file. Possible examples are

    <div className="code">
    <b>Examples</b>
    <br/>invoke log This is LM Tools
    <br/>invoke log Value of variable string is [string]
    <br/>invoke log Function add(v1, v2) output is [f-add(int1, int2)]
    <br/>invoke log Value [p-random-number] is a digit only string, and [p-random-hex-number:20] is a hexadecimal string of 20 bytes
    </div>
    </li>
    
    <br/><li><b>invoke tcpdump &lt;absmdn1,absmdn2,absmdn3,...&gt; &lt;intf&gt; &lt;filename&gt; &lt;timeout&gt; &lt;port1,port2,port3,...&gt;</b>
    
    <br/>You can start tcpdump using this instruction. As typically you will be
    executing this instruction in an instruction block, so it is obvious that you 
    would not like to start multiple tcpdump captures. To avoid multiple captures,
    provide absolute mdn of endpoint(s) for those this instruction is executed. 
    
    <br/><br/>
    
    If you want to capture multiple tcpdumps for multiple endpoints you may like to use
    wildcard <b>*</b> in place of absolude mdn. In that case, this instruction will 
    be executed for all applicable endpoints.
    
    <br/><br/>
     
    Even if it is executed for one endpoint, it will capture all packets for all endpoints 
    in system. Also you need to provide interface (or <i>any</i> if you do not know the 
    interface), capture filename, time (in seconds). If you want to capture only
    for certain ports, you can provide ports as comma separaed ports as shown in below examples.
    
    <br/><br/>
    Using the port filters you can capture the packets per call. For example, do <i>media-init</i>,
    after start tcpdump and for port filter use corresponding media port plugin variable.<br/><br/> 
    
    
    <div className="code">
    <b>Examples</b>
    <br/>invoke tcpdump 100 any packets.pcap 60 5000
    <br/>invoke tcpdump 100,101 any packets.pcap [timeout] 5000,5010
    <br/>invoke tcpdump * any packets.pcap [timeout] 5000,5010
    <br/>invoke tcpdump * any packets.pcap [timeout]
    </div>
    </li>
    
    
    <br/><li><b>invoke script &lt;command&gt; &lt;arg1&gt; ... &lt;argn&gt;</b>
    <br/>You can start a script or command using this instruction. You need to provide script filename or just some command and required command line arguments.<br/>
    <div className="code">
    <b>Examples</b>
    <br/>invoke script /path/script/file
    <br/>invoke script command arg1 arg2 ... argn
    </div>
    </li>
    
    <br/><li><b>invoke set-csv-files &lt;csv-files-directory&gt;</b>
    <br/>Instruction <b>set-csv-files</b> is used to load csv files to memory. 
    When you want to feed certain data to tool, one of the option is to use set-csv-files instruction. 
    <div className="code">
    <b>Examples</b>
    <br/>invoke set-csv-files /usr/local/apat/multiplier/csvfiles/calling-party/
    <br/>invoke set-csv-files /usr/local/apat/multiplier/csvfiles/called-party/
    </div>
    </li>
    
    <br/><li><b>invoke set-text-templates &lt;text-template-files-directory&gt;</b>
    <br/><a href="/lmuserguide/' . LM_DOC_VERSION . '/common/test_case_type_text_template">Text templates</a> either you can put as a test case in test suite, or just write template files and load to memory using above instruction. If you have more templates you may like to write the template files and load it at run time, so that you can reduce number of test cases in test suite.

    <div className="code">
    <b>Examples</b>
    <br/>invoke set-text-templates /usr/local/apat/multiplier/device/templates/text/calling-party/
    <br/>invoke set-text-templates /usr/local/apat/multiplier/device/templates/text/called-party/
    </div>
    </li>
    
    <br/><li><b>invoke set-default-text-templates &lt;template-name&gt;</b>
    <br/>Starting from LM 4.0 version this instruction is supported. You can create text templates in browser interface just like test suite. Then use this instruction to load the text templates.
    <div className="code">
    <b>Examples</b>
    <br/>invoke set-default-text-templates sip-call-fsip-calling-party-video
    </div>
    </li>
    
    <br/><li><b>invoke set-binary-templates &lt;binary-template-files-directory&gt;</b>
    <br/><a href="/lmuserguide/' . LM_DOC_VERSION . '/common/test_case_type_binary_template">Binary templates</a> either you can put as a test case in test suite, or just write template files and load to memory using above instruction. If you have more templates you may like to write the template files and load it at run time, so that you can reduce number of test cases in test suite.

    <div className="code">
    <b>Examples</b>
    <br/>invoke set-binary-templates /usr/local/apat/multiplier/device/templates/binary/calling-party/
    <br/>invoke set-binary-templates /usr/local/apat/multiplier/device/templates/binary/called-party/
    </div>
    </li>
    
    <br/><li><b>invoke set-default-binary-templates &lt;template-name&gt;</b>
    <br/>Starting from LM 4.0 version this instruction is supported. You can create binary templates in browser interface just like test suite. Then use this instruction to load the binary templates.
    <div className="code">
    <b>Examples</b>
    <br/>invoke set-default-binary-templates sip-call-fsip-calling-party-video
    </div>
    </li>
    
    <br/><li><b>invoke create-server-connection &lt;ip&gt; &lt;port&gt; &lt;transport&gt; &lt;conn-name&gt;</b>
    <br/>Starting from LM 4.0 create-server-connection instruction is supported. You can create create the server connection at a specific port.
    <div className="code">
    <b>Examples</b>
    <br/>invoke create-server-connection 192.168.1.10 5000 tcp tcpconn1
    <br/>invoke create-server-connection 192.168.1.10 0 udp udpconn1
    </div>
    </li>
    
    <br/><li><b>invoke create-client-connection &lt;ip&gt; &lt;port&gt; &lt;transport&gt; &lt;conn-name&gt;</b>
    <br/>Starting from LM 4.0 create-client-connection instruction is supported. You can create create the client connection at a specific port. If you want to create client connection at a random port provide 0 for port in instruction.
    <div className="code">
    <b>Examples</b>
    <br/>invoke create-client-connection 192.168.1.10 5000 tcp
    <br/>invoke create-client-connection 192.168.1.10 0 udp
    </div>
    </li>
    
    <br/><li><b>invoke connect &lt;remote-ip&gt; &lt;remote-port&gt; &lt;transport&gt; timer &lt;timeout value&gt;</b>
    <br/>Starting from LM 4.0 connect instruction is supported. You can connect to a remote server using this instruction.
    <div className="code">
    <b>Examples</b>
    <br/>invoke connect 192.168.1.11 5060 tcp timer 60000
    <br/>invoke connect 192.168.1.11 5060 udp timer 60000
    </div>
    You may think, why need to do connect for UDP sockets, but from LM perspective it is required. Using this instruction user can set destination (ip, port) for UDP socket.<br/>
    </li>
    
    <br/><li><b>invoke init-transport timer &lt;timeout-value&gt;</b>
    <br/>Transport connection to SIP server can be initiated using <i>invoke init-transport</i> instruction. In create-instance test case, user has already configured transport protocol, server ip address, server port. Using these information, transport connection is initiated. For tls, websocket, secured websocket sockets you must make use of <i>timer &lt;timeout-value&gt;</i>. Timeout value is in milliseconds unit. Within specified timeout value if connection is not established (including handshake if applicable), then test case fails and returns from here, else continues to next instruction. 

    <div className="code">
    <b>Examples</b>
    <br/>invoke init-transport timer 60000
    <br/>invoke init-transport timer 120000
    <br/>invoke init-transport timer 180000
    </div>
    </li>
    
    
    <br/><li><b>invoke deinit-transport</b>
    <br/>Transport connection to SIP server can be destroyed using <i>invoke deinit-transport</i> instruction. 

    <div className="code">
    <b>Examples</b>
    <br/>invoke deinit-transport
    </div>
    </li>
    
    <br/><li><b>invoke set-media-ep-mode server</b>
    <br/>Endpoint involved in a call with SRTP-DTLS media, can have either a client or server role during DTLS handshake. You can specify either client or server role as shown in below.

    <div className="code">
    <b>Examples</b>
    <br/>invoke set-media-ep-mode server
    <br/>invoke set-media-ep-mode client
    </div>
    </li>
    
    
    <br/><li><b>invoke set-ice-role controlling</b>
    <br/>Endpoint involved in call, if using ICE for media, can have either controlling or controlled role. You can specify ice role using this instruction. 

    <div className="code">
    <b>Examples</b>
    <br/>invoke set-ice-role controlling
    <br/>invoke set-ice-role controlled
    </div>
    </li>
    
    
    <br/><li><b>invoke media-init</b>
    <br/>This instruction initialises media session. This does not start media, just it creates required resources for media session. 

    <div className="code">
    <b>Example</b>
    <br/>invoke media-init
    </div>
    </li>
    
    <br/><li><b>invoke set-turn-server-ip &lt;variable&gt;</b>
    <br/>You can set turn server ip per endpoint using this instruction.

    <div className="code">
    <b>Examples</b>
    <br/>invoke set-turn-server-ip 192.168.1.100
    <br/>invoke set-turn-server-ip [turn-server-ip]
    </div>
    </li>
    
    <br/><li><b>invoke set-turn-server-port &lt;variable&gt;</b>
    <br/>You can set turn server port per endpoint using this instruction.

    <div className="code">
    <b>Examples</b>
    <br/>invoke set-turn-server-port 3478
    <br/>invoke set-turn-server-port [turn-server-port]
    </div>
    </li>
    
    <br/><li><b>invoke set-turn-user &lt;variable&gt;</b>
    <br/>You can set turn user per endpoint using this instruction.

    <div className="code">
    <b>Examples</b>
    <br/>invoke set-turn-user multiplier
    <br/>invoke set-turn-user [user]
    </div>
    </li>
    
    <br/><li><b>invoke set-turn-password &lt;variable&gt;</b>
    <br/>You can set turn password per endpoint using this instruction.

    <div className="code">
    <b>Examples</b>
    <br/>invoke set-turn-password multiplier
    <br/>invoke set-turn-password [password]
    </div>
    </li>
    
    <br/><li><b>invoke set-remote-audio-srtp-initial-encrypt-key &lt;variable&gt;</b>
    <br/>You can set audio srtp encryption key using this instruction. In SRTP call (no DTLS), key is exchanged through signalling. Using template / variable mechanism extract the key during signalling, then use this instruction to set key.

    <div className="code">
    <b>Examples</b>
    <br/>invoke set-remote-audio-srtp-initial-encrypt-key [audio-srtp-enc-key]
    </div>
    </li>
    
    <br/><li><b>invoke set-remote-video-srtp-initial-encrypt-key &lt;variable&gt;</b>
    <br/>You can set video srtp encryption key using this instruction. In SRTP call (no DTLS), key is exchanged through signalling. Using template / variable mechanism extract the key during signalling, then use this instruction to set key.

    <div className="code">
    <b>Examples</b>
    <br/>invoke set-remote-video-srtp-initial-encrypt-key [video-srtp-enc-key]
    </div>
    </li>
    
    
    <br/><li><b>invoke ice-agent-init timer &lt;timeout-value&gt;</b>
    <br/>This instruction initialises ICE agent. You must mention timeout value in milliseconds, within specified time if it can not initialise ICE agent, test case fails and returns from here. As part of ICE agent initialisation, stun binding or turn allocation are done.  Whether to use host / lite / stun / turn, you have already mentioned in create-instance ice-mode configuration.

    <div className="code">
    <b>Examples</b>
    <br/>invoke ice-agent-init timer 30000
    <br/>invoke ice-agent-init timer 60000
    <br/>invoke ice-agent-init timer 120000
    </div>
    </li>
    
    
    <br/><li><b>invoke set-audio-codec &lt;variable&gt;</b>
    <br/>During offer answer exchange, using template / variable mechanism you can extract audio codec name to a variable say audio-codec. Then use this instruction to set audio codec.
    
    <div className="code">
    <b>Example</b>
    <br/>invoke set-audio-codec [audio-codec]
    </div>
    </li>
    
    
    <br/><li><b>invoke set-audio-codec-number &lt;variable&gt;</b>
    <br/>During offer answer exchange, using template / variable mechanism you can extract audio codec number to a variable say audio-codec-number. Then use this instruction to set audio codec.
    
    <div className="code">
    <b>Example</b>
    <br/>invoke set-audio-codec-number  [audio-codec-number]
    </div>
    </li>
    
    
    <br/><li><b>invoke set-video-codec &lt;variable&gt;</b>
    <br/>During offer answer exchange, using template / variable mechanism you can extract video codec name to a variable say video-codec. Then use this instruction to set video codec.
    
    <div className="code">
    <b>Example</b>
    invoke set-video-codec [video-codec]
    </div>
    </li>

    <br/><li><b>invoke set-video-codec-number &lt;variable&gt;</b>
    <br/>During offer answer exchange, using template / variable mechanism you can extract video codec number to a variable say video-codec-number. Then use this instruction to set video codec.

    <div className="code">
    <b>Example</b>
    <br/>invoke set-video-codec-number  [video-codec-number]
    </div>
    </li>

    <br/><li><b>invoke set-remote-audio-rtp-ufrag &lt;variable&gt;</b>
    <br/>During offer answer exchange, using template / variable mechanism you can extract ICE audio ufrag to a variable say remote-audio-rtp-ufrag. Then using this instruction set remote audio rtp ice ufrag.

    <div className="code">
    <b>Example</b>
    <br/>invoke set-remote-audio-rtp-ufrag [remote-audio-rtp-ufrag]
    </div>
    </li>
    
    
    <br/><li><b>invoke set-remote-audio-rtp-icepasswd &lt;variable&gt;</b>
    <br/>During offer answer exchange, using template / variable mechanism you can extract ICE audio password to a variable say remote-audio-rtp-icepasswd. Then using this instruction set remote audio rtp ice password.

    <div className="code">
    <b>Example</b>
    <br/>invoke set-remote-audio-rtp-icepasswd [remote-audio-rtp-icepasswd]
    </div>
    </li>
    
    
    <br/><li><b>invoke set-remote-audio-rtp-candidate &lt;variable&gt;</b>
    <br/>Before ICE connectivity check start, each endpoint should know each others candidates. During offer answer exchange, using template / variable mechanism you can extract remote audio rtp candidate to a variable say remote-audio-rtp-cand. Then using this instruction, you can set remote audio rtp candidate.

    <div className="code">
    <b>Example</b>
    <br/>invoke set-remote-audio-rtp-candidate [remote-audio-rtp-cand]
    </div>
    </li>
    
    
    <br/><li><b>invoke set-remote-audio-rtcp-ufrag &lt;variable&gt;</b>
    <br/>During offer answer exchange, using template / variable mechanism you can extract ICE audio ufrag to a variable say remote-audio-rtcp-ufrag. Then using this instruction set remote audio rtcp ice ufrag.

    <div className="code">
    <b>Example</b>
    <br/>invoke set-remote-audio-rtcp-ufrag [remote-audio-rtcp-ufrag]
    </div>
    </li>
    
    
    <br/><li><b>invoke set-remote-audio-rtcp-icepasswd &lt;variable&gt;</b>
    During offer answer exchange, using template / variable mechanism you can extract ICE audio password to a variable say remote-audio-rtcp-icepasswd. Then using this instruction set remote audio rtcp ice password.

    <div className="code">
    <b>Example</b>
    <br/>invoke set-remote-audio-rtcp-icepasswd [remote-audio-rtcp-icepasswd]
    </div>
    </li>
    
    
    <br/><li><b>invoke set-remote-audio-rtcp-candidate &lt;variable&gt;</b>
    <br/>Before ICE connectivity check start, each endpoint should know each others candidates. During offer answer exchange, using template / variable mechanism you can extract remote audio rtcp candidate to a variable say remote-audio-rtcp-cand. Then using this instruction, you can set remote audio rtcp candidate.

    <div className="code">
    <b>Example</b>
    <br/>invoke set-remote-audio-rtcp-candidate [remote-audio-rtcp-cand]
    </div>
    </li>
    
    
    <br/><li><b>invoke set-remote-video-rtp-ufrag &lt;variable&gt;</b>
    <br/>During offer answer exchange, using template / variable mechanism you can extract ICE video ufrag to a variable say remote-video-rtp-ufrag. Then using this instruction set remote video rtp ice ufrag.

    <div className="code">
    <b>Example</b>
    <br/>invoke set-remote-video-rtp-ufrag [remote-video-rtp-ufrag]
    </div>
    </li>
    
    
    <br/><li><b>invoke set-remote-video-rtp-icepasswd &lt;variable&gt;</b>
    <br/>During offer answer exchange, using template / variable mechanism you can extract ICE video password to a variable say remote-video-rtp-icepasswd. Then using this instruction set remote video rtp ice password.

    <div className="code">
    <b>Example</b>
    <br/>invoke set-remote-video-rtp-icepasswd [remote-video-rtp-icepasswd]
    </div>
    </li>
    
    
    <br/><li><b>invoke set-remote-video-rtp-candidate &lt;variable&gt;</b>
    <br/>Before ICE connectivity check start, each endpoint should know each others candidates. During offer answer exchange, using template / variable mechanism you can extract remote video rtp candidate to a variable say remote-video-rtp-cand. Then using this instruction, you can set remote video rtp candidate.

    <div className="code">
    <b>Example</b>
    <br/>invoke set-remote-video-rtp-candidate [remote-video-rtp-cand]
    </div>
    </li>
    
    
    <br/><li><b>invoke set-remote-video-rtcp-ufrag &lt;variable&gt;</b>
    <br/>During offer answer exchange, using template / variable mechanism you can extract ICE video ufrag to a variable say remote-video-rtcp-ufrag. Then using this instruction set remote video rtcp ice ufrag.

    <div className="code">
    <b>Example</b>
    <br/>invoke set-remote-video-rtcp-ufrag [remote-video-rtcp-ufrag]
    </div>
    </li>
    
    
    <br/><li><b>invoke set-remote-video-rtcp-icepasswd &lt;variable&gt;</b>
    <br/>During offer answer exchange, using template / variable mechanism you can extract ICE video password to a variable say remote-video-rtcp-icepasswd. Then using this instruction set remote video rtcp ice password.

    <div className="code">
    <b>Example</b>
    <br/>invoke set-remote-video-rtcp-icepasswd [remote-video-rtcp-icepasswd]
    </div>
    </li>
    
    
    <br/><li><b>invoke set-remote-video-rtcp-candidate &lt;variable&gt;</b>
    <br/>Before ICE connectivity check start, each endpoint should know each others candidates. During offer answer exchange, using template / variable mechanism you can extract remote video rtcp candidate to a variable say remote-video-rtcp-cand. Then using this instruction, you can set remote video rtcp candidate.

    <div className="code">
    <b>Example</b>
    <br/>invoke set-remote-video-rtcp-candidate [remote-video-rtcp-cand]
    </div>
    </li>
    
    
    <br/><li><b>invoke ice-agent-con-check timer &lt;timeout-value&gt;</b>
    <br/>After offer answer exchange over, each endpoint learnt others candidates, ice ufrag, ice password etc, ice connectivity is initiated using this instruction. As part of ice connectivity check, internally ice candidate pairs are formed, ordered, certain candidates are replaced with base candidates, pruned and then ice bind requests are sent for all candidate pairs. The ice bind transaction may succeed for several pairs, priority logic is applied and one pair is chosen as selected pair. Corresponding transport path is chosen for media exchange.

    <div className="code">
    <b>Examples</b>
    <br/>invoke ice-agent-con-check timer 30000
    <br/>invoke ice-agent-con-check timer 60000
    <br/>invoke ice-agent-con-check timer 90000
    </div>
    </li>
    
    
    <br/><li><b>invoke set-remote-ip &lt;variable&gt;</b>
    <br/>Once offer answer exchange over, if ICE connectivity over (whenever applicable), you would have got final remote ip address and port for media exchange. If ICE is used for call (sip webrtc call), a plugin variable <i>p-ice-remote-audio-ip:session_id_1</i> contains selected remote ip address for media. Note that variable name is <i>p-ice-remote-audio-ip</i> and session_id_1 is the session key. If ICE is not applicable (normal sip call), probably you would have extracted remote ip address to a variable using template / variable mechanism. So summary is either as plugin variable or normal variable, you have got remote ip address. Use this instruction to set remote IP address.

    <div className="code">
    <b>Examples</b>
    <br/>#set remote ip through a normal variable - applicable for sip call
    <br/>invoke set-remote-ip [remote-ip]
    <br/><br/>#set remote ip through a plugin variable - applicable for sip webrtc call
    <br/>invoke set-remote-ip [p-ice-remote-audio-ip:session_id_1]
    </div>
    </li>
    
    
    <br/><li><b>invoke set-remote-audio-rtp-port &lt;variable&gt;</b>
    <br/>Once offer answer exchange over, if ICE connectivity over (whenever applicable), you would have got final remote ip address and port for media exchange. If ICE is used for call (sip webrtc call), a plugin variable <i>p-ice-remote-audio-rtp-port:session_id_1</i> contains selected remote port for media. Note that variable name is <i>p-ice-remote-audio-rtp-port</i> and session_id_1 is the session key. If ICE is not applicable (normal sip call), probably you would have extracted remote port to a variable using template / variable mechanism. So summary is either as plugin variable or normal variable, you have got remote port. Use this instruction to set remote port.

    <div className="code">
    <b>Examples</b>
    <br/>#set remote port through a normal variable - applicable for sip call
    <br/>invoke set-remote-audio-rtp-port [remote-port]
    <br/><br/>#set remote port through a plugin variable - applicable for sip webrtc call
    <br/>invoke set-remote-audio-rtp-port [p-ice-remote-audio-rtp-port:session_id_1]
    </div>
    </li>
    
    <br/><li><b>invoke set-remote-video-rtp-port &lt;variable&gt;</b>
    <br/>Once offer answer exchange over, if ICE connectivity over (whenever applicable), you would have got final remote ip address and port for media exchange. If ICE is used for call (sip webrtc call), a plugin variable <i>p-ice-remote-video-rtp-port:session_id_1</i> contains selected remote port for media. Note that variable name is <i>p-ice-remote-video-rtp-port</i> and session_id_1 is the session key. If ICE is not applicable (normal sip call), probably you would have extracted remote port to a variable using template / variable mechanism. So summary is either as plugin variable or normal variable, you have got remote port. Use this instruction to set remote port.

    <div className="code">
    <b>Examples</b>
    <br/>#set remote port through a normal variable - applicable for sip call
    <br/>invoke set-remote-video-rtp-port [remote-port]
    <br/><br/>#set remote port through a plugin variable - applicable for sip webrtc call
    <br/>invoke set-remote-video-rtp-port [p-ice-remote-video-rtp-port:session_id_1]
    </div>
    </li>
    
    <br/><li><b>invoke media-ready timer &lt;timeout-value&gt;</b>
    <br/>Previously you have done media-init, with offer answer exchange and ICE connectivity (wherever applicable), you have selected and set appropriate media transport details. In a normal case, it will do everything required to start a media session. In case of SRTP-DTLS call, it will do everything required to start a media session, plus DTLS handshake. You need to mention timeout value as last parameters in millisecond. Within that specified time if all media ready activities are not completed test case returns fail.  

    <div className="code">
    <b>Examples</b>
    <br/>invoke media-ready timer 30000
    <br/>invoke media-ready timer 60000
    <br/>invoke media-ready timer 90000
    </div>
    </li>

    <br/><li><b>invoke media-sendrecv timer &lt;timeout-value&gt;</b>
    <br/>Now all media prerequisites are done. Just start media using this instruction. Mention time in millisecond you would like to have media exchange between endpoints. You can have <i>media-sendonly</i>,   <i>media-recvonly</i> etc as shown in below example.
   
    <div className="code">
    <b>Examples</b>
    <br/>invoke media-sendrecv timer 30000
    <br/>invoke media-sendrecv timer 60000
    <br/>invoke media-sendonly timer 30000
    <br/>invoke media-sendonly timer 60000
    <br/>invoke media-recvonly timer 30000
    <br/>invoke media-recvonly timer 60000
    </div>
    </li>

    <br/><li><b>invoke media-inactive</b>
    <br/>Say you have started media using <i>invoke media-sendrecv timer</i>, after media-sendrecv over, if you want to change the media mode to inactive, you can do so using this instruction.

    <div className="code">
    <b>Example</b>
    <br/>invoke media-inactive
    </div>
    </li>

    <br/><li><b>invoke dtls-close</b>
    <br/>In a SRTP-DTLS call, at the end of call, you need to send DTLS close alert to peer. You can do so using this instruction.

    <div className="code">
    <b>Example</b>
    <br/>invoke dtls-close
    </div>
    </li>

    <br/><li><b>invoke timer &lt;timeout-value&gt;</b>
    <br/>In an instruction block execution, if you want to introduce some delay, you can do so by using this instruction. 

    <div className="code">
    <b>Examples</b>
    <br/>invoke timer 30000
    <br/>invoke timer 60000
    </div>

    In first example, delay is 30 sec, in second example delay in 60 sec.
    </li>

    <br/><br/><li><b>invoke config-transport-connection &lt;conn-name&gt; ping &lt;timeout-value&gt;</b>
    <br/>You can initiate websocket ping functionality using above instruction. The conn-name is just a place holder here, just put some value. The websocket ping message will be send at regular interval of <i>time-out</i> millisecond. 

    <div className="code">
    <b>Examples</b>
    <br/>invoke config-transport-connection conn-name1 ping 5000
    <br/>invoke config-transport-connection conn-name2 ping 6000
    <br/>invoke config-transport-connection conn-name3 ping 7000
    </div>
    </li>
    
    <br/><li><b>invoke audio-rtp-header-extn &lt;id&gt; &lt;data&gt;</b>
    <br/>You can put rtp header extension data in audio rtp packets using this instruction.

    <div className="code">
    <b>Examples</b>
    <br/>invoke audio-rtp-header-extn 1 [somedata]
    <br/>invoke audio-rtp-header-extn 2 [someotherdata]
    </div>    
    </li>

    <br/><li><b>invoke video-rtp-header-extn &lt;id&gt; &lt;data&gt;</b>
    <br/>You can put rtp header extension data in video rtp packets using this instruction.

    <div className="code">
    <b>Examples</b>
    <br/>invoke video-rtp-header-extn 1 [somedata]
    <br/>invoke video-rtp-header-extn 2 [someotherdata]
    </div>    
    </li>
    
    </ul>

    {ugc_invoke_url()}
    <br/>
    <h3>File Graphs</h3>
    LM Tools supports file based graphs. Required data, configurations etc will be in files and
    graph is drawn using these data. Database is avoided and most of the gaph draw work is done
    using javascript to make it faster.
    
    Say for example, user wants to draw graph SIP sent invites and received invites. Below instruction
    set exactly does this.

    <div className="code">  
    invoke graph create sipinvites<br/>
    invoke graph sipinvites xaxis elements time<br/>
    invoke graph sipinvites yaxis elements txinvite rxinvite<br/>
    invoke graph sipinvites yaxis type distinct<br/>
    invoke graph sipinvites draw type bar<br/>
    invoke graph sipinvites start<br/>
    </div>
    <br/>
    Lets analyze this example in detail.
    
    <br/><br/><b>invoke graph create sipinvites</b><br/>
    Here <i>graph</i> is a keyword, <i>create</i> is a keyword,
    <i>sipinvites</i> is the graph name. For graph name you can put anything, but space not allowed. Here
    user is creating a graph by name sipinvites.


    <br/><br/><b>invoke graph sipinvites xaxis elements time</b><br/>
    Here <i>xaxis</i>, <i>elements</i> and <i>time</i> are keywords. Here time is configured for xaxis.

    <br/><br/><b>invoke graph sipinvites yaxis elements txinvite rxinvite</b><br/>
    Here <i>yaxis</i>, <i>txinvite</i> and <i>rxinvite</i> are keywords. Here txinvite and 
    rxinvite are configured for yaxis. In yaxis, user can configure many items, all possible items
    are listed below.

    <br/><br/><b>invoke graph sipinvites yaxis type distinct</b><br/>
    Here <i>type</i> and <i>distinct</i> are keywords. Yaxis scale configured distinct for each yaxis item.

    <br/><br/><b>invoke graph sipinvites draw type line</b><br/>
    Here <i>draw</i> and <i>line</i> are keywords. Draw type is configured as line for each yaxis item.
    Various draw types are
    <ul>
    <li>line</li>
    <li>bar</li>
    <li>pie</li>
    <li>donut</li>
    <li>eclipse</li>
    <li>rectangle</li>
    </ul>

    <b>invoke graph sipinvites start</b><br/>
    Here <i>start</i> is a keyword. Now the graph is started, and from now on, graph data is captured and communicated to draw engine.
    
    FSIP has many available yaxis graph items as listed in below table.<br/>
    
    <br/>
    {ugc_file_graphs_sip()} 
    
    <br/>Media related file graph items listed below.<br/> 
    <br/>
    {ugc_file_graphs_media()}

    <br/><br/><h3>Send Instruction</h3>
    Send instruction is used when user wants to send a sip message out. 
    <ul>
    <li><b>send [t-ack-template]</b></li>

    <div className="code">
    <b>Examples</b>
    <br/>send [t-ack-template]
    <br/>send [t-bye-template]
    <br/>send [t-200-ok-bye-template]
    </div>

    <br/>Using this instruction, you can format a template and send the resultant data out. 
    </ul>

    <h3>Send-with-blength Instruction</h3>
    <br/>Send-with-blength Instruction is used when user wants to send a sip message out and also want to put content-length header with proper value. 
    <ul>
    <li><b>send-with-blength Content-Length [p-blank-line] [buffer] conn-name</b>

    <div className="code">
    <b>Example</b>
    <br/>send-with-blength Content-Length [p-blank-line] [buffer] conn-name
    </div>

    <br/>Using this instruction, you can format a template, and correct content length header and send the resultant data out. The second token is <i>Content-Length</i>, it means during formatting it will break at <i>Content-Length</i> header, and until it gets blank line, the in-between data it discards, puts the right content length value and then append the message body, finally sends formatted data out. The conn-name token is just a futuristic design (inherited from APAT), not used right now.
    </li>
    </ul>

    <h3>Send-data Instruction</h3>
    <br/>Send-data instruction is used when user wants to send a non-sip message out. 
    <ul>
    <li><b>send-data [t-any-template]</b>

    <div className="code">
    <b>Examples</b>
    <br/>send [t-any-template1]
    <br/>send [t-any-template2]
    <br/>send [t-any-template3]
    </div>

    <br/>Using this instruction, you can format a template and send the resultant data out.
    </li>
    </ul>

    <h3>Receive Instruction</h3>
    <br/>Receive instructions are used to receive data, and extract 
    some portion of data to a variable using template / variable 
    mechanism.
    <ul>
    <li><b>receive [t-template-name] timer &lt;timeout-value&gt;</b>

    <div className="code">
    <b>Examples</b>
    <br/>receive [t-receive-invite-template] timer 60000
    </div>

    <br/>The invite-template is already loaded to memory earlier. Here you 
    are instructing to multiplier to receive an invite message. The 
    received message is checked against invite template, some portion 
    of invite message (like codec name, codec number etc) are extracted 
    if template contains variables. In templates sometimes you will 
    see [...], it means for that specific portion whatever data comes 
    multiplier ignores it.

    <br/>Practically you can extract any part of a message. The receive 
    invite template example is given below for better understanding.

    <div className="code">
    <b>Receive INVITE template</b>
    <br/>INVITE[...]From:[...]sip:[from-user:session_id_1]@[...]tag=[from-tag:session_id_1]
    <br/>To:[...]sip:[to-user:session_id_1]@[...]
    <br/>[...]Call-ID: [call-id:session_id_1]
    <br/>[...]CSeq: [cseq] INVITE
    <br/>[...]m=audio [mport] [...]
    <br/>[...]a=candidate:[cand1]
    <br/>[...]a=ice-ufrag:[remote-audio-rtp-ufrag]
    <br/>[...]a=ice-pwd:[remote-audio-rtp-icepasswd]
    <br/>[...]a=rtpmap:[audio-codec-number] [audio-codec]/[...]
    </div>

    See m= line, port number is extracted to variable <i>mport</i>. If you see from starting from user, from tag, to user, callid etc are extracted and stored to variables. These variables can be accessed later to get corresponding values. You can see [...] in invite template, it indicates that portion of received message is a just a do not care portion for multiplier.

    <br/><br/>If receive operation is not completed within specified timeout value (in millisecond), then test returns fail.
    </li><br/>
    
    <li><b>receive [t-template-name1] [t-template-name2] timer &lt;timeout-value&gt;</b>
    <div className="code">
    <b>Examples</b>
    <br/>receive [t-receive-msg1-template] [t-receive-msg2-template] timer 60000
    </div>
    <br/>At times you may have to receive multiple messages. In that case you can put either two receive instructions or can put a single multi-receive instruction to receive more than one message as shown here. When multiple messages are received and are not in order, it is mandatory to go for multi-receive instruction. This example shows you can receive both msg1 and msg2 in a single multi-receive instruction irrespective of they come in order or out of order.
    </li><br/>
     
    <li><b>receive [t-template-name1] [t-template-name2|f-function (msg,length)|i-instruction-block] timer &lt;timeout-value&gt;</b>
    <div className="code">
    <b>Examples</b>
    <br/>receive [t-receive-msg1] [t-receive-msg2|f-extract_candidates(_RXDATA_,_RXLEN_)|i-ib] timer 60000
    </div>
    The syntax shown here is bit complicated, but quite powerful to handle operations on network received messages. This is multi-receive syntax coupled with function calls, instruction calls etc. In above example, _RXDATA_ and _RXLEN_ are used. These two are special system internal variables containing just received message and its length.

    <br/><br/>When a message is received, and confirms to template msg2, then a lua function by name <b>extract_candidates</b> (you can have your own function name) is invoked. Inside the lua function, you will have access to received data and length as functions arguments. In lua function, you can parse the message, and return pair values (one more more pairs). The pair values consists of data and key. Once this lua function execution is over, whatever pair values you just returned from lua function, those will be available in test scope as any other variable with key as variable name and data as variable value. From lua function you can return one or more string / key pair, integer / key pair, boolean / key and double / key pair.  

    <br/><br/>Similarly if a instruction block is present in a receive syntax (starts with <b>i-</b>), the instruction block gets executed when the particular message is received and confirms to given template. Inside the instruction block you can do any supported operation as you need using instructions, text / binary templates, variables, functions etc.

    <br/><br/>See this lua function below, it is just an example, you can write better lua function (or can take help from our support engineers) in actual receive tests. 
    <div className="code">
    <br/><tab1>function extract_candidates(data, len)</tab1>
    <br/><tab2>index = string.find(data, "candidate")</tab2>
    <br/><tab2>string1 = string.sub(data, index+25)</tab2>
    <br/><tab2>index = string.find(string1, "\\")</tab2>
    <br/><tab2>ac = string.sub(string1, 0, index-1)</tab2>
    <br/><tab2>string1 = string.sub(string1, string.len(ac))</tab2>
    <br/><tab2>index = string.find(string1, "candidate")</tab2>
    <br/><tab2>index = index + 25</tab2>
    <br/><tab2>string3 = string.sub(string1, index)</tab2>
    <br/><tab2>index = string.find(string3, "\\")</tab2>
    <br/><tab2>vc = string.sub(string3, 0, index-1)</tab2>
    <br/><tab2>return ac,"audio-rtp-candidate",vc,"video-rtp-candidate"</tab2>
    <br/><tab1>end</tab1>
    </div>
    <br/>
    <br/>This lua function <b>extract_candidates</b> is written to parse ICE candidates and push those as variables in system, so that from test environment those variables can be accessed. As you can see the function arguments are <i>data</i> and <i>length</i>, since the function was called with argument _RXDATA_ and _RXLEN_, so <i>data</i> contains just received buffer and <i>len</i> contains length of just received buffer. Using lua scripting language syntax / logic the string <i>data</i> is parsed, audio and video candidates are extracted and returned as data / key pair values. See the last line <i>return ac,"audio-rtp-candidate",vc,"video-rtp-candidate"</i>. The <i>ac</i> and <i>vc</i> are string variables and contains audio / video candidates. Lua supports multiple returns, taking this advantage <i>ac (audio candidate)</i> variable is returned with key <i>audio-rtp-candidate</i> and <i>vc (video candidate)</i> variable is returned with key <i>video-rtp-candidate</i>.
     
    <br/><br/>After this function execution is over, in test environment, you can access variable <i>[audio-rtp-candidate]</i> and <i>[video-rtp-candidate]</i> to get audio and video candidate values respectively.
    </li>
    </ul>
     
    <h3>On Instruction</h3>
    On instruction is is a standing instruction, on receiving a particular message call corresponding instruction block. 
    <br/>
    
    <ul>
    <li><b>on &lt;msg&gt; &lt;ib&gt;</b>
    <div className="code">
    <b>Examples</b>
    <br/>on [t-rx-option] [i-ib-option]
    <br/>on [t-rx-notify] [i-ib-notify]
    </div>
    <br/>As you know LM endpoints / clients follow sequential apprach, like send 
    REGISTER, wait for REGISTER response, or start media session for a given time period, 
    in such cases if any message like OPTION or NOTIFY received at LM client its difficult 
    to handle in a sequential approach, hence this instruction <b>on</b> is introduced. It 
    acts like standing instruction whenever a specific message received call corresponding
    instruction block and send a proper response for incoming request.
    </li>
    </ul>
    
    <h3>Execute Instruction</h3>
    Execute instruction is used when user wants to execute an instruction block. The instruction block is a logical unit having certain instructions grouped together. The instruction block you can think of as a routine / function. 
    <ul>
    <li><b>execute &lt;instruction block&gt;</b>

    <div className="code">
    <b>Examples</b>
    <br/>execute [i-calling-party-block] 
    <br/>execute [i-calling-party-block] repeat 2
    <br/>execute [i-calling-party-block] force-repeat 2
    </div>

    Using this instruction, you can invoke an instruction block to execute. The first example is strait forward. An instruction block (name calling-party-block) is invoked. 

    <br/><br/>The second example makes use of repeat. It means the instruction block is invoked twice. Or you can invoke the instruction block for n times. If there is a failure during executing calling-party-ib or during executing any nested instruction blocks, then execution returns with failure.
    <br/><br/>The third example makes use of force-repeat. It means the instruction block is invoked twice. Or you can invoke the instruction block for n times. If there is a failure during executing calling-party-ib or during executing any nested instruction blocks, then execution return for current cycle of execution, increments error stats, and tries calling-party-block again until force-repeat count comes to 0.
    </li>

    <br/><br/><li><b>execute &lt;lua-function&gt;</b>

    <div className="code">
    <b>Examples</b>
    <br/>execute [f-somefunction()]
    <br/>execute [f-branchout()]
    </div>

    Using this instruction, you can invoke a lua function to execute. 
            The first example calls a lua function by name somefunction. 
            Please make sure the lua function <b><i>somefunction</i></b> is declared prior to this test case. 
            <br/><br/>
            There is another intersting feature, you can use this instruction to achieve 
            <b><i>if else</i></b> kind of functionality. Look at second example. Lets say the
            <b><i>branchout</i></b> lua function looks as below.
            <div className="code">
            function branchout()<br/>
                <tab2>return "ib1","executeib"<br/></tab2>
            end<br/>
            </div>
            If a lua function returns two strings, and the last string is <b><i>executeib</i></b>,
            then instruction block identified by first return string (in this case ib1) is executed.
            Depending on certain condition one may return ib1 or ib2, accordingly instruction block
            ib1 or ib2 is executed. 
            
    </li>
    </ul>

    <h3>Multiple Sessions</h3>
    Sometimes multiple sessions are required. In a typical simple case, in a call say user wants two media sessions. So it means, one call, like offer / answer exchange is done once, but two media sessions starts and media flows end to end for both the sessions. In other use case, user want to have two media sessions, and media transmission is done in one session context and media receive is done in other session context.
    <br/><br/>Starting from multiplier 3.03, multiple session simulation is supported. You can create two sessions (primary and secondary), you can execute instructions in a given session context, moreover you can synchronize between the sessions using wait / notify mechanism.
    <br/><br/>Lets see how it works
    <ol>
    <li>Create first session using session-init native api.</li>
    <li>Create second session using session-init native api.</li>
    <li>From native api call instruction block. If in instruction block session-id is given, than the instructions present in instruction block are executed in given session context, else executed in primary session context.</li>
    <li>From one session, you can wait other session to complete certain tasks.</li>
    <li>From one session, you can notify other session after completion of certain tasks.</li>

    <br/>Now lets see available instructions to achieve the functionalities described above.

    <br/><br/>Say you have below instruction blocks and native api.

    <div className="code">
    <b>calling-party-media-init-2 instruction block</b>
    <br/>session-id session_id_2 
    <br/>invoke media-init 
    <br/>invoke notify session_id_1
    </div>

    <div className="code">
    <b>calling-party-ib instruction block</b>
    <br/>execute [i-calling-party-media-init-2] 
    <br/>invoke media-init 
    <br/>invoke wait session_id_2 timer 90000
    </div>

    <div className="code">
    <b>execute-call native apt</b>
    <br/>session-id session_id_1 
    <br/>execute [i-calling-party-ib] 
    </div>
    <br/>
    <br/>From native api, calling party-ib is called. Session id given is session_id_1. Now session identified by session_id_1 becomes your primary session. From native api, calling-party-ib instruction block is invoked.  

    <br/><br/>In calling-party-ib instruction block, session id is not given, hence this instruction block will be executed in context of primary session. In calling-party-ib instruction block, in first line calling-party-media-init-2 instruction block is invoked.

    <br/><br/>The calling-party-media-init-2 instruction bock specifies session id as session_id_2. Hence calling-party-media-init-2 instructions will be executed in session context identified by session_id_2.

    <br/><br/>Take a note of wait and notify syntax, these are explained below.
    </ol>

    <ul>
    <li><b>invoke notify &lt;session id&gt;</b>
    <br/>After the execution over, notify to session identified by <b>session id</b>.
    <div className="code">
    <b>Example</b>
    <br/>invoke notify session_id_1
    </div>
    In above example, while you are executing in session_id_2 session, you are notifying to session_id_1 session.
    <br/></li><br/>
    
    
    <li><b>invoke wait &lt;session id&gt; timer &lt;timeout value in milliseconds&gt;</b>
    <br/>Wait for notification from a session identified by <b>session id</b>.
    <div className="code">
    <b>Example</b>
    <br/>invoke wait session_id_2 timer 90000
    </div>
    In above example, while you are executing in session_id_1 session, you are waiting for notification from session_id_2 session within 90 seconds.
    </li>
    </ul>

    <h3>Rps Instruction</h3>
    RPS stands for rates per second. In multiplier case, it can be a sip call, a generic session, a generic execution that has no similarity to call, so instead of using calls per sec multiplier uses rps (rates per second).

    <ul>
    <li><b>rps &lt;value&gt;</b>

    <div className="code">
    <b>Examples</b>
    <br/>rps 16
    <br/>rps 96
    <br/>rps 1024
    </div>

    Depending on what value you have set for rps, corresponding test case 
    is attempted for those many endpoints. Say for <i>execute-makecall</i> you 
    have set <i>rps 16</i>, every second 16 endpoints will attempt to execute-makecall test case. It is strongly recommended to read <a href="/lmuserguide/' . LM_DOC_VERSION . '/common/threads_endpoints_and_rps">this</a> for better understanding of rps and related parameters. 
    </li>
    </ul>

        
        
        
        <br/>
        
        <div className="inlinecenter">
          <button onClick={() => window.location.href="/userguide/flexisipwebrtcsyntax" }>Back</button> 
          <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
          <button onClick={() => window.location.href="/userguide/b2bavariables" }>Next</button>
        </div>
    </div>
  );
  
  return element;
}

export {UGFlexiSIPInstructions}

