
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

import 
  {
    ugc_variables_syntax, ugc_variables_syntax_desc,
    ugc_plugin_syntax, ugc_plugin_syntax_desc, 
    ugc_instructions_syntax, ugc_instructions_syntax_desc,
    ugc_text_template_syntax, ugc_text_template_syntax_desc,
    ugc_binary_template_syntax, ugc_binary_template_syntax_desc
  } from './ugcommon.js'

import 
  {
    sipb2ba_createinstance_syntax, sipb2ba_createinstance_syntax_desc,
    sipb2ba_configinstance_syntax, sipb2ba_configinstance_syntax_desc,
    sipb2ba_configsdp_syntax, sipb2ba_configsdp_syntax_desc,
  } from './sipb2basyntax.js'


const UGSIPServerSyntax = () => {

  setPageTitle('SIP Server Syntax');
  
  let index = 0;
  let modulename = "sipserver";
  
  const element = (
    <div className="userguide">
      <h1>SIP Server Syntax</h1>
      
      <br/>
      
      <table className="statstbl">
        <thead>
          <tr>
            <th style={{width: "5%"}}>
              Sl
            </th>
            
            <th style={{width: "45%"}}>
              Test block
            </th>
            
            <th style={{width: "50%"}}>
              Syntax
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              {++index} 
            </td>
            
            <td>
              {sipb2ba_createinstance_syntax()}
            </td>
            
            <td>
              {sipb2ba_createinstance_syntax_desc("Server")}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {sipb2ba_configinstance_syntax()}
            </td>
            
            <td>
              {sipb2ba_configinstance_syntax_desc()}
            </td>
          </tr>

          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {sipb2ba_configsdp_syntax()}
            </td>
            
            <td>
              {sipb2ba_configsdp_syntax_desc()}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {ugc_variables_syntax()}
            </td>
            
            <td>
              {ugc_variables_syntax_desc(modulename)}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {ugc_plugin_syntax()}
            </td>
            
            <td>
              {ugc_plugin_syntax_desc(modulename)}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {ugc_instructions_syntax()}
            </td>
            
            <td>
              {ugc_instructions_syntax_desc(modulename)}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {ugc_text_template_syntax()}
            </td>
            
            <td>
              {ugc_text_template_syntax_desc()}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {ugc_binary_template_syntax()}
            </td>
            
            <td>
              {ugc_binary_template_syntax_desc()}
            </td>
          </tr>
          
        </tbody>
      </table>
      
      <br/>
      
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/sipservervariables" }>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/sipserverinstructions" }>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGSIPServerSyntax}