
import React, {useState, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import {Line} from 'rc-progress';
import {lmReports, lmFrequencies, lmBlockTypes, lmPlugins, getURLQueryVariable, setPageTitle, handleErrorResponse} from '../../routes/lm.js';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

var myTimeOut;

const TestSuites = () => 
{
  const [tsBody, setTsBody]=useState([
  {
    tcname:"",
    startindex:0,
    deltaindex:0,
    endindex:10,
    timeout: 300000,
    repeat: 1,
    tolerance: 10,
    plugin: 100,
    type: 900,
    freq: 100,
    report: 0,
    attribs: "",
    toggle: false, 
    checked: false,
    tmplts: [],
  }]);

  const [tsHead, setTsHead] = useState({
    tsName: "",
    tsDesc: "",
    plugin: 100,
    type: 900,
    freq: 100,
    report: 0,
    toggle: false,
    tsId: getURLQueryVariable("id"),
    progress: 0,
  });

  const [status, setStatus] = useState(null);
  const [collapseButtonState, setCollapseButtonState] = useState("Expand All");
  const [selectAllState, setSelectAllState] = useState("Select All");
  
  setPageTitle("Test Suites");

  var pluginObj = lmPlugins();
  var blockTypesObj = lmBlockTypes();
  var frequencyObj = lmFrequencies();
  var reportObj = lmReports();
  
  const handleChange=(e,i)=> {
    const {name,value}=e.target;
    const onchangeVal = [...tsBody];
    onchangeVal[i][name]=value;
    setTsBody(onchangeVal);
  }

  const handleTextAreaChange=(e,i)=> {
    const {name,value}=e.target;
    const newTbData = [...tsBody];
    newTbData[i].attribs = value;
    setTsBody(newTbData);
  }

  const insertOneTestBlock=(e,i) => {
    e.preventDefault();

    const newTbData = [...tsBody];
    newTbData.splice(i, 0, {
      tcname:"",
      startindex:0,
      deltaindex:0,
      endindex:10,
      timeout: 300000,
      repeat: 1,
      tolerance: 10,
      plugin: 100,
      type: 900,
      freq: 100,
      report: 0,
      attribs: "",
      toggle: false, 
      checked: false, 
      tmplts: [],
    });
    
    setTsBody(newTbData);
  }
  
  const handleTSNameChange=(value)=> {
    const newTSData = {...tsHead};
    newTSData.tsName = value;
    setTsHead(newTSData); 
  }


  const handleTSDescChange=(value)=> {
    const newTSData = {...tsHead};
    newTSData.tsDesc = value;
    setTsHead(newTSData); 
  }

  function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  const handleTSPluginChange=(e,i)=> {
    const key = Object.keys(pluginObj)[e.target.selectedIndex];
    const newTbData = [...tsBody];
    newTbData[i].plugin = key;
    setTsBody(newTbData);
  }

  const handleTSTypeChange=(e,i)=> {
    const key = Object.keys(blockTypesObj)[e.target.selectedIndex];
    const newTbData = [...tsBody];
    newTbData[i].type = key;
    setTsBody(newTbData);
  }
  
   const handleTSFreqChange=(e,i)=> {
    const key = Object.keys(frequencyObj)[e.target.selectedIndex];
    const newTbData = [...tsBody];
    newTbData[i].freq = key;
    setTsBody(newTbData);
  }

  const handleTSReportChange=(e,i)=> {
    const key = Object.keys(reportObj)[e.target.selectedIndex];
    const newTbData = [...tsBody];
    newTbData[i].report = key;
    setTsBody(newTbData);
  }

  const handleTSControlPluginChange=(e)=> {
    const key = Object.keys(pluginObj)[e.target.selectedIndex];
    
    const newTSData = {...tsHead};
    newTSData.plugin = key;

    var newTbData = [...tsBody];
    const keys = newTbData.keys();
    for(let i of keys) {
      newTbData[i].plugin = key;
    }
    
    setTsBody(newTbData);
    setTsHead(newTSData);
  }

  const handleTSControlTypeChange=(e)=> {
    const key = Object.keys(blockTypesObj)[e.target.selectedIndex];
    const newTSData = {...tsHead};
    newTSData.type = key;

    var newTbData = [...tsBody];
    const keys = newTbData.keys();
    for(let i of keys) {
      newTbData[i].type = key;
    }
    
    setTsBody(newTbData);
    setTsHead(newTSData);
  }

  const handleTSControlFrequencyChange=(e)=> {
    const key = Object.keys(frequencyObj)[e.target.selectedIndex];
    
    const newTSData = {...tsHead};
    newTSData.freq = key;

    var newTbData = [...tsBody];
    const keys = newTbData.keys();
    for(let i of keys) {
      newTbData[i].freq = key;
    }
    
    setTsBody(newTbData);
    setTsHead(newTSData);
  }


  const handleTSControlReportChange=(e)=> {
    const key = Object.keys(reportObj)[e.target.selectedIndex];
    const newTSData = {...tsHead};
    newTSData.report = key;
    var newTbData = [...tsBody];
    const keys = newTbData.keys();
    for(let i of keys) {
      newTbData[i].report = key;
    }
    setTsBody(newTbData);
    setTsHead(newTSData);
  }
  
  const handleDelClick=(e)=>{
    e.preventDefault();
    
    if(tsBody.length > 0) {
    
      let newTbData = tsBody.filter(item => (item.checked === false));
      let length = tsBody.length - newTbData.length;
      if(length > 0) {
        if (window.confirm("Press OK to delete " + length  + " selected item(s)")) {
          setTsBody(newTbData);
        }
      }
    }
  }
  
  function changeDataToggleTrue(obj) {
    obj.toggle = true;
    return obj;
  }

  function changeDataToggleFalse(obj) {
    obj.toggle = false;
    return obj;
  }
  
  const handleExpandCollapseClick=(e)=>{
    e.preventDefault();   
    const newTsData = {...tsHead};
    
    if(e.target.innerText === "Collapse All") {
      setCollapseButtonState("Expand All");
      let newTbData = tsBody.map(changeDataToggleFalse);
      setTsBody(newTbData);
      newTsData.toggle = false;
    } else {
      setCollapseButtonState("Collapse All");
      let newTbData = tsBody.map(changeDataToggleTrue);
      setTsBody(newTbData);
      newTsData.toggle = true;
    }
    
    setTsHead(newTsData);
  }

  function changeDataCheckedTrue(obj) {
    obj.checked = true;
    return obj;
  }

  function changeDataCheckedFalse(obj) {
    obj.checked = false;
    return obj;
  }
  
  const handleSelectAllClick=(e)=>{
    e.preventDefault();   

    if(tsBody.length > 0) {
      if(e.target.innerText === "Select All") {
        setSelectAllState("Deselect All");
        let newTbData = tsBody.map(changeDataCheckedTrue);
        setTsBody(newTbData);
      } else {
        setSelectAllState("Select All");
        let newTbData = tsBody.map(changeDataCheckedFalse);
        setTsBody(newTbData);
      }
    }
  }

  const handleAddClick=(e)=>{
    e.preventDefault();
    
    const newTCData = {
      tcname:"",
      startindex:0,
      deltaindex:0,
      endindex:10,
      timeout: 300000,
      repeat: 1,
      tolerance: 10,
      plugin: 100,
      type: 900,
      freq: 100,
      report: 0,
      attribs: "",
      toggle: false,
      checked: false,
      tmplts: [],
    };
    
    const newTbData = [...tsBody];

    //for(let i=0; i<502; i++)
    newTbData[newTbData.length] = newTCData;
    setTsBody(newTbData);
  }
  
  function getUniqueKey(i) {
    let key = "1st" + i;
    return key;
  }
  
  function getTableRowColor(tindex) {
    if((tindex%2) == 0) {
      return "#b3e6ff";
    }
    return "#66ccff";
  }
  
  function tcCellCBClicked(tindex) {    
    const newTbData = [...tsBody];
    newTbData[tindex].checked = !(tsBody[tindex].checked);
    setTsBody(newTbData);
  }
  
  function tsThinClicked() {
    const newTsData = {...tsHead};
    newTsData.toggle = !(newTsData.toggle);
    setTsHead(newTsData);
  }

  function tsUpperExpand() {
    const newTsData = {...tsHead};
    newTsData.toggle = true;
    setTsHead(newTsData);
  }

  function tsUpperCollapse() {
    const newTsData = {...tsHead};
    newTsData.toggle = false;
    setTsHead(newTsData);
  }
    
  function tcCellClicked(tindex) {
    const newTbData = [...tsBody];
    newTbData[tindex].toggle = !(tsBody[tindex].toggle);
    setTsBody(newTbData);
  }
  
  function getInnerDivKey(tindex) {
    let key = "innerdiv" + tindex;
    return key;
  }

  function setProgressBar(value) {
    tsHead.progress = value;
    const newTsData = {...tsHead};
    setTsHead(newTsData);
  }


  function increaseProgress() {
    tsHead.progress = tsHead.progress + 50;
    const newTsData = {...tsHead};

    if(newTsData.progress > 100) {
      newTsData.progress = 100;
      setTsHead(newTsData);
      myTimeOut = setTimeout(completeProgress, 5000);
    } else {
      setTsHead(newTsData);
      if(tsBody.length > 0) {
        myTimeOut = setTimeout(increaseProgress, 200);
      } else {
        myTimeOut = setTimeout(increaseProgress, 100);
      }
    }
  }

  function completeProgress() {
    if(tsHead.progress >= 100) {
      tsHead.progress = 0;
      const newTsData = {...tsHead};
      setTsHead(newTsData);
      clearTimeout(myTimeOut); 
    }
  }

  var user = JSON.parse(localStorage.getItem('user'));

  const handleSaveAll = async (e) => {

    var recordId = -1;

    let tsName = tsHead.tsName.trim();
    if(tsName === "") {
      if(tsHead.toggle === false) {
        tsUpperExpand();
        e.preventDefault();
        alert("Please enter test suite name");
        return;
      }
    }

    let index = 0;
    while(index < tsBody.length) {
      if(tsBody[index].tcname.trim() === "") {
        e.preventDefault();
        alert(`Test block ${index+1}, please enter test case name`);
        return;
      }
      
      if(tsBody[index].attribs.trim() === "") {
        e.preventDefault();
        alert(`Test block ${index+1}, please enter user data`);
        return;
      }
      
      index++;
    }

    e.preventDefault();

    if(user) {
      if (window.confirm("You are about to save test suite " + tsHead.tsName  + ", press OK to confirm")) {

        //Create a new object reqdTsHead excluding plugin, type, freq, report, toggle members 
        const {plugin, type, freq, report, toggle, progress, ...reqdTsHead} = tsHead;
        
        try {
          await axios.post(
            '/api/testsuite/data/save', 
            {uid: user.uid, email: user.email, data: JSON.stringify(reqdTsHead)},
            {headers: {Authorization: user.jwt,}},
          ).then( function (res) {
            if(res.status === 200) {
              recordId = res.data.tsId;

              tsHead.tsId = recordId;
              const newTSHead = {...tsHead};
              setTsHead(newTSHead); 
              
              setProgressBar(5);
            } else {
              console.log("TS save, got error " + res.status);
            }
          })
        }catch(err) {
          handleErrorResponse(err);
        }

        if(recordId > 0) {
          if(tsBody.length > 0) {
            //Create array of new objects excluding toggle, checked property of array elements (object)
            const reqdTsBody = tsBody.map(({toggle, checked, tmplts, ...rest}) => rest);
            const tbJsonData = JSON.stringify(reqdTsBody);
            const tsBodySha256 = CryptoJS.SHA256(tbJsonData).toString();
            
            try {
              await axios.post(
                '/api/testblocks/data/save',
                {uid: user.uid, email: user.email, tsid: recordId, sha256: tsBodySha256, data: tbJsonData},
                {headers: {Authorization: user.jwt,}},
              ).then ( function (res2) {
                if(res2.status === 200) {
                  myTimeOut = setTimeout(increaseProgress, 200);
                } else {
                  console.log("TS blocks save, got error " + res2.status);
                }
              });
            } catch(err2) {
              handleErrorResponse(err2);
            }
          } else {
            myTimeOut = setTimeout(increaseProgress, 100);
          }
        } else {
          console.log(`Error in testblocks save/update, tsid=${recordId} and test blocks array length=${tsBody.length}`);
        }
      }
    }    
  };
 

  const fetchTestSuite = async () => {
    if(Math.round(tsHead.tsId) === -1) {
      return;
    }

    var flag = 0;
    
    try {
      await axios.post('/api/testsuite/data/fetch', 
      {uid: user.uid, email: user.email, tsid: tsHead.tsId},
      {headers: {Authorization: user.jwt,}}).then (function (res) {
        if(res.status === 200) {
          if(res.data.length > 0) {
            const newTSData = {...tsHead};
            newTSData.tsDesc = res.data[0].description;
            newTSData.tsName = res.data[0].name;
            setTsHead(newTSData);
            flag = 1;
          } else {
              console.log("No TS record found");
          }          
        } else {
          console.log("Error in searching TS record");
        } 
      });
      
    } catch (err) {
      handleErrorResponse(err);
    }
    
    if(flag === 1) {
      try {
       
        await axios.post('/api/testblocks/data/fetch',
        {uid: user.uid, email: user.email, tsid: tsHead.tsId},
        {headers: {Authorization: user.jwt,}}).then (function (res) {
          
          if(res.status === 200) {
            
            let index=0;
            const newTbData = [...tsBody];
            while(index < res.data.length) {
              const newTCData = {};

              newTCData.tcname = res.data[index].name;
              newTCData.plugin = res.data[index].plugin;
              newTCData.type = res.data[index].type;
              newTCData.freq = res.data[index].freq;
              newTCData.report = res.data[index].report;
              newTCData.startindex = res.data[index].startindex;
              newTCData.deltaindex = res.data[index].deltaindex;
              newTCData.endindex = res.data[index].endindex;
              newTCData.timeout = res.data[index].timeout;
              newTCData.repeat = res.data[index].repeat;
              newTCData.tolerance = res.data[index].tolerance;
              newTCData.attribs = res.data[index].attribs;
              newTCData.toggle = false;
              newTCData.checked = false;
              newTCData.tmplts = [];
              
              res.data[index].tmplts.forEach(obj => {
                newTCData.tmplts.push({id: obj.id, name: obj.name});
              });
              
              newTbData[index] = newTCData;
              index++;
            }
            
            setTsBody(newTbData);
            
          } else {
            console.log("No TS record found");
          }
        })
      } catch (err) {
        handleErrorResponse(err);
      }    
    }
    
  }

  //Hook to get data from backend server
  useEffect ( () => {
     fetchTestSuite();
  }, []);  
     
 
  if(user) 
  {
    return (
    <form name="tsform" id="tsform" onSubmit={handleSaveAll}>
      <div key="tsmain" className="medium-text">
        <div id="tsmain2" className="center" >
          {(tsHead.tsName.length == 0) ? 
            <h3><p align="center">Test Suite</p></h3> : 
            <h3><p align="center">Test Suite [{tsHead.tsName}]</p></h3>}
                    
          { 
            tsHead.toggle ? (

              <div id="tsmain3" className="txupper"> 
                
                <div id="tsmain2" style={{cursor: "zoom-out"}} onClick={()=>{tsThinClicked()}} >
                  <p align="right">
                    <button className="minibuttons" onClick={tsThinClicked}></button>
                  </p>
                </div>
                
                <label>Test suite name</label>
                
                <input required name="name" style={{width: "100%"}}
                value={tsHead.tsName}
                onChange={(e)=>handleTSNameChange(e.target.value)} /> 

                <br/><label>Test suite description</label>
                <textarea value={tsHead.tsDesc} name="description" 
                  spellCheck="false" 
                  rows={4} cols={5}  style={{width: "100%"}}
                  onChange={(e) => handleTSDescChange(e.target.value)}>                                      
                </textarea> 


                <br/><br/><br/><h3><p align="center" >Controls</p></h3>
  
                <br/>
                <table key={`tscontrols`} className="tstbl" style={{width: "100%"}}>
                  <tbody>
                    <tr>
                      <th style={{width: "25%"}}>
                        <Tooltip title="Select plugin to change in all test blocks." arrow>
                          <label>Plugin</label>
                        </Tooltip>
                      </th>
                      <th style={{width: "25%"}}>
                        <Tooltip title="Select type to change in all test blocks." arrow>
                          <label>Type</label>
                        </Tooltip>  
                      </th>
                      <th style={{width: "25%"}}>
                        <Tooltip title="Select frequency to change in all test blocks." arrow>
                          <label>Frequency</label>
                        </Tooltip>
                      </th>
                      <th style={{width: "25%"}}>
                        <Tooltip title="Select report to change in all test blocks." arrow>
                          <label>Report</label>
                        </Tooltip>
                      </th>
                    </tr> 
                    <tr>
                      <td>
                        <select style={{width: "100%"}} value={pluginObj[tsHead.plugin]} onChange={handleTSControlPluginChange}>
                          {Object.keys(pluginObj).map(item => 
                            <option key={`tsplugin${item}`}>
                            {pluginObj[item]}
                            </option>
                          )}
                        </select>                        
                      </td>
                      <td>
                        <select style={{width: "100%"}} value={blockTypesObj[tsHead.type]} onChange={handleTSControlTypeChange}>
                          {Object.keys(blockTypesObj).map(item => 
                            <option key={`tstype${item}`}>
                              {blockTypesObj[item]}
                            </option>
                          )}
                        </select>                        
                      </td>
                      <td>
                        <select style={{width: "100%"}} value={frequencyObj[tsHead.freq]} onChange={handleTSControlFrequencyChange}>
                          {Object.keys(frequencyObj).map(item => 
                            <option key={`tsfreq${item}`}>
                              {frequencyObj[item]}
                            </option>
                          )}
                        </select>                        
                      </td>
                      <td>
                        <select style={{width: "100%"}} value={reportObj[tsHead.report]} onChange={handleTSControlReportChange}>
                          {Object.keys(reportObj).map(item => 
                            <option key={`tsreport${item}`}>
                              {reportObj[item]}
                            </option>
                          )}
                        </select>                        
                      </td>

                    </tr>
                  </tbody>
                </table>
                
                
                
              </div>  
              
            ) : (
              <div id="tsmain3" className="txthinner" 
              style={{cursor: "zoom-in", background: getTableRowColor(1)}} onClick={()=>{tsThinClicked()}}
              >
              TS details & Control elements
              </div>
            ) 
          }

          
          <h3><p align="center"><br/><br/>Test Blocks</p></h3>
          {
            tsBody.map((val,tindex)=> 
              <div key={getInnerDivKey(tindex)} className="tstbl">
              <table key={`tblmain{tindex}`} >

                <tbody>
                  { val.toggle ? (<tr  key={`tblmainrow{tindex}`}

                    style={{background: getTableRowColor(tindex)}}  >
                    <td style={{cursor: "zoom-out"}} onClick={()=>{tcCellClicked(tindex)}} key={`tblmaintc{tindex}`}> 
                      <b>&nbsp;{tindex+1}</b>&nbsp;&nbsp;
                    </td>

                    <td>
                      <table key={tindex}>
                      
                        <tbody key={`tbd{tindex}`}>
                          <tr key={getUniqueKey(tindex)}>
                            <td>
                              <br/>
                              Test case name<br/>
                              <input required name="tcname" value={val.tcname} 
                              onChange={(e)=>handleChange(e,tindex)} />
                            </td>
                            <td>
                              <br/>
                              Start index<br/>
                              <input required name="startindex" value={val.startindex} 
                              onChange={(e)=>handleChange(e,tindex)} />
                            </td>
                            <td>
                              <br/>
                              Delta index<br/>
                              <input required size={8} name="deltaindex" value={val.deltaindex} 
                              onChange={(e)=>handleChange(e,tindex)} />
                            </td>
                            <td>
                              <br/>
                              End index<br/>
                              <input required size={8} name="endindex" value={val.endindex} 
                              onChange={(e)=>handleChange(e,tindex)} />
                            </td>
                          </tr>

                          <tr key={`2nd{i}`}>
                            <td>
                              Timeout<br/>
                              <input required name="timeout" value={val.timeout} 
                              onChange={(e)=>handleChange(e,tindex)} />
                            </td>
                            <td>
                              Repeat<br/>
                              <input required name="repeat" value={val.repeat} 
                              onChange={(e)=>handleChange(e,tindex)} />
                            </td>
                            <td>
                              Tolerance<br/>
                              <input required size={8} name="tolerance" value={val.tolerance} 
                              onChange={(e)=>handleChange(e,tindex)} />
                            </td>
                            <td>
                              Plugin<br/>
                              <select value={pluginObj[val.plugin]} onChange={(e)=>handleTSPluginChange(e,tindex)} >

                                {Object.keys(pluginObj).map(item => 
                                  <option key={item}>
                                    {pluginObj[item]}
                                  </option>
                                )}

                              </select>                        
                            </td>
                          </tr>
                          <tr key={`3rd{i}`}>
                            <td>
                              Type <br/>
                              
                              <select value={blockTypesObj[val.type]} onChange={(e)=>handleTSTypeChange(e,tindex)}>

                                {Object.keys(blockTypesObj).map(item => 
                                  <option key={item}>
                                    {blockTypesObj[item]}
                                  </option>
                                )}

                              </select>                        
                            </td>
                            <td>
                              Frequency<br/>
                              <select value={frequencyObj[val.freq]} onChange={(e)=>handleTSFreqChange(e,tindex)}>
                              
                                {Object.keys(frequencyObj).map(item => 
                                  <option key={item}>
                                    {frequencyObj[item]}
                                  </option>
                                )}
                              
                              </select>                        
                            </td>
                            <td>
                              Report <br/>
                              <select  value={reportObj[val.report]} onChange={(e)=>handleTSReportChange(e,tindex)}>
                              
                                {Object.keys(reportObj).map(item => 
                                  <option key={item}>
                                    {reportObj[item]}
                                  </option>
                                )}
                              
                              </select>                        
                            </td>
                            <td>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      
                      <p align="center">
                      {
                        val.tmplts.map((item) => (
                          <a href={`/components/editsingletemplates?id=${item.id}`}> <br/>[ {item.name} ] </a>
                        ))
                      }
                      </p>
                      
                      <table>
                        <tbody>
                          <tr key={`4th{i}`}>
                            <td>
                              <br/>Configurations / Instructions
                              <br/>
                              <textarea required name="attrib" rows={8} cols={45} 
                                spellCheck="false" 
                                value={val.attribs}
                                onChange={(e)=>handleTextAreaChange(e,tindex)}                                      
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>

                    <td key={`tblrowtc{tindex}`} > 
                      <Tooltip title="Insert test block just above this." arrow>
                        <button className="miniimgbtns" onClick={(e)=>insertOneTestBlock(e,tindex)}> ++ </button>
                      </Tooltip>
                      &nbsp;<input type="checkbox" id={`chk{tindex}`} 
                        onChange={()=>{tcCellCBClicked(tindex)}} 
                        checked={val.checked}
                      />
                      &nbsp;
                    </td>
                    
                  </tr>
                  ) : 
                  (
                    <tr key={`tblmainrow{tindex}`} style={{background: getTableRowColor(tindex)}}  >
                    <td style={{cursor: "zoom-in"}} onClick={()=>{tcCellClicked(tindex)}} key={`tblmaintc{tindex}`}> 
                      <b> &nbsp;{tindex+1}</b>&nbsp;&nbsp;
                    </td>                              

                    <td style={{cursor: "zoom-in", width:"610px"}} onClick={()=>{tcCellClicked(tindex)}}>
                      {val.tcname ? val.tcname : "None"}
                    </td>
                    
                    <td key={`tblrowtc{tindex}`}> 
                      <Tooltip title="Insert test block just above this." arrow>
                        <button className="miniimgbtns" onClick={(e)=>insertOneTestBlock(e,tindex)}> ++ </button>
                      </Tooltip>
                      &nbsp;<input type="checkbox" id={`chk{tindex}`} 
                      onChange={()=>{tcCellCBClicked(tindex)}} 
                      checked={val.checked} 
                    />
                      &nbsp;
                    </td>
                    
                    </tr>
                  )} 
                </tbody>
              </table>
              </div>
            )
          }
          </div>
          <div className="inlinecenter">
            <Line className="progressbar" style={{visibility: (tsHead.progress > 0) ? "visible" : "hidden"}} percent={tsHead.progress} strokeWidth="1" strokeColor="#009973" />
            <br/>
            
            <button onClick={handleAddClick}>Add</button> 
            <button onClick={handleDelClick}>Delete</button>
            <button onClick={handleExpandCollapseClick}>{collapseButtonState}</button>
            <button onClick={handleSelectAllClick}>{selectAllState}</button>
            <button>Save</button>
          </div>
        </div>
        
      </form>      
    );
  } 
  else 
  {
    return(
      <div key="tsmain" className="medium-text">
        <div id="tsmain2" className="center" >
          You are not allowed to access this page.
        </div>
      </div>
    );
  }
};

const EditSingleTestSuites = () => {
  return TestSuites();
};
  
const EditTestSuites = () => {
  const initState = [{id: 0, name: "", checked: false}];
  const [state, setState] = useState(initState);
  const [status, setStatus] = useState(null);
  const [selectAllBtnText, setSelectAllBtnText] = useState("Select All");

  var user = null;
  const userData = localStorage.getItem('user');

  if(userData != null) {
    user = JSON.parse(userData);
  }

  setPageTitle("Test Suites");
  
  const fetchTestSuites = async () => {
    setStatus(null);
    if(user != null) {
      try {
        const res = await axios.post('/api/testsuites/edit', 
        {uid: user.uid, email: user.email} ,
        {headers: {
          'Authorization': user.jwt,
        }}).then( 
        function (res) {
          if(res.status === 200) {
            //This also works, keeping it as reference
            //const newState = res.data.map(obj => ({...obj, checked: false}));
            //console.log(newState);
            
            res.data.forEach(element => (element.checked = false));
            //console.log(res.data);

            setState(res.data);
          }else {
            setStatus({
              type: 'error',
              message: 'Error in displaying data',
            });  
          }
        });
      }catch(err) {
        setStatus({
          type: 'error',
          message: err.message,
        }); 
        handleErrorResponse(err);
      }
    } else {
      setStatus({
        type: 'error',
        message: "User not logged in",
      });  
    }
  }
  
  useEffect ( () => {
   fetchTestSuites();
  }, []);
  

  function tsRowCBClicked(tindex) {
    const newState = [...state];
    newState[tindex].checked = !(state[tindex].checked);
    setState(newState);
  }

  const handleEditTsDelete= async (e)=> {
    e.preventDefault();
      
    if(state.length > 0) {
     
      let delIds = [];
      let newState = [];
      
      state.forEach(function (element) {
        if(element.checked) {
          delIds.push(element.id);
        } else {
          newState.push(element);
        }
      });
      
      
      if(delIds.length > 0) {
        if (window.confirm("Press OK to delete " + delIds.length  + " selected testsuite(s)")) {
          
          try {
            const res = await axios.post('/api/testsuites/data/delete', 
            {uid: user.uid, email: user.email, data: JSON.stringify(delIds) } ,
            {headers: {
              'Authorization': user.jwt,
            }}).then( 
            function (res) {
              if(res.status === 200) {
                setState(newState);
              }else {
                setStatus({
                  type: 'error',
                  message: 'Error in displaying data',
                });  
              }
            });
          }catch(err) {
            setStatus({
              type: 'error',
              message: err.message,
            }); 
            handleErrorResponse(err);
          } 
        }
      }
    }
  }
  
  function makeTSRowChecked(obj) {
    obj.checked = true;
    return obj;
  }

  function makeTSRowUnchecked(obj) {
    obj.checked = false;
    return obj;
  }
  
  const handleEditTsSelectAll=(e)=> {
    e.preventDefault();
    
    if(state.length > 0) {
      if(e.target.innerText === "Select All") {
        setSelectAllBtnText("Deselect All");
        const newState = state.map(makeTSRowChecked);
        setState(newState);
      } else {
        setSelectAllBtnText("Select All");
        const newState = state.map(makeTSRowUnchecked);
        setState(newState);
      }
    } 
  }
  
  if(user === null) {
    window.location.href = "/authrequired";
  } else {
    var tindex = 0;
    return (
      <div className="medium-text">
        <div className="center">
          <h3><p align="center">Test Suites</p></h3>
          
          <table className="tealtbl">
            <thead>
              <tr>
                <th>Serial</th>
                <th>Test Suite</th>
                <th>Select</th>
              </tr>
            </thead>
            
            <tbody>
              {state.map((item, index) => (
                <tr key={`row${index}`}>
                  <td>{(++tindex)}</td>
                  <td><a href={`/components/editsingletestsuites?id=${item.id}`}>{item.name}</a></td>
                  <td>
                    <input type="checkbox" id={`chk{index}`} 
                      onChange={()=>{tsRowCBClicked(index)}} 
                      checked={item.checked} 
                    />
                  
                  </td>
                </tr>
              ))}
            </tbody>
            
          </table> 
        </div>

        <div className="inlinecenter small-text">
          {status && 
            <div className={`alert ${status.type}`}>{status.message}</div>}
              <button onClick={handleEditTsDelete}>Delete</button>
              <button onClick={handleEditTsSelectAll}>{selectAllBtnText}</button>
          </div>
        </div>
    );
  }
};

export {TestSuites, EditSingleTestSuites, EditTestSuites};
