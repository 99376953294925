
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGFlexiSIPVariables = () => {

  setPageTitle('FlexiSIP Variables');
  
  const element = (
    <div className="userguide">
      <p>
        <h1>FlexiSIP Variables</h1>
        <br/>

        Multiplier plugins are dependent on variables to large extent. There are different kind of variables like normal variable, system variable, plugin variable and relative variables. Here normal variables and plugin variables are discussed. 

        <h3>Flexi SIP Normal Variables</h3>
        The normal variables starts with <i>[</i>  and ends with <i>]</i>. For example [count] is a normal variable, variable name is count. Each variable has name, type and value. The string type variables has additionally length as well. You can use normal variables to store data, extract data and fetch data.

        <h3>Flexi SIP Length Variables</h3>
        The length variables starts with <i>[l-</i>  and ends with <i>]</i>. For example [l-string1] is a length variable, that returns length of variable <i>string1</i>, string1 being a variable containing a string. 

        <h3>Flexi SIP Plugin Variables</h3>
        Flexi SIP plugin provides internal variables to user known as <i><b>plugin variables</b></i> to enable to fetch plugin internal values. The plugin variables are written in a special way, start with <i>[p-</i> and ends with <i>]</i>. For example <i>[p-random-string]</i> is a plugin variable that gives a random digit-only string.

        <br/><br/>Before explaining each and every plugin variable, lets discuss some basic attributes. In create-instance test case you have set <i>id</i>. Thats nothing but <i>base index</i>. It can be 0, 1000 or any positive integer value. Each endpoint in system identified with an index like 0, 1, 2 etc. The absolute index or mdn is sum of base index and endpoint index. Assuming if base index is 1000, endpoint index is 5, the absolute index of endpoint is 1005.

        <br/><br/>Sometimes you will come across session specific plugin variables like [p-var-name:session-id]. In such cases variable's value for session identified by session-id is returned. 

        <ol>
        <li><div className="code">[p-client-index]</div>
        This returns absolute index of endpoint. If base index is 100, endpoint index is 5, it returns 105.
        </li>
        
        <br/><li><div className="code">[p-calling-party-index]</div>
        This returns calling party index.
        </li>

        <br/><li><div className="code">[p-called-party-index]</div>
        This returns called party index.
        </li>

        <br/><li><div className="code">[p-private-user]</div>
        You have set private-user (private user prefix) in create-instance. The plugin variable [p-private-user] returns concatenation of private user prefix and absolute mdn. For example, if private-user you have set <i>multiplier_</i>, id you have set <i>1000</i> and endpoint index is 5, then [p-private-user] returns <i>multiplier_1005</i>.
        </li>

        <br/><li><div className="code">[p-private-password]</div>
        You have set private-password (private password prefix) in create-instance. The plugin variable [p-private-password] returns concatenation of private password prefix and absolute mdn. For example, if private-password you have set <i>multiplier_</i>, id you have set <i>1000</i> and endpoint index is 5, then [p-private-password] returns <i>multiplier_1005</i>.
        </li>

        <br/><li><div className="code">[p-public-user]</div>
        You have set public-user (public user prefix) in create-instance. The plugin variable [p-public-user] returns concatenation of public user prefix and absolute mdn. For example, if public-user you have set <i>multiplier_</i>, id you have set <i>1000</i> and endpoint index is 5, then [p-public-user] returns <i>multiplier_1005</i>.
        </li>

        <br/><li><div className="code">[p-public-password]</div>
        You have set public-password (public password prefix) in create-instance. The plugin variable [p-public-password] returns concatenation of public password prefix and absolute mdn. For example, if public-password you have set <i>multiplier_</i>, id you have set <i>1000</i> and endpoint index is 5, then [p-public-password] returns <i>multiplier_1005</i>.
        </li>

        <br/><li><div className="code">[p-server]</div>
        The plugin variable [p-server] returns server value you have configured in create-instance. 
        </li>

        <br/><li><div className="code">[p-server-port]</div>
        This returns server port you have configured in create-instance.
        </li>

        <br/><li><div className="code">[p-transport]</div>
        This plugin variable returns transport protocol you have configured in create-instance. Possible values are UDP / TCP / TLS / DTLS / SCTP / WS / WSS. Please note that WS stands for websocket and WSS stands for secured websocket.
        </li>

        <br/><li><div className="code">[p-local-ipaddress]</div>
        The plugin variable [p-local-ipaddress] returns local-ipaddress value you have configured in create-instance.
        </li>

        <br/><li><div className="code">[p-domain]</div>
        The plugin variable [p-domain] returns domain value you have configured in create-instance. 
        </li>

        <br/><li><div className="code">[p-realm]</div>
        The plugin variable [p-realm] returns realm value you have configured in create-instance. 
        </li>

        <br/><li><div className="code">[p-server-port]</div>
        The plugin variable [p-server-port] returns server-port value you have configured in create-instance. 
        </li>

        <br/><li><div className="code">[p-local-client-port]</div>
        The plugin variable [p-local-client-port] returns endpoints client transport connection source port. 
        </li>

        <br/><li><div className="code">[p-called-party-port:session_id_1]</div>
        This plugin variable has two tokens. First token <i>p-called-party-port</i> and second token is <i>session_id_1</i>. Whenever you see session id as second token, you can assume these kind of plugin variables are session specific. For this case, remote endpoint's (of session identified by key session_id_1)  port is returned. 
        </li>

        <br/><li><div className="code">[p-called-party-ipaddress:session_id_1]</div>
        Remote endpoint (of session identified by session_id_1) url host portion is returned for this plugin variable.
        </li>

        <br/><li><div className="code">[p-local-server-port]</div>
        This plugin variable returns endpoints server transport connection listen port. 
        </li>

        <br/><li><div className="code">[p-called-party-user:session_id_1]</div>
        Remote endpoint (of session identified by session_id_1) url user portion is returned for this plugin variable.
        </li>

        <br/><li><div className="code">[p-current-time]</div>
        This returns system current time. By default it returns in Y:m:d:H:M:S format. If you want in a different format, you can specify format like <i>[p-current-time:format]</i>. Internally strftime is used to generate time in given format. So for all possible format 
        see <a href="http://man7.org/linux/man-pages/man3/strftime.3.html">here</a>.
        </li>

        <br/><li><div className="code">[p-time:offset:format]</div>
        Sometime you need current time with given offset in a presentable format. Like current time + 10 minutes or current time - 10 minutes with a presentable format. In such case, please make use of this variable. Note that offset is in millisecond unit. 
        <br/><b>Examples</b>
        <ul>
        <li>[p-time] - Current time in %Y:%m:%d:%H:%M:%S format (default format)</li>
        <li>[p-time:0:%H:%M] - Current time, with no additional time in %H:%M format</li>
        <li>[p-time:600000:%H:%M] - Current time, with more 10 minutes in %H:%M format</li>
        <li>[p-time:-600000:%H:%M] - Current time, with less 10 minutes in %H:%M format</li>
        </ul>
        Please note that you can use any permissible format 
        given <a href="http://man7.org/linux/man-pages/man3/strftime.3.html">here</a>.

        <br/><br/><li><div className="code">[p-millisec:offset]</div></li>
        This returns current time in millisecond with given offset. Please note that offset is in milliseconds unit.
        <br/><b>Examples</b>
        <ul>
        <li>[p-millisec] - Current time in millseconds</li>
        <li>[p-millisec:600000] - Current time in milliseconds with more 10 minutes time</li>
        <li>[p-millisec:-600000] - Current time in milliseconds with less 10 minutes time</li>
        </ul>
        </li>

        <br/><li><div className="code">[p-microsec:offset]</div>
        This returns current time in microseconds with given offset. Please note that offset is in microseconds unit.
        <br/><b>Examples</b>
        <ul>
        <li>[p-microsec] - Current time in microseconds</li>
        <li>[p-microsec:600000000] - Current time in microseconds with more 10 minutes time</li>
        <li>[p-microsec:-600000000] - Current time in microseconds with less 10 minutes time</li>
        </ul>
        </li>

        <br/><li><div className="code">[p-random-string]</div>
        This returns 7 byte decimal string. If you want a different size string, you can mention size as well like <i>[p-random-string:n]</i>, where n is the size of string. 
        </li>

        <br/><li><div className="code">[p-random-hex-string]</div>
        This returns 7 byte hexadecimal string. If you want a different size string, you can mention size as well like <i>[p-random-hex-string:n]</i>, where n is the size of string. 
        </li>

        <br/><li><div className="code">[p-random-number-u16]</div>
        This returns 2 byte decimal number.
        </li>

        <br/><li><div className="code">[p-fingerprint:session_id_1]</div>
        Sometime you need to use certificate fingrprint. One such typical case is, in a webrtc call in sdp you need to put fingerprint. This plugin variable returns endpoints certificate fingerprint. 
        </li>

        <br/><li><div className="code">[p-audio-codec]</div>
        This returns audio codec you have configured in config-sdp test case under session-config block. 
        </li>

        <br/><li><div className="code">[p-video-codec]</div>
        This returns video codec you have configured in config-sdp test case under session-config block. 
        </li>

        <br/><li><div className="code">[p-audio-media-ip:session_id_1]</div>
        This returns audio transport associated local ip address for session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-audio-rtp-port:session_id_1]</div>
        This returns audio transport associated local rtp port for session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-audio-rtcp-port:session_id_1]</div>
        This returns audio transport associated local rtcp port for session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-video-media-ip:session_id_1]</div>
        This returns video transport associated local ip address for session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-video-rtp-port:session_id_1]</div>
        This returns video transport associated local rtp port for session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-video-rtcp-port:session_id_1]</div>
        This returns video transport associated local rtcp port for session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-ice-audio-media-ip:session_id_1]</div>
        This returns audio transport ICE local ip address for session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-ice-audio-rtp-port:session_id_1]</div>
        This returns audio transport ICE local rtp port for session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-ice-audio-rtcp-port:session_id_1]</div>
        This returns audio transport ICE local rtcp port for session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-ice-video-media-ip:session_id_1]</div>
        This returns video transport ICE local ip address for session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-ice-video-rtp-port:session_id_1]</div>
        This returns video transport ICE local rtp port for session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-ice-video-rtcp-port:session_id_1]</div>
        This returns video transport ICE local rtcp port for session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-ice-remote-audio-ip:session_id_1]</div>
        This returns audio transport ICE remote ip address for session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-ice-remote-audio-rtp-port:session_id_1]</div>
        This returns audio transport ICE remote rtp port for session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-ice-remote-audio-rtcp-port:session_id_1]</div>
        This returns audio transport ICE remote rtcp port for session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-ice-remote-video-ip:session_id_1]</div>
        This returns video transport ICE remote ip address for session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-ice-remote-video-rtp-port:session_id_1]</div>
        This returns video transport ICE remote rtp port for session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-ice-remote-video-rtcp-port:session_id_1]</div>
        This returns video transport ICE remote rtcp port for session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-audio-srtp-initial-encrypt-key:session_id_1]</div>
        This returns audio session initial srtp encryption key of session identified by session_id_1. Used when call uses SRTP only (no DTLS).
        </li>

        <br/><li><div className="code">[p-video-srtp-initial-encrypt-key:session_id_1]</div>
        This returns video session initial srtp encryption key of session identified by session_id_1. Used when call uses SRTP only (no DTLS).
        </li>

        <br/><li><div className="code">[p-audio-rtp-ufrag:session_id_1]</div>
        This returns ICE rtp ufrag of audio session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-audio-rtp-icepasswd:session_id_1]</div>
        This returns ICE rtp password of audio session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-audio-rtp-host-candidate:session_id_1]</div>
        This returns ICE rtp host candidate for audio session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-audio-rtp-srflx-candidate:session_id_1]</div>
        This returns ICE rtp server reflexive candidate for audio session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-audio-rtp-relay-candidate:session_id_1]</div>
        This returns ICE rtp relay candidate for audio session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-audio-rtcp-host-candidate:session_id_1]</div>
        This returns ICE rtcp host candidate for audio session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-audio-rtcp-srflx-candidate:session_id_1]</div>
        This returns ICE rtcp server reflexive candidate for audio session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-audio-rtcp-relay-candidate:session_id_1]</div>
        This returns ICE rtcp relay candidate for audio session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-audio-rtcp-ufrag:session_id_1]</div>
        This returns ICE rtcp ufrag of audio session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-audio-rtcp-icepasswd:session_id_1]</div>
        This returns ICE rtcp password of audio session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-video-rtp-ufrag:session_id_1]</div>
        This returns ICE rtp ufrag of video session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-video-rtp-icepasswd:session_id_1]</div>
        This returns ICE rtp password of video session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-video-rtp-host-candidate:session_id_1]</div>
        This returns ICE rtp host candidate for video session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-video-rtp-srflx-candidate:session_id_1]</div>
        This returns ICE rtp server reflexive candidate for video session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-video-rtp-relay-candidate:session_id_1]</div>
        This returns ICE rtp relay candidate for video session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-video-rtcp-host-candidate:session_id_1]</div>
        This returns ICE rtcp host candidate for video session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-video-rtcp-srflx-candidate:session_id_1]</div>
        This returns ICE rtcp server reflexive candidate for video session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-video-rtcp-relay-candidate:session_id_1]</div>
        This returns ICE rtcp relay candidate for video session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-video-rtcp-ufrag:session_id_1]</div>
        This returns ICE rtcp ufrag of video session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-video-rtcp-icepasswd:session_id_1]</div>
        This returns ICE rtcp password of video session identified by session_id_1.
        </li>

        <br/><li><div className="code">[p-local-audio-ssrc:session_id_1]</div>
        This returns local ssrc value of audio session identified by session_id_1. 
        </li>

        <br/><li><div className="code">[p-remote-audio-ssrc:session_id_1]</div>
        This returns remote ssrc value of audio session identified by session_id_1. 
        </li>

        <br/><li><div className="code">[p-local-video-ssrc:session_id_1]</div>
        This returns local ssrc value of video session identified by session_id_1. 
        </li>

        <br/><li><div className="code">[p-remote-video-ssrc:session_id_1]</div>
        This returns remote ssrc value of video session identified by session_id_1. 
        </li>

        <br/><li><div className="code">[p-local-audio-seq-num:session_id_1]</div>
        This returns local sequence number of audio session identified by session_id_1. 
        </li>

        <br/><li><div className="code">[p-local-video-seq-num:session_id_1]</div>
        This returns local sequence number of video session identified by session_id_1. 
        </li>

        <br/><li><div className="code">[p-remote-audio-seq-num:session_id_1]</div>
        This returns remote sequence number of audio session identified by session_id_1. 
        </li>

        <br/><li><div className="code">[p-remote-video-seq-num:session_id_1]</div>
        This returns remote sequence number of video session identified by session_id_1. 
        </li>

        <br/><li><div className="code">[p-local-audio-cname:session_id_1]</div>
        This returns local cname value for audio session identified by session_id_1. 
        </li>

        <br/><li><div className="code">[p-local-video-cname:session_id_1]</div>
        This returns local cname value for video session identified by session_id_1. 
        </li>

        <br/><li><div className="code">[p-transport]</div>
        This returns transport you have configured in create-instance.
        </li>
        </ol>
        
        <br/><br/>
        
        <div className="inlinecenter">
          <button onClick={() => window.location.href="/userguide/apatinstructions" }>Back</button> 
          <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
          <button onClick={() => window.location.href="/userguide/flexisipsyntax" }>Next</button>
        </div>
      </p>
    </div>
  );
  
  return element;
}

export {UGFlexiSIPVariables}