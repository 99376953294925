
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGB2BASyntax = () => {

  setPageTitle('B2BA Syntax');
  
  const element = (
    <div className="userguide">
      <h1>B2BA Syntax</h1>
      <br/>
        B2BA syntax is similar across supported modules (HTTPD, APAT, SIP). 
        However for clarity and easy to use, syntax is explained in corresponding 
        module syntax page.
        <br/><br/>
        The best way to read the B2BA documentation is first pick a working example,
        then check variables, syntax and instructions of corresponding pages.
        <br/><br/>
        You may like to check modules specific syntax pages listed below.
        <ul>
        <li><a href="/userguide/apatb2basyntax">APAT B2BA syntax</a></li>
        <li><a href="/userguide/httpdb2basyntax">HTTPD B2BA syntax</a></li>
        <li><a href="/userguide/sipb2basyntax">SIP B2BA syntax</a></li>
        </ul>

        Check <a href="/components/scenarios?id=-1">scenario</a> page to create a B2BA based scenario to get started.        
      <br/>
      <br/>
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/b2bavariables" }>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/b2bainstructions" }>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGB2BASyntax}

