
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGSIPB2BAVariables = () => {

  setPageTitle('SIP B2BA Variables');
  
  const element = (
    <div className="userguide">
      <p>
        <h1>SIP B2BA Variables</h1>
        
        <br/>
        
    Multiplier plugins are dependent on variables to large extent. There are 
    different kind of variables like normal variable, plugin variable and 
    other party variables. Here normal variables, other party variables and 
    plugin variables are discussed. 
    
    <br/><br/>
    <h3>B2BA SIP Normal Variables</h3>
    The normal variables starts with <i>[</i>  and ends with <i>]</i>. 
    For example [count] is a normal variable, variable name is count. 
    Each variable has name, type and value. The string type variables has 
    additionally length as well. You can use normal variables to store data, 
    extract data and reuse data.

    <br/><br/>
    <h3>B2BA SIP Other Party Variables</h3>
    The other party variables are used to extract variables from other parties. The syntax for
    other party variable in B2BA looks as below.
    <br/><br/>
    <i>[o-varname:module:epid]</i>
    <br/><br/>
    As you can see above, other party variables starts with <i>[o-</i> and ends with
    <i>]</i>. It has three portions <i>varname</i>, <i>module</i> and <i>epid</i>. 
    First portion <i>varname</i> is variable name, second one is module name 
    (apat / sip / httpd) and third one is endpoint id.
    
    <br/><br/>
    <h3>B2BA SIP Plugin Variables</h3>
    B2BA SIP plugin exposes internal variables to users known as <i><b>plugin variables</b></i>. 
    This helps users to get internal data of system, which can be used for various purposes. 
    The plugin variables are written in a 
    special way, start with <i>[p-sip:</i> and ends with <i>]</i>. For example <i>[p-random-string]</i>
    is a plugin variable that gives a random digit-only string.

    <br/><br/>
    The general plugin variables start with <i>[p-</i> and ends with <i>]</i>. The
    B2BA general plugin variables (valid for all B2BA modules) are  
    documented <a href="/userguide/b2bavariables">here</a>. 

    <br/><br/>
    Please note that below plugin variables are valid for only B2BA SIP module.
    <br/><br/>
    <ol>
    
    <li><div className="code">[p-sip:local-ip]</div>
    This returns SIP entity (server / b2ba / gateway) ip address configured in <b>create-instance</b> test case.
    
    </li><br/>
    <li><div className="code">[p-sip:local-client-port]</div>
    This returns current session session local port.
    
    </li><br/>
    <li><div className="code">[p-sip:transport-conn-local-port:conn-name]</div>
    This returns a transport connection (identified by conn-name) local bind port.
    
    </li><br/>
    <li><div className="code">[p-sip:transport]</div>
    This returns SIP entity transport type (configured in <b>create-instance</b> test case) in lower case string format.
    
    </li><br/>
    <li><div className="code">[p-sip:call-id]</div>
    Get current sip session callid using this plugin varaible.

    </li><br/>
    <li><div className="code">[p-sip:via]</div>
    You can get a fresh new via using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:audio-rtp-port]</div>
    This provides current sip session audio rtp port.

    </li><br/>
    <li><div className="code">[p-sip:audio-rtcp-port]</div>
    This provides current sip session audio rtcp port.
    
    </li><br/>
    <li><div className="code">[p-sip:video-rtp-port]</div>
    This provides current sip session video rtp port.

    </li><br/>
    <li><div className="code">[p-sip:video-rtcp-port]</div>
    This provides current sip session video rtcp port.

    </li><br/>
    <li><div className="code">[p-sip:audio-codec]</div>
    This provides audio codec configued in <i>config-sdp</i> test case.

    </li><br/>
    <li><div className="code">[p-sip:video-codec]</div>
    This provides video codec configued in <i>config-sdp</i> test case.

    </li><br/>
    <li><div className="code">[p-sip:audio-rtp-ufrag]</div>
    Audio rtp ufrag is internaly genertaed. User can get audio rtp ufrag value using this plugin variable. 

    </li><br/>
    <li><div className="code">[p-sip:audio-rtp-icepasswd]</div>
    Audio rtp icepasswd is internaly genertaed. User can get audio rtp icepasswd value using this plugin variable. 

    </li><br/>
    <li><div className="code">[p-sip:audio-rtp-host-candidate]</div>
    User can get audio rtp channel host candidates using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:audio-rtp-srflx-candidate]</div>
    User can get audio rtp channel srflx (stun case) candidates using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:audio-rtp-relay-candidate]</div>
    User can get audio rtp channel relay (turn case) candidates using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:audio-rtcp-ufrag]</div>
    Audio rtcp ufrag is internaly genertaed. User can get audio rtcp ufrag value using this plugin variable. 

    </li><br/>
    <li><div className="code">[p-sip:audio-rtcp-icepasswd]</div>
    Audio rtcp icepasswd is internaly genertaed. User can get audio rtcp icepasswd value using this plugin variable. 

    </li><br/>
    <li><div className="code">[p-sip:audio-rtcp-host-candidate]</div>
    User can get audio rtcp channel host candidates using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:audio-rtcp-srflx-candidate]</div>
    User can get audio rtcp channel srflx (stun case) candidates using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:audio-rtcp-relay-candidate]</div>
    User can get audio rtcp channel relay (turn case) candidates using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:video-rtp-ufrag]</div>
    video rtp ufrag is internaly genertaed. User can get video rtp ufrag value using this plugin variable. 

    </li><br/>
    <li><div className="code">[p-sip:video-rtp-icepasswd]</div>
    video rtp icepasswd is internaly genertaed. User can get video rtp icepasswd value using this plugin variable. 

    </li><br/>
    <li><div className="code">[p-sip:video-rtp-host-candidate]</div>
    User can get video rtp channel host candidates using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:video-rtp-srflx-candidate]</div>
    User can get video rtp channel srflx (stun case) candidates using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:video-rtp-relay-candidate]</div>
    User can get video rtp channel relay (turn case) candidates using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:video-rtcp-ufrag]</div>
    video rtcp ufrag is internaly genertaed. User can get video rtcp ufrag value using this plugin variable. 

    </li><br/>
    <li><div className="code">[p-sip:video-rtcp-icepasswd]</div>
    video rtcp icepasswd is internaly genertaed. User can get video rtcp icepasswd value using this plugin variable. 

    </li><br/>
    <li><div className="code">[p-sip:video-rtcp-host-candidate]</div>
    User can get video rtcp channel host candidates using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:video-rtcp-srflx-candidate]</div>
    User can get video rtcp channel srflx (stun case) candidates using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:video-rtcp-relay-candidate]</div>
    User can get video rtcp channel relay (turn case) candidates using this plugin variable.
    
    </li><br/>
    <li><div className="code">[p-sip:local-audio-ssrc]</div>
    SSRC value in audio rtp packet header is a 32 bit value. User can get local SSRC value using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:remote-audio-ssrc]</div>
    SSRC value in audio rtp packet header is a 32 bit value. User can get remote SSRC value using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:local-video-ssrc]</div>
    SSRC value in video rtp packet header is a 32 bit value. User can get local SSRC value using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:remote-video-ssrc]</div>
    SSRC value in video rtp packet header is a 32 bit value. User can get remote SSRC value using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:local-audio-seq-num]</div>
    Sequence number value in audio rtp packet header is a 16 bit value. User can get local sequence number value using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:remote-audio-seq-num]</div>
    Sequence number value in audio rtp packet header is a 16 bit value. User can get remote sequence number value using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:local-video-seq-num]</div>
    Sequence number value in video rtp packet header is a 16 bit value. User can get local sequence number value using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:remote-video-seq-num]</div>
    Sequence number value in video rtp packet header is a 16 bit value. User can get remote sequence number value using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:local-audio-cname]</div>
    RTCP cname remains constant even if SSRC change in a call. You can get local audio cname value using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:local-video-cname]</div>
    RTCP cname remains constant even if SSRC change in a call. You can get local video cname value using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:local-audio-timestamp]</div>
    Timestamp is a 32 bit value in RTP header. It keeps increasing depending on audio sampling rate.
    User can get local audio timestamp value using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:ice-audio-rtp-port]</div>
    This returns audio transport ICE local rtp port. If you are using TURN, 
    this plugin variable returns relay audio rtp port. For STUN, it returns srflx audio rtp port.

    </li><br/>
    <li><div className="code">[p-sip:ice-audio-rtcp-port]</div>
    This returns audio transport ICE local rtcp port. If you are using TURN, 
    this plugin variable returns relay audio rtcp port. For STUN, it returns srflx audio rtcp port.

    </li><br/>
    <li><div className="code">[p-sip:ice-video-rtp-port]</div>
    This returns video transport ICE local rtp port. If you are using TURN, 
    this plugin variable returns relay video rtp port. For STUN, it returns srflx video rtp port.

    </li><br/>
    <li><div className="code">[p-sip:ice-video-rtcp-port]</div>
    This returns video transport ICE local rtcp port. If you are using TURN, 
    this plugin variable returns relay video rtcp port. For STUN, it returns srflx video rtcp port.

    </li><br/>
    <li><div className="code">[p-sip:local-video-timestamp]</div>
    Timestamp is a 32 bit value in RTP header. It keeps increasing depending on video fps (frames per second).
    User can get local video timestamp value using this plugin variable.

    </li><br/>
    <li><div className="code">[p-sip:ice-remote-audio-ip]</div>
    This returns audio transport ICE remote ip address, selected from ICE connectivity check.
    
    </li><br/>
    <li><div className="code">[p-sip:ice-remote-audio-rtp-port]</div>
    This returns audio transport ICE remote rtp port, selected from ICE connectivity check.
    
    </li><br/>
    <li><div className="code">[p-sip:ice-remote-audio-rtcp-port]</div>
    This returns audio transport ICE remote rtcp port, selected from ICE connectivity check.

    </li><br/>
    <li><div className="code">[p-sip:ice-remote-video-ip]</div>
    This returns video transport ICE remote ip address, selected from ICE connectivity check.
    
    </li><br/>
    <li><div className="code">[p-sip:ice-remote-video-rtp-port]</div>
    This returns video transport ICE remote rtp port, selected from ICE connectivity check.
    
    </li><br/>
    <li><div className="code">[p-sip:ice-remote-video-rtcp-port]</div>
    This returns video transport ICE remote rtcp port, selected from ICE connectivity check.

    </li><br/>
    <li><div className="code">[p-sip:audio-srtp-initial-encrypt-key]</div>
    This returns audio session initial srtp encryption key. Used when call uses SRTP only (no DTLS).
    
    </li><br/>
    <li><div className="code">[p-sip:video-srtp-initial-encrypt-key]</div>
    This returns video session initial srtp encryption key. Used when call uses SRTP only (no DTLS).

    </li><br/>
    <li><div className="code">[p-sip:fingerprint]</div>
    Sometime you need to use certificate fingrprint. One such typical case is, in a webrtc call in sdp you need to put fingerprint. This plugin variable returns endpoints certificate fingerprint.

    </li><br/>
    <li><div className="code">[p-sip:onstart-epid]</div>
    This returns endpoint id. This plugin variable should be used very carefully.
    During onstart IB execution you can use this plugin variable. Only first time
    you will get proper value, next time onwards it would not give proper value. 
    So accordingly set values in other variables for future usage.

    </li><br/>
    <li><div className="code">[p-sip:onstart-remote-epid]</div>
    This returns remote endpoint id based on call gap index provided in onstart 
    instruction. This plugin variable should be used very carefully. During onstart 
    IB execution you can use this plugin variable. Only first time you will get 
    proper value, next time onwards it would not give proper value. So accordingly 
    set values in other variables for future usage.
    </li>
    </ol>
        
        <br/>
        
        <div className="inlinecenter">
          <button onClick={() => window.location.href="/userguide/httpdb2bainstructions" }>Back</button> 
          <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
          <button onClick={() => window.location.href="/userguide/sipb2basyntax" }>Next</button>
        </div>
      </p>
    </div>
  );
  
  return element;
}

export {UGSIPB2BAVariables}

