
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGUserSeries = () => {

  setPageTitle('User Series');
  
  const element = (
    <div className="userguide">
      <p>
        <h1>User Series</h1>
        <br/>
        In a typical load test user deals with hundreds of thousands of endpoints. In 
        LM Tools setup, user need to configure them with a particular pattern. Lets 
        consider two different categories.
        <br/><br/>
        In first category, then length of user-id length is not constrained, like user 
        can have endpoints like shown below
        <br/><br/>

        <div className="code">
          user_0
          <br/>user_1
          <br/>user_2
          <br/>user_3
          <br/>user_4
          <br/>user_5
          <br/>user_6
          <br/>user_7
          <br/>user_8
          <br/>user_9
          <br/>user_10
          <br/>user_11
          <br/>etc..
        </div>
        <br/>
        You can see above length is 6 or 7 or can be more as it cross 100 endpoints, 
        1000 endpoints etc. Here user-id length is not constrained.
        <br/><br/>
        In second category, the user-id length is constrained. Say user wants user-ids 
        as shown below.

        <div className="code">
        user_100000
        <br/>user_100001
        <br/>user_100002
        <br/>user_100003
        <br/>user_100004
        <br/>user_100005
        <br/>user_100006
        <br/>user_100007
        <br/>user_100008
        <br/>user_100009
        <br/>user_100010
        <br/>user_100011
        <br/>etc..
        </div>
        <br/>

        Here you can see always user-id length is 11.
        <br/><br/>
        The first category configuration is easy. Just put user prefix as user_ and 
        then automatically you have users like user_0, user_1 so on.The second category 
        configuration is bit complex. Please follow below instructions to configure the 
        endpoints.
        <br/><br/>
        User-id of nth --> User prefix + (base id + index)
        So in create-instance command you put below configurations
        user-prefix user_
        id 100000
        <br/><br/>
        Now lets see how user-ids looks

        <div className="code">
        <br/>Endpoint 0 -> user_(100000+0) -> user_100000
        <br/>Endpoint 1 -> user_(100000+1) -> user_100001
        <br/>Endpoint 2 -> user_(100000+2) -> user_100002
        <br/>Endpoint 3 -> user_(100000+3) -> user_100003
        <br/>Endpoint 4 -> user_(100000+4) -> user_100004
        <br/>Endpoint 5 -> user_(100000+5) -> user_100005
        <br/>Endpoint 6 -> user_(100000+6) -> user_100006
        <br/>Endpoint 7 -> user_(100000+7) -> user_100007
        <br/>Endpoint 8 -> user_(100000+8) -> user_100008
        <br/>Endpoint 9 -> user_(100000+9) -> user_100009
        <br/>Endpoint 10 -> user_(100000+10) -> user_100010
        <br/>Endpoint 11 -> user_(100000+11) -> user_100011
        <br/>etc..
        </div>
        <br/>
        This way, you can configure endpoints with length constrained user-ids. If 
        users are not in particular pattern, then using CSV file, you can feed userid 
        and password to LM Tools. However the preferred way is to take userid and 
        password in particular pattern.
        <br/><br/>
        <div className="inlinecenter">
          <button onClick={() => window.location.href="/userguide/variables" }>Back</button> 
          <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
          <button onClick={() => window.location.href="/userguide/apatvariables" }>Next</button>
        </div>
      </p>
    </div>
  );
  
  return element;
}

export {UGUserSeries}

