
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGHTTPB2BAInstructions = () => {

  setPageTitle('HTTP B2BA Instructions');
  
  const element = (
    <div className="userguide">
      <h1>HTTP B2BA Instructions</h1>
      <br/>
          
      B2BA HTTP stands for HTTP (Hyper Text Transfer Protocol) back to back agent. 
      Typically there are more than one component
      in B2BA and it interfaces with different products.
      Instructions are means to instruct certain operations to be executed in multiplier 
      test environment. Unlike other B2BA module, this one is not a stand alone module. It
      is used along with other B2BA modules. 
      <br/><br/>
      Say you got a SIP INVITE message, and you need to send a HTTP POST message. In such case
      your main module is SIP B2BA and HTTP B2BA is a helping module.
      <br/><br/>
      There is another module B2BA HTTPD, that acts as a Web / HTTP Server, whereas B2BA HTTP 
      acts as Web / HTTP client. Please note B2BA HTTP and B2BA
      HTTPD are two different modules in multiplier environment.
      <br/><br/>
      Instructions can be in instruction blocks or in native APIs those 
      starts with <b>execute-</b> tag. Multiplier (B2BA) supports various types of 
      instructions listed below.
      <br/><br/>
      <h3>B2BA HTTP Instructions</h3>
      Now lets explore on various instructions supported by B2BA HTTP module. Each instruction is
      explained in a single block with syntax, example and followed by explanation. Typically HTTP
      instructions in B2BA starts with <b>http start</b> and ends with <b>http end</b> as shown below.
      <br/><br/>
      <div className="code">
      http start<br/>
      instruction 1<br/>
      instruction 2<br/>
      ....................<br/>
      instruction n<br/>
      http end<br/>
      </div><br/>
      
      
      <h3>Instructions</h3>
      <ul>
      <li><b>create &lt;name&gt;</b>
      <div className="code">
      <b>Examples</b>
      <br/>#Here name can be any unique name like url1 mylink1 etc. 
      <br/>create url1
      </div>
      <br/>Instruction <b>create</b> is used to create an url session with a given name identifier.
      </li>
      
      <br/><li><b>access &lt;link&gt;</b>
      <div className="code">
      <b>Examples</b>
      <br/>access www.somedomain.com
      <br/>access [domainvar]
      </div>
      <br/>Instruction <b>access</b> is used to access a given link.
      </li>

      <br/><li><b>follow enable</b>
      <div className="code">
      <b>Examples</b>
      <br/>follow enable
      <br/>follow disable
      </div>
      <br/>Instruction <b>follow</b> is used to enable auto redirection. When you access a link, if 3xx
      is received ideally next link should be accessed. You can enable / disable this functionality
      using <b>follow</b> instruction. By default it is disabled.
      </li>

      <br/><li><b>ipversion &lt;version&gt;</b>
      <div className="code">
      <b>Examples</b>
      <br/>ipversion 6
      <br/>ipversion 4
      </div>
      <br/>Instruction <b>ipversion</b> is used to set the preference for ipversion 6 or 4
      during access of web service or a link.
      </li>

      <br/><li><b>transfer-chunk enable</b>
      <div className="code">
      <b>Examples</b>
      <br/>transfer-chunk enable
      <br/>transfer-chunk disable
      </div>
      <br/>Instruction <b>transfer-chunk</b> is used to enable chunk data transfer.
      By default it is disabled.
      </li>

      <br/><li><b>log true</b>
      <div className="code">
      <b>Examples</b>
      <br/>log true
      <br/>log false
      </div>
      <br/>Instruction <b>log</b> is used to enable detail log for a particular url access. 
      By default it is disabled.
      </li>

      <br/><li><b>method &lt;http method&gt;</b>
      <div className="code">
      <b>Examples</b>
      <br/>method GET
      <br/>method POST
      </div>
      <br/>Instruction <b>method</b> is used to specify HTTP method like GET, POST, PUT etc. 
      </li>

      <br/><li><b>header &lt;http header&gt;</b>
      <div className="code">
      <b>Examples</b>
      <br/>header Content-Type: audio/amr
      <br/>header Expect:
      header [var]
      </div>
      <br/>Instruction <b>header</b> is used to specify additional HTTP header. This instruction
      can be used to remove a header. For example see <i>header Expect:</i> line, what it does 
      basically, it removes Expect header from message.
      </li>

      <br/><li><b>body &lt;template&gt;</b>
      <div className="code">
      <b>Examples</b>
      <br/>body [t-body1]
      <br/>body [t-body1] [t-body2] [t-body3]
      <br/>body Body with variable [var]
      </div>
      <br/>Instruction <b>body</b> is used to specify HTTP body. You can specify body with 
      single template, multiple templates, body with raw text along with some variables as 
      shown in above example section.
      </li>

      <br/><li><b>body-file &lt;filen ame&gt;</b>
      <div className="code">
      <b>Examples</b>
      <br/>body-file /home/user/file1.txt
      <br/>body-file [t-filename]
      </div>
      <br/>Instruction <b>body-file</b> is used to specify a file as HTTP body. You can 
      specifiy absolute filename like raw data, or you can specify filename using a template.
      This is useful when you want to really send big chunk of static data.
      </li>

      <br/><li><b>body-image &lt;image file name&gt;</b>
      <div className="code">
      <b>Examples</b>
      <br/>body-image /home/user/file1.png
      <br/>body-image [t-imagefilename]
      </div>
      <br/>Instruction <b>body-image</b> is used to specify a image file as HTTP body. You can 
      specifiy absolute filename like raw data, or you can specify filename using a template.
      </li>

      <br/><li><b>receive &lt;template&gt;</b>
      <div className="code">
      <b>Examples</b>
      <br/>receive [t-rxmsg]
      </div>
      <br/>Instruction <b>receive</b> is used to verify / extract data from a received HTTP response.
      Say you are waiting for 200 OK for a HTTP request, and you got 400 Bad Request. Using template
      you can check the received data for correctness. Also you can extract any portions of data from
      received message.
      </li>
      
      </ul>
              
      <br/>
      
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/sipb2bainstructions" }>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/servervariables" }>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGHTTPB2BAInstructions}

