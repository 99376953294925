import React, {useState, useEffect} from 'react';
import Select from 'react-select';

import 
  {
    checkRecordCounts, createSelectStyle, setPageTitle, 
    getLmPluginId, getLmFrequencyId, getLmBlockTypeId, getLmReportId,
    handleErrorResponse, lmTransports, getLmTransportId,
  } from '../../routes/lm.js';
  
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import moment from 'moment';
import axios from 'axios';
import {Line} from 'rc-progress';
import CryptoJS from 'crypto-js';

import { ScenarioHeader } from './utils/scenarioheader'
import { LMUserConfig } from './utils/lmuserconfig'
import { LMAPATServerConfig } from './utils/lmapatserverconfig'

const jsonAttribs = require("./testsuites/jsonproxytestsuites.js")

let myTimeOut = null;

export const ScenarioJsonProxy = () => 
{
  const [status, setStatus] = useState(null);
  const [progressBar, setProgressBar] = useState({value: 0});
  const [btnState, setBtnState] = useState(false);
  const [state, setState] = useState({id: -1,});

  const [headerConfig, setHeaderConfig] = useState(null);
  const [userConfig, setUserConfig] = useState(null);
  const [serverConfig, setServerConfig] = useState(null);

  setPageTitle('JSON proxy scenario');

  let transportsObj = lmTransports();  
  const user = JSON.parse(localStorage.getItem('user'));

  const jsonProxyCreateTS = async (name) => {

    let tsRecordId = -1;
    
    let reqdTsHead = {
      tsName: name, 
      tsDesc: headerConfig.desc, 
      tsId: tsRecordId
    };

    try {
      await axios.post(
        '/api/testsuite/data/save', 
        {uid: user.uid, email: user.email, data: JSON.stringify(reqdTsHead)},
        {headers: {Authorization: user.jwt,}},
      ).then( function (res) {
        if(res.status === 200) {
          tsRecordId = res.data.tsId;
          reqdTsHead.tsId = tsRecordId;
          increaseProgressBy(42);
        } else {
          console.log("TS save, got error " + res.status);
        }
      })
    }catch(err) {
      handleErrorResponse(err);
    }

    if(tsRecordId > 0) {
      
      let pluginId = getLmPluginId('FlexiSip');
      let nativeBlock = getLmBlockTypeId('Native API');
      let freqOnce = getLmFrequencyId('Once');
      let freqAlways = getLmFrequencyId('Always');
      let reportId = getLmReportId('Yes');
      
      const reqdTsBody = [
        jsonAttribs.jsonProxyCreateInstanceAttribs(userConfig, serverConfig, pluginId, freqOnce, reportId),
        jsonAttribs.jsonProxyExecuteAttribs(pluginId, nativeBlock, freqAlways, reportId),
      ];
        
      const tbJsonData = JSON.stringify(reqdTsBody); //test block json data
      const tsBodySha256 = CryptoJS.SHA256(tbJsonData).toString();
      
      try {
        await axios.post(
          '/api/testblocks/data/save',
          {uid: user.uid, email: user.email, tsid: tsRecordId, sha256: tsBodySha256, data: tbJsonData},
          {headers: {Authorization: user.jwt,}},
        ).then ( function (res2) {
          if(res2.status === 200) {
            increaseProgressBy(56);
          } else {
            console.log("TS blocks save, got error " + res2.status);
          }
        });
      } catch(err2) {
        handleErrorResponse(err2);
      }
    }
    
    return tsRecordId;
  }

  const jsonProxyCreateTB = async (name, tsRecordId) => {
    let tbRecordId = -1;
    
    let reqdTbHead = {
      name: name,
      
      cid: headerConfig.cvalue,
      controller: headerConfig.clabel,
      
      loglevel: 0,
      valgrind: 0,
      repeat: 1,
      description: headerConfig.desc,
      tbId: -1,
    };

    try {
      await axios.post(
        '/api/testbed/data/save', 
        {uid: user.uid, email: user.email, data: JSON.stringify(reqdTbHead)},
        {headers: {Authorization: user.jwt,}},
      ).then( function (res) {
        if(res.status === 200) {
          tbRecordId = Math.round(res.data.tbId);
          increaseProgressBy(70);
        } else {
          console.log("Testbed save, got error " + res.status);
        }
      })
    }catch(err) {
      handleErrorResponse(err);
    }
    
    let reqdTbBody = [{
      tsid: tsRecordId,
      testsuite: name,
      
      mid: headerConfig.mvalue,
      multiplier: headerConfig.mlabel,
      
      endpoints: 2,
      threads: 1,
      
      state: 1,
      loglevel: 0,
      valgrind: 0,
    }];
    
    if(tbRecordId > 0) {
            
      const tbJsonData = JSON.stringify(reqdTbBody);
      const tbBodySha256 = CryptoJS.SHA256(tbJsonData).toString();
      
      try {
        await axios.post(
          '/api/testbedblocks/data/save',
          {uid: user.uid, email: user.email, tbid: tbRecordId, sha256: tbBodySha256, data: tbJsonData},
          {headers: {Authorization: user.jwt,}},
        ).then ( function (res2) {
          if(res2.status === 200) {
            increaseProgressBy(84);
          } else {
            console.log("Testbed blocks save, got error " + res2.status);
          }
        });
      } catch(err2) {
        handleErrorResponse(err2);
      }
    }
    
    return tbRecordId;
  }

  function increaseProgressBy(delta) {
    let newProgressBar = {...progressBar};
    newProgressBar.value += delta;
    setProgressBar(newProgressBar);
  }

  const savejsonProxyScenarioData = async (name, tss, tbs) => {
    let scRecordId = -1;
    let atps = [];
    let atss = [tss];
    let atbs = [tbs];
    
    try {
      await axios.post(
        '/api/scenario/data/save', 
        {
          uid: user.uid, 
          email: user.email, 
          name: name,
          desc: headerConfig.desc,
          tparr: JSON.stringify(atps),
          tsarr: JSON.stringify(atss),
          tbarr: JSON.stringify(atbs),
        },
        {headers: {Authorization: user.jwt,}},
      ).then( function (res) {
        if(res.status === 200) {
          scRecordId = Math.round(res.data.scid);
          
          setStatus({
            type: 'success',
            message: `Scenarion ${name} is created successfully.`
          });
          
          increaseProgressBy(100);
          let newBtnState = true;
          setBtnState(newBtnState);              

          let newState = {...state};
          state.id = scRecordId;
          newState.id = scRecordId;
          setState(newState);
          myTimeOut = setTimeout(moveToScenarioPage, 1500);
          
        } else {
          console.log("Scenario save, got error " + res.status);
        }
      })
    }catch(err) {
      handleErrorResponse(err);
    }
  }
  
  function moveToScenarioPage() {
    clearTimeout(myTimeOut);
    window.location.href = `/components/view/the/scenario?id=${state.id}`;
  }
  
  const handleCreateScenario = async (e) => {
    e.preventDefault();
    
    if(headerConfig.mvalue == 0) {
      setStatus({type: 'error', message: 'Multiplier not set'});
      return;
    }

    if(headerConfig.cvalue == 0) {
      setStatus({type: 'error', message: 'Controller not set'});
      return;
    }

    let formatedname = headerConfig.name.replaceAll(' ', '-').toLowerCase();
    let uniqid = user.uid + '-' + moment().format('YYYY-MM-DD-HH-mm-ss-SSS');
    let name = formatedname + '-' + uniqid;

    //Record check result
    let rcr = await checkRecordCounts(user);
    
    if(rcr === 'success') {
      
      const tss = await jsonProxyCreateTS(name);
      const tbs = await jsonProxyCreateTB(name, tss);
      
      //Add entries 
      await savejsonProxyScenarioData(name, tss, tbs);
    } else {
      alert('Scenario creation failed. Error: ' + rcr);
    }
  }

  
  const handleUserConfigChange = (updatedState) => {
    setUserConfig(updatedState);
  }

  const handleScenarioHeaderChange = (updatedState) => {
    setHeaderConfig(updatedState);
  }

  const handleServerConfigChange = (updatedState) => {
    setServerConfig(updatedState);
  }

  if(user) {
    let configObj = {
      baseid: true, uprefix: false, pprefix: false, 
      localip: true, transport: true, urischeme: false,
    };
    
    return (
      <form name="scform" id="scform" onSubmit={handleCreateScenario}>
        <div key="scmain" className="medium-text">
          <div id="scmain2" className="center" >

            <ScenarioHeader scenarioName={'JSON proxy'} onConfigChange={(updatedState) => 
            handleScenarioHeaderChange(updatedState)} />

            <LMUserConfig configObj={configObj} onConfigChange={(updatedState) => 
            handleUserConfigChange(updatedState)} />

            <LMAPATServerConfig onConfigChange={(updatedState) => 
            handleServerConfigChange(updatedState)} />

            <div className="inlinecenter">
              {status && <div className={`alert ${status.type}`}>{status.message}</div>}
              <Line className="progressbar" style={{visibility: (progressBar.value > 0) ? "visible" : "hidden"}} percent={progressBar.value} strokeWidth="1" strokeColor="#009973" />
              <br/>
              <button disabled={btnState}>Create Scenario</button> 
            </div>
          </div>
        </div>
      </form>
    );
  }  else { 
    return(
      <div key="scmain" className="medium-text">
        <div id="scmain2" className="center" >
          You are not allowed to access this page.
        </div>
      </div>
    );
  }  
}
