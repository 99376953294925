
import React from 'react';
import {getURLQueryVariable} from '../../routes/lm.js';

import {ScenarioSIPCall} from './flexisipcall.js'
import {ScenarioAccessSingleURL} from './apataccesssingleurl.js'
import {ScenarioAccessMultiURL} from './apataccessmultiurl.js'
import {ScenarioServerSIPProxy} from './sipserver.js'
import {ScenarioApatWebRTCCall} from './apatwebrtccall.js'
import {ScenarioJsonProxy} from './jsonproxy.js'

export const CreateScenarios = () => {
  const scid = getURLQueryVariable("scid");
    
  if(scid === "sip-call") 
  {
    return ScenarioSIPCall(false);
  } 
  else if(scid === "sip-webrtc-call") 
  {
    return ScenarioSIPCall(true);
  } 
  else if(scid === "access-single-url") 
  {
    return ScenarioAccessSingleURL();
  } 
  else if(scid === "access-multiple-url") 
  {
    return ScenarioAccessMultiURL();
  }
  else if(scid === "server-sip-proxy") 
  {
    return ScenarioServerSIPProxy();
  }
  else if(scid === "apat-webrtc") 
  {
    return ScenarioApatWebRTCCall();
  }
  else if(scid === "json-proxy") 
  {
    return ScenarioJsonProxy();
  }

  return ('Invalid scenario');
}
