
import React from 'react';
import { HashLink } from 'react-router-hash-link'
import {setPageTitle} from '../../routes/lm.js';
import {apatsyntax_table} from './apatsyntax.js';

const UGAPATWebRTCSyntax = () => {

  setPageTitle('APAT WebRTC Syntax');
  
  const element = (
    <div className="userguide">
      <p>
        <h1>APAT WebRTC Syntax</h1>
      <br/>
      
      {apatsyntax_table()}
      
      <br/> <br/>
      <div className="inlinecenter">          <button onClick={() => window.location.href="/userguide/apatsyntax" }>Back</button> 
          <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
          <button onClick={() => window.location.href="/userguide/apatinstructions" }>Next</button>
        </div>
      </p>
    </div>
  );
  
  return element;
}

export {UGAPATWebRTCSyntax}

