import react, {useState, useEffect} from 'react';

const SeatChart = () => {

    let zones = new Array(
      {
        name: 'A-250',
        lines: 5,
        seats: new Array(20,21,22,23,24),
      },
      
      {
        name: 'B-250',
        lines: 5,
        seats: new Array(12,13,14,15,16),
      },

      {
        name: 'C-250',
        lines: 5,
        seats: new Array(20,21,22,23,24),
      },
      
    );
    
    let out = '';
    return (

      <div className="medium-text">
        <div className="center">
          <h3><p align="center">Seat Chart</p></h3>
        
          zones.map((val,tindex)=>
            <table className="tealtbl">
              
              <tbody>
              </tbody>
            
            </table> 
          )
        
        </div>
      </div>
    );
}

export default SeatChart;