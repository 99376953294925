
import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import axios from 'axios';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import 
  {
    createSelectStyle, handleErrorResponse,
    lmTransports, lmURISchemes
  } from '../../../routes/lm.js';


export const LMUserConfig = ({configObj, onConfigChange}) =>  {
  const [state, setState] = useState({
    localip: '127.0.0.1', baseid: "0", transport: 0, 
    urischeme: 0, uprefix: "lm", pprefix: "lm",    
  });

  let transportsObj = lmTransports();
  let uriSchemesObj = lmURISchemes();

  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    let newState = { ...state };
    newState[name] = value; 
    setState(newState);
    onConfigChange(newState);
  };
  
  const handleSelectInputChange=(e)=> {
    const {name,value}=e.target;
    let newState = {...state};

    /* 
    * This block of code is very important.
    * When onChange triggers for a select, we need to keep track of id of item 
    * selected in state object. The id may not be like 0,1,2.. but could be like
    * 0.10,20,35,56 etc..
    * 
    * So for 2nd case where id is montonic, that time we must set the id (through data-id)
    * in JSX and retrieve here, set the id value to state object's corresponding member.
    */
    for (let node of e.target.children) {
      if (node.value === e.target.value) {
        newState[name] = Math.round(node.getAttribute('data-id'));
        break;
      }
    }
    
    setState(newState);
    onConfigChange(newState);
  }

  function justUpdateState() {
    let newState = { ...state };
    setState(newState);
    onConfigChange(newState);
  }

  //Hook to get data from backend server
  useEffect ( () => {
     justUpdateState();
  }, []);    

  return(
    <div key="userconfig">
      <br/><br/><h3>Local user configuration</h3>
      <table key='tuserconfig' className="statstbl">
        <tbody key='tuserconfigbd'>
          
          <tr key='tr1'>
            <td>
            {
              configObj.baseid ? (
                <div key='tcbaseid'>
                  Base Id
                  <Tooltip title="Base Id is starting reference number. Say prefix is user, base id is 10 then user series will be like user10, user11, so on ..." arrow>
                    <span className="round">?</span>
                  </Tooltip><br/>
                  <input required name="baseid" value={state.baseid} style={{width: "100%"}} onChange={(e)=>handleInputTextChange(e)}/>
                </div> ) : ("Base id not required")
            }
            </td>

            <td>
            {
              configObj.uprefix ? (
                <div key='tcpurefix'>
                  User prefix
                  <Tooltip title="Enter user prefix. If you want user series like user10, user11 .., then enter user prefix as user and base id as 10." arrow>
                    <span className="round">?</span>
                  </Tooltip><br/>
                  <input required name="uprefix" value={state.uprefix} style={{width: "100%"}} onChange={(e)=>handleInputTextChange(e)}/>
                </div> ) : ("User prefix not required")
            }
            </td>

            <td>
            {
              configObj.pprefix ? (
                <div key='tcpprefix'>
                  Password prefix
                  <Tooltip title="Enter password prefix. If you want password series like pass10, pass11 .., then enter password prefix as pass and base id as 10." arrow>
                    <span className="round">?</span>
                  </Tooltip><br/>
                  <input required name="pprefix" value={state.pprefix} style={{width: "100%"}} onChange={(e)=>handleInputTextChange(e)}/>
                </div> ) : ("Password prefix not required") 
            }
            </td>

          </tr>

          <tr key='tr2'>

            <td>
            {
              configObj.localip ? (
                <div key='tcip'>
                  Local IP address
                  <Tooltip title="Enter multiplier machine IP address. Use local IP addresses to reduce bandwidth cost." arrow>
                    <span className="round">?</span>
                  </Tooltip><br/>
                  <input required name="localip" value={state.localip} style={{width: "100%"}} onChange={(e)=>handleInputTextChange(e)}/>
                </div> ) : ("Local ip not required")
            }
            </td>

            <td>
            {
              configObj.transport ? (
              <div key='tctransport'>
                Transport
                <Tooltip title="Select transport. Here ws / wss refers to websocket / secured websocket transport." arrow>
                  <span className="round">?</span>
                </Tooltip><br/>

                <select style={{width: "100%"}} value={transportsObj[state.transport]} 
                  name="transport" onChange={handleSelectInputChange}>
                  {Object.keys(transportsObj).map(item => 
                    <option key={`sip${item}`} data-id = {`${item}`}>
                    {transportsObj[item]}
                    </option>
                  )}
                </select> 
              </div>) : 
              ("Transport not required")                      
            }
            </td>
          
            <td>
              {
                configObj.urischeme ? (
                  <div key='tcuri'>
                    URI Scheme
                    <Tooltip title="Select sip uri scheme." arrow>
                      <span className="round">?</span>
                    </Tooltip><br/>

                    <select style={{width: "100%"}} value={uriSchemesObj[state.urischeme]} 
                      name="urischeme" onChange={handleSelectInputChange}>
                      {Object.keys(uriSchemesObj).map(item => 
                        <option key={`uri${item}`} data-id = {`${item}`}>
                        {uriSchemesObj[item]}
                        </option>
                      )}
                    </select> 
                  </div>
                  ) : ("URI scheme not required")
              }
              
            </td>          
          </tr>
          
        </tbody>
      </table>
    
    </div>
  );
  
}

