/*
* Please note the attribs are indented from start of line intentionally.
* Do not do normal formating else sip test blocks will have additional spaces.
*/

import 
  {
    lmTransports, getLmBlockTypeId, 
  } from '../../../routes/lm.js';

export function jsonProxyCreateInstanceAttribs(
    userConfig, serverConfig,
    pluginId, frequency, reportId
  ) {
  
  let transportsObj = lmTransports();
  let transport = transportsObj[userConfig.transport];
  
  let attribs = 
`id ${userConfig.baseid}
transport ${transport}
local-ipaddress ${userConfig.localip}
local-port 8080
ep-status details
rps 960`;

  return ({
    tcname:"create-instance",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: getLmBlockTypeId('Initialize'),
    freq: frequency,
    report: reportId,
    attribs: attribs,
  });
}

export function jsonProxyExecuteAttribs(pluginId, typeId, frequency, reportId) {

  let attribs = 
`rps 16`;
  
  return ({
    tcname:"execute-json-proxy",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs, 
  });
}
