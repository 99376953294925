
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGMultiplier = () => {

  setPageTitle('Multiplier');
  
  const element = (
    <div className="userguide">
      <h1>LM Tools - Multiplier</h1>
      <br/>
      Its a software component, often installed in a separate machine. It simulates 
      the servers, endpoints / clients, gateways, back-to-back agents etc in a typical LM Tools setup. 
      <br/><br/>
      For our free cloud setup, if you want to just try out you can use the free Multiplier provided by us.
      Please note this uses bandwidth hence cost is a factor here that makes us to limit the number of virtual subscribers 
      and the session time.
      <br/><br/>
      However just purchase a bare metal machine [<a href="/userguide/multiplier/machineinfo">details here</a>] from any 
      service providers like leasesweb, contabo, amazon, digital ocean etc.
      
      You can add, edit and delete multiplier from frontend as shown in below image.
      
      <br/><img className="borderimg" src={process.env.PUBLIC_URL + '/userguide/multiplier-ui.png'} alt="Multiplier" width='100%'/>
      
      As you can see in above image, Multiplier fields are - 
      <ul>
        <li>
          <b>Name</b> - The name should be unique one. It signifies the logical name of a Multiplier.
        </li>

        <li>
          <b>IP address</b> - IP address of Multiplier.
        </li>

        <li>
          <b>Access token</b> - This is for future use. This will be used for enhancing security mechanism to protect misuse of Multipliers. 
        </li>
      </ul>
      
      <br/>
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/controller"}>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/testbed"}>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGMultiplier}

