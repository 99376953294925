
import { Routes, Route } from 'react-router-dom';
import Home from '../routes/Home';
import License from '../routes/License';
import Contact from '../routes/Contact';
import Components from '../routes/Components';
import Layout from './Layout';
import Login from '../routes/Login';
import {Register, RegisterSuccess} from '../routes/Register';
import Profile from '../routes/Profile';
import SeatChart from '../routes/SeatChart';
import Logout from '../routes/Logout';
import AuthReqd from '../routes/AuthReqd';
import ChangePasswd from '../routes/ChangePasswd';

import {UGTableOfContents} from '../modules/userguide/toc.js';
import {UGDownload} from '../modules/userguide/download.js';
import {UGIntroduction} from '../modules/userguide/intro.js';
import {UGMultiplierInstallation} from '../modules/userguide/minstallation.js';
import {UGTerminologies} from '../modules/userguide/terminologies.js';
import {UGController} from '../modules/userguide/controller.js';
import {UGMultiplier} from '../modules/userguide/multiplier.js';
import {UGTestbed} from '../modules/userguide/testbed.js';
import {UGTestSuite} from '../modules/userguide/testsuite.js';
import {UGTemplate} from '../modules/userguide/template.js';
import {UGScenario} from '../modules/userguide/scenario.js';
import {UGMachineInfo} from '../modules/userguide/machineinfo.js';
import {UGThreadsEndpointsRPS} from '../modules/userguide/thrds-endps-rps.js';
import {UGVariables} from '../modules/userguide/variables.js';
import {UGUserSeries} from '../modules/userguide/userseries.js';

import {UGAPATVariables} from '../modules/userguide/apatvariables.js';
import {UGAPATSyntax} from '../modules/userguide/apatsyntax.js';
import {UGAPATWebRTCSyntax} from '../modules/userguide/apatwebrtcsyntax.js';
import {UGAPATInstructions} from '../modules/userguide/apatinstructions.js';

import {UGFlexiSIPVariables} from '../modules/userguide/flexisipvariables.js';
import {UGFlexiSIPSyntax} from '../modules/userguide/flexisipsyntax.js';
import {UGFlexiSIPWebRTCSyntax} from '../modules/userguide/flexisipwebrtcsyntax.js';
import {UGFlexiSIPInstructions} from '../modules/userguide/flexisipinstructions.js';

import {UGB2BAVariables} from '../modules/userguide/b2bavariables.js';
import {UGB2BASyntax} from '../modules/userguide/b2basyntax.js';
import {UGB2BAInstructions} from '../modules/userguide/b2bainstructions.js';

import {UGAPATB2BAVariables} from '../modules/userguide/apatb2bavariables.js';
import {UGAPATB2BASyntax} from '../modules/userguide/apatb2basyntax.js';
import {UGAPATB2BAInstructions} from '../modules/userguide/apatb2bainstructions.js';

import {UGHTTPDB2BAVariables} from '../modules/userguide/httpdb2bavariables.js';
import {UGHTTPDB2BASyntax} from '../modules/userguide/httpdb2basyntax.js';
import {UGHTTPDB2BAInstructions} from '../modules/userguide/httpdb2bainstructions.js';

import {UGSIPB2BAVariables} from '../modules/userguide/sipb2bavariables.js';
import {UGSIPB2BASyntax} from '../modules/userguide/sipb2basyntax.js';
import {UGSIPB2BAInstructions} from '../modules/userguide/sipb2bainstructions.js';

import {UGHTTPB2BAInstructions} from '../modules/userguide/httpb2bainstructions.js';

import {UGServerVariables} from '../modules/userguide/servervariables.js';
import {UGServerSyntax} from '../modules/userguide/serversyntax.js';
import {UGServerInstructions} from '../modules/userguide/serverinstructions.js';

import {UGAPATServerVariables} from '../modules/userguide/apatservervariables.js';
import {UGAPATServerSyntax} from '../modules/userguide/apatserversyntax.js';
import {UGAPATServerInstructions} from '../modules/userguide/apatserverinstructions.js';

import {UGHTTPDServerVariables} from '../modules/userguide/httpdservervariables.js';
import {UGHTTPDServerSyntax} from '../modules/userguide/httpdserversyntax.js';
import {UGHTTPDServerInstructions} from '../modules/userguide/httpdserverinstructions.js';

import {UGSIPServerVariables} from '../modules/userguide/sipservervariables.js';
import {UGSIPServerSyntax} from '../modules/userguide/sipserversyntax.js';
import {UGSIPServerInstructions} from '../modules/userguide/sipserverinstructions.js';

import {UGHTTPServerInstructions} from '../modules/userguide/httpserverinstructions.js';
import {UGVirtualIPConfig} from '../modules/userguide/virtualipconfig.js';
import {UGQuickStart} from '../modules/userguide/quickstart.js';

import {Scenarios, ViewScenarios, ViewTheScenario} from '../modules/scenarios/scenarios.js'
import {CreateScenarios} from '../modules/scenarios/createscenarios.js'

import {TestSuites, EditSingleTestSuites, EditTestSuites} from '../modules/testsuites/testsuites.js'
import {Templates, EditSingleTemplates, EditTemplates} from '../modules/templates/templates.js'
import {Testbeds, EditSingleTestbeds, EditTestbeds} from '../modules/testbeds/testbeds.js'

import {Multiplier, EditSingleMultiplier, EditMultipliers} from '../modules/multiplier/multiplier.js'
import {Controller, EditSingleController, EditControllers} from '../modules/controller/controller.js'

import {StartTest, StopTest, RestartTest} from '../modules/execute/testexecute.js'
import {TestStats, TestStatsDetails, TestAllErrors, TestHistory, TestStatsCurrent, TestLogs, TestMTS,} from '../modules/stats/teststats.js'

import {CreateAllTables, DropAllTables} from '../modules/admin/dbadmin.js'
import {ViewAllUsers, ViewTheUser} from '../modules/admin/users.js'

import {CreateCharts} from '../modules/echarts/lmcharts.js'
import {Parent} from '../modules/scenarios/test/parent.js'

const App = () => {
        
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="test/parent/" element={<Parent />} />
          <Route index element={<Home />} />
          <Route path="components" element={<Components />} />

          <Route path="components/charts/create" element={<CreateCharts />} />
          
          <Route path="components/scenarios" element={<Scenarios />} />
          <Route path="components/view/scenarios" element={<ViewScenarios />} />
          <Route path="components/view/the/scenario" element={<ViewTheScenario />} />
          <Route path="components/scenarios/create/" element={<CreateScenarios />} />

          <Route path="components/testsuites" element={<TestSuites />} />
          <Route path="components/editsingletestsuites" element={<EditSingleTestSuites />} />
          <Route path="components/edittestsuites" element={<EditTestSuites />} />

          <Route path="components/templates" element={<Templates />} />
          <Route path="components/editsingletemplates" element={<EditSingleTemplates />} />
          <Route path="components/edittemplates" element={<EditTemplates />} />

          <Route path="components/testbeds" element={<Testbeds />} />
          <Route path="components/editsingletestbeds" element={<EditSingleTestbeds />} />
          <Route path="components/edittestbeds" element={<EditTestbeds />} />

          <Route path="components/execute/test" element={<StartTest />} />
          
          <Route path="components/view/test/stats" element={<TestStats />} />
          <Route path="components/view/test/stats/details" element={<TestStatsDetails />} />
          <Route path="components/view/test/all/errors" element={<TestAllErrors />} />
          <Route path="components/view/test/multiplier/logs" element={<TestLogs />} />
          <Route path="components/view/test/history" element={<TestHistory />} />
          <Route path="components/view/test/stats/current" element={<TestStatsCurrent />} />
          <Route path="components/view/test/multipliers/testsuites" element={<TestMTS />} />

          <Route path="components/controller" element={<Controller />} />
          <Route path="components/editsinglecontroller" element={<EditSingleController />} />
          <Route path="components/editcontrollers" element={<EditControllers />} />

          <Route path="components/multiplier" element={<Multiplier />} />
          <Route path="components/editsinglemultiplier" element={<EditSingleMultiplier />} />
          <Route path="components/editmultipliers" element={<EditMultipliers />} />

          <Route path="contact" element={<Contact />} />
          <Route path="license" element={<License />} />
          
          <Route path="seatchart" element={<SeatChart />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="regsuccess" element={<RegisterSuccess />} />
          <Route path="profile" element={<Profile />} />
          <Route path="logout" element={<Logout />} />
          <Route path="authrequired" element={<AuthReqd />} />
          <Route path="user/changepasswd" element={<ChangePasswd />} />
          <Route path="exec/stop/test" element={<StopTest />} />
          <Route path="exec/restart/test" element={<RestartTest />} />

          <Route path="exec/drop/alltables" element={<DropAllTables />} />
          <Route path="exec/create/alltables" element={<CreateAllTables />} />

          <Route path="view/all/users" element={<ViewAllUsers />} />
          <Route path="view/the/user" element={<ViewTheUser />} />

          <Route path="userguide/tableofcontents" element={<UGTableOfContents />} />
          <Route path="userguide/download" element={<UGDownload />} />
          <Route path="userguide/introduction" element={<UGIntroduction />} />
          <Route path="userguide/install/multiplier" element={<UGMultiplierInstallation />} />
          <Route path="userguide/terminologies" element={<UGTerminologies />} />
          <Route path="userguide/controller" element={<UGController />} />
          <Route path="userguide/multiplier" element={<UGMultiplier />} />
          <Route path="userguide/testbed" element={<UGTestbed />} />
          <Route path="userguide/testsuite" element={<UGTestSuite />} />
          <Route path="userguide/template" element={<UGTemplate />} />
          <Route path="userguide/scenario" element={<UGScenario />} />
          <Route path="userguide/multiplier/machineinfo" element={<UGMachineInfo />} />
          <Route path="userguide/multiplier/threadsendpointsrps" element={<UGThreadsEndpointsRPS />} />
          <Route path="userguide/variables" element={<UGVariables />} />
          <Route path="userguide/userseries" element={<UGUserSeries />} />

          <Route path="userguide/apatvariables" element={<UGAPATVariables />} />
          <Route path="userguide/apatsyntax" element={<UGAPATSyntax />} />
          <Route path="userguide/apatwebrtcsyntax" element={<UGAPATWebRTCSyntax />} />
          <Route path="userguide/apatinstructions" element={<UGAPATInstructions />} />

          <Route path="userguide/flexisipvariables" element={<UGFlexiSIPVariables />} />
          <Route path="userguide/flexisipsyntax" element={<UGFlexiSIPSyntax />} />
          <Route path="userguide/flexisipwebrtcsyntax" element={<UGFlexiSIPWebRTCSyntax />} />
          <Route path="userguide/flexisipinstructions" element={<UGFlexiSIPInstructions />} />

          <Route path="userguide/b2bavariables" element={<UGB2BAVariables />} />
          <Route path="userguide/b2basyntax" element={<UGB2BASyntax />} />
          <Route path="userguide/b2bainstructions" element={<UGB2BAInstructions />} />

          <Route path="userguide/apatb2bavariables" element={<UGAPATB2BAVariables />} />
          <Route path="userguide/apatb2basyntax" element={<UGAPATB2BASyntax />} />
          <Route path="userguide/apatb2bainstructions" element={<UGAPATB2BAInstructions />} />

          <Route path="userguide/httpdb2bavariables" element={<UGHTTPDB2BAVariables />} />
          <Route path="userguide/httpdb2basyntax" element={<UGHTTPDB2BASyntax />} />
          <Route path="userguide/httpdb2bainstructions" element={<UGHTTPDB2BAInstructions />} />

          <Route path="userguide/sipb2bavariables" element={<UGSIPB2BAVariables />} />
          <Route path="userguide/sipb2basyntax" element={<UGSIPB2BASyntax />} />
          <Route path="userguide/sipb2bainstructions" element={<UGSIPB2BAInstructions />} />

          <Route path="userguide/httpb2bainstructions" element={<UGHTTPB2BAInstructions />} />

          <Route path="userguide/servervariables" element={<UGServerVariables />} />
          <Route path="userguide/serversyntax" element={<UGServerSyntax />} />
          <Route path="userguide/serverinstructions" element={<UGServerInstructions />} />

          <Route path="userguide/apatservervariables" element={<UGAPATServerVariables />} />
          <Route path="userguide/apatserversyntax" element={<UGAPATServerSyntax />} />
          <Route path="userguide/apatserverinstructions" element={<UGAPATServerInstructions />} />

          <Route path="userguide/httpdservervariables" element={<UGHTTPDServerVariables />} />
          <Route path="userguide/httpdserversyntax" element={<UGHTTPDServerSyntax />} />
          <Route path="userguide/httpdserverinstructions" element={<UGHTTPDServerInstructions />} />

          <Route path="userguide/sipservervariables" element={<UGSIPServerVariables />} />
          <Route path="userguide/sipserversyntax" element={<UGSIPServerSyntax />} />
          <Route path="userguide/sipserverinstructions" element={<UGSIPServerInstructions />} />

          <Route path="userguide/httpserverinstructions" element={<UGHTTPServerInstructions />} />
          <Route path="userguide/multiplier/virtualipconfig" element={<UGVirtualIPConfig />} />
          <Route path="userguide/quickstart" element={<UGQuickStart />} />


          <Route path="*" element={<p>Not found!<br/><br/><br/><br/><br/><br/></p>} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
