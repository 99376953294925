import React from 'react';
import { useState, useRef } from 'react'
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha"
import {setPageTitle, handleErrorResponse} from './lm.js'

const RegisterSuccess = () => {
  
  setPageTitle('Registration');
  
  return(
    <>
      <h2>Registration successfull</h2>
      You have successfully registered at <b>free.lmtools.com</b>.
      Please read carefully below notes.
      
      <br/><br/><b><div className="red">Important notes</div></b>
      <ul>
        <li>
          The lmtools.com and free.lmtools.com are two different 
          sites. lmtools.com provides information on LM Tools, 
          whereas free.lmtools.com is a hosted service to execute various 
          test scenarios.
        </li>
        
        <li>
          Credentials you just used for registration should be used to login 
          to free.lmtools.com site only. This credential would not work at 
          lmtools.com site.
        </li>
        
        <li>
          You may like to view <a href="/license">license terms</a> here. For  
          any queries on LM Tools, please <a href="/contact">contact us</a> and 
          we shall revert back to you as early as possible. Preferred mode to 
          contact us is through email.
        </li>
      </ul>
      
      <br/>
      <hr/>
      <br/>
      
      <p align="center">You may like to <a href="">login</a> to free.lmtools.com to start testing test scenarios.</p>
      
    </>
  );
}


const Register = () => {

  const [myIp, setMyIp] = useState(null);
  const [status, setStatus] = useState(null);
  const recaptcha = useRef();

  axios.get('https://api.ipify.org?format=json').then(function(res) {
    if(res.status === 200) {
      setMyIp(res.data.ip);
    }
  });

  const handleSubmit = (event) => {
    setStatus(null);
    const captchaValue = recaptcha.current.getValue();
    recaptcha.current.reset();
    
    if (!captchaValue) {
      setStatus({
        type: 'error',
        message: 'Captch error',
      });  
      
    } else {
      const [firstname, lastname, email, password, 
            mobile, skype, dob, gender] = event.target.elements;

      const data = {
          'firstname': firstname.value,
          'lastname': lastname.value,
          'email': email.value,
          'password': password.value,
          'mobile': mobile.value,
          'skype': skype.value,
          'dob': dob.value,
          'gender': gender.value,
          'ip': myIp,
          'captcha': captchaValue
      };

      const headers = {
          "Content-Type": "application/json",
      }

      axios.post('/api/register', data, {
          headers: headers
      })
      .then( function (response) {
          alert("Registration successful");
          window.location.href = '/regsuccess';
      })
      .catch(function (error) {
          var email = document.getElementById("email");
          email.style.border = "2px solid red";

          window.location = '/login';

          const response = error.response.data;
          alert(response.message);
      })
    }
  }

  setPageTitle('Register');
  
  return(
      <form
          onSubmit={(event) => {
              event.preventDefault()
              handleSubmit(event);
          }}        
      >
          <div className="medium-text">
              <div className="center">

                  <table>
                      <tbody>
                          <tr>
                              <td>
                                  <b>First name</b> *<br/>
                                  <input required type="text" maxLength={16} id="firstname" name="firstname" placeholder="First name"/>
                              </td>

                              <td>
                                  <b>Last name</b> *<br/>
                                  <input required type="text" maxLength={16} id="lastname" name="lastname" placeholder="Last name"/>
                              </td>
                          </tr>

                          <tr> 
                              <td>
                                  <b>Email Id</b> * <br/>
                                  <input required type="text" maxLength={48} id="email" name="email" placeholder="Email Id"/>
                              </td>
                              <td>
                                  <b>Password</b> *<br/>
                                  <input required type="password" maxLength={15}  id="password" name="password" placeholder="New password"/>
                              </td>
                          </tr>

                          <tr>
                              <td>
                                  <b>Mobile</b> *<br/>
                                  <input required type="text" maxLength={20} id="mobile" name="mobile" placeholder="Mobile number"/>
                              </td>
                              <td>
                                  <b>Skype Id</b> (optional)<br/>
                                  <input type="text" maxLength={48} id="skype" name="skype" placeholder="Skype Id"/>
                              </td>
                          </tr>

                          <tr>
                              <td>
                                  <b>Date of Birth</b> *<br/>
                                  <input required type="date" id="dob" name="dob" placeholder="Date of Birth"/>
                              </td>
                              <td>
                                  <b>Gender</b> *<br/>
                                  <select name="gender" id="gender" defaultValue={'0'}>
                                      <option value="0" disabled>Gender</option>
                                      <option value="1">Male</option>
                                      <option value="2">Female</option>
                                      <option value="3">Other</option>
                                  </select>
                              </td>
                          </tr>
                      </tbody>
                  </table>

                  <div>
                      <ReCAPTCHA 
                        ref={recaptcha}
                        sitekey={process.env.REACT_APP_SITE_KEY} 
                      />
                      {status && <div className={`alert ${status.type}`}>{status.message}</div>}
                      
                      <button type="submit">Submit</button>
                      <label className='small-text'>&nbsp;Fields marked with * are mandatory
                      </label>

                  </div>


              </div>
          </div>
      </form>
  );
};

export {Register, RegisterSuccess};
