
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGScenario = () => {

  setPageTitle('Scenario');
  
  const element = (
    <div className="userguide">
      <h1>LM Tools - Scenario</h1>
      <br/>
      Scenario is the combination of templates, test suites and testbed. The test suites are 
      passed to Multiplier machines interactively and executed each test case / block one by one.
      Scenario looks as below.
      <br/>
      <br/><img className="noborderimg" src={process.env.PUBLIC_URL + '/userguide/scenario-tb-ts-tp-transformed.png'} alt="Scenario" width='50%'/>
      <br/>
      
      Scenario is not a core LM Tools component whereas testbed, test suites, templates are core 
      LM Tools component. 
      <br/><br/>
      Why so? 
      You can configure templates, test suites, testbed, controller machine,
      multiplier machines and execute a test without even creating a scenario.
      <br/><br/>
      The obvious question is why Scenarios?
      
      Scenario helps you automatically generate templates, test suites and testbed.
      Just you need to enter minimal information in add scenario GUI and create a scenario.
      With this LM Tools automatically generates templates, test suites and testbed. So it 
      reduces time and effort. 
      <br/><br/>
      
      This may not be the final version of test you want to execute,
      but you have got something similar running in system, and you can add / remove / modify 
      the test blocks in test suite to have your final version of test.

      <br/><br/>
      <h3>How to create a Scenario and Start the Test?</h3>
      
      Go to Test Environment --> Scenarios --> Add Scenario menu as shown below.
      
      <br/>
      <br/><img className="noborderimg" src={process.env.PUBLIC_URL + '/userguide/scenario-add-ui.png'} alt="Scenario menu" width='100%'/>
      
      <br/>
      Next you will see supported scenario links, click the one you want to create.
      Choose the link which is similar or closer to your actual scenario. For example 
      if you want to access an URL, then choose the <span>Access single URL</span> link, if you want to 
      simulate a SIP call, then choose the <span>SIP call</span> link etc.
      <br/>
      
      <br/><img className="noborderimg" src={process.env.PUBLIC_URL + '/userguide/scenario-links-ui.png'} alt="Scenario links" width='100%'/>
      
      <br/>
      Next you will see scenario form, enter or update information in form as you like. Hit  
      the <span>Create Scenario</span> button to create the scenario.
      <br/>
      
      <br/><img className="noborderimg" src={process.env.PUBLIC_URL + '/userguide/scenario-form-ui.png'} alt="Scenario form" width='100%'/>

      <br/>
      If the scenario is created successfully you will corresponding testbed, test suites, templates 
      as shown in below image.
      <br/>

      <br/><img className="noborderimg" src={process.env.PUBLIC_URL + '/userguide/scenario-created-ui.png'} alt="Scenario created" width='100%'/>

      <br/>
      With this scenario is created and go to testbed, hit the <span>Execute</span> button to start the test.
      Alternatively you can go to <span>Test Execution >> Start Test</span> page and select the testbed and start the test.
      <br/>
      
      <br/>
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/template"}>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/multiplier/threadsendpointsrps"}>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGScenario}

