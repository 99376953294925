
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGB2BAInstructions = () => {

  setPageTitle('B2BA Instructions');
  
  const element = (
    <div className="userguide">
      <h1>B2BA Instructions</h1>
      <br/>
          
      B2BA module is an unique module in LM Tools. In a single module it 
      supports HTTP, JSON, XML, SIP, Proprietary product and more simulation. You may question 
      what is the need to support so much functionality in a single module. 
      <br/><br/>
      Yes, it was intentionally designed
      that way, reason to support multiple cross functionality for a single test. For example,
      in a single test you may want on receiving SIP Invite message, send HTTP Post message to
      another peer. Or on a SIP call, put media to a web server using PUT message, or on receiving
      a JSON message, send a particular SIP message. 
      <br/><br/>
      There are  hundreds / thousands of such examples of cross functionality test. You can think 
      of similar use cases of cross functionality. In such cases B2BA helps to do functional / 
      load / performance / capacity testing for products. 
      <br/><br/>
      Currently B2BA is used by different companies, to automate the entire range of test suites
      of standard and proprietary products. If you would like to know more, if a specific case can
      be automated by Multiplier B2BA module, please reach out to contact 
      listed <a href="/contact" target="_blank">here</a>.
      <br/><br/>
      <h3>Instructions</h3>
      Like any other module in LM Tools, B2BA has various test cases like
      <i>create-instance, config-instance, config-sdp</i> etc. It is recomondded to 
      refer an existing test case or example on B2BA. In this page, only common instructions
      applicable for APAT, HTTPD and SIP are explained. In next pages, instructions applicable to
      individual B2BA like APAT, HTTPD and SIP are explained.
      
      <ul>
          
      <li><b>set-text-templates &lt;directory&gt;</b>
      <div className="code">
      set-text-templates /usr/local/multiplier/templates/b2ba/text/calling-party<br/>
      set-text-templates /usr/local/multiplier/templates/b2ba/text/called-party
      </div>
      Instruction <b>set-text-templates</b> is used to load file based text templates to 
      memory. If you want to learn more on text templates, please check
      <a href="/lmuserguide/' . LM_DOC_VERSION . '/common/test_case_type_text_template">
      here</a>. It is recommended, not to use this in cloud setup as you do not have access  
      to machine, instead use <i>set-default-text-templates</i> instruction. 
      set-default-text-templates. 
      </li><br/>
      
      <li><b>set-default-text-templates &lt;template&gt;</b>
      <div className="code">
      set-default-text-templates fsip-call-calling-party-text-templates<br/>
      set-default-text-templates fsip-call-called-party-text-templates<br/>
      </div>
      Instruction <b>set-default-text-templates</b> is used to load web based text templates to 
      memory. If you want to learn more on text templates, please check
      <a href="/lmuserguide/' . LM_DOC_VERSION . '/common/test_case_type_text_template">
      here</a>. It is recommended, to use this in cloud setup as to use this you do not   
      need access to machine. 
      set-default-text-templates. 
      </li><br/>
      
      <li><b>set-binary-templates &lt;directory&gt;</b>
      <div className="code">
      set-binary-templates /usr/local/multiplier/templates/b2ba/binary/calling-party<br/>
      set-binary-templates /usr/local/multiplier/templates/b2ba/binary/called-party
      </div>
      Instruction <b>set-binary-templates</b> is used to load file based binary templates to 
      memory. If you want to learn more on binary templates, please check
      <a href="/lmuserguide/' . LM_DOC_VERSION . '/common/test_case_type_binary_template">
      here</a>. It is recommended, not to use this in cloud setup as you do not have access  
      to machine, instead use <i>set-default-binary-templates</i> instruction. 
      set-default-binary-templates. 
      </li><br/>
      
      <li><b>set-default-binary-templates &lt;template&gt;</b>
      <div className="code">
      set-default-binary-templates fsip-call-calling-party-binary-templates<br/>
      set-default-binary-templates fsip-call-called-party-binary-templates<br/>
      </div>
      Instruction <b>set-default-binary-templates</b> is used to load web based binary templates to 
      memory. If you want to learn more on binary templates, please check
      <a href="/lmuserguide/' . LM_DOC_VERSION . '/common/test_case_type_binary_template">
      here</a>. It is recommended, to use this in cloud setup as to use this you do not   
      need access to machine. 
      set-default-binary-templates. 
      </li><br/>
      
      <li><b>set-csv-files &lt;directory&gt;</b>
      <div className="code">
      set-csv-files /usr/local/multiplier/templates/csv-files/<br/>
      </div>
      Instruction <b>set-csv-files</b> is used to load csv files to 
      memory. When you want to feed certain data to tool, you need to use 
      <i>set-csv-files</i> instruction.

      </li><br/>
      
      <li><b>on &lt;entity&gt; &lt;token</b>1<b>&gt; &lt;token</b>2<b>&gt; . . .  &lt;token</b>n<b>&gt;</b></li>
      <li><b>ondata &lt;entity&gt; &lt;token</b>1<b>&gt; &lt;token</b>2<b>&gt; . . .  &lt;token</b>n<b>&gt;</b>
      
      <div className="code">
      on httpd1 POST [t-link] [t-body] [i-httpib]<br/>
      ondata httpd1 POST [t-link] [t-body] [i-httpib]<br/><br/>
      
      on sip1 [t-sipmsg] [i-sipib]<br/>
      on sip1 [t-sipmsg] [f-sipfunction]<br/>
      ondata sip1 [t-sipmsg] [i-sipib]<br/>
      ondata sip1 [t-sipmsg] [f-sipfunction]<br/><br/>
      
      on apat1 [t-apatmsg] [i-apatib]<br/>
      on apat1 [t-apatmsg] [f-apatfunction]<br/>
      ondata apat1 [t-apatmsg] [i-apatib]<br/>
      ondata apat1 [t-apatmsg] [f-apatfunction]<br/>
      </div>

      Instruction <b>on</b> is used to set standing instruction to execute an instruction block
      or a function on receiving a specific message. Instruction <b>ondata</b> is same as <b>on</b>
      instruction.
      <br/><br/>
      Instruction <b>on</b> is used for HTTP Server, SIP server and APAT server. As you can see
      in above example, <b>on</b> statement is used for http, sip and apat case and syntax is bit
      differnt in HTTP server case. 
      <br/><br/>
      In above examples, httpd1, sip1 and apat1 are names of http, sip and apat entities. Please ease refer 
      <b>create-instance</b> syntax in B2BA syntax page, there entity create syntax is explained.
      <br/><br/>
      Incase of <b>HTTP</b> after httpd1, three parameters are respectively method, link template 
      and body template of 
      HTTP message. The next parameter is an instruction block name. When a HTTP message is received,
      and method, link and body matches corresponding instruction block is invoked.
      <br/><br/>
      Incase of <b>SIP</b> after sip1, the next parameter is sip message template. The next parameter
      is either an instruction block name or a function name. When a particular SIP message is received,
      corresponding instruction block / function is invoked. In function case, function should return
      an instruction block name, and corresponding instruction block is executed.
      <br/><br/>
      Incase of <b>APAT</b> after apat1, the next parameter is apat message template. The next parameter
      is either an instruction block name or a function name. When a particular APAT message is received,
      corresponding instruction block / function is invoked. In function case, function should return
      an instruction block name, and corresponding instruction block is executed.

      </li><br/>
      <li><b>onconnect &lt;entity&gt; &lt;port&gt; &lt;instruction block&gt;</b>
      
      <div className="code">
      onconnect apat1 4080 [i-connect]
      </div>
      Instruction <b>onconnect</b> is used to execute an instruction block, on accepting a new
      connection at a given port. Say APAT server is listening on port 4080, when a new connection
      is received at port 4080, corresponding instruction block is invoked. This is used, when a
      new connection is accepted and immediately a new message needs to be sent on that accepted
      connection. As of now, this is supported only for apat case.

      </li><br/>
      <li><b>onstart &lt;entity&gt; &lt;endpoint prefix&gt; &lt;endpoint suffix&gt; 
      &lt;base index&gt; &lt;call gap index&gt; &lt;instruction block&gt; 
      &lt;endpoints&gt; &lt;rps&gt; </b>
      
      <div className="code">
      onstart sip1 necs_ _lm 10 1000 [i-ib1] 100 1<br/>
      onstart sip1 necs_ - 10 1000 [i-ib2] 100 10
      </div>
      
      B2BA <i>on some event initiates some action</i>. There are cases, where B2BA
      need to initiate a transaction or action just after it is up, for these 
      scenarios <b>onstart</b> instruction is required.

      In first example, on start of entity <b>sip1</b>, start instruction 
      block <b>ib1</b>. All parameters associated with this instruction are explained below.
      <ol>
      <li>sip1 - The entity name</li>
      <li>necs_ - The endpoint prefix name</li>
      <li>_lm - The endpoint suffix name. You can give - (hyphen) incase there is no suffix.</li>
      <li>10 - The base index. Say you want to start from the endpoint <i>necs_10</i>, this is acheived using 
                prefix as <i>necs_</i> and base index as <i>10</i>. </li>
      <li>1000 - Call gap index. Say you want to have calls from necs_10 to necs_1010, necs_11 to necs_1011 then
                 you can have call gap index as 1000.</li>
      <li>[i-ib1] - The instruction block that will be invoked on start of this entity.</li>
      <li>100 - Total endpoints.</li>
      <li>1 - RPS (rates per second). Per second how many endpoints are brought into service.</li>
      </ol>
      </li><br/>
      
      <li><b>wait &lt;value-in-milliseconds&gt; </b>
      
      <div className="code">
      wait 5000<br/>
      wait [time]<br/>
      </div>

      Instruction <b>wait</b> is used to introduce delay during execution. In a typical
      scenario, you may have more than one B2BA, if you want one of them must wait for 
      5 sec and then start the execution, you can do it using wait instruction.
      
      </li><br/>
      <li><b>client-connection &lt;entity&gt; &lt;localip&gt; &lt;localport&gt; &lt;remoteip&gt; &lt;remoteport&gt; &lt;transport&gt; timer &lt;timeout value&gt &lt;conn name&gt; </b>
      
      <div className="code">
      client-connection sip1 [p-sip:local-ip] [p-sip:local-client-port] [serverip] [serverport] [transport] clientconn<br/><br/>
      client-connection sip1 [p-sip:local-ip] [p-sip:local-client-port] [serverip] [serverport] [transport] timer 120000 clientconn<br/>
      </div>

      The instruction <b>client-connection</b> is used to connect B2BA to a remote peer. In create-instance 
      test case, B2BA's server connection is created. Here B2BA's client connection
      is created. This client connection can be used by all endpoints of B2BA.
      <i> Now it is supported only for SIP B2BA.</i>

      </li><br/>
      <li><b>execute [i-ib]</b>
      
      <div className="code">
      execute [i-ib1]<br/>
      execute [i-ib2]<br/>
      execute [i-ib3]<br/>
      </div>
      
      The execute block (native api) in B2BA sometimes may be larger, and LM Tools has got a limitation,
      can not accomodate so much instructions. That time it is better user can break the execute block to
      smaller instruction blocks and call those instruction blocks using <b>execute</b> syntax as shown above.
      <br/><br/>
      Please note that asynchronous instructions are not supported in these instruction blocks, however 
      you can put asynchronous instructions directly in execute block.
      
      </li><br/>
      <li><b>daemon &lt;entity&gt; start</b>
      
      <div className="code">
      daemon httpd1 start<br/>
      daemon sip1 start<br/>
      daemon apat1 start<br/>
      </div>
      Instruction <b>daemon</b> is used to start an entity identified by entity name. As shown
      in above example, httpd1, sip1 and apat1 entities are instructed to start. You can start
      all the entities in a native api test case.
      </li>
      </ul>

      
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/b2basyntax" }>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/apatb2bavariables" }>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGB2BAInstructions}

