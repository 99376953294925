import React, { useState } from 'react';

export const Child = ({ onNameChange }) => {
   const [state, setState] = useState({ name: '' });

   const handleInputTextChange = (e) => {
   const { name, value } = e.target;
   let newState = { ...state };
   newState[name] = value;  // Use name as key to update state dynamically
   setState(newState);
   onNameChange(newState); // Call callback with updated state
  };

 return (
   <div key="c-div" className="child">
    <input
     required
     id="child"
     name="name"  // Fixed the name attribute to "name"
     value={state.name}
     onChange={(e) => handleInputTextChange(e)}
   />
 </div>
 );
 };