
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGServerSyntax = () => {

  setPageTitle('Server Syntax');
  
  const element = (
    <div className="userguide">
      <h1>Server Syntax</h1>
            
      <br/>
      
      Server syntax is similar across supported modules (HTTPD, APAT, SIP). 
      However for clarity and easy to use, syntax is explain in corresponding 
      module syntax page.
      <br/><br/>
      The best way to read the Server documentation is first pick a working example,
      then check syntax, instructions and variables corresponding pages.
      <br/><br/>
      You may like to check modules specific syntax pages listed below.
      <ul>
      <li><a href="/userguide/apatserversyntax">APAT server syntax</a>
      </li>
      <li><a href="/userguide/httpdserversyntax">HTTPD server syntax</a>
      </li>
      <li><a href="/userguide/sipserversyntax">SIP server syntax</a>
      </li>
      </ul>

      Check <a href="/components/scenarios?id=-1">scenario</a> page to create a Server based scenario to get started.        
      
      <br/><br/>
      
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/servervariables" }>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/serverinstructions" }>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGServerSyntax}

