
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGIntroduction = () => {

  setPageTitle('Introduction');
  
  const element = (
    <div className="userguide">
      <p>
        <h1>Introduction</h1>
        <br/><b>LM Tools</b> offers testing tools and solutions for
        products across domains listed below.
        <br/>
        <ul>
        <li>HTTP, SOAP, REST</li>
        <li>STUN, TURN, ICE</li>
        <li>WebRTC with any signalling</li> 
        <li>VoIP, SIP, IMS</li>
        <li>Proprietary products (test by simulating any text / binary transactions over any transport protocols)</li>
        </ul>
        <br/>
        <div className="t1">Deployment</div>
        A typical lm tools deployment looks as below.
        <br/><img className="borderimg" src={process.env.PUBLIC_URL + '/lmtools-systems.png'} alt="LM Tools Deployment" width='100%'/>
        <br/>
        LM Tools offers <b>Controller</b> and <b>Web server</b> and those are already installed
        and running. Only you need to setup <b>Multiplier</b> machine. 
        <br/><br/>
        You can purchase
        a cloud machine from any service provider with <a href="/userguide/multiplier/machineinfo">this specification</a> and this should be enough to get started with
        LM Tools Multiplier machine.
        <br/><br/>
        Follow next page to download the Multiplier binaries and start with installation. 
        <br/><br/>
        
        <div className="inlinecenter">
          <button onClick={() => window.location.href="/userguide/tableofcontents" }>Back</button> 
          <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
          <button onClick={() => window.location.href="/userguide/download" }>Next</button>
        </div>
      </p>
    </div>
  );
  
  return element;
}

export {UGIntroduction}

