
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

import 
  {
    ugc_plugin_syntax, ugc_plugin_syntax_desc, 
    ugc_instructions_syntax, ugc_instructions_syntax_desc,
    ugc_text_template_syntax, ugc_text_template_syntax_desc,
    ugc_binary_template_syntax, ugc_binary_template_syntax_desc
  } from './ugcommon.js'
  
function flexisip_module_syntax_createinstance_syntax()
{
  return(
    <>
      <br/>call-type sip
      <br/>transport wss
      <br/>uri-scheme sip
      <br/>server 192.168.1.216
      <br/>server-port 443
      <br/>public-user necs_
      <br/>private-user necs_
      <br/>private-password-prefix necs_
      <br/>domain 192.168.1.216
      <br/>realm 192.168.1.216
      <br/>local-ipaddress 192.168.1.218
      <br/>user-agent NECS SIP UA 1.0.1
      <br/>video 0
      <br/>ws-hs-template ws-handshake
      <br/>ice-mode turn
      <br/>turn-server 192.168.1.217
      <br/>turn-server-port 3478
      <br/>rtcp-mux 1
      <br/>av-bundle 1
      <br/>ipversion 6
      <br/>javascript 1
      <br/>automation 1
      <br/>dns-round-robin 1
      <br/>media-capture-offset 1
      <br/>media-cert-verify 1
      <br/>client-cert-verify 1
      <br/>call-participants 2
      <br/>media-enable-call-offset 1
      <br/>t1-timer 500
      <br/>rps 960
      <br/>#ip-granularity siblings
      <br/>gnutls-log 99
      <br/>ep-status none
      <br/>#gnutls-audit-log 99
    </>
  );
}

function flexisip_module_syntax_createinstance_syntax_description()
{
  return(
    <>
      <br/>The <b>create-instance</b> command creates endpoint. This test case name is fixed, you can not use any arbitrary name. Various attributes used in create instance are explained below.
      <br/><br/><b>id</b> - The id indicates base index. If you want the endpoints to start from 1000, you may like to enter 1000 for id.   
      <br/><br/><b>transport</b> - The transport type. Possible values are <i>udp, tcp, ws, wss, sctp, tls </i>etc.
      <br/><br/><b>call-type</b> - Call type indicates what type of call it is. Possible value is <i>sip</i>
      <br/><br/><b>uri-scheme</b> - The sip uri scheme. Possible values are <i>sip, sips, tel</i> 
      <br/><br/><b>server</b> - The proxy server ip address. You can also provide proxy server FQDN.
      <br/><br/><b>server-port</b> - The proxy server port.
      <br/><br/><b>public-user</b> - The public user prefix. The MDN (or index) is appended to compute endpoint public URI. 
      <br/><br/><b>private-user</b> -  The private user prefix. The MDN (or index) is appended to compute endpoint private URI.
      <br/><br/><b>private-password-prefix</b> -  The password prefix. The MDN (or index) is appended to compute endpoint password.
      <br/><br/><b>domain</b> - The proxy server domain. 
      <br/><br/><b>realm</b> - The proxy server realm.
      <br/><br/><b>local-ipaddress</b> The ip address that will be used by multiplier.
      <br/><br/><b>user-agent</b> -  The user agent value that will be used to prepare SIP user-agent header.
      <br/><br/><b>video</b> - To disable / enable video set it to 0 / 1.
      <br/><br/><b>ws-hs-template</b> - The template that is used for websocket handshake. During websocket handshake, multiplier sends handshake (http GET) message. You can customise the handshake message using this template. 
      <br/><br/><b>ws-req-uri</b> - Request URI in websocket handsake (HTTP GET) message. 
      <br/><br/><b>ws-ahh</b> - Websocket additional http headers. You can send additional http headers in websocket handsake (HTTP GET) message.
      <br/><br/><b>rtcp-mux</b> - To disable / enable rtcp mux set it to 0 / 1.
      <br/><br/><b>av-bundle</b> - To disable / enable audio video bundle set it to 0 / 1.
      <br/><br/><b>ipversion</b> - To set the ip version 6 or 4 set it to 6 / 4.
      <br/><br/><b>javascript</b> - To initialize the javascript runtime engine set it to 1.
      <br/><br/><b>automation</b> - To view the test result (Overall stats) on basis of number of test cases instead of number of virtual users set it to 1.
      <br/><br/><b>dns-round-robin</b> - User can configure sip server FQDN instead of ipaddress. LM Tool resolves FQDN to corresponding ipaddress by A query. By default it always picks the first record from resolved record list. If you want LM Tool should pick the record in a round robin way, you need to set this to 1. This helps load blance testing (through DNS mechanism) if you have multiple sip servers resolving to same FQDN.
      <br/><br/><b>media-capture-offset</b> -   If user wants to capture all audio / video data and write to a file for all endpoints, set media-capture-offset to 1. If you want to capture for certain endpoints then provide the offset value accordingly. Possible values for this attributes are <i>1, 2, ... n</i> etc. If you have set it to 10, then media is captured for endpoint 0, 10, 20 so on ... 
      <br/><br/><b>media-enable-call-offset</b> - This enables users to have calls with media disabled. Assuming you have set this value to 10, then 0th, 10th, 20th etc.. calls will have signalling and media, other calls will have just signalling without. 
      <br/><br/><b>t1-timer</b> -  The retransmission timeout (T1 timer) value. Typically it is 500 millisec.
      <br/><br/><b>media-cert-verify</b> - If you are making call (see session-init syntax) with srtp-dtls and you want to verify certificates in media channel set it to 1, else 0. 
      <br/><br/><b>client-cert-verify</b> -  If you have selected signalling transport as TLS,WSS and you want to present the client certificates to server in TLS connection then client-cert-verify flag need to be enabled (i.e. 1) else set it to 0.
      <br/><br/><b>call-participants</b> - Total number of participants in a call. For basic call, it should be 2. For transfer case it should be 3.
      <br/><br/><b>rps</b> -  The rates per second. You can mention any positive value here. Please 
      check <a href="/userguide/multiplier/threadsendpointsrps">this</a> to 
      know more on rps.
      <br/><br/><b>ip-granularity</b> - Possible values are none, siblings, endpoints. None indicates local ip address is used for multiplier, and endpoints under all siblings. Siblings indicates local ip address is used for multiplier, next valid ip address used for all endpoints under first sibling, then  next valid ip address used for all endpoints under second sibling and so on. Endpoints indicates  local ip address is used for multiplier, and all endpoints will have unique ip address. 
      Please check <a href="/userguide/multiplier/virtualipconfig">this</a> for more information.
      <br/><br/><b>ep-status</b> -  Endpoint status is written into log file in every few seconds. Three possible value are given below.
      <ul>
        <br/><li>none - Endpoint status is not be written into log file. During heavy load testing this is the preferred option.</li>
        <br/><li>resource - Endpoint status, only resource usage portion (like size of linked lists, timer counts etc) is written to log file.</li>
        <br/><li>details -  Endpoint status in full is written into log file. This helps to debug which particular endpoint is not returned from current command execution. Once you know which endpoint is not returned, filter log for that endpoint and it helps to analyze the issue further.</li>
      </ul>
      <br/><b>gnutls-log</b> - Only if you want to see GNU TLS log set this log level. For all logs set it to 99. Only developers should use it.
    </>
  );
}

function flexisip_module_syntax_config_sdp()
{
  return(
    <>
    <b>config-sdp</b>
    <br/>session-config start 
    <br/>version 0 
    <br/>origin multiplier 
    <br/>session multiplier 
    <br/>audio-codec AMR 
    <br/>video-codec VP8 
    <br/>audio-ptime 20
    <br/>video-ptime 60
    <br/>video-fps 24
    <br/>audio-samplingrate 8000
    <br/>video-samplingrate 90000
    <br/>audio-file g711a.wav
    <br/>video-file in-vp8.mlt
    <br/>session-config end 
    <br/>session-attributes start 
    <br/>t 10101 2222233 
    <br/>session-attributes end 
    <br/>#Start of audio codecs details 
    <br/>audio-codecs start 
    <br/>codecs 0 8 99 101 102 
    <br/>a rtpmap:0 PCMU/8000 
    <br/>a rtpmap:8 PCMA/8000 
    <br/>a rtpmap:99 AMR/8000 
    <br/>a rtpmap:101 telephone-event/8000 
    <br/>a fmtp:101 0-11 
    <br/>a rtpmap:102 OPUS/8000 
    <br/>b AS 64 
    <br/>a ptime:25 
    <br/>a curr:qos local none 
    <br/>a curr:qos remote none 
    <br/>a des:qos mandatory local sendrecv 
    <br/>a des:qos mandatory remote sendrecv 
    <br/>audio-codecs end 
    <br/>#Start of video codecs details 
    <br/>video-codecs start 
    <br/>codecs 107 108 
    <br/>a rtpmap:107 VP8/40000 
    <br/>a rtpmap:108 VP9/40000 
    <br/>b AS 128 
    <br/>a ptime:70 
    <br/>a curr:qos local none 
    <br/>a curr:qos remote none 
    <br/>a des:qos mandatory local sendrecv 
    <br/>a des:qos mandatory remote sendrecv 
    <br/>video-codecs end 
    <br/>#spread-over 10000
    </>
  );
}

function flexisip_module_syntax_config_sdp_description()
{
  return(
    <>
    <b>config-sdp</b> is used to configure sdp parameters. This test case name is fixed, you can not use any arbitrary name. Various attributes are explained below.  
    <br/><br/><b>session-config start</b> - Indicates start of session level configuration.
    <br/><br/><b>version</b> - SDP version. Typically set to 0. 
    <br/><br/><b>origin</b> - SDP origin. You can put any token say multiplier.
    <br/><br/><b>session</b> - Session. You can put any token say multiplier.
    <br/><br/><b>audio-codec</b> - Audio codec names. Possible values are <i>PCMA, PCMU, AMR, OPUS</i>. 
    <br/><br/><b>video-codec</b> - Video codec name. Possible value is <i>VP8</i>. 
    <br/><br/><b>audio-ptime</b> - Audio packetization time in millisecond.
    <br/><br/><b>video-ptime</b> - Video packetization time in millisecond. With mlt file introduction (since multiplier 3.03), this parameter is not required.
    <br/><br/><b>video-fps</b> - Video frames per second. Introduced in multiplier 3.03.
    <br/><br/><b>audio-samplingrate</b> - Audio sampling in Hz.
    <br/><br/><b>video-samplingrate</b> - Video sampling in Hz.
    <br/><br/><b>audio-file</b> - Audio input file name. The output file takes a format like out-<i>multiplierid</i>-<i>infilename</i>.
    <br/><br/><b>video-file</b> - Video input file name. The output file takes a format like out-<i>multiplierid</i>-<i>infilename</i>. Starting from Multiplier version 3.03, <b>mlt</b> video files are introduced. This is multiplier proprietary video format file. This file is not playable, however you can use utility <b>mfmedia</b> to to convert mlt file to mkv file.
    <br/><br/><b>session-config end</b> - Indicates end of session level configuration.
    <br/><br/><hr></hr>
    <b>session-attributes start</b> - Indicates start of session attributes.
    <br/><br/><b>t</b> - Time stamp. You can put start and end values. Example given below.
    <br/><br/><i>t 10101 2222233</i>. 
    <br/><br/><b>a</b> - You can put any attribute. Some examples given below.
    <br/><br/><i>a group:BUNDLE audio</i>
    <br/><br/><i>a msid-semantic: WMS<br/> FUNgvHSv45OGnJA8ikuFBKVOBekWCfkM3xNZ</i>
    <br/><br/><b>session-attributes end</b> - Session level attribute end. 
    <br/><br/><hr></hr>
    <br/><b>audio-codecs start</b> - Audio codec configuration start. 
    <br/><br/><b>codecs</b> - You need to set all audio codecs here as space separated values. Example given below.
    <br/><br/><i>0 8 99 101 111</i>
    <br/><br/><b>a rtpmap:</b> - RTP map for audio codec 
    <br/><br/><b>a fmtp:</b> - fmtp attribute.
    <br/><br/><b>a ptime:</b>- Packetization time in millisecond. 
    <br/><br/><b>b AS</b> - Specify bandwidth here. 
    <br/><br/><b>a curr:</b> - Current qos attribute, used in precondition case. 
    <br/><br/><b>a des:</b> - Desired qos attribute, used in precondition case. 
    <br/><br/><b>a cnf:</b> - Confirmed qos attribute, used in precondition case. 
    <br/><br/><b>audio-codecs end</b> - Audio codec configuration end. 
    <br/><br/><hr></hr>
    <br/><b>video-codecs start</b> - Video codec configuration start. 
    <br/><br/><b>codecs</b> - You need to set all video codecs here as space separated values. 
    <br/><br/><b>a rtpmap:</b> - RTP map for video codec 
    <br/><br/><b>a ptime:</b> - Packetization time in millisecond. 
    <br/><br/><b>b AS</b> - Specify bandwidth here. 
    <br/><br/><b>a curr:</b> - Current qos attribute, used in precondition case. 
    <br/><br/><b>a des:</b> - Desired qos attribute, used in precondition case. 
    <br/><br/><b>a cnf:</b> - Confirmed qos attribute, used in precondition case. 
    <br/><br/><b>video-codecs end</b> - Video codec configuration end.
    </>
  );
}

function flexisip_module_syntax_variables()
{
  return(
    <>
      <b>variables</b>
      <br/>global msid=[p-random-hex-number:36]
      <br/>global label=[p-random-hex-number]
      <br/>global server-port=5060
      <br/>global entity-id=[p-random-number]
      <br/>global sess_id=[p-random-number]
      <br/>#global sess_id="session1"
      <br/>global mdn=[p-public-user]
      <br/>global sess-version=[p-random-number]
    </>
  );
}

function flexisip_module_syntax_variables_description()
{
  return(
    <>
    This test case is of type variable. To know more on this please 
    visit <a href="/userguide/variables">here</a>. 
    This test case name is not fixed, you can use any test case name as you like.  You can declare strings, integers. Even you can declare a variable with random values. Lets see some examples.
    <br/><ol>
    <br/><li><i>global msid=[p-random-hex-number:36]</i>
    <br/>In above example, msid is a string variable initialised with a hex-string of size 36 bytes. Please note that length is mentioned here as 36 bytes.
    </li><br/><li><i>global label=[p-random-hex-number]</i>
    <br/>Here label is a string variable, initialised to a 7 byte random hex-string. If length is not mentioned by default length is 7 bytes.
    </li><br/><li><i>global server-port=5060</i>
    <br/>Here server-port is a integer variable initialised to value 5060.
    </li><br/><li><i>global entity-id=[p-random-number]</i>
    <br/>Here label is a string variable, initialised to a 7 byte random digit string. If length is not mentioned by default length is 7 bytes.
    </li><br/><li><i>global sess_id=[p-random-number]</i>
    <br/>Here label is a string variable, initialised to a 7 byte random digit string. If length is not mentioned by default length is 7 bytes.
    </li><br/><li><i>global str1="LM Tools"</i>
    <br/>Here str1 is a string variable initialised with value <i>LM Tools</i>
    </li><br/><li><i>global mdn=[p-public-user]</i>
    <br/>Here mdn is a string variable, initialised to a plugin variable <i>public-user</i>.  Plugin variables are variables provided by multiplier plugins. In create-instance you have configured public-user, same is stored in multiplier plugin, and here that same value is provided. Sometimes even if you have not configured certain things, you will be able to extract certain system internal values as plugin variables. More of this you will see in instruction blocks down.
    </li><br/></ol>
    </>
  );
}

function flexisip_module_syntax_session_init()
{
  return (
    <>
    <b>session-init</b><br/>
    session-id session1
    <br/>call-gap-index -1
    <br/>called-party-domain 192.168.1.216
    <br/>called-party-prefix necs_ 
    <br/>media-transport rtp-udp
    <br/>
    <br/>#crypto-suite AES_CM_128_HMAC_SHA1_80
    <br/>#sec-service confidential
    <br/>
    <br/>#crypto-suite SRTP_AES128_CM_HMAC_SHA1_80
    <br/>#crypto-suite SRTP_AES128_CM_HMAC_SHA1_32
    <br/>rps 512
    </>
  );   
}

function flexisip_module_syntax_session_init_description()
{
  return(
    <>
    <b>session-id</b> -  Each session posses unique session key. You can specify session key using this parameter. Later this session is referred using this key.
    <br/><br/><b>call-gap-index</b> - The call gap index is used by calling party to determine called party index. Either a positive value or negative value you can specify here. If your test involves hundreds or thousands of endpoints for basic call, it is strongly recommended to set this as -1. You should think of a call model where endpoint 1 calls endpoint 0,  endpoint 3 calls endpoint 2 so on. This model ensures, first called party is scheduled and then calling party, hence chance of succeeding calls is more.
    <br/><br/><b>called-party-domain</b> - You can set the domain IP address or domain name using this parameter.
    <br/><br/><b>called-party-prefix</b> - You can set called party user prefix using this parameter. 
    <br/><br/><b>media-transport</b> - Media transport profiles. Possible values are rtp-udp, rtp-tcp, rtp-tls, srtp-udp and srtp-dtls.
    <br/><br/><ul><li><b>rtp-udp</b>: RTP packets exchange over UDP.</li>
    <br/><li><b>rtp-tcp</b>: RTP packets exchange over TCP. </li>
    <br/><li><b>rtp-tls</b>: RTP packets exchange over TLS. </li>
    <br/><li><b>srtp-udp</b>: SRTP packets exchange over UDP. </li>
    <br/><li><b>srtp-dtls</b>: SRTP packets exchange over UDP, key is extracted from DTLS handshake. </li></ul>
    <br/><b>crypto-suite </b>  - Crypto-suite type based on security service.
    <br/>Possible values are - <ul><li>SRTP_AES128_CM_HMAC_SHA1_80</li>
    <br/><li>SRTP_AES128_CM_HMAC_SHA1_32</li>
    <br/><li>SRTP_NULL_HMAC_SHA1_80</li>
    <br/><li>SRTP_NULL_SHA1_32</li></ul>
    <br/><b>sec-service</b> - Security services. Possible values are 
    <br/><ul><li>confidential-authentication</li>
    <br/><li>authentication</li>
    <br/><li>confidential</li>
    <br/><li>none</li></ul>
    <br/>This attribute is only applicable when <i>media-transport</i> is srtp-udp.
    </>
  );
}

function flexisipsyntax_table(modulename)
{
  return (
      <table className="statstbl">
        <thead>
          <tr>
            <th style={{width: "5%"}}>
              Sl
            </th>
            
            <th style={{width: "40%"}}>
              Test block
            </th>
            
            <th style={{width: "55%"}}>
              Syntax
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              1
            </td>
            
            <td>
              <b>create-instance</b>
              {flexisip_module_syntax_createinstance_syntax()}
            </td>
            
            <td>
            {flexisip_module_syntax_createinstance_syntax_description()}
            </td>
          </tr>


          <tr>
            <td>
              2
            </td>
            
            <td>
              {flexisip_module_syntax_config_sdp()}
            </td>
            
            <td>
            {flexisip_module_syntax_config_sdp_description()}
            </td>
          </tr>


          <tr>
            <td>
              3
            </td>
            
            <td>
              {flexisip_module_syntax_variables()}
            </td>
            
            <td>
            {flexisip_module_syntax_variables_description()}
            </td>
          </tr>


          <tr>
            <td>
              4
            </td>
            
            <td>
              {ugc_plugin_syntax()}
            </td>
            
            <td>
              {ugc_plugin_syntax_desc(modulename)}
            </td>
          </tr>

          <tr>
            <td>
              5
            </td>
            
            <td>
              {ugc_instructions_syntax()}
            </td>
            
            <td>
              {ugc_instructions_syntax_desc(modulename)}
            </td>
          </tr>


          <tr>
            <td>
              6
            </td>
            
            <td>
              {ugc_text_template_syntax()}
            </td>
            
            <td>
              {ugc_text_template_syntax_desc()}
            </td>
          </tr>



          <tr>
            <td>
              7
            </td>
            
            <td>
              {ugc_binary_template_syntax()}
            </td>
            
            <td>
              {ugc_binary_template_syntax_desc()}
            </td>
          </tr>

          <tr>
            <td>
              8
            </td>
            
            <td>
              {flexisip_module_syntax_session_init()}
            </td>
            
            <td>
              {flexisip_module_syntax_session_init_description()}
            </td>
          </tr>

          <tr>
            <td>
              9
            </td>
            
            <td>
              <b>session-deinit</b>
              <br/>rps 512
              <br/>
            </td>
            
            <td>
              It de-initialises session that was created in session-init test case.
            </td>
          </tr>


          <tr>
            <td>
              10
            </td>
            
            <td>
              <b>sleep</b>
              <br/>sleep 1000
              <br/>
            </td>
            
            <td>
              You can specify sleep time in millisecond here.
            </td>
          </tr>


        </tbody>
      </table>    
  );
}


const UGFlexiSIPSyntax = () => {

  setPageTitle('FlexiSIP Syntax');
  
  const element = (
    <div className="userguide">
    <h1>FlexiSIP Syntax</h1>
    <br/>
        
      {flexisipsyntax_table("flexisip")}

      <br/>
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/flexisipvariables" }>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/flexisipwebrtcsyntax" }>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGFlexiSIPSyntax, flexisipsyntax_table}

