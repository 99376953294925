

import {setPageTitle} from './lm.js'

const AuthReqd = () => {
    
    setPageTitle('Authentication required');
    
    return (
        <div>
            You are not allowed to access this page. 
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    );
};

export default AuthReqd;
