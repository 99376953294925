
import { lmURISchemes } from '../../../routes/lm.js';
  
/*
* Please note the formats are indented from start of line intentionally.
* Do not do normal formating else sip messages will have additional spaces.
*/
function sipRxRegister(serverConfig) {

  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[serverConfig.urischeme];
  
  return {
    tpname: 'rx-register',
    format: 
`REGISTER [...]\\r\\n
Via: [reg-via]\\r\\n
From:[...]${urischeme}:[from-user]@[from-host]>;[...]\\r\\n
To:[...]${urischeme}:[to-user]@[to-host]>\\r\\n
[...]Call-ID: [call-id]\\r\\n
[...]CSeq: [cseq] REGISTER\\r\\n
[...]`
  };
}

function sipTxRegister401(serverConfig) {

  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[serverConfig.urischeme];
  
  return {
    tpname: 'tx-register401',
    format: 
`SIP/2.0 401 Authentication required\\r\\n
Via: [reg-via]\\r\\n
From: <${urischeme}:[from-user]@[from-host]>;tag=[p-sip:from-tag]\\r\\n
To: <${urischeme}:[to-user]@[to-host]>;tag=[p-sip:to-tag]\\r\\n
Call-ID: [call-id]\\r\\n
CSeq: [cseq] REGISTER\\r\\n
Content-Length: 0\\r\\n
\\r\\n`
  };
}

function sipTxRegister200(serverConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[serverConfig.urischeme];
    
  return {
    tpname: 'tx-register-200',
    format: 
`SIP/2.0 200 OK\\r\\n
Via: [reg-via]\\r\\n
From: <${urischeme}:[from-user]@[from-host]>;tag=[p-sip:from-tag]\\r\\n
To: <${urischeme}:[to-user]@[to-host]>;tag=[p-sip:to-tag]\\r\\n
Call-ID: [call-id]\\r\\n
CSeq: [cseq] REGISTER\\r\\n
Content-Length: 0\\r\\n
\\r\\n`
  };
}

function sipRxUnRegister(serverConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[serverConfig.urischeme];
  
  return {
    tpname: 'rx-unregister',
    format: 
`REGISTER [...]\\r\\n
Via: [reg-via]\\r\\n
From:[...]${urischeme}:[from-user]@[from-host]>;[...]\\r\\n
To:[...]${urischeme}:[to-user]@[to-host]>\\r\\n
[...]Call-ID: [call-id]\\r\\n
[...]CSeq: [cseq] REGISTER\\r\\n
[...]Expires: 0\\r\\n
[...]`
  };
}

function sipTxUnRegister200(serverConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[serverConfig.urischeme];
  
  return {
    tpname: 'tx-unregister-200',
    format: 
`SIP/2.0 200 OK\\r\\n
Via: [reg-via]\\r\\n
From: <${urischeme}:[from-user]@[from-host]>;tag=[p-sip:from-tag]\\r\\n
To: <${urischeme}:[to-user]@[to-host]>;tag=[p-sip:to-tag]\\r\\n
Call-ID: [call-id]\\r\\n
CSeq: [cseq] REGISTER\\r\\n
Content-Length: 0\\r\\n
\\r\\n`
  };
}

function sipTxInvite(serverConfig, callConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[serverConfig.urischeme];

    let msg = 
`INVITE ${urischeme}:[p-sip:remote:session1]@[p-sip:local-ip] SIP/2.0\\r\\n
Via: SIP/2.0/[p-sip:transport] [p-sip:local-ip]:[p-sip:local-client-port];rport;branch=z9hG4bK[p-random-hex-string]\\r\\n
From: <${urischeme}:[p-sip:local]@[p-sip:local-ip]>;tag=[p-random-hex-string]\\r\\n
To: <${urischeme}:[p-sip:remote:session1]@[p-sip:local-ip]>\\r\\n
Contact: <${urischeme}:[p-sip:local]@[p-sip:local-ip]:[p-sip:local-client-port];transport=[p-sip:transport]>\\r\\n
Call-ID: [p-sip:call-id]\\r\\n
CSeq: 2 INVITE\\r\\n
Max-Forwards: 70\\r\\n
Required: 100rel,precondition\\r\\n
Allow: INVITE, ACK, CANCEL, BYE, REFER, MESSAGE, SUBSCRIBE, NOTIFY, PUBLISH\\r\\n
Content-type: application/sdp\\r\\n
User-Agent: multiplier-v2.0\\r\\n
Organization: Load Multiplier\\r\\n
Content-Length:\\r\\n
\\r\\n
v=0\\r\\n
o=- media 0 IN IP4 [p-sip:local-ip]\\r\\n
s=-\\r\\n
t=0 0\\r\\n\n`;      
      
    if(callConfig.avbundle === 1) {
      msg += "a=group:BUNDLE audio video\\r\\n\n";
    }
      
    msg += 
`m=audio [p-sip:audio-rtp-port:session1] RTP/AVP [p-sip:audio-codec-number]\\r\\n
c=IN IP4 [p-sip:local-ip]\\r\\n
a=rtcp:[p-sip:audio-rtp-port:session1] IN IP4 [p-sip:local-ip]\\r\\n
a=sendrecv\\r\\n
a=rtpmap:[p-sip:audio-codec-number] [p-sip:audio-codec]/[p-sip:audio-sampling-rate]\\r\\n`;
            
    if(callConfig.vcodec !== 0) {
      msg += 
`\nm=video [p-sip:video-rtp-port:session1] RTP/AVP [p-sip:video-codec-number]\\r\\n
c=IN IP4 [p-sip:local-ip]\\r\\n
a=rtcp:[p-sip:video-rtp-port:session1] IN IP4 [p-sip:local-ip]\\r\\n
a=sendrecv\\r\\n
a=rtpmap:[p-sip:video-codec-number] [p-sip:video-codec]/[p-sip:video-sampling-rate]\\r\\n`;       
    } 

  return {
    tpname: 'tx-invite',
    format: msg
  };
}

function sipRxInvite100() {
  return {
    tpname: "rx-invite-100",
    format: "SIP/2.0 100[...]"
  };
}

function sipRxInvite180(serverConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[serverConfig.urischeme];
  
  return {
    tpname: "rx-invite-180",
    format:
`SIP/2.0 180[...]\\r\\n
From:[...]<${urischeme}:[from-user:session1]@[...]tag=[from-tag:session1]\\r\\n
To:[...]<${urischeme}:[to-user:session1]@[...]tag=[to-tag:session1]\\r\\n
[...]Call-ID: [callid_1]\\r\\n
[...]CSeq: [cseq] INVITE\\r\\n
[...]Contact: <[remote-contact]>\\r\\n
[...]`    
  };
}

function sipRxInvite200(serverConfig, callConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[serverConfig.urischeme];
  
  let msg = 
`SIP/2.0 200 OK[...]\\r\\n
From:[...]<${urischeme}:[from-user:session1]@[...]tag=[from-tag:session1]\\r\\n
To:[...]<${urischeme}:[to-user:session1]@[...]tag=[to-tag:session1]\\r\\n
[...]Call-ID: [callid_1]\\r\\n
[...]CSeq: [cseq] INVITE\\r\\n
[...]Contact: <[remote-contact]>\\r\\n
[...]m=audio [remote-audio-rtp-port] RTP/AVP [remote-acodec-number]\\r\\n
[...]c=IN IP4 [remote-audio-ip]\\r\\n
[...]a=rtpmap:[...] [remote-acodec-name]/[...]\\r\\n`;
        
  if(callConfig.vcodec !== 0) {
    msg += 
`\n[...]m=video [remote-video-rtp-port] RTP/AVP [remote-vcodec-number]\\r\\n
[...]c=IN IP4 [remote-video-ip]\\r\\n
[...]a=rtpmap:[...] [remote-vcodec-name]/[...]`;          
  }
  
  return {
    tpname: 'rx-invite-200',
    format: msg
  };
}

function sipTxAck(serverConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[serverConfig.urischeme];
  
  return {
    tpname: 'tx-ack',
    format:
`ACK [remote-contact] SIP/2.0\\r\\n
Via: SIP/2.0/[p-sip:transport] [p-sip:local-ip];branch=z9hG4bK[p-random-hex-string];rport\\r\\n
From: <${urischeme}:[from-user:session1]@[p-sip:local-ip]>;tag=[from-tag:session1]\\r\\n
To: <${urischeme}:[to-user:session1]@[p-sip:local-ip]>;tag=[to-tag:session1]\\r\\n
Contact: <${urischeme}:[from-user:session1]@[p-sip:local-ip];transport=[p-sip:transport]>\\r\\n
Call-ID: [callid_1]\\r\\n
CSeq: [cseq] ACK\\r\\n
Max-Forwards: 70\\r\\n
User-Agent: multiplier-v2.0\\r\\n
Organization: Load Multiplier\\r\\n
Content-Length: 0\\r\\n
\\r\\n`   
  };
}

function sipTxBye(serverConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[serverConfig.urischeme];
  
  return {
    tpname: 'tx-bye',
  format:
`BYE [remote-contact] SIP/2.0\\r\\n
Via: SIP/2.0/[p-sip:transport] [p-sip:local-ip]:[p-sip:local-client-port];rport;branch=z9hG4bK[p-random-hex-string]\\r\\n
Max-Forwards: 70\\r\\n
From: <${urischeme}:[p-sip:local]@[p-sip:local-ip]>;tag=[from-tag:session1]\\r\\n
To: <${urischeme}:[p-sip:remote:session1]@[p-sip:local-ip]>;tag=[to-tag:session1]\\r\\n
Call-ID: [callid_1]\\r\\n
CSeq: 6 BYE\\r\\n
Contact: <${urischeme}:[p-sip:local]@[p-sip:local-ip]:[p-sip:local-client-port];transport=[p-sip:transport]>\\r\\n
Content-Length: 0\\r\\n
\\r\\n`   
  };
}

function sipRxBye200() {
  return {
    tpname: 'rx-bye-200',
    format:
`SIP/2.0 200 OK\\r\\n
[...]CSeq: [...]\\r\\n
[...]`
  };
}

/////////////////////////////////////////////////////////

function sipRxInvite(serverConfig, callConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[serverConfig.urischeme];
  
  let msg = 
`INVITE[...]\\r\\n
Via: [rx-inv-via]\\r\\n
From:[...]${urischeme}:[from-user:session1]@[...]tag=[from-tag:session1]\\r\\n
To:[...]${urischeme}:[to-user:session1]@[...]\\r\\n
[...]Call-ID: [call-id:session1]\\r\\n
[...]CSeq: [cseq] INVITE\\r\\n
[...]m=audio [remote-audio-rtp-port] [...]\\r\\n
[...]c=IN IP4 [remote-audio-ip]\\r\\n
[...]a=rtpmap:[remote-acodec-number] [remote-acodec-name]/[...]\\r\\n
[...]m=video [remote-video-rtp-port] [...]\\r\\n`;
  
  if(callConfig.vcodec !== 0) {
    msg += 
`\n[...]c=IN IP4 [remote-video-ip]\\r\\n
[...]a=rtpmap:[remote-vcodec-number] [remote-vcodec-name]/[...]\\r\\n`;    
  }
  
  return {
    tpname: 'rx-invite',
    format: msg,
  };
}

function sipTxInvite180(serverConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[serverConfig.urischeme];
  
  return {
    tpname: 'tx-invite-180',
    format:
`SIP/2.0 180 Ringing\\r\\n
Via: [rx-inv-via]\\r\\n
From: <${urischeme}:[p-sip:remote]@[p-sip:local-ip]>;tag=[p-sip:from-tag]\\r\\n
To: <${urischeme}:[p-sip:local]@[p-sip:local-ip]>;tag=[p-sip:to-tag]\\r\\n
Call-ID: [p-sip:call-id]\\r\\n
CSeq: [cseq] INVITE\\r\\n
Contact: <${urischeme}:[p-sip:remote]@[p-sip:local-ip]:[p-sip:local-client-port];transport=[p-sip:transport]>\\r\\n
Allow: ACK, BYE, CANCEL, INVITE, MESSAGE, NOTIFY, OPTIONS, PRACK, REFER, UPDATE\\r\\n
Content-Length:0\\r\\n
\\r\\n`
  };
}

function sipTxInvite200(serverConfig, callConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[serverConfig.urischeme];
  
  let msg = 
`SIP/2.0 200 OK\\r\\n
Via: [rx-inv-via]\\r\\n
From: <${urischeme}:[p-sip:remote]@[p-sip:local-ip]>;tag=[p-sip:from-tag]\\r\\n
To: <${urischeme}:[p-sip:local]@[p-sip:local-ip]>;tag=[p-sip:to-tag]\\r\\n
Call-ID: [p-sip:call-id]\\r\\n
CSeq: [cseq] INVITE\\r\\n
Contact: <${urischeme}:[p-sip:remote]@[p-sip:local-ip]:[p-sip:local-client-port];transport=[p-sip:transport]>\\r\\n
Content-Type: application/sdp\\r\\n
Allow: ACK, BYE, CANCEL, INVITE, MESSAGE, NOTIFY, OPTIONS, PRACK, REFER, UPDATE\\r\\n
Content-Length:\\r\\n
\\r\\n
v=0\\r\\n
o=- media 0 IN IP4 IN IP4 [p-sip:local-ip]\\r\\n
s=-\\r\\n
t=0 0\\r\\n`;

  if(callConfig.avbundle === 1) {
    msg += 
`\na=group:BUNDLE audio video\\r\\n`;    
  }
 
  msg += 
`\nm=audio [p-sip:audio-rtp-port:session1] RTP/AVP [p-sip:audio-codec-number]\\r\\n
c=IN IP4 [p-sip:local-ip]\\r\\n
a=sendrecv\\r\\n
a=rtpmap:[p-sip:audio-codec-number] [p-sip:audio-codec]/[p-sip:audio-sampling-rate]\\r\\n`;
  

  if(callConfig.vcodec !== 0) {
    msg += 
`\nm=video [p-sip:video-rtp-port:session1] RTP/AVP [p-sip:video-codec-number]\\r\\n
c=IN IP4 [p-sip:local-ip]\\r\\n
a=sendrecv\\r\\n
a=rtpmap:[p-sip:video-codec-number] [p-sip:video-codec]/[p-sip:video-sampling-rate]\\r\\n`;
  }
  
  msg += `\na=maxptime:60\\r\\n`;
  
  return {
    tpname: 'tx-invite-200',
    format: msg,
  };
}

function sipRxAck() {
  
  return {
    tpname: 'rx-ack',
    format:
`ACK[...]\\r\\n
To: [...]tag=[to-tag:session1]\\r\\n
[...]\\r\\n`
  };
}

function sipRxBye() {
  
  return {
    tpname: 'rx-bye',
    format:
`BYE [...]\\r\\n
Via: [bye-via]\\r\\n
[...]CSeq: [cseq] BYE[...]`
  };
}

function sipTxBye200(serverConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[serverConfig.urischeme];
  
  return {
    tpname: 'tx-bye-200',
    format:
`SIP/2.0 200 OK\\r\\n
Via: [bye-via]\\r\\n
From: <${urischeme}:[p-sip:local]@[p-sip:local-ip]>;tag=[p-sip:from-tag]\\r\\n
To: <${urischeme}:[p-sip:remote]@[p-sip:local-ip]>;tag=[p-sip:to-tag]\\r\\n
Contact: <${urischeme}:[p-sip:local]@[p-sip:local-ip];transport=[p-sip:transport]>\\r\\n
Call-ID: [p-sip:call-id]\\r\\n
CSeq: [cseq] BYE\\r\\n
Max-Forwards: 70\\r\\n
User-Agent: multiplier-v2.0\\r\\n
Organization: Load Multiplier\\r\\n
Content-Length: 0\\r\\n
\\r\\n`
  };
}

export {
  sipRxRegister, sipTxRegister401, sipTxRegister200,
  
  sipRxUnRegister, sipTxUnRegister200,
  
  sipTxInvite, sipRxInvite100, sipRxInvite180, sipRxInvite200,
  sipTxAck, sipTxBye, sipRxBye200,
  
  sipRxInvite, sipTxInvite180, sipTxInvite200,
  sipRxAck, sipRxBye, sipTxBye200
}


