
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGQuickStart = () => {

  setPageTitle('Quick Start - Everything in Single Page');
  
  let index=0;
  const element = (
    <div className="userguide">
      <h1>Quick Start - Everything in Single Page</h1>
      <br/>
      You should be able to use <b>LM Tools</b> within 5 minutes.  
      LM Tools has 3 components - 
      <br/>
      <ul>
        <li><b>Front end</b> - It's a light weight webserver and it is already running in cloud.</li>
        <li><b>Controller</b> - It manages N number of simulators (multipliers) and is already running in cloud.</li>
        <li><b>Multiplier</b> - It actually simulates virtual users, exchanges messages with system under test. This needs to be installed 
          in a new machine and the machine needs to be arranged by user. 
          <br/><br/><ol>
            <li>Buy the machine (<a href="/userguide/multiplier/machineinfo">specification</a>) from any service provider like Contabo, Digital ocean, Amazon etc.</li>
            <li><a href="/userguide/download">Download</a> the multiplier installation package.</li>
            <li><a href="/userguide/install">Install</a> the multiplier.</li>
          </ol>
        </li>
      </ul>
      Now you can create scenarios and start testing.
      <br/><br/><br/>
      <h2>Create scenario</h2>
      Before creating any scenario lets add controller and multiplier to setup.
      <ol>
        <li>
          First <a href="/components/controller?id=-1">add controller</a>.
          This step is required only once. Next time
          when you create a scenario this step is not required. 
          
          IP address of the default controller provided by LM Tools is 173.212.222.222, please add 
          the default controller as shown in below diagram.
          
          
          <br/><img className="noborderimg" src={process.env.PUBLIC_URL + '/userguide/add-controller-ui.png'} alt="Scenario menu" width='100%'/>

          To know more on controllers please  
          refer <a href="/userguide/controller">controller manual</a> page.

        </li>

        <li>
          Next <a href="/components/multiplier?id=-1">add multiplier</a>. 
          This step is required only once. Next time
          when you create a scenario this step is not required. You have already 
          purchased a new machine, note its ip address and add the multiplier as shown below.
          <br/><br/>
          
          <img className="noborderimg" src={process.env.PUBLIC_URL + '/userguide/add-multiplier-ui.png'} alt="Scenario menu" width='100%'/>

          To know more on multipliers please 
          refer <a href="/userguide/multiplier">multiplier manual</a> page.

        </li>
      </ol>
      
      <br/>
      <u><h3>Example {++index} - Access Single URL</h3></u> 
      <ol>
      
        <li>
          Goto page <a href="/components/scenarios?id=-1">Test Environment &#x2B95; Scenarios &#x2B95; Add Scenario</a>.
        </li>

        <li>
          Choose the right scenario. For this example let's choose a simple 
          scenario <a href="/components/scenarios/create/access-single-url"> 
          Access single URL</a>.
        </li>


        <li>
          For access single URL scenario, you will see a form as below.
          <br/><img className="noborderimg" src={process.env.PUBLIC_URL + '/userguide/scenario-form-ui.png'} alt="Scenario menu" width='100%'/>
        </li>

        <li>
          Most of the fields will have default values. Please enter 
          local ip address and url value as per your requirement. In Local ip 
          address field, either you can enter public ip or private ip. Using public 
          ip will increase bandwidth cost. You can use private ip, if you can make 
          sure there is connectivity between your multiplier machine and system under 
          test.
        </li>

        <li>
          Hit the <b>Create Scenario</b> button to create the scenario.
        </li>

        <li> 
          With this scenario is created, go to testbed <i>(link shown on successful creation of scenario)</i>, hit the <b>Execute</b> button present in testbed to start the test.
          Alternatively you can go to <a href="/components/execute/test">Test Execution &#x2B95; Start Test</a> page and select the testbed and start the test.
        </li>

      </ol>
      
      <br/>
      <u><h3>Example {++index} - SIP Call</h3></u> 

      <ol>
      
        <li>
          Goto page <a href="/components/scenarios?id=-1">Test Environment &#x2B95; Scenarios &#x2B95; Add Scenario</a>.
        </li>

        <li>
          Choose the right scenario. For this example let's choose  
          scenario <a href="/components/scenarios/create/sip-call"> 
          SIP call</a>.
        </li>


        <li>
          For sip call scenario, you will see a form as below.
          <br/><img className="noborderimg" src={process.env.PUBLIC_URL + '/userguide/sip-call-ui-1.png'} alt="SIP call part 1" width='100%'/>
          <img className="noborderimg" src={process.env.PUBLIC_URL + '/userguide/sip-call-ui-2.png'} alt="SIP call part 2" width='100%'/>
        </li>

        <li>
          Most of the fields will have default values. Enter the ip addresses 
          as per your test setup. Using public ip for Local Ip address field may cost 
          more bandwidth cost. You can use private ip address if you can plan the test setup 
          properly.
        </li>

        <li>
          Hit the <b>Create Scenario</b> button to create the scenario.
        </li>

        <li> 
          With this scenario is created, go to testbed <i>(link shown on successful creation of scenario)</i>, hit the <b>Execute</b> button present in testbed to start the test.
          Alternatively you can go to <a href="/components/execute/test">Test Execution &#x2B95; Start Test</a> page and select the testbed and start the test.
        </li>

      </ol>

      
      <br/>
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/install/multiplier" }>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/terminologies" }>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGQuickStart}

