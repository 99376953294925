
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

import 
  {
    ugc_variables_syntax, ugc_variables_syntax_desc,
    ugc_plugin_syntax, ugc_plugin_syntax_desc, 
    ugc_instructions_syntax, ugc_instructions_syntax_desc,
    ugc_text_template_syntax, ugc_text_template_syntax_desc,
    ugc_binary_template_syntax, ugc_binary_template_syntax_desc
  } from './ugcommon.js'

function sipb2ba_createinstance_syntax()
{
  return (
    <>
    <b>create-instance</b><br/>
    sip 192.168.1.216 5060 udp sip1<br/>
    http 192.168.1.216 http1<br/>
    max-endpoints 1000<br/>
    max-sessions 1000<br/>
    max-session-time 10<br/>
    ipversion 6<br/>
    automation 1<br/>
    </>
    );
}

function sipb2ba_createinstance_syntax_desc(etype)
{
  return (
    <>
    The <b>create-instance</b> command creates {etype}. This test case name is 
    fixed, you can not use any arbitrary name. Various attributes used in create 
    instance are explained below.
    <br/><br/>
    <b>sip</b> - SIP entity is created using <b>sip</b> synntax. See in left column
    for example. User need to mention IP address, port and transport protocol for SIP
    entity. The last parameter is name of SIP entity.
    
    <br/><br/>
    <b>http</b> - User can associate a HTTP client with {etype} SIP entity using 
    <b>http</b> syntax. Just IP address and a name for HTTP client needs to be
    given for HTTP client.
    
    <br/><br/>
    <b>max-endpoints</b> - You can specify how many maximum endpoints can be handled 
    by {etype} SIP using <b>max-endpoints</b>.
    
    <br/><br/>
    <b>max-sessions</b> - You can specify how many maximum sessions can be handled 
    by {etype} SIP using <b>max-sessions</b>.
    
    <br/><br/>
    <b>max-session-time</b> - You can specify maximum session time for a session in 
    {etype} SIP using <b>max-session-time</b>.

    <br/><br/>
    <b>ipversion</b> - Set the ipversion preference IPv6 / IPv4 by setting this to 6 / 4.

    <br/><br/>
    <b>automation</b> - To view the test result (Overall stats) on basis of number of test cases instead of number of virtual users set it to 1.
    
    <br/><br/>
    <b>ep-status</b> - Endpoint status is written into log file in every few 
    seconds. Three possible value are given below.<ul>
    
    <br/><li><b>none</b> - Endpoint status is not be written into log file. 
    During heavy load testing this is the preferred option.
    </li>
    
    <br/><li><b>resource</b> - Endpoint status, only resource usage portion 
    (like size of linked lists, timer counts etc) is written to log file.
    </li>
    
    <br/><li><b>details</b> -  Endpoint status in full is written into log file. 
    This helps to debug which particular endpoint is not returned from current 
    command execution. Once you know which endpoint is not returned, filter log 
    for that endpoint and it helps to analyze the issue further.
    </li>
    </ul>

    </>
    );
  
}

function sipb2ba_configinstance_syntax()
{
  return (
    <>
    <b>config-instance</b><br/>
    ws-hs-template template<br/>
    ws-ahh headers<br/>
    ws-req-uri requri<br/>
    video 1<br/>
    media-transport rtp-udp<br/>
    <br/>
    #media-transport srtp-udp<br/>
    #crypto-suite AES_CM_128_HMAC_SHA1_80<br/>

    <br/>
    #media-transport srtp-dtls<br/>
    #crypto-suite SRTP_AES128_CM_HMAC_SHA1_80<br/>
    #crypto-suite SRTP_AES128_CM_HMAC_SHA1_32<br/>
    <br/>
    media-enable 1<br/>
    media-write 1<br/>
    <br/>
    media-ep-mode server<br/>
    data-mode data-over-ch<br/>
    permission-mode channel-bind<br/>
    <br/>
    rtcp-mux 1<br/>
    av-bundle 1<br/>
    <br/>
    ice-role controlling<br/>
    ice-mode turn<br/>
    turn-server ipaddress<br/>
    turn-server-port port<br/>
    osip-log 7
    
    </>
    );
}

function sipb2ba_configinstance_syntax_desc()
{
  return (
    <>
    
    The <b>config-instance</b> command is used to configure the entity. This test case name is 
    fixed, you can not use any arbitrary test case name for this. 
    Various attributes used in config instance are explained below.
    <br/><br/>
    <b>ws-hs-template</b> - The template that is used for websocket handshake. 
    During websocket handshake, multiplier sends handshake (http GET) message. 
    You can customise the handshake GET message using template given here. 

    <br/><br/>
    <b>ws-ahh</b> - You can add additional headers in websocket handshake GET message.
    The additional headers are provided using <b>ws-ahh</b> configuration.

    <br/><br/>
    <b>ws-req-uri</b> - You can specify request uri in GET message of websocket 
    handshake GET message. 

    <br/><br/>
    <b>video</b> - To disable / enable video set it to 0 / 1.

    <br/><br/> 
    <b>media-transport</b> - Media transport profiles. Possible values are rtp-udp, rtp-tcp, rtp-tls, srtp-udp and srtp-dtls.
    
    
    <br/><br/><b>media-transport</b> - Media transport profiles. Possible values are rtp-udp, rtp-tcp, rtp-tls, srtp-udp and srtp-dtls.
    <ul>
    <li><b>rtp-udp</b>: RTP packets exchange over UDP</li>
    <br/><li><b>rtp-tcp</b>: RTP packets exchange over TCP</li>
    <br/><li><b>rtp-tls</b>: RTP packets exchange over TLS</li>
    <br/><li><b>srtp-udp</b>: SRTP packets exchange over UDP</li>
    <br/><li><b>srtp-dtls</b>: SRTP packets exchange over UDP, key is extracted from DTLS handshake. </li>
    </ul>
    
    If you are using srtp-udp or srtp-dtls media profile, accordingly use crypto-suite shown.
    <br/><br/><b>media-enable</b> - Set it to 1 to enable media.
    <br/><br/><b>media-write</b> - Set it to 1 to write the received media. Please note that if you are 
    using media-link kind of functionality then captured media is not written to disk. 
    <br/><br/><b>media-ep-mode</b> - Media endpoint mode. Set it either client or server as required. For a single call
    B2BA will have two sessions, terminating session and originating session. The mode you set here is
    for terminating session, and for originating session it takes other value.
    <br/><br/><b>data-mode</b> - Data mode. Typically used when you have turn server in media path.
    Possible values are plain-data, data-over-ch (data over channel), data-over-ind (data over indication).
    <br/><br/><b>permission-mode</b> - Permission mode is used when turn server is in media path. Possible
    values are channel-bind and create-permission. Depending on this mode either channel bind or create permission 
    procedure is followed during media channel setup.
    <br/><br/><b>rtcp-mux</b> - To disable / enable rtcp mux, you can set it to 0 or 1. 
    <br/><br/><b>av-bundle</b> -To disable / enable audio video bundle, set it to 0 or 1. 
    <br/><br/><b>ice-mode</b> - ICE mode. Possible values are <i>host / lite / stun / turn</i>.
    <br/><br/><b>ice-role</b> - ICE role. Possible values are controlling and controlled.
    <br/><br/><b>turn server</b> - Specify turn server ip address or FQDN.
    <br/><br/><b>turn-server-port</b> - Specify turn server port.
    <br/><br/><b>osip-log</b> - You can generate osip log files in m-log folder. The log level 7 is the highest log level.
    This helps to check if message encode / decode issues are there.
    
    </>
    );
}

function sipb2ba_configsdp_syntax()
{
  return (
    <>
    <b>config-sdp</b><br/>
    session-config start<br/>
    version 0<br/>
    origin lm<br/>
    session lm<br/>
    audio-codec PCMU<br/>
    video-codec VP8<br/>
    audio-ptime 20<br/>
    video-fps 24<br/>
    video-file in-vp8.mlt<br/>
    audio-samplingrate 8000<br/>
    video-samplingrate 90000<br/>
    session-config end<br/>
    </>
    );
}

function sipb2ba_configsdp_syntax_desc()
{
  return (
    <>
    This test case name is fixed, you can not use any arbitrary name. 
    Various attributes are explained below.
    
    <br/><br/><b>session-config start</b> - Indicates start of session level 
    configuration.
    
    <br/><br/><b>version</b> - SDP version. Typically set to 0
    <br/><br/><b>origin</b> - SDP origin. You can put any token say multiplier
    <br/><br/><b>session</b> - Session. You can put any token say multiplier
    <br/><br/><b>audio-codec</b> - Audio codec name. Possible values are <i>PCMA, PCMU, AMR, OPUS</i>
    <br/><br/><b>video-codec</b> - Video codec name. Possible values are <i>VP8</i>, <i>H.264</i>
    <br/><br/><b>audio-ptime</b> - Audio packetization time in millisecond
    <br/><br/><b>video-fps</b> -Video frames per second
    <br/><br/><b>audio-file</b> - Audio input file 
    <br/><br/><b>video-file</b> - Video input file 
    <br/><br/><b>audio-samplingrate</b> - Audio sampling rate 
    <br/><br/><b>video-samplingrate</b> - Video sampling rate 
    
    <br/><br/><b>session-config end</b> - Indicates end of session level 
    configuration.    
    </>
    );
}

const UGSIPB2BASyntax = () => {

  setPageTitle('SIP B2BA Syntax');
  
  let index = 0;
  let modulename = 'sipb2ba';
  
  const element = (
    <div className="userguide">
      <h1>SIP B2BA Syntax</h1>
      <br/>
        
      <table className="statstbl">
        <thead>
          <tr>
            <th style={{width: "5%"}}>
              Sl
            </th>
            
            <th style={{width: "45%"}}>
              Test block
            </th>
            
            <th style={{width: "50%"}}>
              Syntax
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              {++index} 
            </td>
            
            <td>
              {sipb2ba_createinstance_syntax()}
            </td>
            
            <td>
              {sipb2ba_createinstance_syntax_desc("B2BA")}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {sipb2ba_configinstance_syntax()}
            </td>
            
            <td>
              {sipb2ba_configinstance_syntax_desc()}
            </td>
          </tr>

          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {sipb2ba_configsdp_syntax()}
            </td>
            
            <td>
              {sipb2ba_configsdp_syntax_desc()}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {ugc_variables_syntax()}
            </td>
            
            <td>
              {ugc_variables_syntax_desc(modulename)}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {ugc_plugin_syntax()}
            </td>
            
            <td>
              {ugc_plugin_syntax_desc(modulename)}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {ugc_instructions_syntax()}
            </td>
            
            <td>
              {ugc_instructions_syntax_desc(modulename)}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {ugc_text_template_syntax()}
            </td>
            
            <td>
              {ugc_text_template_syntax_desc()}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {ugc_binary_template_syntax()}
            </td>
            
            <td>
              {ugc_binary_template_syntax_desc()}
            </td>
          </tr>
          
        </tbody>
      </table>
        
      <br/>
      
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/sipb2bavariables" }>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/sipb2bainstructions" }>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {
  UGSIPB2BASyntax,
  sipb2ba_createinstance_syntax, sipb2ba_createinstance_syntax_desc,
  sipb2ba_configinstance_syntax, sipb2ba_configinstance_syntax_desc,
  sipb2ba_configsdp_syntax, sipb2ba_configsdp_syntax_desc,
}

