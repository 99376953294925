
import React from 'react';
import axios from 'axios';

function formatBytes(bytes) {
    var marker = 1024; // Change to 1000 if required
    var decimal = 2; // Change as required
    var kiloBytes = marker; // One Kilobyte is 1024 bytes
    var megaBytes = marker * marker; // One MB is 1024 KB
    var gigaBytes = marker * marker * marker; // One GB is 1024 MB
  
    // return bytes if less than a KB
    if(bytes < kiloBytes) return bytes + " Bytes";
    // return KB if less than a MB
    else if(bytes < megaBytes) return(bytes / kiloBytes).toFixed(decimal) + " KB";
    // return MB if less than a GB
    else if(bytes < gigaBytes) return(bytes / megaBytes).toFixed(decimal) + " MB";
    // return GB if less than a TB
    else return(bytes / gigaBytes).toFixed(decimal) + " GB";
}

const getGender = function (gender) {

  if(gender === 1) {
    return("Male");
  }
  else if(gender === 2) {
    return("Female");
  }
  else if(gender === 3) {
    return("Other");
  }

  return("None");
}

function isNumeric(value) {
    return /^-?\d+$/.test(value);
}

function setPageTitle(title: string) {
    document.title = `${title} - LM Tools`;
}

//If url is http://www.google.com.au?app=home&act=article&aid=160990
//getQueryVariable("aid") shall return 160990
function getURLQueryVariable(variable) {
    var query = window.location.search.substring(1);
    //console.log(query)//"app=article&act=news_content&aid=160990"
    var vars = query.split("&");
    //console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split("=");
        //console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
        if(pair[0] === variable) {
            return pair[1];
        }
    }
    
    return(false);
}

function createSelectStyle() {
  return ({
    indicatorSeparator: ({
      display: "none",
    }),
    
    control: (styles) => ({
      ...styles,
      minHeight: "30px",
      height: "32px",
      backgroundColor: "white",
      marginBottom: 10,
      fontSize: "inherit",
      border: "1px solid #666666",
    }),
    
    option: (provided, state) => ({
      ...provided,
      fontSize: "inherit",
      minHeight: "28px",
      padding: "0 0 0 10px",
    }),
    
    input: (provided, state) => ({
      ...provided,
      gridArea: "unset",
    }),
    
    ValueContainer: (provided, state) => ({
      ...provided,
      padding: "2px 2px",
    }),

  });
}

function lmPlugins() {
  return ({
    100: 'Apat',
    200: 'ApatWebRtc',
    300: 'FlexiSip',
    400: 'FlexiSipWebRtc',
    500: 'B2BA',
    600: 'Recorder',
    700: 'JsonProxy',
    800: 'Server',
    900: 'LoadBalancer',
  });
}


function lmBlockTypes() {
  return ({
    0: 'Initialize' ,
    100: 'Variables' ,
    200: 'Function' ,
    300: 'Text Template' ,
    400: 'Binary Template' ,
    500: 'Instructions Block' ,
    900: 'Native API' ,
  });
}

function lmFrequencies() {
  return ({
    0: 'Never' ,
    100: 'Once' ,
    200: 'Always' ,
  });
}

function lmReports() {
  return ({
    0: 'No' ,
    100: 'Yes' ,
  });  
}

function lmLogLevels() {
  return ({
    0: 'Verbose' ,
    1: 'Info' ,
    2: 'Debug' ,
    3: 'Warning' ,
    4: 'Error' ,
    5: 'Fatal' ,
    6: 'None' ,
  });
}

function lmStates() {
  return ({
    0: 'Disable' ,
    1: 'Enable' ,
  });  
}

function lmICEModes() {
  return ({
    0: 'host' ,
    10: 'lite' ,
    20: 'stun' ,
    30: 'turn' ,
  });  
}

/* icemode is integer like 0, 10, 20 etc .. */
function lmICECandidateType(icemode) {
  let ctype = 'host';
  let iceModesObj = lmICEModes();
  let myicemode = iceModesObj[icemode];
  
  if(myicemode === 'turn') {
    ctype = 'relay';
  } else if(myicemode === 'stun') {
    ctype = 'srflx';
  }
  
  return ctype;
}


function lmTransports() {
  return ({
    0: 'tcp' ,
    10: 'udp' ,
    20: 'tls' ,
    30: 'sctp' ,
    40: 'ws' ,
    50: 'wss' ,
  });  
}

function lmURISchemes() {
  return ({
    0: 'sip' ,
    10: 'sips' ,
    20: 'tel' ,
  });  
}

function userRoles() {
  return ({
    1: 'admin' ,
    2: 'moderator' ,
    3: 'truested' ,
    4: 'authenticated' ,
    5: 'anonymous' ,
  });  
}

function userGenders() {
  return ({
    1: 'Male' ,
    2: 'Female' ,
    3: 'Other'
  });  
}

function userStatus() {
  return ({
    1: 'Active' ,
    2: 'Blocked' ,
  });  
}

function testResults() {
  return ({
    0: 'Pass' ,
    1: 'InProgress' ,
    2: 'PartialPass' ,
    3: 'Fail' ,
    4: 'Timeout' ,
    5: 'Invalid',
  });  
}

function lmAudioCodecs() {
  return ({
    0: 'pcmu' ,
    10: 'pcma' ,
    20: 'amr' ,
    30: 'opus' ,
  });  
}


function getLmAudioCodecId(value) {
  return getKeyByValue(lmAudioCodecs(), value);
}

function lmVideoCodecs() {
  return ({
    0: 'none' ,
    10: 'vp8' ,
    20: 'h264' ,
  });  
}


function getLmVideoCodecId(value) {
  return getKeyByValue(lmVideoCodecs(), value);
}

function lmMediaProfiles() {
  return ({
    0: 'rtp-udp' ,
    10: 'srtp-udp' ,
    20: 'srtp-dtls' ,
  });  
}


function getLmMediaProfileId(value) {
  return getKeyByValue(lmMediaProfiles(), value);
}

function lmSIPAuthScheme() {
  return ({
    0: 'none' ,
    1: '401 authentication' ,
  });  
}

function getLmSIPAuthSchemeId(value) {
  return getKeyByValue(lmSIPAuthScheme(), value);
}

function lmCryptoSuites() {
  return ({
    0: 'srtp_aes128_cm_hmac_sha1_80' ,
    10: 'srtp_aes128_cm_hmac_sha1_32' ,
  });  
}

function getLmCryptoSuiteId(value) {
  return getKeyByValue(lmCryptoSuites(), value);
}


function getLmTransportId(value) {
  return getKeyByValue(lmTransports(), value);
}

function getLMICEModeId(value) {
  return getKeyByValue(lmICEModes(), value);
}

function getLmURISchemeId(value) {
  return getKeyByValue(lmURISchemes(), value);
}

function getUserGenderId(value) {
  return getKeyByValue(userGenders(), value);
}

function getTestResultsId(value) {
  return getKeyByValue(testResults(), value);
}

function getUserStatusId(value) {
  return getKeyByValue(userStatus(), value);
}

function getUserRoleId(value) {
  return getKeyByValue(userRoles(), value);
}

function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

function getLmPluginId(value) {
  return getKeyByValue(lmPlugins(), value);
}

function getLmFrequencyId(value) {
  return getKeyByValue(lmFrequencies(), value);
}

function getLmBlockTypeId(value) {
  return getKeyByValue(lmBlockTypes(), value);
}

function getLmReportId(value) {
  return getKeyByValue(lmReports(), value);
}

function getLmLogLevelId(value) {
  return getKeyByValue(lmLogLevels(), value);
}

function getLmStateId(value) {
  return getKeyByValue(lmStates(), value);
}

function getTimeDiff(endtime, starttime) {
  let one_day = 24*60*60*1000;              // total milliseconds in one day
  let time_diff = Math.abs(endtime - starttime);  //time diff in ms  
  let days = Math.floor(time_diff / one_day);            // no of days

  let remaining_time = time_diff - (days*one_day);      // remaining ms  

  let hours = Math.floor(remaining_time/(60*60*1000));   
  remaining_time = remaining_time - (hours*60*60*1000);  

  let minutes = Math.floor(remaining_time/(60*1000));        
  remaining_time = remaining_time - (minutes * 60 * 1000);   

  let seconds = Math.ceil(remaining_time / 1000);   
  return `${days}d ${hours}h ${minutes}m ${seconds}s`;
}


function getISOFormatedLocaleDate(theTime) {
  let odate = new Date(theTime).toLocaleDateString().slice(0,10);
  let oarr = odate.split('/');
  let formatedDate = `${oarr[2]}-`;
  
  if(oarr[1].length < 2) {
    formatedDate += `0${oarr[1]}-`;
  } else {
    formatedDate += `${oarr[1]}-`;
  }

  if(oarr[0].length < 2) {
    formatedDate += `0${oarr[0]}-`;
  } else {
    formatedDate += `${oarr[0]}`;
  }

  return formatedDate; 
}

function handleErrorResponse(err, alertErr=true) {

  if(err.response) {

    if(alertErr) {
      alert(err.response.data.message);
    }
    
    if((err.response.status === 401) || (err.response.status === 407)) {
      localStorage.removeItem("user");
      window.location.href = '/login';
    }
  }
}

const checkRecordCounts = async (user) => {    

  let errors = [];
  let rcr = 'success';
    
  try {
    await axios.post(
      '/api/check/records/count', 
      {uid: user.uid, email: user.email},
      {headers: {Authorization: user.jwt,}},
    ).then( function (res) {
      
      if(res.status === 200) {
        
        let maxrecords = res.data.maxrecords;
        let tbrecords = res.data.counters.tb;
        let tsrecords = res.data.counters.ts;
        let tprecords = res.data.counters.tp;
        let screcords = res.data.counters.sc;
        
        if(maxrecords === tbrecords) {
          errors.push('testbeds');
        }
        
        if(maxrecords === tsrecords) {
          errors.push('test suites');
        }
        
        if(maxrecords === tprecords) {
          errors.push('templates');
        }

        if(maxrecords === screcords) {
          errors.push('scenarios');
        }
        
        if(errors.length > 0) {
          rcr = 'Max limit reached for ';
          
          errors.forEach(element => { 
            rcr += (element + ', ');
          });
          
          rcr = rcr.substring(0, rcr.length-2) + '.';
          
        } else {
          //rcr default value is success
        }
      }
    });
  }catch(err) {
    console.log(err);
    rcr = 'Got exception';
  }
  
  return rcr;
}

export {
  lmPlugins, lmBlockTypes, lmFrequencies,  
  lmReports, lmLogLevels, lmStates,
  
  isNumeric, getGender, setPageTitle, getURLQueryVariable,
  createSelectStyle, getTimeDiff, getKeyByValue,
  
  getLmPluginId, getLmFrequencyId, getLmBlockTypeId, getLmReportId, 
  getLmLogLevelId, getLmStateId, lmTransports, getLmTransportId,
  lmURISchemes, getLmURISchemeId, lmAudioCodecs, getLmAudioCodecId,
  lmVideoCodecs, getLmVideoCodecId, lmMediaProfiles, getLmMediaProfileId, 
  lmCryptoSuites, getLmCryptoSuiteId, lmSIPAuthScheme, getLmSIPAuthSchemeId,
  lmICEModes, getLMICEModeId, lmICECandidateType, 

  userRoles, getUserRoleId, userGenders, getUserGenderId,
  userStatus, getUserStatusId, testResults, getTestResultsId,
  getISOFormatedLocaleDate, handleErrorResponse,
  
  checkRecordCounts, formatBytes,
};
