import Navbar from './Navbar';
import Signup from './Signup';

import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header>
      <div className="nav-area">
        <Link to="/" className="logo">
        <img src= {process.env.PUBLIC_URL + "/logo.png"} height="50px" alt="Logo"/>
        </Link>
        <Navbar />
        <Signup />
      </div>
    </header>
  );
};

export default Header;
