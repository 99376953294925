
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGAPATServerVariables = () => {

  setPageTitle('APAT Server Variables');
  
  const element = (
    <div className="userguide">
      <h1>APAT Server Variables</h1>
      <br/>
      
      Multiplier plugins are dependent on variables to large extent. There are 
      different kind of variables like normal variable, system variable, plugin 
      variable and relative variables. Here normal variables and plugin variables are discussed. 
      
      <br/><br/>
      <h3>Server APAT Normal Variables</h3>
      The normal variables starts with <i>[</i>  and ends with <i>]</i>. 
      For example [count] is a normal variable, variable name is count. 
      Each variable has name, type and value. The string type variables has 
      additionally length as well. You can use normal variables to store data, 
      extract data and fetch data.

      <br/><br/>
      <h3>Server Plugin Variables</h3>
      Server plugin provides internal variables to user known as <i><b>plugin variables</b></i> 
      to enable to fetch plugin internal values. The plugin variables are written in a 
      special way, start with <i>[p-</i> and ends with <i>]</i>. For example <i>[p-random-string]</i>
      is a plugin variable that gives a random digit-only string.
      <br/><br/>
      As of now, there are no specific Server apat plugin variables. However the Server
      generic plugin variables explained in <a href="/userguide/servervariables">Server variables page</a>, 
      can be used for apat Server, since those are generic plugin varianles across Server modules.
      
      <br/>
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/serverinstructions" }>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/apatserversyntax" }>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGAPATServerVariables}

