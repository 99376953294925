
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGThreadsEndpointsRPS = () => {

  setPageTitle('Threads, Endpoints & RPS');
  
  const element = (
    <div className="userguide">
      <h1>Threads Endpoints & RPS</h1>
      <br/>
      For high load case, user must understand the parameters threads, endpoints, rps (rates per second).
      A right configuration improves test execution speed to great extent.
      
      <ul>
        <li>
          <b>Threads</b> - Number of threads represents number of parallel execution context. If a typical system is 
          Quad core one, with two Hardware sockets, then the system is actually having practically eight parallel execution context.
          In that use user should configure number of threads to 8 or 16 or 32.
          
          Typicaly from our experience we have seen in this particular case configuring threads to 
          16 or 32 results in higher execution speed thereby using system resources effectively.
          
        </li>

        <li>
          <b>Endpoints</b> - When you configure endpoints in a testbed, thats the total endpoints for test.
          If number of threads is 16 and you have configured endpoints to 16000, then per thread endpoints assigned internally is 1000.
          However if number of endpoints not divisible by number of threads, in that case residual endpoints are additionally 
          assigned to last thread. For example if number of threads is 16, and you have configured 16015 endpoints, then 
          threads will have 1000 endpoints each, and last thread will have 1015 endpoints.
        </li>

        <li>
          <b>RPS</b> - RPS stands for rates per second. In a test suite, there are several test blocks like create-instance, variables, session-init etc.
          Each block is configured with rps. The rps configured is for all threads not per thread. Concept is very
          similar with endpoints case. If you have 16 threads and rps is 32, then each thread
          rps will be 2. If you have 16 threads and rps is 34, then threads will have rps 2 each and last 
          thread rps will be 4.
        </li>

      </ul>
      
      <br/>
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/scenario"}>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/variables"}>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGThreadsEndpointsRPS}

