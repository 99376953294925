
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGAPATVariables = () => {

  setPageTitle('APAT Variables');
  
  const element = (
    <div className="userguide">
      <p>
        <h1>APAT Variables</h1>
        <br/>
        Multiplier plugins are dependent on variables to large extent. There are 
        different kind of variables like normal variable, system variable, plugin 
        variable and relative variables. Here normal variables and plugin variables 
        are discussed.  

        <br/><br/>
        <h3>APAT Normal Variables</h3>
        The normal variables starts with [ and ends with ]. For example [count] is 
        a normal variable, variable name is count. Each variable has name, type and 
        value. The string type variables has additionally length as well. You can 
        use normal variables to store data, extract data and fetch data.        

        <br/><br/>
        <h3>APAT Length Variables</h3>
        The length variables starts with [l- and ends with ]. For example 
        [l-string1] is a length variable, that returns length of variable string1, 
        string1 being a variable containing a string.

        <br/><br/>
        <h3>APAT Plugin Variables</h3>
        APAT plugin provides internal variables to user known as plugin variables to enable to fetch plugin internal values. The plugin variables are written in a special way, start with [p- and ends with ]. For example [p-random-string] is a plugin variable that gives a random digit-only string.
        <br/><br/>

        Before explaining each and every plugin variable, lets discuss some basic attributes. In create-instance test case you have set id. That's nothing but base index. It can be 0, 1000 or any positive integer value. Each endpoint in system identified with an index like 0, 1, 2 etc. The absolute index or mdn is sum of base index and endpoint index. Assuming if base index is 1000, endpoint index is 5, the absolute index of endpoint is 1005.
        <br/><br/>

        Sometimes you will come across session specific plugin variables like [p-var-name:session-id]. In such cases variable's value for session identified by session-id is returned.
        <br/><br/>
        
        <ol>
        <li><div className="code">[p-user]</div></li>
        You have set user-prefix and user-suffix in create-instance. The plugin variable [p-user] returns concatenation of user prefix, absolute mdn and user suffix. For example, if user you have set multiplier_, id you have set 1000, endpoint index is 5 and suffix is say _endpoint then [p-user] returns multiplier_1005_endpoint.

        <br/><br/><li><div className="code">[p-password]</div></li>
        You have set password-prefix and password-suffix in create-instance. The plugin variable [p-password] returns concatenation of password prefix, absolute mdn and password suffix. For example, if password you have set multiplier_, id you have set 1000, endpoint index is 5 and suffix is say _endpoint then [p-password] returns multiplier_1005_endpoint.

        <br/><br/><li><div className="code">[p-server]</div></li>
        This returns server you have configured in create-instance.

        <br/><br/><li><div className="code">[p-server-port]</div></li>
        This returns server port you have configured in create-instance.

        <br/><br/><li><div className="code">[p-transport]</div></li>
        This returns transport you have configured in create-instance in upper case.

        <br/><br/><li><div className="code">[p-local-client-ipaddress:conn-name]</div></li>
        This returns local ip address of transport connection identified by conn-name.

        <br/><br/><li><div className="code">[p-local-client-port]</div></li>
        The plugin variable [p-local-client-port] returns endpoints client transport connection source port.

        <br/><br/><li><div className="code">[p-client-index]</div></li>
        This returns absolute index of endpoint. If base index is 100, endpoint index is 5, it returns 105.

        <br/><br/><li><div className="code">[p-calling-party-index]</div></li>
        This returns calling party index.

        <br/><br/><li><div className="code">[p-called-party-index]</div></li>
        This returns called party index.

        <br/><br/><li><div className="code">[p-current-time]</div></li>
        This returns system current time. By default it returns in Y:m:d:H:M:S format. If you want in a different format, you can specify format like [p-current-time:format]. Internally strftime is used to generate time in given format. So for all possible format see here.

        <br/><br/><li><div className="code">[p-time:offset:format]</div></li>
        Sometime you need current time with given offset in a presentable format. Like current time + 10 minutes or current time - 10 minutes with a presentable format. In such case, please make use of this variable. Note that offset is in millisecond unit.
        Examples
        <ul>
          <li><div className="code">[p-time]</div></li> - Current time in %Y:%m:%d:%H:%M:%S format (default format)
          <br/><br/><li><div className="code">[p-time:0:%H:%M]</div></li> - Current time, with no additional time in %H:%M format
          <br/><br/><li><div className="code">[p-time:600000:%H:%M]</div></li> - Current time, with more 10 minutes in %H:%M format
          <br/><br/><li><div className="code">[p-time:-600000:%H:%M]</div></li> - Current time, with less 10 minutes in %H:%M format
        </ul>
        Please note that you can use any permissible format given here.
        <br/><li><div className="code">[p-millisec:offset]</div></li>
        This returns current time in millisecond with given offset. Please note that offset is in milliseconds unit.
        Examples
        <ul>
          <li><div className="code">[p-millisec]</div></li> - Current time in millseconds
          <br/><br/><li><div className="code">[p-millisec:600000]</div></li> - Current time in milliseconds with more 10 minutes time
          <br/><br/><li><div className="code">[p-millisec:-600000]</div></li> - Current time in milliseconds with less 10 minutes time
        </ul>
        <br/><li><div className="code">[p-microsec:offset]</div></li>
        This returns current time in microseconds with given offset. Please note that offset is in microseconds unit.
        Examples
        <ul>
          <li><div className="code">[p-microsec]</div></li> - Current time in microseconds
          <br/><br/><li><div className="code">[p-microsec:600000000]</div></li> - Current time in microseconds with more 10 minutes time
          <br/><br/><li><div className="code">[p-microsec:-600000000]</div></li> - Current time in microseconds with less 10 minutes time
        </ul>

        <br/><li><div className="code">[p-ws-req-uri]</div></li>
        This returns request uri (ws-req-uri) you have configured in create-instance test case.

        <br/><br/><li><div className="code">[p-audio-codec]</div></li>
        This returns audio codec you have configured in config-sdp test case under session-config block.

        <br/><br/><li><div className="code">[p-video-codec]</div></li>
        This returns video codec you have configured in config-sdp test case under session-config block.

        <br/><br/><li><div className="code">[p-audio-media-ip:session1]</div></li>
        This returns audio transport associated local ip address for session identified by session1.

        <br/><br/><li><div className="code">[p-audio-rtp-port:session1]</div></li>
        This returns audio transport associated local rtp port for session identified by session1.

        <br/><br/><li><div className="code">[p-audio-rtcp-port:session1]</div></li>
        This returns audio transport associated local rtcp port for session identified by session1.

        <br/><br/><li><div className="code">[p-video-media-ip:session1]</div></li>
        This returns video transport associated local ip address for session identified by session1.

        <br/><br/><li><div className="code">[p-video-rtp-port:session1]</div></li>
        This returns video transport associated local rtp port for session identified by session1.

        <br/><br/><li><div className="code">[p-video-rtcp-port:session1]</div></li>
        This returns video transport associated local rtcp port for session identified by session1.

        <br/><br/><li><div className="code">[p-ice-audio-media-ip:session1]</div></li>
        This returns audio transport ICE local ip address for session identified by session1.v
        <br/><br/><li><div className="code">[p-ice-audio-rtp-port:session1]</div></li>
        This returns audio transport ICE local rtp port for session identified by session1.

        <br/><br/><li><div className="code">[p-ice-audio-rtcp-port:session1]</div></li>
        This returns audio transport ICE local rtcp port for session identified by session1.

        <br/><br/><li><div className="code">[p-ice-video-media-ip:session1]</div></li>
        This returns video transport ICE local ip address for session identified by session1.

        <br/><br/><li><div className="code">[p-ice-video-rtp-port:session1]</div></li>
        This returns video transport ICE local rtp port for session identified by session1.

        <br/><br/><li><div className="code">[p-ice-video-rtcp-port:session1]</div></li>
        This returns video transport ICE local rtcp port for session identified by session1.

        <br/><br/><li><div className="code">[p-ice-remote-audio-ip:session1]</div></li>
        This returns audio transport ICE remote ip address for session identified by session1.

        <br/><br/><li><div className="code">[p-ice-remote-audio-rtp-port:session1]</div></li>
        This returns audio transport ICE remote rtp port for session identified by session1.

        <br/><br/><li><div className="code">[p-ice-remote-audio-rtcp-port:session1]</div></li>
        This returns audio transport ICE remote rtcp port for session identified by session1.

        <br/><br/><li><div className="code">[p-ice-remote-video-ip:session1]</div></li>
        This returns video transport ICE remote ip address for session identified by session1.

        <br/><br/><li><div className="code">[p-ice-remote-video-rtp-port:session1]</div></li>
        This returns video transport ICE remote rtp port for session identified by session1.

        <br/><br/><li><div className="code">[p-ice-remote-video-rtcp-port:session1]</div></li>
        This returns video transport ICE remote rtcp port for session identified by session1.

        <br/><br/><li><div className="code">[p-audio-srtp-initial-encrypt-key:session1]</div></li>
        This returns audio session initial srtp encryption key of session identified by session1. Used when call uses SRTP only (no DTLS).

        <br/><br/><li><div className="code">[p-video-srtp-initial-encrypt-key:session1]</div></li>
        This returns video session initial srtp encryption key of session identified by session1. Used when call uses SRTP only (no DTLS).

        <br/><br/><li><div className="code">[p-video-rtp-ufrag:session1]</div></li>
        This returns ICE rtp ufrag of video session identified by session1.

        <br/><br/><li><div className="code">[p-video-rtp-icepasswd:session1]</div></li>
        This returns ICE rtp password of video session identified by session1.

        <br/><br/><li><div className="code">[p-video-rtcp-ufrag:session1]</div></li>
        This returns ICE rtcp ufrag of video session identified by session1.

        <br/><br/><li><div className="code">[p-video-rtcp-icepasswd:session1]</div></li>
        This returns ICE rtcp password of video session identified by session1.

        <br/><br/><li><div className="code">[p-video-rtp-host-candidate:session1]</div></li>
        This returns ICE rtp host candidate for video session identified by session1.

        <br/><br/><li><div className="code">[p-video-rtp-srflx-candidate:session1]</div></li>
        This returns ICE rtp server reflexive candidate for video session identified by session1.

        <br/><br/><li><div className="code">[p-video-rtp-relay-candidate:session1]</div></li>
        This returns ICE rtp relay candidate for video session identified by session1.

        <br/><br/><li><div className="code">[p-video-rtcp-host-candidate:session1]</div></li>
        This returns ICE rtcp host candidate for video session identified by session1.

        <br/><br/><li><div className="code">[p-video-rtcp-srflx-candidate:session1]</div></li>
        This returns ICE rtcp server reflexive candidate for video session identified by session1.

        <br/><br/><li><div className="code">[p-video-rtcp-relay-candidate:session1]</div></li>
        This returns ICE rtcp relay candidate for video session identified by session1.

        <br/><br/><li><div className="code">[p-local-audio-ssrc:session1]</div></li>
        This returns local ssrc value of audio session identified by session1.

        <br/><br/><li><div className="code">[p-remote-audio-ssrc:session1]</div></li>
        This returns remote ssrc value of audio session identified by session1.

        <br/><br/><li><div className="code">[p-local-video-ssrc:session1]</div></li>
        This returns local ssrc value of video session identified by session1.

        <br/><br/><li><div className="code">[p-remote-video-ssrc:session1]</div></li>
        This returns remote ssrc value of video session identified by session1.

        <br/><br/><li><div className="code">[p-local-audio-seq-num:session1]</div></li>
        This returns local sequence number of audio session identified by session1.

        <br/><br/><li><div className="code">[p-local-video-seq-num:session1]</div></li>
        This returns local sequence number of video session identified by session1.

        <br/><br/><li><div className="code">[p-remote-audio-seq-num:session1]</div></li>
        This returns remote sequence number of audio session identified by session1.

        <br/><br/><li><div className="code">[p-remote-video-seq-num:session1]</div></li>
        This returns remote sequence number of video session identified by session1.

        <br/><br/><li><div className="code">[p-local-audio-cname:session1]</div></li>
        This returns local cname value for audio session identified by session1.

        <br/><br/><li><div className="code">[p-local-video-cname:session1]</div></li>
        This returns local cname value for video session identified by session1.

        <br/><br/><li><div className="code">[p-random-string]</div></li>
        This returns 7 byte decimal string. If you want a different size string, you can mention size as well like [p-random-string:n], where n is the size of string.

        <br/><br/><li><div className="code">[p-random-hex-string]</div></li>
        This returns 7 byte hexadecimal string. If you want a different size string, you can mention size as well like [p-random-hex-string:n], where n is the size of string.

        <br/><br/><li><div className="code">[p-random-number-u16]</div></li>
        This returns 2 byte decimal number.

        <br/><br/><li><div className="code">[p-fingerprint:session1]</div></li>
        Sometime you need to use certificate fingrprint. One such typical case is, in a webrtc call in sdp you need to put fingerprint. This plugin variable returns endpoints certificate fingerprint.

        
        </ol>
        
        
        
        <br/><br/>        
        <div className="inlinecenter">
          <button onClick={() => window.location.href="/userguide/userseries" }>Back</button> 
          <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
          <button onClick={() => window.location.href="/userguide/apatsyntax" }>Next</button>
        </div>
      </p>
    </div>
  );
  
  return element;
}

export {UGAPATVariables}

