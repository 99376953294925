
import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {Line} from 'rc-progress';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import moment from 'moment';

import { ScenarioHeader } from './utils/scenarioheader'
import { LMUserConfig } from './utils/lmuserconfig'
import { LMCallConfig } from './utils/lmcallconfig'
import { LMSIPServerConfig } from './utils/lmsipserverconfig'

import 
  {
    checkRecordCounts, createSelectStyle, setPageTitle, getLmPluginId, 
    getLmFrequencyId, getLmBlockTypeId, getLmReportId, handleErrorResponse,
    lmTransports, getLmTransportId, lmURISchemes, lmSIPAuthScheme,
    lmAudioCodecs, lmVideoCodecs, lmMediaProfiles, lmCryptoSuites, lmStates, 
  } from '../../routes/lm.js';
  

const sipTmplts = require("./templates/sipservertemplates.js")
const sipServerAtrbs = require("./testsuites/sipservertestsuites.js")
  
let myTimeOut = null;
const myIPAddress = "127.0.0.1";

export const ScenarioServerSIPProxy = () => 
{
  const [status, setStatus] = useState(null);
  const [progressBar, setProgressBar] = useState({value: 0});
  const [btnState, setBtnState] = useState(false);
  const [state, setState] = useState({id: -1,});

  const [headerConfig, setHeaderConfig] = useState(null);
  const [userConfig, setUserConfig] = useState(null);
  const [callConfig, setCallConfig] = useState(null);
  const [serverConfig, setServerConfig] = useState(null);

  let transportsObj = lmTransports();
  let uriSchemesObj = lmURISchemes();
  let lmAuthsObj = lmSIPAuthScheme();

  let audioCodecsObj = lmAudioCodecs();
  let videoCodecsObj = lmVideoCodecs();
  
  let mediaprofilesObj = lmMediaProfiles();
  let cryptoSuitesObj = lmCryptoSuites();
  let lmStatesObj = lmStates();
  
  setPageTitle('SIP server scenario');
  const user = JSON.parse(localStorage.getItem('user'));
  
  const handleCreateScenario = async (e) => {
    e.preventDefault();
    
    if(headerConfig.mvalue == 0) {
      setStatus({type: 'error', message: 'Multiplier not set'});
      return;
    }

    if(headerConfig.cvalue == 0) {
      setStatus({type: 'error', message: 'Controller not set'});
      return;
    }

    let uniqid = user.uid + '-' + moment().format('YYYY-MM-DD-HH-mm-ss-SSS');
    let name = headerConfig.name.replaceAll(' ', '-').toLowerCase() + '-' + uniqid;

    //Record check result
    let rcr = await checkRecordCounts(user);
    
    if(rcr === 'success') {
      const tps = await sipServerCreateTP(name);
      const tss = await sipServerCreateTS(name);
      const tbs = await sipServerCreateTB(name, tss);
      
      //Add entries 
      await saveSIPServerScenarioData(name, tps, tss, tbs);
    } else {
      alert('Scenario creation failed. Error: ' + rcr);
    }
      
  }

  function moveToScenarioPage() {
    clearTimeout(myTimeOut);
    window.location.href = `/components/view/the/scenario?id=${state.id}`;
  }

  const saveSIPServerScenarioData = async (name, tps, tss, tbs) => {
    let scRecordId = -1;
    let atps = [tps];
    let atss = [tss];
    let atbs = [tbs];
    
    try {
      await axios.post(
        '/api/scenario/data/save', 
        {
          uid: user.uid, 
          email: user.email, 
          name: name,
          desc: headerConfig.desc,
          tparr: JSON.stringify(atps),
          tsarr: JSON.stringify(atss),
          tbarr: JSON.stringify(atbs),
        },
        {headers: {Authorization: user.jwt,}},
      ).then( function (res) {
        if(res.status === 200) {
          scRecordId = Math.round(res.data.scid);
          
          setStatus({
            type: 'success',
            message: `Scenarion ${name} is created successfully.`
          });
          
          increaseProgressValue(100);
          let newBtnState = true;
          setBtnState(newBtnState);              

          let newState = {...state};
          state.id = scRecordId;
          newState.id = scRecordId;
          setState(newState);
          myTimeOut = setTimeout(moveToScenarioPage, 1500);
          
        } else {
          console.log("Scenario save, got error " + res.status);
        }
      })
    }catch(err) {
      handleErrorResponse(err);
    }
  }

  const sipServerCreateTB = async (name, tsRecordId) => {
    let tbRecordId = -1;
    
    let reqdTbHead = {
      name: name,
      
      cid: headerConfig.cvalue, 
      controller: headerConfig.clabel,
      
      loglevel: 0,
      valgrind: 0,
      repeat: 1,
      description: headerConfig.desc,
      tbId: -1,
    };

    try {
      await axios.post(
        '/api/testbed/data/save', 
        {uid: user.uid, email: user.email, data: JSON.stringify(reqdTbHead)},
        {headers: {Authorization: user.jwt,}},
      ).then( function (res) {
        if(res.status === 200) {
          tbRecordId = Math.round(res.data.tbId);
          increaseProgressValue(70);
        } else {
          console.log("Testbed save, got error " + res.status);
        }
      })
    }catch(err) {
      handleErrorResponse(err);
    }
    
    let reqdTbBody = [{
      tsid: tsRecordId,
      testsuite: name,
      
      mid: headerConfig.mvalue,
      multiplier: headerConfig.mlabel,
      
      endpoints: 1,
      threads: 1,
      
      state: 1,
      loglevel: 0,
      valgrind: 0,
    }];
    
    if(tbRecordId > 0) {
            
      const tbJsonData = JSON.stringify(reqdTbBody);
      const tbBodySha256 = CryptoJS.SHA256(tbJsonData).toString();
      
      try {
        await axios.post(
          '/api/testbedblocks/data/save',
          {uid: user.uid, email: user.email, tbid: tbRecordId, sha256: tbBodySha256, data: tbJsonData},
          {headers: {Authorization: user.jwt,}},
        ).then ( function (res2) {
          if(res2.status === 200) {
            increaseProgressValue(84);
          } else {
            console.log("Testbed blocks save, got error " + res2.status);
          }
        });
      } catch(err2) {
        handleErrorResponse(err2);
      }
    }
    
    return tbRecordId;
  }

  const sipServerCreateTS = async (name) => {

    let tsRecordId = -1;
    
    let reqdTsHead = {
      tsName: name, 
      tsDesc: headerConfig.desc, 
      tsId: tsRecordId
    };

    try {
      await axios.post(
        '/api/testsuite/data/save', 
        {uid: user.uid, email: user.email, data: JSON.stringify(reqdTsHead)},
        {headers: {Authorization: user.jwt,}},
      ).then( function (res) {
        if(res.status === 200) {
          tsRecordId = res.data.tsId;
          reqdTsHead.tsId = tsRecordId;
          increaseProgressValue(42);
        } else {
          console.log("TS save, got error " + res.status);
        }
      })
    }catch(err) {
      handleErrorResponse(err);
    }

    if(tsRecordId > 0) {
      
      let pluginId = getLmPluginId('Server');
      let nativeBlock = getLmBlockTypeId('Native API');
      let ibBlock = getLmBlockTypeId('Instructions Block');
      let freqOnce = getLmFrequencyId('Once');
      let freqAlways = getLmFrequencyId('Always');
      
      let reportId = getLmReportId('Yes');
      let transport = transportsObj[userConfig.transport];
      let urischeme = uriSchemesObj[userConfig.urischeme];
      
      let acodec = audioCodecsObj[callConfig.acodec].toUpperCase();
      let vcodec = videoCodecsObj[callConfig.vcodec].toUpperCase();
      
      let mediaprofile = mediaprofilesObj[callConfig.mediaprofile];
      let cryptosuite = cryptoSuitesObj[callConfig.cryptosuite].toUpperCase();
      
      const reqdTsBody = [
        sipServerAtrbs.sipCreateInstanceAttribs(serverConfig, pluginId, freqOnce, reportId),
        sipServerAtrbs.sipVariablesAttribs(pluginId, freqOnce, reportId),
        sipServerAtrbs.sipConfigInstance(userConfig, callConfig, serverConfig, pluginId, nativeBlock, freqOnce, reportId),
        sipServerAtrbs.sipConfigSDPAttribs(callConfig, pluginId, nativeBlock, freqOnce, reportId),
        sipServerAtrbs.sipRegisterAttribs(pluginId, ibBlock, freqOnce, reportId),
        sipServerAtrbs.sipUnRegisterAttribs(pluginId, ibBlock, freqOnce, reportId),
        sipServerAtrbs.sipInviteAttribs(pluginId, ibBlock, freqOnce, reportId),
        sipServerAtrbs.sipInvite180Attribs(pluginId, ibBlock, freqOnce, reportId),
        sipServerAtrbs.sipInvite200Attribs(callConfig, pluginId, ibBlock, freqOnce, reportId),
        sipServerAtrbs.sipAckAttribs(callConfig, pluginId, ibBlock, freqOnce, reportId),
        sipServerAtrbs.sipByeAttribs(pluginId, ibBlock, freqOnce, reportId),
        sipServerAtrbs.sipBye200Attribs(pluginId, ibBlock, freqOnce, reportId),
        sipServerAtrbs.sipExecuteServerAttribs(name, pluginId, nativeBlock, freqOnce, reportId),
      ];
      
      const tbJsonData = JSON.stringify(reqdTsBody); //test block json data
      const tsBodySha256 = CryptoJS.SHA256(tbJsonData).toString();
      
      try {
        await axios.post(
          '/api/testblocks/data/save',
          {uid: user.uid, email: user.email, tsid: tsRecordId, sha256: tsBodySha256, data: tbJsonData},
          {headers: {Authorization: user.jwt,}},
        ).then ( function (res2) {
          if(res2.status === 200) {
            increaseProgressValue(56);
          } else {
            console.log("TS blocks save, got error " + res2.status);
          }
        });
      } catch(err2) {
        handleErrorResponse(err2);
      }
    } 

    return tsRecordId;
  }

  const sipServerCreateTP = async (name) => {

    let tpRecordId = -1;
    
    const reqdTpHead = {
      tpName: name,
      type: 0, //0 -> text, 1-> binary template
      tpDesc: headerConfig.desc,
      tpId: -1, //-1 for new template
    };
    
    try {
      await axios.post(
        '/api/template/data/save', 
        {uid: user.uid, email: user.email, data: JSON.stringify(reqdTpHead)},
        {headers: {Authorization: user.jwt,}},
      ).then( function (res) {
        tpRecordId = res.data.tpId;
        reqdTpHead.tpId = tpRecordId;
        increaseProgressValue(14);
      });
    }catch(err) {
      handleErrorResponse(err);
    }
 
    if(tpRecordId > 0) {
      const reqdTpData = [];
      let wsTransport = getLmTransportId("ws");
      let wssTransport = getLmTransportId("wss");
      
      reqdTpData.push(sipTmplts.sipRxRegister(serverConfig));
      reqdTpData.push(sipTmplts.sipTxRegister200(serverConfig));
      if(serverConfig.auth > 0) {
        reqdTpData.push(sipTmplts.sipTxRegister401(serverConfig));
      }
      
      reqdTpData.push(sipTmplts.sipRxUnRegister(serverConfig));
      reqdTpData.push(sipTmplts.sipTxUnRegister200(serverConfig));
      
      reqdTpData.push(sipTmplts.sipRxInvite(serverConfig, callConfig));
      reqdTpData.push(sipTmplts.sipTxInvite180(serverConfig));
      reqdTpData.push(sipTmplts.sipTxInvite200(serverConfig, callConfig));
      reqdTpData.push(sipTmplts.sipRxAck());
      reqdTpData.push(sipTmplts.sipRxBye());
      reqdTpData.push(sipTmplts.sipTxBye200(serverConfig));

      reqdTpData.push(sipTmplts.sipTxInvite(serverConfig, callConfig));
      reqdTpData.push(sipTmplts.sipRxInvite100());
      reqdTpData.push(sipTmplts.sipRxInvite180(serverConfig));
      reqdTpData.push(sipTmplts.sipRxInvite200(serverConfig, callConfig));
      reqdTpData.push(sipTmplts.sipTxAck(serverConfig));
      reqdTpData.push(sipTmplts.sipTxBye(serverConfig));
      reqdTpData.push(sipTmplts.sipRxBye200());
    
      const tpJsonData = JSON.stringify(reqdTpData);
      const tpBodySha256 = CryptoJS.SHA256(tpJsonData).toString();

      try {
        await axios.post(
          '/api/templateblocks/data/save',
          {uid: user.uid, email: user.email, tpid: tpRecordId, sha256: tpBodySha256, data: tpJsonData},
          {headers: {Authorization: user.jwt,}},
        ).then ( function (res) {
          if(res.status === 200) {
            
            increaseProgressValue(28);
          
          } else {
            console.log("Template blocks save, got error " + res.status);
          }
        });
      } catch(err) {
        handleErrorResponse(err);
      }
    }      
    return tpRecordId;    
  }

  function increaseProgressValue(delta) {
    let newProgressBar = {...progressBar};
    newProgressBar.value += delta;
    setProgressBar(newProgressBar);
  }

  const handleCallConfigChange = (updatedState) => {
    setCallConfig(updatedState);
  }

  const handleUserConfigChange = (updatedState) => {
    setUserConfig(updatedState);
  }

  const handleScenarioHeaderChange = (updatedState) => {
    setHeaderConfig(updatedState);
  }

  const handleServerConfigChange = (updatedState) => {
    setServerConfig(updatedState);
  }

  let configObj = {
    baseid: true, 
    uprefix: true, pprefix: true, 
    transport: true, urischeme: true,
  };

  return (
    <form name="scform" id="scform" onSubmit={handleCreateScenario}>
      <div key="scmain" className="medium-text">
        <div id="scmain2" className="center" >

          <ScenarioHeader scenarioName={'SIP proxy'} onConfigChange={(updatedState) => 
          handleScenarioHeaderChange(updatedState)} />

          <LMUserConfig configObj={configObj} onConfigChange={(updatedState) => 
          handleUserConfigChange(updatedState)} />

          <LMCallConfig onConfigChange={(updatedState) => 
          handleCallConfigChange(updatedState)} />

          <LMSIPServerConfig onConfigChange={(updatedState) => 
          handleServerConfigChange(updatedState)} />

            <div className="inlinecenter">
              {status && <div className={`alert ${status.type}`}>{status.message}</div>}
              <Line className="progressbar" style={{visibility: (progressBar.value > 0) ? "visible" : "hidden"}} percent={progressBar.value} strokeWidth="1" strokeColor="#009973" />
              <br/>
              <button disabled={btnState}>Create Scenario</button> 
            </div>
          
        </div>
      </div>
    </form>
  );
}

