import { Outlet } from 'react-router-dom';
import Header from './Header';
import ImageSlider from './ImageSlider';
import ImageSliderAuto from './ImageSliderAuto';
import { ImageData } from './json/JsonData';

function mainContentHeight() {
  return "52vh";
}

const Layout = () => {
  return (
    <div id="all-content">
      <Header />

      {<ImageSlider ImageData={ImageData}/>}
      {/*<ImageSliderAuto ImageData={ImageData} SlideInterValTime={ 8000}/>*/}

      <div id="main-content" style={{minHeight: mainContentHeight()}}>
        <div className="content">
          <Outlet />
        </div>
      </div>

      <div id="footer" align="center">
          LM Tools @ {new Date().getFullYear()}. All rights reserved.  &nbsp; <a href="/contact"><span className="footspan">Contact us</span></a> &nbsp; | &nbsp; <a href="/license"><span className="footspan">License</span></a>
      </div>
    </div>
  );
};

export default Layout;
