
import React from 'react';

function ugc_rxpkt_expected_length_syntax()
{
  return (
    <>

    <b>rxpkt_expected_length</b><br/>
    
    function rxpkt_expected_length(s, num)<br/>
    <tab1>local len=0</tab1><br/>
    <tab1>first_byte = s:byte(1);</tab1><br/>
    <tab1>second_byte = s:byte(2);</tab1><br/>
    <tab1>len = first_byte * 256 + second_byte + 2</tab1><br/>
    <tab1>return len</tab1><br/>
    end<br/>
        
    </>
  );
}

function ugc_rxpkt_expected_length_syntax_desc()
{
  return (
    <>
    The <b>rxpkt_expected_length</b> command is used to provide length callback. 
    This test case name is fixed, you can not use any arbitrary test case name 
    for this. In apat case, user need to define the length calculation function for
    all transport protocol cases except websocket and secured websocket.
    <br/><br/>
    You can see left side function example. It is just a lua function. In this 
    case length comes as unsigned short, as you can see in function, first two
    bytes are taken to calculate length. Length is increased by two bytes to accomodate
    the length two bytes (unsigned short) in message.    
    </>
  );
}

function ugc_variables_syntax()
{
  return (
    <>
    <b>variables</b>
    <br/>global msid=[p-random-hex-number:36]
    <br/>global label=[p-random-hex-number]
    <br/>global server-port=5060
    <br/>global entity-id=[p-random-number]
    <br/>global sess_id=[p-random-number]
    <br/>global str1="LM Tools"
    <br/>global mdn=[p-public-user]
    </>
  );
}

function ugc_variables_syntax_desc(modulename)
{
  let link = "/userguide/" + modulename + "variables";
  return(
    <>

    This test case is of type variable. To know more on this please visit <a href={link}>here</a>. This test case name is not fixed, you 
    can use any test case name as you like.  You can declare strings, integers. Even you can declare a variable with random values. Lets 
    see some examples.
    
    <br/>
    <ol>
    <br/><li><i>global msid=[p-random-hex-number:36]</i>
    
    <br/>In above example, msid is a string variable initialised with a hex-string of size 36 bytes. Please note that length is mentioned here as 36 bytes.
    </li>
    
    <br/><li><i>global label=[p-random-hex-number]</i>
    <br/>Here label is a string variable, initialised to a 7 byte random hex-string. If length is not mentioned by default length is 7 bytes.
    </li>
    
    <br/><li><i>global server-port=5060</i>
    <br/>Here server-port is a integer variable initialised to value 5060.
    </li>
    
    <br/><li><i>global entity-id=[p-random-number]</i>
    <br/>Here label is a string variable, initialised to a 7 byte random digit string. If length is not mentioned by default length is 7 bytes.
    </li>
    
    <br/><li><i>global sess_id=[p-random-number]</i>
    <br/>Here label is a string variable, initialised to a 7 byte random digit string. If length is not mentioned by default length is 7 bytes.
    </li>
    
    <br/><li><i>global str1="LM Tools"</i>
    <br/>Here str1 is a string variable initialised with value <i>LM Tools</i>
    </li>
    
    <br/><li><i>global mdn=[p-public-user]</i>
    <br/>Here mdn is a string variable, initialised to a plugin variable <i>public-user</i>.  Plugin variables are variables provided by multiplier plugins. In create-instance you have configured public-user, same is stored in multiplier plugin, and here that same value is provided. Sometimes even if you have not configured certain things, you will be able to extract certain system internal values as plugin variables. More of this you will see in instruction blocks down.
    </li>
    
    </ol>    
    </>
  );
}

function ugc_text_template_syntax()
{
  return (
    <>
      <b>text templates</b><br/>
      invoke set-default-text-templates<br/> access-multi-url
    </>
  );
}


function ugc_text_template_syntax_desc()
{
  return (
    <>
      The text templates are message formats used by multiplier to prepare a 
      message. Templates definition can be set through a test case, or can 
      be written directly in a file, and the file can be loaded to memory 
      using <i>set-text-templates</i> instruction. Actually 
      <i>set-text-templates</i> instruction loads all templates in a 
      directory to memory. 
      <br/><br/>
      
      Another way, probably the best way you can write text templates through 
      web interface using <i>set-default-text-templates</i>.
      <br/><br/>
      
      Before instructions are executed templates must 
      be loaded, so that templates referred in instructions are valid and 
      available. 
      <br/><br/>When you encode or format a message, if a variable / function found in template,  variable / function is replaced with corresponding value.
      <br/><br/>When you receive and match an incoming message against a template, if variable is found, corresponding value is extracted to variable and stored for future use.
      <br/><br/>For more details please 
      visit <a href="/userguide/template#texttemplates">template page</a>.
    </>
  );
}

function ugc_binary_template_syntax()
{
  return (
    <>
      <b>binary templates</b><br/>
      invoke set-default-binary-templates dns-naptr-req
    </>
  );
}


function ugc_binary_template_syntax_desc()
{
  return (
    <>
      Just like text templates, binary templates are message formats. 
      Text templates are used for text messages and binary templates 
      are used for binary messages. Binary templates can be set through 
      test case or one can write in file and load to memory 
      using <i>set-binary-templates</i> instruction or you can write 
      through web interface using <i>set-default-binary-templates</i>.
      <br/><br/>
      
      In binary templates you can specify fix bytes, 
      you can do simple arithmetic and use result, you can advance 
      offset, back offset, add padding bytes, kind of provides 
      complete features to prepare a binary message as per your 
      requirement. 
      <br/><br/>
      
      For more information you may like to 
      visit <a href="/userguide/template#binarytemplates">binary template page</a>.
    </>
  );
}


function ugc_plugin_syntax()
{
  return (
    <>
      <b>Plugin Variables</b><br/>
      [p-var-name]
    </>
  );
}


function ugc_plugin_syntax_desc(modulename)
{
  let link = "/userguide/" + modulename + "variables";
  return (
    <>
      Plugin variables are provided to access internal value of a plugin. You may like to check all 
      available <a href={link}>plugin variables</a>.
    </>
  );
}

function ugc_instructions_syntax()
{
  return (
    <>
      <b>Instructions</b><br/>
      invoke &lt;command&gt; &lt;token1&gt; &lt;token2&gt; &lt;token3&gt; ... &lt;tokenN&gt
    </>
  );
}


function ugc_instructions_syntax_desc(modulename)
{
  let link = "/userguide/" + modulename + "instructions";
  return (
    <>
      Instructions are means to execute certain operations in multiplier. 
      Multiplier offers instructions set for each and every operation 
      possible. It is strongly recommended to 
      check <a href={link}>instructions page</a>.    
    </>
  );
}

function ugc_invoke_url()
{
  return (
    <div id="invkurl">
    <h3>Invoke Url Instructions</h3>
    Invoke url instructions are used to access a web link by sending a GET or POST or any other request, receiving responses, extracting and saving required information etc. Lets see each <b>invoke url </b> instructions in detail below. 
    <ul>
    <li><b>invoke url create &lt;link&gt; &lt;method&gt; &lt;name&gt;</b>
    <br/>First you need to create a url using above instruction, then you can set various parameters, attributes as shown in below instructions. For link, you can put actual link you want to access, for method, please put the method like GET, POST etc. For name use any unique name, using that this particular url is identified throughout. Examples are shown below. 
    <div className="code">
    <b>Examples</b>
    <br/>invoke url create https://www.lmtools.com/node/1 GET myurl1
    <br/>invoke url create https://lmtools.com/lmuserprofiles_temp/manage/tempentries POST myurl2
    </div>
    </li>
    <br/>
    <li><b>invoke url &lt;name&gt; body &lt;body-template&gt;</b>
    <br/>Once you create an url, if you can set body to url using above instruction. Below example shows that for url myurl1 body1 is set. If you wan to set multiple body chunks, you can see second example. You can combine first and second example in a test case as well. Please note that body1, body2 etc are templates and those will be evaluated before a message is sent out.
    <div className="code">
    <b>Examples</b>
    <br/>invoke url myurl1 body [t-body1]
    <br/>invoke url myurl1 body [t-body2] [t-body3] [t-body4]
    </div>
    </li>
    <br/>
    <li><b>invoke url &lt;name&gt; receive &lt;receive-packet-template&gt;</b>
    <br/>This instructions helps to set a receive template on url object. Once url object is instructed to start (see last instruction of this section), the HTTP request is formatted and send to web server. Web server processes request and responds accordingly. Once multiplier receives the response, it makes use of receive template set in this instruction to match correctness of received response and also extracts certain values as mentioned in templates for future use. 
    <div className="code">
    <b>Examples</b>
    <br/>invoke url myurl1 receive [t-rx-template1]
    <br/>invoke url myurl2 receive [t-rx-template2]
    </div>
    </li>
    <br/>
    <li><b>invoke url &lt;name&gt; content-type &lt;type&gt;</b>
    <br/>You can set content type of an url object using above syntax. Please see below examples. In first example you can see text/plain content type is set for url myurl1.
    <div className="code">
    <b>Examples</b>
    <br/>invoke url myurl1 content-type text/plain
    <br/>invoke url myurl2 content-type application/xml
    </div>
    </li>
    <br/>
    <li><b>invoke url &lt;name&gt; header &lt;tag&gt;: &lt;value&gt;</b>
    <br/>You can add a new header to url object using above syntax. For tag, please put the header tag and for value put the header value. Please see below examples. In first example you can see a header with tag as newtag1 and value as newvalue1 is added to url myurl1.
    <div className="code">
    <b>Examples</b>
    <br/>invoke url myurl1 header newtag1: newvalue1
    <br/>invoke url myurl2 header newtag2: newvalue2
    </div>
    </li>
    <br/>
    <li><b>invoke url &lt;name&gt; log enable</b>
    <br/>You can enable log for url object by using above instruction.
    <div className="code">
    <b>Examples</b>
    <br/>invoke url myurl1 log enable
    </div>
    </li>
    <br/>
    <li><b>invoke url &lt;name&gt; follow enable</b>
    <br/>When 3xx response is received, again a web client / browser must send request to a new location. By default this behaviour is disabled. You can enable it by using above instruction. 
    <div className="code">
    <b>Examples</b>
    <br/>invoke url myurl1 follow enable
    </div>
    </li>
    <br/>
    <li><b>invoke url &lt;name&gt; encoding &lt;encoding1&gt;,&lt;encoding2&gt;</b>
    <br/>You can set encoding (like deflate or gzip) using above instruction.
    <div className="code">
    <b>Examples</b>
    <br/>invoke url myurl1 encoding defalte, gzip
    </div>
    </li>
    <br/>
    <li><b>invoke url &lt;name&gt; transfer-chunk enable</b>
    <br/>You can set transfer encoding using above instruction. Multiplier by default sends HTTP request with content length header. In case you do not want to send content length header, instead you want to send transfer-encoding header with value as chunked, you may like to configure it using above syntax. 
    <div className="code">
    <b>Examples</b>
    <br/>invoke url myurl1 transfer-chunk enable
    <br/>invoke url myurl1 transfer-chunk disable
    </div>
    </li>
    <br/>
    <li><b>invoke url &lt;name&gt; timeout &lt;timeout value in milliseconds&gt;</b>
    <br/>You can set total timeout in millseconds. Within this time, the transaction should complete, else transaction is terminated and considered as failed. 
    <div className="code">
    <b>Examples</b>
    <br/>invoke url myurl1 timeout 10000
    <br/>invoke url myurl1 timeout 20000
    </div>
    </li>
    <br/>
    <li><b>invoke url &lt;name&gt; connect-timeout &lt;timeout value in milliseconds&gt;</b>
    <br/>You can set connect timeout in millseconds. Within this time, the multiplier should complete the connection with web server, else transaction is terminated and considered as failed. 
    <div className="code">
    <b>Examples</b>
    <br/>invoke url myurl1 timeout 10000
    <br/>invoke url myurl1 timeout 20000
    </div>
    </li>
    <br/>
    
    <li><b>invoke url &lt;name&gt; ipversion &lt;version&gt;</b>
    <br/>You can set preference for ip version while accessing web service or link as shown in below example. 
    <div className="code">
    <b>Examples</b>
    <br/>invoke url myurl1 ipversion 6
    <br/>invoke url myurl1 ipversion 4
    </div>
    </li>
    <br/>

    <li><b>invoke url &lt;name&gt; javascript enable</b>
    <br/>When a link ending with .js is accessed, typically a javascript file is downloaded. Next what to do with the javascript file downloaed. If you want to execute the javascript downloaded, you can do it by enabling javascript property in curl handle as shown in below example. For successful javascript execution, user need to intialize javascript runtime engine. It is done by setting <i>javascript 1</i> in create-instance test case.
    <div className="code">
    <b>Examples</b>
    <br/>invoke url myurl1 javascript enable
    </div>
    </li>
    <br/>
       
    <li><b>invoke url &lt;name1&gt; &lt;name2&gt; start</b>
    <br/>Once you set all required attributes / parameters, receive templates etc, next you need to start the HTTP transaction. You can do so using above instruction. Please note that with this instruction execution only, HTTP messages are sent, in all other url related instructions, tool just configures / remembers attributes.
    <div className="code">
    <b>Examples</b>
    <br/>invoke url myurl1 start
    <br/>invoke url myurl2 start
    <br/>invoke url myurl1 myurl2 start
    <br/>invoke url myurl1 myurl2 myurl3 ... start
    </div>
    </li>
    </ul>

    <h3>Multipart Instruction</h3>
    Multipart instruction is used to set the multipart header and body in a HTTP message. 
    <ul>
    <li><b>invoke url &lt;url name&gt; multipart &lt;multipart name&gt; tag &lt;value&gt;</b>

    <div className="code">
    <b>Examples</b>
  
    <br/>invoke url mycurl1 multipart create mp1
    <br/>invoke url mycurl1 multipart mp1 name name1
    <br/>invoke url mycurl1 multipart mp1 filename filename1
    <br/>invoke url mycurl1 multipart mp1 type type1
    <br/>invoke url mycurl1 multipart mp1 encode encodelogic1
    <br/>invoke url mycurl1 multipart mp1 data [t-data1] 
    <br/>invoke url mycurl1 multipart mp1 filedata /home/user/data/filedata1.txt
    <br/>invoke url mycurl1 multipart mp1 header header1: value1
    <br/>invoke url mycurl1 multipart mp1 enable content-length
    <br/> 
    <br/>invoke url mycurl1 multipart create mp2
    <br/>invoke url mycurl1 multipart mp2 name name2
    <br/>invoke url mycurl1 multipart mp2 filename filename2
    <br/>invoke url mycurl1 multipart mp2 type type2
    <br/>invoke url mycurl1 multipart mp2 encode encodelogic2
    <br/>invoke url mycurl1 multipart mp2 data [t-data2] 
    <br/>invoke url mycurl1 multipart mp2 filedata /home/user/data/filedata2.txt
    <br/>invoke url mycurl1 multipart mp2 header header2: value2
    <br/>
    <br/>invoke url mycurl1 multipart mp1 add subpart mp2

    </div>
    </li>
    <br/>Using this instruction, user can configure multipart parameters in a 
    HTTP message. Above example covers below functionality in order.
    <ol>
    <li>Creates multipart object mp1</li>
    <li>Sets mp1 name as name1</li>
    <li>Sets mp1 filename to filename1 in multipart header part</li>
    <li>Sets mp1 type in multipart header part</li>
    <li>Sets mp1 encoding to encodinglogic1</li>
    <li>Sets mp1 data from template data1 in multipart body part</li>
    <li>Sets mp1 data from file /home/user/data/filedata1.txt</li>
    <li>Sets mp1 custom header with tag as header1 and value as value1</li>
    <li>Enable mp1 content-length</li>
    <br/>
    <li>Creates multipart object mp2</li>
    <li>Sets mp2 name as name1</li>
    <li>Sets mp2 filename to filename1 in multipart header part</li>
    <li>Sets mp2 type in multipart header part</li>
    <li>Sets mp2 encoding to encodinglogic1</li>
    <li>Sets mp2 data from template data1 in multipart body part</li>
    <li>Sets mp2 data from file /home/user/data/filedata1.txt</li>
    <li>Sets mp2 custom header with tag as header1 and value as value1</li>
    <li>Enable mp2 content-length</li>
    <br/>
    <li>Adds mp2 as subpart to mp1</li>
    </ol>
    With this it looks like url1 -> mp1 -> mp2 structure, where mp1 is multipart in url1 and mp2 is subpart in mp1.
    </ul>
    
    </div>
  );
}

function ugc_invoke_headless()
{
  return (
    <div id="invkheadless">
    <h3>Invoke Headless Instructions</h3>
    <ul>
    
    <li><b>invoke headless clearcache</b>
    <br/>Each browser instance in LM Tool have separate cache. If you want the cache 
    to be cleared, use this instruction. Typically it is used as first instruction 
    in a headless context.
    <div className="code">
    <b>Examples</b>
    <br/>invoke headless clearcache
    </div>
    </li>
    <br/>

    <li><b>invoke headless init</b>
    <br/>You can initialize the headless browser session using this instruction.
    <div className="code">
    <b>Examples</b>
    <br/>invoke headless init
    </div>
    </li>
    <br/>

    <li><b>invoke headless launch &lt;arg1&gt; &lt;arg2&gt;</b>
    <br/>You can launch headless browser with this instruction as shown in below examples.
    <div className="code">
    <b>Examples</b>
    <br/>invoke headless launch visible:false
    <br/>invoke headless launch visible:true
    <br/>invoke headless launch visible:false slowby:0
    <br/>invoke headless launch visible:true slowby:10
    </div>
    If visible is set to true, as the test continues and as this instruction is executed
    a chrome browser pops up. And it is automatically managed by LM Tool as per 
    configuration. If visible is set to false, user can not see the browser but, all
    operations are performed as per configuration as browser run in hidden mode. 
    <br/><br/>
    If slowby mode is set to 0, browser operates at normal speed. If slowby is set to 
    higher value, then browser operates at slower speed. In visible mode, typically 
    slowby is used for debugging purpose.
    </li>
    <br/><br/>

    <li><b>invoke headless goto &lt;link&gt;</b>
    <br/>You can access a link by headless browser using this instruction as shown in below examples.
    <div className="code">
    <b>Examples</b>
    <br/>invoke headless goto https://lmtools.com
    <br/>invoke headless goto https://facebook.com
    <br/>invoke headless goto https://linkedin.com
    </div>
    </li>
    <br/>

    <li><b>invoke headless waitforselector &lt;id&gt;</b>
    <br/>You can wait for a particular component to appear in headless browser using this instruction as shown in below example.
    <div className="code">
    <b>Example</b>
    <br/>invoke headless waitforselector #start
    </div>
    </li>
    <br/>

    <li><b>invoke headless click &lt;button&gt;</b>
    <br/>You can click on a button (or similar selectors) in headless browser using this instruction as shown in below example.
    <div className="code">
    <b>Example</b>
    <br/>invoke headless click button[id="start"]
    </div>
    </li>
    <br/>


    <li><b>invoke headless type &lt;selector&gt; &lt;text&gt;</b>
    <br/>You can type in a textfield in headless browser using this instruction as shown in below example.
    <div className="code">
    <b>Example</b>
    <br/>invoke headless type input[id="username"] lm[p-client-index]
    </div>
    </li>
    <br/>

    <li><b>invoke headless wait &lt;timeout value&gt;</b>
    <br/>This instruction makes headless browser to wait for certain duration specified in timeout value in millisec. 
    <div className="code">
    <b>Example</b>
    <br/>invoke headless wait 5000
    </div>
    </li>
    <br/>

    <li><b>invoke headless code &lt;code&gt;</b>
    <br/>You can execute javascript / puppeteer code using this instruction as shown in below example.
    You can make use of LM Tools variables, expressions etc in code as before execution code line is
    evaluated by LM Tool and then it is executed.
    <div className="code">
    <b>Examples</b>
    <br/>invoke headless code await page.waitForSelector('#username', {"{ visible: true }"});
    <br/>invoke headless code await page.waitForSelector('button[data-bb-handler=success]', {"{ visible: true }"});
    </div>
    You can put single line of code (anything compatible with puppeteer), in this instruction. This is useful
    when a specific functionality / feature is not supported by LM Tool, user can make use of this instruction
    to execute a line of code.
    </li>
    <br/><br/>

    <li><b>invoke headless close</b>
    <br/>This instruction closes the headless browser session. 
    <div className="code">
    <b>Example</b>
    <br/>invoke headless close
    </div>
    </li>
    <br/>

    <li><b>invoke headless start timer &lt;timeout value&gt;</b>
    <br/>All the instructions you see above under headless section, are just parsed
    and remembered by LM Tool. While LM Tool executes this instruction, as part of 
    this, all other above instructions  are executed one by one in a sequential manner.
    You can mention the timeout value if you expect the entire test case is going to take
    more than 30 seconds. If no timeout value is specified, it is considered as default value
    of 30 seconds.
    <div className="code">
    <b>Examples</b>
    <br/>invoke headless start
    <br/>invoke headless start timer 90000
    </div>
    </li>
    <br/>
    
    </ul>
    </div>
  );
}


function ugc_file_graphs_sip() {
  let sipitems = [
    "rxregister",
    "rxinvite",
    "rxoptions",
    "rxsubscribe",
    "rxnotify",
    "rxpublish",
    "rxmessage",
    "rxcancel",
    "rxack",
    "rxbye",
    "rxinfo",
    "rxprack",
    "rxupdate",
    "rxrefer",

    "txregister",
    "txinvite",
    "txoptions",
    "txsubscribe",
    "txnotify",
    "txpublish",
    "txmessage",
    "txcancel",
    "txack",
    "txbye",
    "txinfo",
    "txprack",
    "txupdate",
    "txrefer",
    
    "rxregister100",
    "rxregister200",
    "rxregister300",
    "rxregister301",
    "rxregister302",
    "rxregister400",
    "rxregister401",
    "rxregister407",
    "rxregister408",
    "rxregister423",
    "rxregister1xx",
    "rxregister2xx",
    "rxregister3xx",
    "rxregister4xx",
    "rxregister5xx",
    "rxregister6xx",

    "rxinvite100",
    "rxinvite180",
    "rxinvite181",
    "rxinvite182",
    "rxinvite183",
    "rxinvite200",
    "rxinvite300",
    "rxinvite301",
    "rxinvite302",
    "rxinvite400",
    "rxinvite401",
    "rxinvite407",
    "rxinvite408",
    "rxinvite415",
    "rxinvite420",
    "rxinvite421",
    "rxinvite480",
    "rxinvite481",
    "rxinvite486",
    "rxinvite487",
    "rxinvite488",
    "rxinvite500",
    "rxinvite503",
    "rxinvite504",
    "rxinvite580",
    "rxinvite600",
    "rxinvite603",
    "rxinvite604",
    "rxinvite606",
    "rxinvite1xx",
    "rxinvite2xx",
    "rxinvite3xx",
    "rxinvite4xx",
    "rxinvite5xx",
    "rxinvite6xx",
    
    "rxoptions200",
    "rxoptions300",
    "rxoptions301",
    "rxoptions302",
    "rxoptions400",
    "rxoptions401",
    "rxoptions407",
    "rxoptions408",
    "rxoptions500",
    "rxoptions503",
    "rxoptions504",
    "rxoptions600",
    "rxoptions603",
    "rxoptions1xx",
    "rxoptions2xx",
    "rxoptions3xx",
    "rxoptions4xx",
    "rxoptions5xx",
    "rxoptions6xx",

    "rxsubscribe200",
    "rxsubscribe300",
    "rxsubscribe301",
    "rxsubscribe302",
    "rxsubscribe400",
    "rxsubscribe401",
    "rxsubscribe407",
    "rxsubscribe408",
    "rxsubscribe500",
    "rxsubscribe503",
    "rxsubscribe504",
    "rxsubscribe600",
    "rxsubscribe603",
    "rxsubscribe1xx",
    "rxsubscribe2xx",
    "rxsubscribe3xx",
    "rxsubscribe4xx",
    "rxsubscribe5xx",
    "rxsubscribe6xx",

    "rxnotify200",
    "rxnotify300",
    "rxnotify301",
    "rxnotify302",
    "rxnotify400",
    "rxnotify401",
    "rxnotify407",
    "rxnotify408",
    "rxnotify500",
    "rxnotify503",
    "rxnotify504",
    "rxnotify600",
    "rxnotify603",
    "rxnotify1xx",
    "rxnotify2xx",
    "rxnotify3xx",
    "rxnotify4xx",
    "rxnotify5xx",
    "rxnotify6xx",

    "rxpublish200",
    "rxpublish300",
    "rxpublish301",
    "rxpublish302",
    "rxpublish400",
    "rxpublish401",
    "rxpublish407",
    "rxpublish408",
    "rxpublish500",
    "rxpublish503",
    "rxpublish504",
    "rxpublish600",
    "rxpublish603",
    "rxpublish1xx",
    "rxpublish2xx",
    "rxpublish3xx",
    "rxpublish4xx",
    "rxpublish5xx",
    "rxpublish6xx",

    "rxmessage200",
    "rxmessage300",
    "rxmessage301",
    "rxmessage302",
    "rxmessage400",
    "rxmessage401",
    "rxmessage407",
    "rxmessage408",
    "rxmessage500",
    "rxmessage503",
    "rxmessage504",
    "rxmessage600",
    "rxmessage603",
    "rxmessage1xx",
    "rxmessage2xx",
    "rxmessage3xx",
    "rxmessage4xx",
    "rxmessage5xx",
    "rxmessage6xx",
    
    "rxcancel200",
    "rxcancel300",
    "rxcancel301",
    "rxcancel302",
    "rxcancel400",
    "rxcancel401",
    "rxcancel407",
    "rxcancel408",
    "rxcancel500",
    "rxcancel503",
    "rxcancel504",
    "rxcancel600",
    "rxcancel603",
    "rxcancel1xx",
    "rxcancel2xx",
    "rxcancel3xx",
    "rxcancel4xx",
    "rxcancel5xx",
    "rxcancel6xx",
    
    "rxbye200",
    "rxbye300",
    "rxbye301",
    "rxbye302",
    "rxbye400",
    "rxbye401",
    "rxbye407",
    "rxbye408",
    "rxbye500",
    "rxbye503",
    "rxbye504",
    "rxbye600",
    "rxbye603",
    "rxbye1xx",
    "rxbye2xx",
    "rxbye3xx",
    "rxbye4xx",
    "rxbye5xx",
    "rxbye6xx",

    "rxinfo200",
    "rxinfo300",
    "rxinfo301",
    "rxinfo302",
    "rxinfo400",
    "rxinfo401",
    "rxinfo407",
    "rxinfo408",
    "rxinfo500",
    "rxinfo503",
    "rxinfo504",
    "rxinfo600",
    "rxinfo603",
    "rxinfo1xx",
    "rxinfo2xx",
    "rxinfo3xx",
    "rxinfo4xx",
    "rxinfo5xx",
    "rxinfo6xx",

    "rxprack200",
    "rxprack300",
    "rxprack301",
    "rxprack302",
    "rxprack400",
    "rxprack401",
    "rxprack407",
    "rxprack408",
    "rxprack500",
    "rxprack503",
    "rxprack504",
    "rxprack600",
    "rxprack603",
    "rxprack1xx",
    "rxprack2xx",
    "rxprack3xx",
    "rxprack4xx",
    "rxprack5xx",
    "rxprack6xx",

    "rxupdate200",
    "rxupdate300",
    "rxupdate301",
    "rxupdate302",
    "rxupdate400",
    "rxupdate401",
    "rxupdate407",
    "rxupdate408",
    "rxupdate500",
    "rxupdate503",
    "rxupdate504",
    "rxupdate600",
    "rxupdate603",
    "rxupdate1xx",
    "rxupdate2xx",
    "rxupdate3xx",
    "rxupdate4xx",
    "rxupdate5xx",
    "rxupdate6xx",

    "rxrefer200",
    "rxrefer202",
    "rxrefer300",
    "rxrefer301",
    "rxrefer302",
    "rxrefer400",
    "rxrefer401",
    "rxrefer407",
    "rxrefer408",
    "rxrefer500",
    "rxrefer503",
    "rxrefer504",
    "rxrefer600",
    "rxrefer603",
    "rxrefer1xx",
    "rxrefer2xx",
    "rxrefer3xx",
    "rxrefer4xx",
    "rxrefer5xx",
    "rxrefer6xx",

    "txregister100",
    "txregister200",
    "txregister300",
    "txregister301",
    "txregister302",
    "txregister400",
    "txregister401",
    "txregister407",
    "txregister408",
    "txregister423",
    "txregister1xx",
    "txregister2xx",
    "txregister3xx",
    "txregister4xx",
    "txregister5xx",
    "txregister6xx",

    "txinvite100",
    "txinvite180",
    "txinvite181",
    "txinvite182",
    "txinvite183",
    "txinvite200",
    "txinvite300",
    "txinvite301",
    "txinvite302",
    "txinvite400",
    "txinvite401",
    "txinvite407",
    "txinvite408",
    "txinvite415",
    "txinvite420",
    "txinvite421",
    "txinvite480",
    "txinvite481",
    "txinvite486",
    "txinvite487",
    "txinvite488",
    "txinvite500",
    "txinvite503",
    "txinvite504",
    "txinvite580",
    "txinvite600",
    "txinvite603",
    "txinvite604",
    "txinvite606",
    "txinvite1xx",
    "txinvite2xx",
    "txinvite3xx",
    "txinvite4xx",
    "txinvite5xx",
    "txinvite6xx",
    
    "txoptions200",
    "txoptions300",
    "txoptions301",
    "txoptions302",
    "txoptions400",
    "txoptions401",
    "txoptions407",
    "txoptions408",
    "txoptions500",
    "txoptions503",
    "txoptions504",
    "txoptions600",
    "txoptions603",
    "txoptions1xx",
    "txoptions2xx",
    "txoptions3xx",
    "txoptions4xx",
    "txoptions5xx",
    "txoptions6xx",

    "txsubscribe200",
    "txsubscribe300",
    "txsubscribe301",
    "txsubscribe302",
    "txsubscribe400",
    "txsubscribe401",
    "txsubscribe407",
    "txsubscribe408",
    "txsubscribe500",
    "txsubscribe503",
    "txsubscribe504",
    "txsubscribe600",
    "txsubscribe603",
    "txsubscribe1xx",
    "txsubscribe2xx",
    "txsubscribe3xx",
    "txsubscribe4xx",
    "txsubscribe5xx",
    "txsubscribe6xx",

    "txnotify200",
    "txnotify300",
    "txnotify301",
    "txnotify302",
    "txnotify400",
    "txnotify401",
    "txnotify407",
    "txnotify408",
    "txnotify500",
    "txnotify503",
    "txnotify504",
    "txnotify600",
    "txnotify603",
    "txnotify1xx",
    "txnotify2xx",
    "txnotify3xx",
    "txnotify4xx",
    "txnotify5xx",
    "txnotify6xx",

    "txpublish200",
    "txpublish300",
    "txpublish301",
    "txpublish302",
    "txpublish400",
    "txpublish401",
    "txpublish407",
    "txpublish408",
    "txpublish500",
    "txpublish503",
    "txpublish504",
    "txpublish600",
    "txpublish603",
    "txpublish1xx",
    "txpublish2xx",
    "txpublish3xx",
    "txpublish4xx",
    "txpublish5xx",
    "txpublish6xx",

    "txmessage200",
    "txmessage300",
    "txmessage301",
    "txmessage302",
    "txmessage400",
    "txmessage401",
    "txmessage407",
    "txmessage408",
    "txmessage500",
    "txmessage503",
    "txmessage504",
    "txmessage600",
    "txmessage603",
    "txmessage1xx",
    "txmessage2xx",
    "txmessage3xx",
    "txmessage4xx",
    "txmessage5xx",
    "txmessage6xx",
    
    "txcancel200",
    "txcancel300",
    "txcancel301",
    "txcancel302",
    "txcancel400",
    "txcancel401",
    "txcancel407",
    "txcancel408",
    "txcancel500",
    "txcancel503",
    "txcancel504",
    "txcancel600",
    "txcancel603",
    "txcancel1xx",
    "txcancel2xx",
    "txcancel3xx",
    "txcancel4xx",
    "txcancel5xx",
    "txcancel6xx",
    
    "txack200",
    "txack300",
    "txack301",
    "txack302",
    "txack400",
    "txack401",
    "txack407",
    "txack408",
    "txack500",
    "txack503",
    "txack504",
    "txack600",
    "txack603",
    "txack1xx",
    "txack2xx",
    "txack3xx",
    "txack4xx",
    "txack5xx",
    "txack6xx",
    
    "txbye200",
    "txbye300",
    "txbye301",
    "txbye302",
    "txbye400",
    "txbye401",
    "txbye407",
    "txbye408",
    "txbye500",
    "txbye503",
    "txbye504",
    "txbye600",
    "txbye603",
    "txbye1xx",
    "txbye2xx",
    "txbye3xx",
    "txbye4xx",
    "txbye5xx",
    "txbye6xx",

    "txinfo200",
    "txinfo300",
    "txinfo301",
    "txinfo302",
    "txinfo400",
    "txinfo401",
    "txinfo407",
    "txinfo408",
    "txinfo500",
    "txinfo503",
    "txinfo504",
    "txinfo600",
    "txinfo603",
    "txinfo1xx",
    "txinfo2xx",
    "txinfo3xx",
    "txinfo4xx",
    "txinfo5xx",
    "txinfo6xx",

    "txprack200",
    "txprack300",
    "txprack301",
    "txprack302",
    "txprack400",
    "txprack401",
    "txprack407",
    "txprack408",
    "txprack500",
    "txprack503",
    "txprack504",
    "txprack600",
    "txprack603",
    "txprack1xx",
    "txprack2xx",
    "txprack3xx",
    "txprack4xx",
    "txprack5xx",
    "txprack6xx",

    "txupdate200",
    "txupdate300",
    "txupdate301",
    "txupdate302",
    "txupdate400",
    "txupdate401",
    "txupdate407",
    "txupdate408",
    "txupdate500",
    "txupdate503",
    "txupdate504",
    "txupdate600",
    "txupdate603",
    "txupdate1xx",
    "txupdate2xx",
    "txupdate3xx",
    "txupdate4xx",
    "txupdate5xx",
    "txupdate6xx",

    "txrefer200",
    "txrefer202",
    "txrefer300",
    "txrefer301",
    "txrefer302",
    "txrefer400",
    "txrefer401",
    "txrefer407",
    "txrefer408",
    "txrefer500",
    "txrefer503",
    "txrefer504",
    "txrefer600",
    "txrefer603",
    "txrefer1xx",
    "txrefer2xx",
    "txrefer3xx",
    "txrefer4xx",
    "txrefer5xx",
    "txrefer6xx",
    "txmsrp",
    "rxmsrp",  
  ];
  
  return (
      <div className="small-text">
         <div className="statstbl">
          <h3><p align="center">Graphs - Supported SIP Items </p></h3>
          <table key='fgst' style={{ tableLayout: 'fixed', width: '100%' }}>
            
            <tbody key='fgsb'>
              
              <tr key='fgsh'>
                <th style={{width: "20%"}}>
                  Serial
                </th>
                
                <th style={{width: "80%"}}>
                  Y-Axis SIP Items
                </th>
              </tr>
              
              {
                sipitems.map(( (val,tindex) => 
                  <tr key={'fgsr' + tindex}>
                    
                    <td>
                      {tindex+1}
                    </td>
                    
                    <td>
                      {val}
                    </td>
                    
                  </tr>
                ))
              }
              
            </tbody>
            
          </table>
        </div>
      </div>
  );
}

function ugc_file_graphs_media() {
  let sipitems = [
    "rtptxpackets",
    "rtprxpackets",
    "jitter",
    "packetlost",
    "fractionlost",
    "delay"  
  ];

  return (
      <div className="small-text">
         <div className="statstbl">
          <h3><p align="center">Graphs - Supported Media Items </p></h3>
          <table key='fgst' style={{ tableLayout: 'fixed', width: '100%' }}>
            
            <tbody key='fgmb'>
              
              <tr key='fgmh'>
                <th style={{width: "20%"}}>
                  Serial
                </th>
                
                <th style={{width: "80%"}}>
                  Y-Axis Media Items
                </th>
              </tr>
              
              {
                sipitems.map(( (val,tindex) => 
                  <tr key={'fgmr' + tindex}>
                    
                    <td>
                      {tindex+1}
                    </td>
                    
                    <td>
                      {val}
                    </td>
                    
                  </tr>
                ))
              }
              
            </tbody>
            
          </table>
        </div>
      </div>
  );

}


export 
  {
    ugc_invoke_url, ugc_invoke_headless, 
    ugc_file_graphs_sip, ugc_file_graphs_media,
    ugc_variables_syntax, ugc_variables_syntax_desc,
    ugc_plugin_syntax, ugc_plugin_syntax_desc, 
    ugc_rxpkt_expected_length_syntax, ugc_rxpkt_expected_length_syntax_desc,    
    ugc_instructions_syntax, ugc_instructions_syntax_desc,
    ugc_text_template_syntax, ugc_text_template_syntax_desc,
    ugc_binary_template_syntax, ugc_binary_template_syntax_desc,
  }
  