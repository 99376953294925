import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';

import {setPageTitle, getUserRoleId, getUserStatusId, getGender, getURLQueryVariable, userGenders, userRoles, userStatus, getISOFormatedLocaleDate, handleErrorResponse} from '../../routes/lm.js';
import axios from 'axios';

const ViewTheUser = () => {

  const [status, setStatus] = useState(null);
  const [theUser, setTheUser] = useState(
    {
      uid: getURLQueryVariable("id"),
      
      firstname: '',
      lastname: '',
      dob: '',
      
      email: '',
      mobile: '',
      skype: '',

      created: '',
      country: '',
      gender: 1,
      
      blocked: false,
      rid: 0,
      role: '',

      //These fields below are not returned from sql query at backend. Need explicitily 
      //to set the fields else get warning from chrome
      password: '',
      confpassword: '',
      dobchanged: false,
      createdchanged: false,
    },
  );
  
  setPageTitle('User');
  const user = JSON.parse(localStorage.getItem('user'));
  
  //Hook to get data from backend server
  useEffect ( () => {
     fetchTheUser();
  }, []);    


    const fetchTheUser = async () => {
    setStatus(null);
    if(user != null) {
      try {
        const res = await axios.post('/api/admin/view/the/user', 
        {uid: user.uid, email: user.email, theuid: theUser.uid} ,
        {headers: {
          'Authorization': user.jwt,
        }}).then( 
        function (res) {
          
          if(res.status === 200) {
            let newUser = {...res.data.theuser};

            newUser.dob = getISOFormatedLocaleDate(newUser.dob);
            newUser.created = getISOFormatedLocaleDate(newUser.created);
            
            newUser.password = '';
            newUser.confpassword = '';
            newUser.dobchanged = false;
            newUser.createdchanged = false;
        
            setTheUser(newUser);
            
          }else {
            setStatus({
              type: 'error',
              message: 'Error in displaying users',
            });  
          }
        });
      }catch(err) {
        setStatus({
          type: 'error',
          message: err.message,
        });  
        handleErrorResponse(err);
      }
    } else {
      setStatus({
        type: 'error',
        message: "User not logged in",
      });  
    }
  }

  const inputTextChange=(e)=> {
    const {name,value}=e.target;
    const newUser = {...theUser};
    newUser[name] = value;
    setTheUser(newUser);
  }  

  const handleGenderChange=(e)=> {
    const key = Object.keys(theGenders)[e.target.selectedIndex];
    const newUser = {...theUser};
    newUser.gender = key;
    setTheUser(newUser);
  }

  const handleRoleChange=(e)=> {
    const key = Object.keys(theRoles)[e.target.selectedIndex];
    const newUser = {...theUser};
    newUser.rid = key;
    setTheUser(newUser);
  }

  const handleStatusChange=(e)=> {
    const key = Object.keys(theStatus)[e.target.selectedIndex];
    const newUser = {...theUser};
    
    let status = getUserStatusId('Blocked');
        
    if(key === status) {
      newUser.blocked = true;
    } else {
      newUser.blocked = false;
    }
    setTheUser(newUser);
  }

  function setConfirmPassword(value) {
    const newUser = {...theUser};
    newUser.confpassword = value;
    setTheUser(newUser);
  }

  function setPassword(value) {
    const newUser = {...theUser};
    newUser.password = value;
    setTheUser(newUser);
  }

  function handleDoBChange(value) {
    const newUser = {...theUser};
    newUser.dob = value;
    newUser.dobchanged = true;
    setTheUser(newUser);
  }

  function handleCreatedChange(value) {
    const newUser = {...theUser};
    newUser.created = value;
    newUser.createdchanged = true;
    setTheUser(newUser);
  }
  
  const handleUpdateUser= async (e)=> {
    e.preventDefault();
    setStatus(null);
    
    if(theUser.password !== theUser.confpassword)
    {
        alert('Password and confirm password does not match');
        return;
    }
    
    if(user != null) {
      if (window.confirm("You are about to update user, press OK to confirm")) {
        try {
          
          let data = [];
          let fieldValue = theUser.firstname.trim();
          if(fieldValue.length > 0) {
            data.push({firstname: fieldValue});
          }

          fieldValue = theUser.lastname.trim();
          if(fieldValue.length > 0) {
            data.push({lastname: fieldValue});
          }

          fieldValue = theUser.email.trim();
          if(fieldValue.length > 0) {
            data.push({email: fieldValue});
          }

          fieldValue = theUser.mobile.trim();
          if(fieldValue.length > 0) {
            data.push({mobile: fieldValue});
          }

          fieldValue = theUser.skype.trim();
          if(fieldValue.length > 0) {
            data.push({skype: fieldValue});
          }

          fieldValue = theUser.password.trim();
          if(fieldValue.length > 0) {
            data.push({password: fieldValue});
          }
          
          if(theUser.dobchanged) {
            fieldValue = theUser.dob.trim();
            if(fieldValue.length > 0) {
              data.push({dob: fieldValue});
            }
          }

          if(theUser.createdchanged) {
            fieldValue = theUser.created.trim();
            if(fieldValue.length > 0) {
              data.push({created: fieldValue});
            }
          }
          
          data.push({gender: theUser.gender});
          data.push({rid: theUser.rid});
          data.push({blocked: theUser.blocked});
          
          const res = await axios.post('/api/admin/update/user', 
          {uid: user.uid, email: user.email, theuid: theUser.uid, udata: data} ,
          {headers: {
            'Authorization': user.jwt,
          }}).then( 
          function (res) {
            if(res.status === 200) {

              setStatus({
                type: 'success',
                message: 'User updated successfully',
              });  

            }else {
              setStatus({
                type: 'error',
                message: 'Error in displaying data',
              });  
            }
          });
        }catch(err) {
          setStatus({
            type: 'error',
            message: err.message,
          });  
          handleErrorResponse(err);
        }            
      }      
    } else {
      setStatus({
        type: 'error',
        message: "User not logged in",
      });  
    }
  }
  
  let rid = getUserRoleId('moderator');
  
  let theGenders = userGenders();
  let theRoles = userRoles();
  let theStatus = userStatus();
  
  if(user) {
    if(user.rid <= rid) {
      return(
        <form name="ueform" id="ueform" onSubmit={handleUpdateUser}>
          <div className="medium-text">
            <div className="center">
                <h2>Edit User</h2>
                <table id="tbl">
                    <tbody>
                        <tr>
                          <th>Property</th>
                          <th>Value</th>
                          <th>Property</th>
                          <th>Value</th>
                        </tr>                        
                        
                        <tr>
                          <td>
                            First name
                          </td>
                          <td>
                            <input type="text" 
                            maxLength={16}
                            name="firstname"
                            value={theUser.firstname}
                            onChange={(e)=>inputTextChange(e)} />
                          </td>

                          <td>
                            Last name
                          </td>
                          <td>
                            <input type="text" 
                            maxLength={16}
                            name="lastname"
                            value={theUser.lastname}
                            onChange={(e)=>inputTextChange(e)} />
                          </td>
                        </tr>
                        

                        <tr>
                          <td>
                            Email Id
                          </td>
                          <td>
                            <input type="text" 
                            maxLength={48}
                            name="email"
                            value={theUser.email}
                            onChange={(e)=>inputTextChange(e)} />
                          </td>

                          <td>
                            Mobile
                          </td>
                          <td>
                            <input type="text" 
                            maxLength={20}
                            name="mobile"
                            value={theUser.mobile}
                            onChange={(e)=>inputTextChange(e)} />
                          </td>
                        </tr>

                        <tr>
                          <td>
                            Skype
                          </td>
                          <td>
                            <input type="text" 
                            maxLength={48}
                            name="skype"
                            value={theUser.skype}
                            onChange={(e)=>inputTextChange(e)} />
                          </td>
    
                            <td>
                            Status
                          </td>
                          <td>
                            <select value={theStatus[theUser.blocked ? 2 : 1]} 
                              style={{width: "100%"}}
                              onChange={handleStatusChange}>
                              {Object.keys(theStatus).map(item => 
                                <option key={`status${item}`}>
                                  {theStatus[item]}
                                </option>
                              )}
                            </select>                        
                          </td>

                        </tr>
    
                          
                        <tr>
                          <td>
                            Gender
                          </td>
                          <td>
                            <select value={theGenders[theUser.gender]} 
                              style={{width: "100%"}}
                              onChange={handleGenderChange}>
                              {Object.keys(theGenders).map(item => 
                                <option key={`gender${item}`}>
                                  {theGenders[item]}
                                </option>
                              )}
                            </select>                        
                          </td>

                          <td>
                            Role
                          </td>
                          <td>
                            <select value={theRoles[theUser.rid]} 
                              style={{width: "100%"}}
                              onChange={handleRoleChange}>
                              {Object.keys(theRoles).map(item => 
                                <option key={`role${item}`}>
                                  {theRoles[item]}
                                </option>
                              )}
                            </select>                        
                          </td>
                        </tr>
                        
                        <tr>
                          <td>
                            New password
                          </td>

                          <td>
                            <input 
                              type="password" 
                              maxLength={15}  
                              id="password" 
                              name="password"
                              autoComplete="on"
                              value={theUser.password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </td>

                          <td>
                            Conf. password
                          </td>
                          <td>
                            <input 
                              type="password" 
                              maxLength={15}  
                              id="cnfpassword" 
                              name="cnfpassword" 
                              autoComplete="on"
                              value={theUser.confpassword}
                              onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                          </td>
                          
                        </tr>

                        <tr>
                          <td>
                            Date of birth
                          </td>
                          <td>
                          {
                            (theUser.dob.length > 0) && (<input 
                              type="date" 
                              id="dob" 
                              name="dob" 
                              value={theUser.dob}
                              onChange={(e) => handleDoBChange(e.target.value)}
                              />
                            )
                          }
                          </td>

                          <td>
                            Created on
                          </td>
                          
                          <td>
                          {
                            (theUser.created.length > 0) && (<input 
                              type="date" 
                              id="created" 
                              name="created" 
                              value={theUser.created}
                              onChange={(e) => handleCreatedChange(e.target.value)}
                              />
                            )
                          }
                          </td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>
            
            <br/>
            {status && <div className={`alert ${status.type}`}>{status.message}</div>}
            
            <div className="inlinecenter">
              <button>Update</button>
            </div>
          </div>
        </form>
      );
      
    } else {
      return(
        <div key="umain" className="medium-text">
          <div id="umain2" className="center" >
            You do not have sufficient permission to access this page.
          </div>
        </div>
      );
    }
    
  } else {
    return(
      <div key="umain" className="medium-text">
        <div id="umain2" className="center" >
          You are not allowed to access this page.
        </div>
      </div>
    );
  }
}

const ViewAllUsers = () => {
  const [status, setStatus] = useState(null);
  const [selectAllBtnText, setSelectAllBtnText] = useState("Select All");
  
  const regUser = [{
    uid: 0,
    
    firstname: '',
    lastname: '',
    dob: '',
    
    email: '',
    mobile: '',
    skype: '',

    created: '',
    country: '',
    gender: 1,
    
    blocked: false,
    role: '',
    
    checked: false,
  }];
  
  const [regUsers, setRegUsers] = useState(regUser);
  
  setPageTitle('Users');
  const user = JSON.parse(localStorage.getItem('user'));

    const fetchUsers = async () => {
    setStatus(null);
    if(user != null) {
      try {
        const res = await axios.post('/api/admin/view/all/users', 
        {uid: user.uid, email: user.email} ,
        {headers: {
          'Authorization': user.jwt,
        }}).then( 
        function (res) {
          
          if(res.status === 200) {
            //This also works, keeping it as reference
            //const newState = res.data.map(obj => ({...obj, checked: false}));
            
            res.data.users.forEach(element => (element.checked = false));
            setRegUsers(res.data.users);

          }else {
            setStatus({
              type: 'error',
              message: 'Error in displaying users',
            });  
          }
        });
      }catch(err) {
        setStatus({
          type: 'error',
          message: err.message,
        });  
        handleErrorResponse(err);
      }
    } else {
      setStatus({
        type: 'error',
        message: "User not logged in",
      });  
    }
  }

  function userRowCBClicked(tindex) {
    const newRegUsers = [...regUsers];
    newRegUsers[tindex].checked = !(newRegUsers[tindex].checked);
    setRegUsers(newRegUsers);
  }

  function makeUserRowChecked(obj) {
    obj.checked = true;
    return obj;
  }

  function makeUserRowUnchecked(obj) {
    obj.checked = false;
    return obj;
  }
    
  const handleEditUserSelectAll=(e)=> {
    e.preventDefault();
    
    if(regUsers.length > 0) {
      if(e.target.innerText === "Select All") {
        setSelectAllBtnText("Deselect All");
        const newRegUsers = regUsers.map(makeUserRowChecked);
        setRegUsers(newRegUsers);
      } else {
        setSelectAllBtnText("Select All");
        const newRegUsers = regUsers.map(makeUserRowUnchecked);
        setRegUsers(newRegUsers);
      }
    } 
  }
  
  
  const handleEditUserDelete= async (e)=> {
    e.preventDefault();

    setStatus(null);
    if(user != null) {
      
      let delIds = [];
      let newRegUsers = [];
    
      if(regUsers.length > 0) {
        
        regUsers.forEach(function (element) {
          if(element.checked) {
            delIds.push(element.uid);
          } else {
            newRegUsers.push(element);
          }
        });
        
        if(delIds.length > 0) {
          if (window.confirm("Press OK to delete " + delIds.length  + " selected user(s)")) {
            try {
              const res = await axios.post('/api/admin/delete/users', 
              {uid: user.uid, email: user.email, data: JSON.stringify(delIds) } ,
              {headers: {
                'Authorization': user.jwt,
              }}).then( 
              function (res) {
                if(res.status === 200) {
                  setRegUsers(newRegUsers);
                }else {
                  setStatus({
                    type: 'error',
                    message: 'Error in displaying data',
                  });  
                }
              });
            }catch(err) {
              setStatus({
                type: 'error',
                message: err.message,
              });  
              handleErrorResponse(err);
            }            
          }
        }
      }      
    } else {
      setStatus({
        type: 'error',
        message: "User not logged in",
      });  
    }
  }
  
  //Hook to get data from backend server
  useEffect ( () => {
     fetchUsers();
  }, []);   

  let mrid =  getUserRoleId('moderator');
  if(user && (user.rid) <= mrid) {
    return (
      <div key="umain" className="small-text">
        <div key="umain2" className="center" >
            <br/><h3>Users</h3>

          <div className="statstbl">
            <table key="utable">
              <tbody key="utblbd">

                <tr key="utblrow1">
                  <th style={{width: "5%"}}>
                    Serial
                  </th>
                  
                  <th style={{width: "40%"}}>
                    Name<br/>
                    DoB<br/>
                    Role / Status
                  </th>
                  
                  <th style={{width: "35%"}}>
                    Email<br/>
                    Phone<br/>
                    Skype
                  </th>

                  <th style={{width: "15%"}}>
                    Country<br/>
                    Created<br/>
                    Gender
                  </th>

                  <th style={{width: "5%"}}>
                  Select
                  </th>
                
                </tr>
                
                {
                  regUsers.map((val,tindex)=>
                    <tr key={"utblrow2" + tindex}>
                      <td style={{width: "5%"}}>
                      {tindex+1}
                      </td>
                      
                      <td style={{width: "40%"}}>
                        <a href={`/view/the/user?id=${val.uid}`}>{val.firstname}.{val.lastname}</a><br/>
                        {new Date(val.dob).toLocaleDateString()}<br/>
                        {val.role} / {(val.blocked ? 'blocked' : 'active')} 
                      </td>
                      
                      <td style={{width: "35%"}}>
                        {val.email}<br/>
                        {val.mobile}<br/>
                        {val.skype}
                      </td>

                      <td style={{width: "15%"}}>
                        {val.country}<br/>
                        {new Date(val.created).toLocaleDateString()}<br/>
                        {getGender(val.gender)}
                      </td>

                      <td style={{width: "5%"}}>
                        <input type="checkbox" id={`chk{tindex}`} 
                          onChange={()=>{userRowCBClicked(tindex)}} 
                          checked={val.checked} 
                        />
                      </td>

                    </tr>
                  )
                }

              </tbody>
            </table>
            </div>
            
          <br/>
          {status && <div className={`alert ${status.type}`}>{status.message}</div>}
            
        </div>
        
        <div className="inlinecenter small-text">
          <button onClick={handleEditUserDelete}>Delete</button>
          <button onClick={handleEditUserSelectAll}>{selectAllBtnText}</button>
        </div>
        
      </div>
    );
  }  else { 
    return(
      <div key="umain" className="medium-text">
        <div id="umain2" className="center" >
          You are not allowed to access this page.
        </div>
      </div>
    );
  }
}

export {ViewAllUsers, ViewTheUser};
