
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGController = () => {

  setPageTitle('Controller');
  
  const element = (
    <div className="userguide">
      <h1>LM Tools - Controller</h1>
      <br/>
      Its a software component, often installed in a separate machine. It controls 
      N number of <b>multipliers</b> in a typical LM Tools setup. 
      
      For our free cloud setup, Controller is already installed, so you do not have 
      to arrange new machine to install the controller software.
      
      You can add, edit and delete controller from frontend as shown in below image.
      
      <br/><img className="borderimg" src={process.env.PUBLIC_URL + '/userguide/controller-ui.png'} alt="Controller" width='100%'/>
      
      As you can see in above image, Controller fields are - 
      <ul>
        <li>
          <b>Name</b> - The name should be unique one. It signifies the logical name of a Controller.
        </li>

        <li>
          <b>IP address</b> - IP address of Controller.
        </li>

        <li>
          <b>Port</b> - The listen of port Controller, where it accespts incoming connections of Multipliers.
        </li>

        <li>
          <b>Access token</b> - This is for future use. This will be used for enhancing security mechanism to protect misuse of Controllers. 
        </li>
      </ul>
      
      <br/>
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/terminologies"}>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/multiplier"}>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGController}

