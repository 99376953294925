
import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import axios from 'axios';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import 
  {
    createSelectStyle, handleErrorResponse,
    lmAudioCodecs, lmVideoCodecs, lmMediaProfiles, lmCryptoSuites, lmStates, 
  } from '../../../routes/lm.js';


export const LMCallConfig = ({onConfigChange}) =>  {
  const [state, setState] = useState({
    acodec: 0, vcodec: 10, mediaprofile: 0, cryptosuite: 0, 
    rtcpmux: 1, avbundle: 1, mediatime: 16000, webrtc: false, 
  });

  let audioCodecsObj = lmAudioCodecs();
  let videoCodecsObj = lmVideoCodecs();
  
  let mediaprofilesObj = lmMediaProfiles();
  let cryptoSuitesObj = lmCryptoSuites();
  let lmStatesObj = lmStates();

  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    let newState = { ...state };
    newState[name] = value; 
    setState(newState);
    onConfigChange(newState);
  };
  
  const handleSelectInputChange=(e)=> {
    const {name,value}=e.target;
    let newState = {...state};

    /* 
    * This block of code is very important.
    * When onChange triggers for a select, we need to keep track of id of item 
    * selected in state object. The id may not be like 0,1,2.. but could be like
    * 0.10,20,35,56 etc..
    * 
    * So for 2nd case where id is montonic, that time we must set the id (through data-id)
    * in JSX and retrieve here, set the id value to state object's corresponding member.
    */
    for (let node of e.target.children) {
      if (node.value === e.target.value) {
        newState[name] = Math.round(node.getAttribute('data-id'));
        break;
      }
    }
    
    setState(newState);
    onConfigChange(newState);
  }

  function justUpdateState() {
    let newState = { ...state };
    setState(newState);
    onConfigChange(newState);
  }

  //Hook to get data from backend server
  useEffect ( () => {
     justUpdateState();
  }, []);    
  
  
  return(
    <div key="callconfig">
      <br/><br/><h3>Call configuration</h3>
      <table key='tcallconfig' className="statstbl">
        <tbody key='tcallconfigbd'>
          <tr key='tcallconfigr1'>
            
            <td>
              Audio codec
              <Tooltip title="Choose the audio codec from list" arrow>
                <span className="round">?</span>
              </Tooltip><br/>

              <select style={{width: "100%"}} value={audioCodecsObj[state.acodec]} 
                name="acodec" onChange={handleSelectInputChange}>
                {Object.keys(audioCodecsObj).map(item => 
                  <option key={`acodec${item}`} data-id = {`${item}`}>
                  {audioCodecsObj[item]}
                  </option>
                )}
              </select>                        
            </td>
            
            <td>
              Video codec
              <Tooltip title="Choose the video codec from list" arrow>
                <span className="round">?</span>
              </Tooltip><br/>

              <select style={{width: "100%"}} value={videoCodecsObj[state.vcodec]} 
                name="vcodec" onChange={handleSelectInputChange}>
                {Object.keys(videoCodecsObj).map(item => 
                  <option key={`vcodec${item}`} data-id = {`${item}`}>
                  {videoCodecsObj[item]}
                  </option>
                )}
              </select>                        
            </td>

            <td>
              Media profile
              <Tooltip title="Choose the media profile from list" arrow>
                <span className="round">?</span>
              </Tooltip><br/>

              <select style={{width: "100%"}} value={mediaprofilesObj[state.mediaprofile]} 
                name="mediaprofile" onChange={handleSelectInputChange}>
                {Object.keys(mediaprofilesObj).map(item => 
                  <option key = {`mediaprofile${item}`} data-id = {`${item}`} >
                    {mediaprofilesObj[item]}
                  </option>
                )}
              </select>                        
            </td>

          </tr>
          <tr key='sipcallr2'>
            
            <td>
              Crypto suites
              <Tooltip title="Choose the crypto suite from list" arrow>
                <span className="round">?</span>
              </Tooltip><br/>

              <select style={{width: "100%"}} value={cryptoSuitesObj[state.cryptosuite]} 
                name="cryptosuite" onChange={handleSelectInputChange}>
                {Object.keys(cryptoSuitesObj).map(item => 
                  <option key={`cryptosuite${item}`} data-id = {`${item}`} >
                  {cryptoSuitesObj[item]}
                  </option>
                )}
              </select>                        
            </td>

            <td>
              RTCP mux
              <Tooltip title="Enable / disable rtcp mux" arrow>
                <span className="round">?</span>
              </Tooltip><br/>

              <select style={{width: "100%"}} value={lmStatesObj[state.rtcpmux]} 
                name="rtcpmux" onChange={handleSelectInputChange}>
                {Object.keys(lmStatesObj).map(item => 
                  <option key={`rtcpmux${item}`} data-id = {`${item}`}>
                  {lmStatesObj[item]}
                  </option>
                )}
              </select>                        
            </td>

            <td>
              AV bundle
              <Tooltip title="Enable / disable audio / video bundle" arrow>
                <span className="round">?</span>
              </Tooltip><br/>

              <select style={{width: "100%"}} value={lmStatesObj[state.avbundle]} 
                name="avbundle" onChange={handleSelectInputChange}>
                {Object.keys(lmStatesObj).map(item => 
                  <option key={`avbundle${item}`} data-id = {`${item}`}>
                  {lmStatesObj[item]}
                  </option>
                )}
              </select>                        
            </td>

          </tr>
          <tr key='sipcallr3'>
          
            <td>
              Media time
              <Tooltip title="Enter audio/video active time in milliseconds." arrow>
                <span className="round">?</span>
              </Tooltip><br/>
              <input required name="mediatime" value={state.mediatime} style={{width: "100%"}} onChange={(e)=>handleInputTextChange(e)}/>
            </td>
          </tr>
          
        </tbody>
      </table>
    
    </div>
  );
  
}

