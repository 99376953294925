import {setPageTitle} from './lm.js'

const License = () => {
  
  setPageTitle('License');
  
  const myElement = (
    <div>
      The cloud version of LM Tools is free for one month up to 100 virtual users. Support for cloud
      version of LM Tools is provided on best effort basis. If you need dedicated
      support please <a href="/contact">contact us</a> with the requirement details.
      <br/><br/> 
      The enterprise version may cost you quarterly / half yearly / annually depending on how many
      concurrent users or sessions you require to simulate for testing the product.
      <br/><br/> 
      If you have any specific testing requirement like how to test the proprietary product or how to test a 
      binary call flow etc, you may like to <a href="/contact">contact us</a> for more details. 
    </div>
  );
  
  return myElement;
}

export default License;
