
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGServerVariables = () => {

  setPageTitle('Server Variables');
  
  const element = (
    <div className="userguide">
      <h1>Server Variables</h1>
      <br/>

        Multiplier plugins are dependent on variables to large extent. The are 
        different kind of variables like normal variable, system variable, plugin 
        variable and relative variables. Here normal variables and plugin variables are discussed. 
        
        <br/><br/>
        <h3>Server Normal Variables</h3>
        The normal variables starts with <i>[</i>  and ends with <i>]</i>. 
        For example [count] is a normal variable, variable name is count. 
        Each variable has name, type and value. The string type variables has 
        additionally length as well. You can use normal variables to store data, 
        extract data and fetch data.

        <br/><br/>
        <h3>Server Plugin Variables</h3>
        Server plugin provides internal variables to user known as <i><b>plugin variables</b></i> 
        to enable to fetch plugin internal values. The plugin variables are written in a 
        special way, start with <i>[p-</i> and ends with <i>]</i>. For example <i>[p-random-string]</i>
        is a plugin variable that gives a random digit-only string.

        <br/><br/>
        Please note that below plugin variables are valid for all Server modules.

        <ol>
        <br/><li><div className="code">[p-current-time]</div>
        This returns system current time. By default it returns in Y:m:d:H:M:S format. If you want in a different format, you can specify format like <i>[p-current-time:format]</i>. Internally strftime is used to generate time in given format. So for all possible format see <a href="http://man7.org/linux/man-pages/man3/strftime.3.html">here</a>.
        </li>
        
        <br/><li><div className="code">[p-time:offset:format]</div>
        Sometime you need current time with given offset in a presentable format. Like current time + 10 minutes or current time - 10 minutes with a presentable format. In such case, please make use of this variable. Note that offset is in millisecond unit. 
        </li>
        <br/><b>Examples</b>
        <ul>
        <li>[p-time] - Current time in %Y:%m:%d:%H:%M:%S format (default format)
        </li>
        <li>[p-time:0:%H:%M] - Current time, with no additional time in %H:%M format
        </li>
        <li>[p-time:600000:%H:%M] - Current time, with more 10 minutes in %H:%M format
        </li>
        <li>[p-time:-600000:%H:%M] - Current time, with less 10 minutes in %H:%M format
        </li>
        </ul>
        Please note that you can use any permissible format given <a href="http://man7.org/linux/man-pages/man3/strftime.3.html">here</a>.

        <br/><br/><li><div className="code">[p-millisec:offset]</div>
        This returns current time in millisecond with given offset. Please note that offset is in milliseconds unit.
        </li>
        <b>Examples</b>
        <ul>
        <li>[p-millisec] - Current time in millseconds
        </li>
        <li>[p-millisec:600000] - Current time in milliseconds with more 10 minutes time
        </li>
        <li>[p-millisec:-600000] - Current time in milliseconds with less 10 minutes time
        </li>
        </ul>

        <li><div className="code">[p-microsec:offset]</div>
        This returns current time in microseconds with given offset. Please note that offset is in microseconds unit.
        </li>
        <br/><b>Examples</b>
        <ul>
        <li>[p-microsec] - Current time in microseconds</li>
        <li>[p-microsec:600000000] - Current time in microseconds with more 10 minutes time</li>
        <li>[p-microsec:-600000000] - Current time in microseconds with less 10 minutes time</li>
        </ul>

        <li><div className="code">[p-random-string]</div>
        This returns 7 byte decimal string. If you want a different size string, you can mention size as well like <i>[p-random-string:n]</i>, where n is the size of string. 
        </li>

        <br/><li><div className="code">[p-random-hex-string]</div>
        This returns 7 byte hexadecimal string. If you want a different size string, you can mention size as well like <i>[p-random-hex-string:n]</i>, where n is the size of string. 
        </li>

        <br/><li><div className="code">[p-random-number-u16]</div>
        This returns 2 byte decimal number.
        </li>
        
        <br/><li><div className="code">[p-audio-rtp-port:session-id]</div>
        This returns local audio rtp port of given session identified by session-id.
        </li>

        <br/><li><div className="code">[p-audio-rtcp-port:session-id]</div>
        This returns local audio rtcp port of given session identified by session-id.
        </li>

        <br/><li><div className="code">[p-video-rtp-port:session-id]</div>
        This returns local video rtp port of given session identified by session-id.
        </li>
        
        <br/><li><div className="code">[p-video-rtcp-port:session-id]</div>
        This returns local video rtcp port of given session identified by session-id.
        </li>

        <br/><li><div className="code">[p-audio-codec]</div>
        This returns audio codec you have configured in config-sdp test case under session-config block.
        </li>
        
        <br/><li><div className="code">[p-video-codec]</div>
        This returns video codec you have configured in config-sdp test case under session-config block.
        </li>
        
        </ol>

        <h3>Server Other Party Variables</h3>
        The other party variables are used to access to variables of other endpoints. Say in Server, you received
        a message for endpoint 0, and you need to send a new request to endpoint 1. The template used to send
        new request to endpoint 1, may contain some variables. Now the difficult part is, you are in context of
        endpoint 0 (as you received request for endpoint 0), and you are formating a message for endpoint 1. In 
        such cases, other party variables are quite helpful. The other party variable syntax is as below.

        <ol>
          <li><div className="code">[o-varname:apat:endpoint-id]</div>
          </li>
          <li><div className="code">[o-varname:sip:endpoint-id]</div>
          </li>
          <li><div className="code">[o-varname:httpd:endpoint-id]</div>
          </li>
        </ol>
        
        In above syntax, <b>[o-</b> indiates start of other party variable. Next token is variable name, followed
        by module name and endpoint identifier.

      <br/><br/>      
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/httpb2bainstructions" }>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/serversyntax" }>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGServerVariables}