
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGVariables = () => {

  setPageTitle('Variables');
  
  const element = (
    <div className="userguide">
      <p>
        <h1>LM Tools - Variables</h1>
        <br/><b>Variables</b> are used extensively in LM Tools environment. In Tx path,
        when a message is sent out, variables / functions / templates present in the template
        are replcaed with actual values and then message is sent out. 
        <br/><br/>
        In Rx path, when a message is received, values for variables are extracted from 
        received message if fixed portions of template matches with received message.
        <br/><br/>
        Various type of variables 
        are listed as below. Lets say the variable name is var, with this variable name let see 
        what all kind of variables are there in LM Tools.
        <br/>
        <ul>
        
        <li>
          <b>[var]</b> - It is a normal variable case. Either the variable is 
          defined in variable test case, or some data is extracted to this 
          variable on message receive from remote. The normal variables also 
          known as absolute variable.
        </li>
        <li>
          <b>[a-var]</b> - Here a in [a-var] stands for absolute variable. 
          This is same as normal variable explained above.
        </li>
        
        <li>
          <b>[r-var]</b> - This is a relative variable case. Typically this 
          works in case of integer variable. The resultant value for a relative 
          variable is higher by its index value. Say one variable value is 5. 
          For 0th endpoint corresponding variable value will be 5, for 1st 
          endpoint, corresponding variable value will be 6 and so on.
        </li>
        
        <li>
          <b>[p-var]</b> - These are known also plugin variables. You can 
          imagine it is like internal variable exposed to user to get real time 
          value. You should not use plugin variables in receive template. Even 
          if you do that, you will never be able to access that value. 
        </li>
        
        <li>
          <b>[c-var]</b> - These are known as csv variables. Sometimes user 
          supplies value trough a CSV file. Lets take an example. [c-test.csv:2] 
          indicates test.csv files second column value. The first row in CSV file 
          contains data types like int, double, bool and string. The second row 
          onwards contains data, each row for one virtual user. So first row 
          contains data type, second row contains data for first virtual user, 
          the third row contains data for second virtual user so on. You can 
          see below image for more clarity.

          <br/>
          <img className="noborderimg" src={process.env.PUBLIC_URL + '/userguide/csv-variables-ui.png'} alt="CSV Variables" width='100%'/>
          The last column contains description only, not the actual data.

        </li>
        
        <li>
          <b>[l-var]</b> - The l specifier works for string variable, It provides length 
          of a string variable.
        </li>
        
        <li>
          <b>[o-var:a:n]</b> - This helps to retrieve other party absolute 
          variable. The o specifier stands for other, at the end a specifier 
          stands for absolute, and n provides the gap index. Lets take an 
          example to see how it works. Say value of 5th endpoint absolute 
          variable is required at 0th endpoint. For that one need to write 
          expression like [o-var:a:5].
        </li>
        
        <li>
          <b>[o-var:p:n]</b> - This helps to retrieve other party plugin 
          variable. The o specifier stands for other, at the end p specifier 
          stands for plugin, and n provides the gap index. Lets take an example 
          to see how it works. Say value of 5th endpoint plugin variable is 
          required at 0th endpoint. For that one need to write expression 
          like [o-var:p:5]. For example [o-local-server-port:p:5] will fetch 
          local server port value of 5th endpoint.
        </li>
        
        <li>
          <b>[o-var:r:n]</b> - This helps to retrieve other party relative 
          variable. The o specifier stands for other, at the end r specifier 
          stands for relative, and n provides the gap index. Lets take an example 
          to see how it works. Say value of 5th endpoint plugin variable is 
          required at 0th endpoint. For that one need to write expression like 
          [o-var:r:5].
        </li>

        </ul>
        <div className="inlinecenter">
          <button onClick={() => window.location.href="/userguide/multiplier/threadsendpointsrps" }>Back</button> 
          <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
          <button onClick={() => window.location.href="/userguide/userseries" }>Next</button>
        </div>
      </p>
    </div>
  );
  
  return element;
}

export {UGVariables}

