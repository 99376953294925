
import {setPageTitle} from './lm.js'

const Contact = () => {
  setPageTitle('Contact');
  const myElement = (
    <div>
      <br/>Email us as per below contact details.<br/>
      <ul>
      <li><b>EMail</b>: info@lmtools.com<br/></li>
      </ul>
    </div>
  );
  
  return myElement;
}

export default Contact;


