
import React, {useState, useEffect} from 'react';
import Select from 'react-select';

import 
  {
    checkRecordCounts, createSelectStyle, setPageTitle, getLmPluginId, 
    getLmFrequencyId, getLmBlockTypeId, getLmReportId, handleErrorResponse,
    lmTransports, getLmTransportId, lmURISchemes, lmAudioCodecs, 
    lmVideoCodecs, lmMediaProfiles, 
    lmCryptoSuites, lmStates, lmSIPAuthScheme,
  } from '../../routes/lm.js';
  
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import moment from 'moment';
import axios from 'axios';
import {Line} from 'rc-progress';
import CryptoJS from 'crypto-js';

import { ScenarioHeader } from './utils/scenarioheader'
import { LMUserConfig } from './utils/lmuserconfig'
import { LMCallConfig } from './utils/lmcallconfig'
import { LMSIPServerConfig } from './utils/lmsipserverconfig'
import { LMTURNServerConfig } from './utils/lmturnserverconfig'

const sipTmplts = require("./templates/sipclienttemplates.js")
const sipAttribs = require("./testsuites/sipclienttestsuites.js")

let myTimeOut = null;
const myIPAddress = "127.0.0.1";

const ScenarioSIPCall = (isWebRTC) => 
{
  const [status, setStatus] = useState(null);
  const [progressBar, setProgressBar] = useState({value: 0});
  const [btnState, setBtnState] = useState(false);
  const [state, setState] = useState({id: -1,});

  const [headerConfig, setHeaderConfig] = useState(null);
  const [userConfig, setUserConfig] = useState(null);
  const [callConfig, setCallConfig] = useState(null);
  const [serverConfig, setServerConfig] = useState(null);
  const [turnConfig, setTurnConfig] = useState(null);

  isWebRTC ? setPageTitle('SIP WebRTC call scenario') : setPageTitle('SIP call scenario');

  let transportsObj = lmTransports();
  let uriSchemesObj = lmURISchemes();
  
  let audioCodecsObj = lmAudioCodecs();
  let videoCodecsObj = lmVideoCodecs();
  
  let mediaprofilesObj = lmMediaProfiles();
  let cryptoSuitesObj = lmCryptoSuites();
  let lmStatesObj = lmStates();
  let lmAuthsObj = lmSIPAuthScheme();
  
  const user = JSON.parse(localStorage.getItem('user'));

  const sipCallTerminatingCreateTP = async (name) => {
    
    let tpRecordId = -1;
    
    const reqdTpHead = {
      tpName: name,
      type: 0, //0 -> text, 1-> binary template
      tpDesc: headerConfig.desc,
      tpId: -1, //-1 for new template
    };
    
    try {
      await axios.post(
        '/api/template/data/save', 
        {uid: user.uid, email: user.email, data: JSON.stringify(reqdTpHead)},
        {headers: {Authorization: user.jwt,}},
      ).then( function (res) {
        tpRecordId = res.data.tpId;
        reqdTpHead.tpId = tpRecordId;
        increaseProgressBy(14);
      });
    }catch(err) {
      handleErrorResponse(err);
    }
    
    if(tpRecordId > 0) {
      const reqdTpData = [];
      
      let wsTransport = getLmTransportId("ws");
      let wssTransport = getLmTransportId("wss");
      
      
      reqdTpData.push(sipTmplts.sipTxRegister(userConfig));

      if(userConfig.auth > 0) {
        reqdTpData.push(sipTmplts.sipRxRegister401());
      }
        
      reqdTpData.push(sipTmplts.sipRxRegister200());
      reqdTpData.push(sipTmplts.sipTxUnRegister(userConfig));
      reqdTpData.push(sipTmplts.sipRxUnRegister200());
 
      if((userConfig.transport === wsTransport) || 
        (userConfig.transport === wssTransport))
      {
        reqdTpData.push(sipTmplts.wsHandshake());
      }
      
      if(isWebRTC) {
        reqdTpData.push(sipTmplts.sipWebRTCRxInvite(userConfig, callConfig, turnConfig));
        reqdTpData.push(sipTmplts.sipWebRTCTxInvite200(userConfig, callConfig, turnConfig));
      } else {
        reqdTpData.push(sipTmplts.sipRxInvite(userConfig, callConfig));
        reqdTpData.push(sipTmplts.sipTxInvite200(userConfig, callConfig));
      }      
      reqdTpData.push(sipTmplts.sipTxInvite180(userConfig));
      reqdTpData.push(sipTmplts.sipRxAck());
      reqdTpData.push(sipTmplts.sipRxBye());
      reqdTpData.push(sipTmplts.sipTxBye200(userConfig));

      const tpJsonData = JSON.stringify(reqdTpData);
      const tpBodySha256 = CryptoJS.SHA256(tpJsonData).toString();
      
      try {
        await axios.post(
          '/api/templateblocks/data/save',
          {uid: user.uid, email: user.email, tpid: tpRecordId, sha256: tpBodySha256, data: tpJsonData},
          {headers: {Authorization: user.jwt,}},
        ).then ( function (res) {
          if(res.status === 200) {
            
            increaseProgressBy(28);
          
          } else {
            console.log("Template blocks save, got error " + res.status);
          }
        });
      } catch(err) {
        handleErrorResponse(err);
      }
    }
    
    return tpRecordId;
  }

 
  const sipCallOriginatingCreateTP = async (name) => {
    
    let tpRecordId = -1;
    
    const reqdTpHead = {
      tpName: name,
      type: 0, //0 -> text, 1-> binary template
      tpDesc: headerConfig.desc,
      tpId: -1, //-1 for new template
    };
    
    try {
      await axios.post(
        '/api/template/data/save', 
        {uid: user.uid, email: user.email, data: JSON.stringify(reqdTpHead)},
        {headers: {Authorization: user.jwt,}},
      ).then( function (res) {
        tpRecordId = res.data.tpId;
        reqdTpHead.tpId = tpRecordId;
        increaseProgressBy(14);
      });
    }catch(err) {
      handleErrorResponse(err);
    }
    
    if(tpRecordId > 0) {
      const reqdTpData = [];
      
      let wsTransport = getLmTransportId("ws");
      let wssTransport = getLmTransportId("wss");
      
      
      reqdTpData.push(sipTmplts.sipTxRegister(userConfig));

      if(userConfig.auth > 0) {
        reqdTpData.push(sipTmplts.sipRxRegister401());
      }

      reqdTpData.push(sipTmplts.sipRxRegister200());
      reqdTpData.push(sipTmplts.sipTxUnRegister(userConfig));
      reqdTpData.push(sipTmplts.sipRxUnRegister200());
 
      if((userConfig.transport === wsTransport) || 
        (userConfig.transport === wssTransport))
      {
        reqdTpData.push(sipTmplts.wsHandshake());
      }

      if(isWebRTC) {
        reqdTpData.push(sipTmplts.sipWebRTCTxInvite(userConfig, callConfig, turnConfig));
        reqdTpData.push(sipTmplts.sipWebRTCRxInvite200(userConfig, callConfig, turnConfig));
      } else {
        reqdTpData.push(sipTmplts.sipTxInvite(userConfig, callConfig));
        reqdTpData.push(sipTmplts.sipRxInvite200(userConfig, callConfig));
      }
      
      reqdTpData.push(sipTmplts.sipRxInvite100());
      reqdTpData.push(sipTmplts.sipRxInvite180(userConfig));
      reqdTpData.push(sipTmplts.sipTxAck(userConfig));
      reqdTpData.push(sipTmplts.sipTxBye(userConfig));
      reqdTpData.push(sipTmplts.sipRxBye200());

      const tpJsonData = JSON.stringify(reqdTpData);
      const tpBodySha256 = CryptoJS.SHA256(tpJsonData).toString();
      
      try {
        await axios.post(
          '/api/templateblocks/data/save',
          {uid: user.uid, email: user.email, tpid: tpRecordId, sha256: tpBodySha256, data: tpJsonData},
          {headers: {Authorization: user.jwt,}},
        ).then ( function (res) {
          if(res.status === 200) {
            
            increaseProgressBy(28);
          
          } else {
            console.log("Template blocks save, got error " + res.status);
          }
        });
      } catch(err) {
        handleErrorResponse(err);
      }
    }
    
    return tpRecordId;
  }

  const sipCallCreateTS = async (name, otpname, ttpname) => {

    let tsRecordId = -1;
    
    let reqdTsHead = {
      tsName: name, 
      tsDesc: headerConfig.desc, 
      tsId: tsRecordId
    };

    try {
      await axios.post(
        '/api/testsuite/data/save', 
        {uid: user.uid, email: user.email, data: JSON.stringify(reqdTsHead)},
        {headers: {Authorization: user.jwt,}},
      ).then( function (res) {
        if(res.status === 200) {
          tsRecordId = res.data.tsId;
          reqdTsHead.tsId = tsRecordId;
          increaseProgressBy(42);
        } else {
          console.log("TS save, got error " + res.status);
        }
      })
    }catch(err) {
      handleErrorResponse(err);
    }

    if(tsRecordId > 0) {
      
      let pluginId = 0;
      if(isWebRTC) {
        pluginId = getLmPluginId('FlexiSipWebRtc');
      } else {
        pluginId = getLmPluginId('FlexiSip');
      }
      
      let nativeBlock = getLmBlockTypeId('Native API');
      let ibBlock = getLmBlockTypeId('Instructions Block');
      let freqOnce = getLmFrequencyId('Once');
      let freqAlways = getLmFrequencyId('Always');
      
      let reportId = getLmReportId('Yes');
      let transport = transportsObj[userConfig.transport];
      let urischeme = uriSchemesObj[userConfig.urischeme];
      
      let acodec = audioCodecsObj[callConfig.acodec].toUpperCase();
      let vcodec = videoCodecsObj[callConfig.vcodec].toUpperCase();
      
      let mediaprofile = mediaprofilesObj[callConfig.mediaprofile];
      let cryptosuite = cryptoSuitesObj[callConfig.cryptosuite].toUpperCase();
      
      const reqdTsBody = [];

      reqdTsBody.push(sipAttribs.sipCreateInstanceAttribs(userConfig, callConfig, serverConfig, turnConfig, pluginId, freqOnce, reportId));
      reqdTsBody.push(sipAttribs.sipVariablesAttribs(pluginId, freqOnce, reportId));
      reqdTsBody.push(sipAttribs.sipConfigSDPAttribs(callConfig, pluginId, nativeBlock, freqOnce, reportId));
      
      
      if(isWebRTC) {
        reqdTsBody.push(sipAttribs.sipWebRTCCallOriginatingAttribs(callConfig, pluginId, ibBlock, freqOnce, reportId));
        reqdTsBody.push(sipAttribs.sipWebRTCCallTerminatingAttribs(callConfig, pluginId, ibBlock, freqOnce, reportId));
      } else {
        reqdTsBody.push(sipAttribs.sipCallOriginatingAttribs(callConfig, pluginId, ibBlock, freqOnce, reportId));
        reqdTsBody.push(sipAttribs.sipCallTerminatingAttribs(callConfig, pluginId, ibBlock, freqOnce, reportId));
      }
      
      reqdTsBody.push(sipAttribs.sipSessionInitAttribs(userConfig, callConfig, serverConfig, turnConfig, pluginId, nativeBlock, freqAlways, reportId));
      reqdTsBody.push(sipAttribs.sipLoadTemplateAttribs("originating", otpname, pluginId, nativeBlock, freqOnce, reportId));
      reqdTsBody.push(sipAttribs.sipLoadTemplateAttribs("terminating", ttpname, pluginId, nativeBlock, freqOnce, reportId));
      reqdTsBody.push(sipAttribs.sipExecuteRegisterAttribs(userConfig, pluginId, nativeBlock, freqAlways, reportId));
      reqdTsBody.push(sipAttribs.sipExecuteCallAttribs(pluginId, nativeBlock, freqAlways, reportId));
      reqdTsBody.push(sipAttribs.sipExecuteUnregisterAttribs(userConfig, pluginId, nativeBlock, freqAlways, reportId));
      reqdTsBody.push(sipAttribs.sipSessionDeInitAttribs(pluginId, nativeBlock, freqAlways, reportId));
        
      const tbJsonData = JSON.stringify(reqdTsBody); //test block json data
      const tsBodySha256 = CryptoJS.SHA256(tbJsonData).toString();
      
      try {
        await axios.post(
          '/api/testblocks/data/save',
          {uid: user.uid, email: user.email, tsid: tsRecordId, sha256: tsBodySha256, data: tbJsonData},
          {headers: {Authorization: user.jwt,}},
        ).then ( function (res2) {
          if(res2.status === 200) {
            increaseProgressBy(56);
          } else {
            console.log("TS blocks save, got error " + res2.status);
          }
        });
      } catch(err2) {
        handleErrorResponse(err2);
      }
    }
    
    return tsRecordId;
  }

  const sipCallCreateTB = async (name, tsRecordId) => {
    let tbRecordId = -1;
    
    let reqdTbHead = {
      name: name,
      
      cid: headerConfig.cvalue,
      controller: headerConfig.clabel,
      
      loglevel: 0,
      valgrind: 0,
      repeat: 1,
      description: headerConfig.desc,
      tbId: -1,
    };

    try {
      await axios.post(
        '/api/testbed/data/save', 
        {uid: user.uid, email: user.email, data: JSON.stringify(reqdTbHead)},
        {headers: {Authorization: user.jwt,}},
      ).then( function (res) {
        if(res.status === 200) {
          tbRecordId = Math.round(res.data.tbId);
          increaseProgressBy(70);
        } else {
          console.log("Testbed save, got error " + res.status);
        }
      })
    }catch(err) {
      handleErrorResponse(err);
    }
    
    let reqdTbBody = [{
      tsid: tsRecordId,
      testsuite: name,
      
      mid: headerConfig.mvalue,
      multiplier: headerConfig.mlabel,
      
      endpoints: 2,
      threads: 1,
      
      state: 1,
      loglevel: 0,
      valgrind: 0,
    }];
    
    if(tbRecordId > 0) {
            
      const tbJsonData = JSON.stringify(reqdTbBody);
      const tbBodySha256 = CryptoJS.SHA256(tbJsonData).toString();
      
      try {
        await axios.post(
          '/api/testbedblocks/data/save',
          {uid: user.uid, email: user.email, tbid: tbRecordId, sha256: tbBodySha256, data: tbJsonData},
          {headers: {Authorization: user.jwt,}},
        ).then ( function (res2) {
          if(res2.status === 200) {
            increaseProgressBy(84);
          } else {
            console.log("Testbed blocks save, got error " + res2.status);
          }
        });
      } catch(err2) {
        handleErrorResponse(err2);
      }
    }
    
    return tbRecordId;
  }

  function increaseProgressBy(delta) {
    let newProgressBar = {...progressBar};
    newProgressBar.value += delta;
    setProgressBar(newProgressBar);
  }

  const saveSipCallScenarioData = async (name, otps, ttps, tss, tbs) => {
    let scRecordId = -1;
    let atps = [otps, ttps];
    let atss = [tss];
    let atbs = [tbs];
    
    try {
      await axios.post(
        '/api/scenario/data/save', 
        {
          uid: user.uid, 
          email: user.email, 
          name: name,
          desc: headerConfig.desc,
          tparr: JSON.stringify(atps),
          tsarr: JSON.stringify(atss),
          tbarr: JSON.stringify(atbs),
        },
        {headers: {Authorization: user.jwt,}},
      ).then( function (res) {
        if(res.status === 200) {
          scRecordId = Math.round(res.data.scid);
          
          setStatus({
            type: 'success',
            message: `Scenarion ${name} is created successfully.`
          });
          
          increaseProgressBy(100);
          let newBtnState = true;
          setBtnState(newBtnState);              

          let newState = {...state};
          state.id = scRecordId;
          newState.id = scRecordId;
          setState(newState);
          myTimeOut = setTimeout(moveToScenarioPage, 1500);
          
        } else {
          console.log("Scenario save, got error " + res.status);
        }
      })
    }catch(err) {
      handleErrorResponse(err);
    }
  }
  
  function moveToScenarioPage() {
    clearTimeout(myTimeOut);
    window.location.href = `/components/view/the/scenario?id=${state.id}`;
  }
  
  const handleCreateScenario = async (e) => {
    e.preventDefault();
    
    if(headerConfig.mvalue == 0) {
      setStatus({type: 'error', message: 'Multiplier not set'});
      return;
    }

    if(headerConfig.cvalue == 0) {
      setStatus({type: 'error', message: 'Controller not set'});
      return;
    }

    let formatedname = headerConfig.name.replaceAll(' ', '-').toLowerCase();
    let uniqid = user.uid + '-' + moment().format('YYYY-MM-DD-HH-mm-ss-SSS');
    let name = formatedname + '-' + uniqid;
    let otpname = formatedname + '-originating-' + uniqid;
    let ttpname = formatedname + '-terminating-' + uniqid;

    //Record check result
    let rcr = await checkRecordCounts(user);
    
    if(rcr === 'success') {
      const otps = await sipCallOriginatingCreateTP(otpname);
      const ttps = await sipCallTerminatingCreateTP(ttpname);
      
      const tss = await sipCallCreateTS(name, otpname, ttpname);
      const tbs = await sipCallCreateTB(name, tss);
      
      //Add entries 
      await saveSipCallScenarioData(name, otps, ttps, tss, tbs);
    } else {
      alert('Scenario creation failed. Error: ' + rcr);
    }
  }

  
  const handleCallConfigChange = (updatedState) => {
    updatedState.webrtc = isWebRTC;
    setCallConfig(updatedState);
  }

  const handleUserConfigChange = (updatedState) => {
    setUserConfig(updatedState);
  }

  const handleScenarioHeaderChange = (updatedState) => {
    setHeaderConfig(updatedState);
  }

  const handleTurnConfigChange = (updatedState) => {
    setTurnConfig(updatedState);
  }

  const handleServerConfigChange = (updatedState) => {
    setServerConfig(updatedState);
  }
  
  if(user) {

    let configObj = {
      baseid: true, 
      uprefix: true, pprefix: true, 
      localip: true, transport: true, urischeme: true,
    };
    
    let scName = 'SIP call';
    if(isWebRTC) {
      scName = 'SIP WebRTC call';
    }
    
    return (
      <form name="scform" id="scform" onSubmit={handleCreateScenario}>
        <div key="scmain" className="medium-text">
          <div id="scmain2" className="center" >

            <ScenarioHeader scenarioName={scName} onConfigChange={(updatedState) => 
            handleScenarioHeaderChange(updatedState)} />

            <LMUserConfig configObj={configObj} onConfigChange={(updatedState) => 
            handleUserConfigChange(updatedState)} />

            <LMCallConfig onConfigChange={(updatedState) => 
            handleCallConfigChange(updatedState)} />

            {
              isWebRTC ? 
              (<LMTURNServerConfig onConfigChange={(updatedState) => 
              handleTurnConfigChange(updatedState)} />) : ("")
            }
            
            <LMSIPServerConfig onConfigChange={(updatedState) => 
            handleServerConfigChange(updatedState)} />
            
            <div className="inlinecenter">
              {status && <div className={`alert ${status.type}`}>{status.message}</div>}
              <Line className="progressbar" style={{visibility: (progressBar.value > 0) ? "visible" : "hidden"}} percent={progressBar.value} strokeWidth="1" strokeColor="#009973" />
              <br/>
              <button disabled={btnState}>Create Scenario</button> 
            </div>
          </div>
        </div>
      </form>
    );
  }  else { 
    return(
      <div key="scmain" className="medium-text">
        <div id="scmain2" className="center" >
          You are not allowed to access this page.
        </div>
      </div>
    );
  }  
}
  
export {ScenarioSIPCall};
