
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';
import {ugc_file_graphs_sip, ugc_file_graphs_media} from './ugcommon.js';

let lcurly = '{';
let rcurly = '}';

const UGSIPB2BAInstructions = () => {

  setPageTitle('SIP B2BA Instructions');
  
  const element = (
    <div className="userguide">
      <p>
        <h1>SIP B2BA Instructions</h1>
        
        <br/>
        
        B2BA SIP stands for SIP (session initiation protocol) back to back agent. 
        Typically there are more than one component
        in B2BA and it interfaces with different products.
        Instructions are means to instruct certain operations to be executed in multiplier 
        test environment. 
        <br/><br/>
        Instructions can be in instruction blocks or in native APIs those 
        starts with <b>execute-</b> key. Multiplier (B2BA) supports various types of 
        instructions listed below.
        <br/><br/>
        <h3>B2BA SIP Instructions</h3>
        Now lets explore on various instructions supported by B2BA SIP module. Each instruction is
        explained in a single block with syntax, example and followed by explanation. Typically SIP
        instructions in B2BA starts with <b>sip start</b> and ends with <b>sip end</b> as shown below.
        <br/><br/>
        <div className="code">
        sip start<br/>
        instruction 1<br/>
        instruction 2<br/>
        ....................<br/>
        instruction n<br/>
        sip end<br/>
        </div><br/>
        
        
        <h3>Transport Instructions</h3>
        <ul>
        <li><b>create-client-connection &lt;ip&gt; &lt;port&gt; &lt;transport&gt; conn-name</b>
        <div className="code">
        <b>Examples</b><br/>
        
        #ip, port, transport are variables
        <br/>create-client-connection &lt;ip&gt; &lt;port&gt; &lt;transport&gt; conn-name-1
        <br/>#transport can be tcp, udp, tls, dtls, sctp, ws (for websocket), wss (secured websocket)
        <br/>create-client-connection 192.168.1.10 5061 tls conn-name-2
        </div>
        <br/>Instruction <b>create-client-connection</b> is used to create a client connection. It just creates 
        a connection, does not connect. To connect this connection (created by
        create-client-connection identified by conn-name), you can use <b>connect</b> instruction. Parameters
        &lt;ip&gt;, &lt;port&gt;, &lt;transport&gt; are used to provide local client transport ipaddress, port and transport protocol.
        &lt;transport&gt; can have one value out of udp, tcp, tls, sctp, ws and wss. For &lt;port&gt; either you can put a proper port
        or put 0. If you put 0, then client will bind any available port in system.
        </li>
        
        <br/><li><b>create-server-connection &lt;ip&gt; &lt;port&gt; &lt;transport&gt; &lt;conn-name&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        
        #ip, port, transport are variables
        <br/>create-server-connection &lt;ip&gt; &lt;port&gt; &lt;transport&gt; conn-name-1
        <br/>#transport can be tcp, udp, tls, dtls, sctp, ws (for websocket), wss (secured websocket)
        <br/>create-server-connection 192.168.1.10 5061 tls conn-name-2
        </div>
        <br/>Instruction <b>create-server-connection</b> is used to create a server connection. To this
        server connection, other client connections can connect as and when required. Parameters
        &lt;ip&gt;, &lt;port&gt;, &lt;transport&gt; are used to provide local server transport ipaddress, port and transport protocol.
        &lt;transport&gt; can have one value out of udp, tcp, tls, sctp, ws and wss.  
        </li>

        <br/><li><b>connect &lt;remoteip&gt; &lt;remoteport&gt; conn-name timer &lt;timeout-value&gt;</b>
        <div className="code">
        <b>Examples</b>
        <br/>#Here remoteip, remoteport are variables
        <br/>connect &lt;remoteip&gt; &lt;remoteport&gt; conn-name timer 60000
        
        <br/>#You can use direct values for remote ip/port as well
        <br/>connect 192.168.1.101 5080 myconn1 timer 60000
        </div>
        <br/>Instruction <b>connect</b> is used to connect to remote server. If you already have created 
        a connection (by using create-client-connection instruction), you can connect the same connection
        to a remote peer using connect instruction. The parameters &lt;remoteip&gt;, &lt;remoteport&gt; are used to
        provide remote server ipaddress and port respectively.
        </li>

        <br/><li><b>start-transport-connection &lt;ip&gt; &lt;port&gt; &lt;transport&gt; conn-name &lt;ws-hs-tmplt&gt; timer &lt;timeout-value&gt;</b>
        <div className="code">
        <b>Examples</b>
        <br/>#Here ip, port, transport are variables. 
        <br/>#ws-hs-tmplt is template name used for websocket handshake. It is optional.
        <br/>start-transport-connection &lt;ip&gt; &lt;port&gt; &lt;transport&gt; conn-name &lt;ws-hs-tmplt&gt; timer 60000
        <br/>#For tcp connection, ws-hs-tmplt not required
        <br/>start-transport-connection 192.168.1.10 5060 tcp conn-name timer 60000
        </div>
        <br/>Instruction <b>start-transport-connection</b> is used to start a transport connection to remote.
        It creates connection and connects to remote server unlike create-client-connection instruction. Parameters
        &lt;ip&gt;, &lt;port&gt;, &lt;transport&gt; are used to provide remote server transport ipaddress, port and transport protocol.
        &lt;transport&gt; can have one value out of udp, tcp, tls, sctp, ws and wss. 
        </li>

        <br/><li><b>stop-transport-connection &lt;conn-name&gt;</b>
        <div className="code">
        <b>Examples</b>
        <br/>stop-transport-connection conn-name1
        <br/>stop-transport-connection conn-name2
        </div>
        <br/>Instruction <b>stop-transport-connection</b> is used to stop / destroy a transport 
        connection identified by conn-name.
        </li>

        </ul>
        
        <h3>Context / Variable Instructions </h3>
        <ul>

        <li><b>set-destination &lt;ip&gt; &lt;port&gt; &lt;transport&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        set-destination [ip-contact] [port-contact] [p-sip:transport]<br/>
        </div>
        <br/>Instruction <b>set-destination</b> is used to set the destination for session.
        It needs ip, port and transport protocol parameters. In a typical scenario, when C1 calls
        C2 and B2BA as B, then at B when C2 registers, extract ip, port and transport. For calls 
        originating from C1, at B set the destination using extracted values (of C2 registration) for session towards C2.
        
        <br/><br/>The possible values of transport protocol are tcp, udp, tls, ws and wss. Please note
        that this is important (must) for udp case, whereas in other cases you can keep it, no harm. 
        </li>

        <br/><li><b>set-connection &lt;epid&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        set-connection [epid]<br/>
        </div>
        <br/>Instruction <b>set-connection</b> is used to set the connection for session. This is used typically 
        in connection oriented transport like tcp, tls, ws and wss. You can keep this instruction
        for udp case, no harm.
        
        <br/><br/>In a typical scenario, when C1 calls C2 and B2BA as B, then at B when C2 registers, it's connection handle 
        is stored at endpoint level. For calls originating from C1, at B set the connection handle for session towards C2.    
        </li>
        
        <br/><li><b>set-variables &lt;other-ep-id&gt; &lt;other-session-id&gt; &lt;varname1&gt; &lt;varvalue1&gt;</b>
        </li>
        
        <br/><li><b>import-variables &lt;other-ep-id&gt; &lt;other-session-id&gt; &lt;varname1&gt; &lt;varvalue1&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        set-variables [other-ep-id] [other-session-id] varname1 [varvalue1], varname2 [varvalue2] ....<br/>
        import-variables [other-ep-id] [other-session-id] varname1 [varvalue1], varname2 [varvalue2] ....<br/>
        </div>
        <br/>
        Instruction <b>set-variables</b> / <b>import-variables</b> is used to copy a single or multiple variables from a different endpoint / session. 
        If you are in a session context and without changing session context need to access variables of other endpoint / session,
        then you can do so using set-variables instruction.
        <br/><br/>
        Please note that you need to provide variable name and variable value pairs. You can refer above example.
        When this instruction gets executed, a new variables with name as <i>varname1</i> is added to current session with
        value of other endpoint / session variable identified by <i>varvalue1</i> name.
        </li>

        <br/><li><b>export-variables &lt;other-ep-id&gt; &lt;other-session-id&gt; &lt;varname1&gt; &lt;varvalue1&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        export-variables [other-ep-id] [other-session-id] varname1 [varvalue1], varname2 [varvalue2] ....<br/>
        </div>
        <br/>
        Instruction <b>export-variables</b> is used to copy a single or multiple variables from a current endpoint / session to different endpoint / session. 
        If you want to copy variables from current session context to other session context,
        then you can do so using export-variables instruction.
        <br/><br/>
        Please note that, you need to provide variable name and variable value pairs. You can refer above example.
        When this instruction gets executed, a new variables with name as <i>varname1</i> is added to other context with
        value of current context variable identified by <i>varvalue1</i> name.
        </li>
        
        <br/><li><b>set-ep-variables &lt;other-ep-id&gt; &lt;varname1&gt; &lt;varvalue1&gt;</b>
        </li>
        
        <br/><li><b>import-ep-variables &lt;other-ep-id&gt; &lt;varname1&gt; &lt;varvalue1&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        set-ep-variables [other-ep-id] varname1 [varvalue1], varname2 [varvalue2] ....<br/>
        import-ep-variables [other-ep-id] varname1 [varvalue1], varname2 [varvalue2] ....<br/>
        </div>
        <br/>
        Instruction <b>set-ep-variables</b> / <b>import-ep-variables</b> is used to copy a single or multiple variables from a different endpoint. 
        If you are in a session context and without changing session context need to access variables of other endpoint, 
        then you can do so using set-ep-variables instruction.
        <br/><br/>
        Please note that you need to provide variable name and variable value pairs. You can refer above example.
        When this instruction gets executed, a new variables with name as <i>varname1</i> is added to current session with
        value of other endpoint variable identified by <i>varvalue1</i> name.
        </li>

        <br/><li><b>export-ep-variables &lt;other-ep-id&gt; &lt;varname1&gt; &lt;varvalue1&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        export-ep-variables [other-ep-id] varname1 [varvalue1], varname2 [varvalue2] ....<br/>
        </div>
        <br/>
        Instruction <b>export-ep-variables</b> is used to copy a single or multiple variables from current endpoint to a different endpoint. 
        If you are in a session context and without changing session context need to copy variables to other endpoint, 
        then you can do so using export-ep-variables instruction.
        <br/><br/>
        Please note that you need to provide variable name and variable value pairs. You can refer above example.
        When this instruction gets executed, a new variables with name as <i>varname1</i> is added to other endpoint with
        value of current endpoint variable identified by <i>varvalue1</i> name.
        </li>

        <br/><li><b>set-context &lt;ep-id&gt; &lt;sessionid1&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        set-context [epid] [sessionid]<br/>
        </div>
        <br/>
        Instruction <b>set-context</b> is used jump to a new context identified by <i>epid</i> and <i>sessionid</i>. 
        In a typical B2BA SIP call if you get message from one endpoint, you need to send it to other endpoint. Just
        before sending to a other endpoint you need to set the other endpoint / session context using this instruction. 
        </li>
        
        <br/><li><b>session-init &lt;epid&gt; &lt;varname1&gt; &lt;varvalue1&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        session-init [epid] varname1 [varvalue1], varname2 [varvalue2], ....<br/>
        </div>
        <br/>
        Instruction <b>session-init</b> is used to create a new session and jumps to new session. In the process
        also it creates one or more variables in new session. To put it together <b>session-init</b> does three things together
        <ol>
        <li>Creates a new session</li>
        <li>Adds one or more variables in new session</li>
        <li>Jumps to new session</li>
        </ol>
        Typically in a B2BA SIP call, when INVITE is received from C1, need to create a new session
        towards C2. Please note that session with C1 gets created automatically as incoming INVITE from C1
        is processed.
        </li>
        
        
        <br/><li><b>session-deinit</b>
        <div className="code">
        <b>Example</b><br/>
        session-deinit<br/>
        </div>
        <br/>
        Delete current session using <b>session-deinit</b> instruction. In register-200, unregister-200, bye, by2-200 one must put session-deinit
        In any negative case, internally session is deleted.
        </li>
        
           
        </ul>
        
        <h3>Media Instructions</h3>
        <ul>

        <li><b>media-init</b>
        <div className="code">
        <b>Example</b><br/>
        media-init<br/>
        </div>
        <br/>Instruction <b>media-init</b> is used to initialize media session. It always creates audio
        session. If video is enabled, it will create video session as well.
        </li>

        <br/><li><b>media-ready timer &lt;timeout-value&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        media-ready timer 60000<br/>
        </div>
        <br/>Previously you have done media-init, with offer answer exchange and ICE connectivity 
        (wherever applicable), you have selected and set appropriate media transport details. 
        In a normal case, it will do everything required to start a media session. In case of 
        SRTP-DTLS call, it will do everything required to start a media session, plus DTLS 
        handshake. You need to mention timeout value as last parameters in millisecond. Within 
        that specified time if all media ready activities are not completed test case returns 
        fail.
        </li>

        <br/><li><b>media-sendrecv timer &lt;timeout-value&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        media-sendrecv timer 20000<br/>
        </div>
        <br/>Instruction <b>media-sendrecv</b> is used to exchange media between participants in a call.
        The timer value is given in milliseconds. Media exchange continues till timeout.
        </li>

        <br/><li><b>media-inactive</b>
        <div className="code">
        <b>Example</b><br/>
        media-inactive<br/>
        </div>
        <br/>Instruction <b>media-inactive</b> is used to set media mode to inactive. This suspends media
        TX and RX operations.
        </li>
        
        <br/><li><b>media-deinit</b>
        <div className="code">
        <b>Example</b><br/>
        media-deinit<br/>
        </div>
        <br/>Instruction <b>media-deinit</b> is used to de-initialise media sessions. By default
        it destroys audio session. If video session exists, it also destroys video session.
        </li>

        <br/><li><b>audio-init</b>
        <div className="code">
        <b>Example</b><br/>
        audio-init<br/>
        </div>
        <br/>Instruction <b>audio-init</b> is used to initialize audio session. It always creates audio
        session. If video is enabled, it will create video session as well.
        </li>

        <br/><li><b>audio-ready timer &lt;timeout-value&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        audio-ready timer 60000<br/>
        </div>
        <br/>Previously you have done audio-int, with offer answer exchange and ICE connectivity 
        (wherever applicable), you have selected and set appropriate audio transport details. 
        In a normal case, it will do everything required to start a audio session. In case of 
        SRTP-DTLS call, it will do everything required to start a audio session, plus DTLS 
        handshake. You need to mention timeout value as last parameters in millisecond. Within 
        that specified time if all audio ready activities are not completed test case returns 
        fail.
        </li>

        <br/><li><b>audio-sendrecv timer &lt;timeout-value&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        audio-sendrecv timer 20000<br/>
        </div>
        <br/>Instruction <b>audio-sendrecv</b> is used to exchange audio between participants in a call.
        The timer value is given in milliseconds. audio exchange continues till timeout.
        </li>

        <br/><li><b>audio-inactive</b>
        <div className="code">
        <b>Example</b><br/>
        audio-inactive<br/>
        </div>
        <br/>Instruction <b>audio-inactive</b> is used to set audio mode to inactive. This suspends audio
        TX and RX operations.
        </li>

        <br/><li><b>video-init</b>
        <div className="code">
        <b>Example</b><br/>
        video-init<br/>
        </div>
        <br/>Instruction <b>video-init</b> is used to initialize video session. It always creates video
        session. If video is enabled, it will create video session as well.
        </li>

        <br/><li><b>video-ready timer &lt;timeout-value&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        video-ready timer 60000<br/>
        </div>
        <br/>Previously you have done video-int, with offer answer exchange and ICE connectivity 
        (wherever applicable), you have selected and set appropriate video transport details. 
        In a normal case, it will do everything required to start a video session. In case of 
        SRTP-DTLS call, it will do everything required to start a video session, plus DTLS 
        handshake. You need to mention timeout value as last parameters in millisecond. Within 
        that specified time if all video ready activities are not completed test case returns 
        fail.
        </li>

        <br/><li><b>video-sendrecv timer &lt;timeout-value&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        video-sendrecv timer 20000<br/>
        </div>
        <br/>Instruction <b>video-sendrecv</b> is used to exchange video between participants in a call.
        The timer value is given in milliseconds. video exchange continues till timeout.
        </li>

        <br/><li><b>video-inactive</b>
        <div className="code">
        <b>Example</b><br/>
        video-inactive<br/>
        </div>
        <br/>Instruction <b>video-inactive</b> is used to set video mode to inactive. This suspends video
        TX and RX operations.
        </li>

        <br/><li><b>audio-link</b>
        <div className="code">
        <b>Example</b><br/>
        audio-link [epid] [sessionid]<br/>
        </div>
        <br/>Instruction <b>audio-link</b> is used to link the audio between two sessions, one session 
        is current session and the other session is identified by <i>epid</i> and <i>sessionid</i>. 
        </li>

        <br/><li><b>video-link</b>
        <div className="code">
        <b>Example</b><br/>
        video-link [epid] [sessionid]<br/>
        </div>
        <br/>Instruction <b>video-link</b> is used to link the video between two sessions, one session 
        is current session and the other session is identified by <i>epid</i> and <i>sessionid</i>. 
        </li>

        <br/><li><b>media-link</b>
        <div className="code">
        <b>Example</b><br/>
        media-link [epid] [sessionid]<br/>
        </div>
        <br/>Instruction <b>media-link</b> is used to link the media (both audio and video) between two sessions, 
        one session  is current session and the other session is identified by <i>epid</i> and <i>sessionid</i>. 
        </li>

        <br/><li><b>audio-unlink</b>
        <div className="code">
        <b>Example</b><br/>
        audio-unlink [epid] [sessionid]<br/>
        </div>
        <br/>Instruction <b>audio-unlink</b> is used to unlink the audio between two sessions, one session 
        is current session and the other session is identified by <i>epid</i> and <i>sessionid</i>. 
        </li>

        <br/><li><b>video-unlink</b>
        <div className="code">
        <b>Example</b><br/>
        video-unlink [epid] [sessionid]<br/>
        </div>
        <br/>Instruction <b>video-unlink</b> is used to unlink the video between two sessions, one session 
        is current session and the other session is identified by <i>epid</i> and <i>sessionid</i>. 
        </li>

        <br/><li><b>media-unlink</b>
        <div className="code">
        <b>Example</b><br/>
        media-unlink [epid] [sessionid]<br/>
        </div>
        <br/>Instruction <b>media-unlink</b> is used to unlink the media (both audio and video) between two sessions, 
        one session  is current session and the other session is identified by <i>epid</i> and <i>sessionid</i>. 
        </li>

        <br/>
        <br/><li><b>audio-rtp-header-extn &lt;id&gt; &lt;data&gt;</b>
        <br/>You can put rtp header extension data in audio rtp packets using this instruction.

        <div className="code">
        <b>Examples</b>
        <br/>audio-rtp-header-extn 1 [somedata]
        <br/>audio-rtp-header-extn 2 [someotherdata]
        </div>    
        </li>

        <br/><li><b>video-rtp-header-extn &lt;id&gt; &lt;data&gt;</b>
        <br/>You can put rtp header extension data in video rtp packets using this instruction.

        <div className="code">
        <b>Examples</b>
        <br/>video-rtp-header-extn 1 [somedata]
        <br/>video-rtp-header-extn 2 [someotherdata]
        </div>
        </li>
        
        <br/><li><b>set-audio-codec &lt;variable&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        set-audio-codec [audio-codec]<br/>
        </div>
        <br/>Instruction <b>set-audio-codec</b> is used to set audio codec name. During offer 
        answer exchange, using template / variable mechanism you can extract audio codec name 
        to a variable say audio-codec. Then use this instruction to set audio codec.
        </li>

        <br/><li><b>set-audio-codec-number &lt;variable&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        set-audio-codec-number [audio-codec-number]<br/>
        </div>
        <br/>Instruction <b>set-audio-codec-number</b> is used to set audio codec number. During offer 
        answer exchange, using template / variable mechanism you can extract audio codec number 
        to a variable say audio-codec-number. Then use this instruction to set audio codec number.
        </li>

        <br/><li><b>set-video-codec &lt;variable&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        set-video-codec [video-codec]<br/>
        </div>
        <br/>Instruction <b>set-video-codec</b> is used to set video codec name. During offer 
        answer exchange, using template / variable mechanism you can extract video codec name 
        to a variable say video-codec. Then use this instruction to set video codec.
        </li>

        <br/><li><b>set-video-codec-number &lt;variable&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        set-video-codec-number [video-codec-number]<br/>
        </div>
        <br/>Instruction <b>set-video-codec-number</b> is used to set video codec number. During offer 
        answer exchange, using template / variable mechanism you can extract video codec number 
        to a variable say video-codec-number. Then use this instruction to set video codec number.
        </li>
        
        
        <br/><li><b>set-dtmf-codec-number &lt;variable&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        set-dtmf-codec-number [dtmf-codec-number]<br/>
        </div>
        <br/>Instruction <b>set-dtmf-codec-number</b> is used to set dtmf codec number. During offer 
        answer exchange, using template / variable mechanism you can extract dtmf codec number 
        to a variable say dtmf-codec-number. Then use this instruction to set dtmf codec number.
        </li>

        <br/><li><b>set-remote-ip &lt;variable&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        set-remote-ip [remoteip]<br/>
        </div>
        <br/>Instruction <b>set-remote-ip</b> is used to set remote ip address. During offer 
        answer exchange, using template / variable mechanism you can extract remote ip address 
        to a variable say remoteip. Then use this instruction to set remote ip address.
        </li>

        <br/><li><b>set-remote-audio-ip &lt;variable&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        set-remote-audio-ip [remoteip]<br/>
        </div>
        <br/>Instruction <b>set-remote-audio-ip</b> is used to set remote ip address for audio channel. During offer 
        answer exchange, using template / variable mechanism you can extract remote audio ip address 
        to a variable say remoteip. Then use this instruction to set remote audio ip address.
        </li>

        <br/><li><b>set-remote-video-ip &lt;variable&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        set-remote-video-ip [remoteip]<br/>
        </div>
        <br/>Instruction <b>set-remote-video-ip</b> is used to set remote ip address for video channel. During offer 
        answer exchange, using template / variable mechanism you can extract remote video ip address 
        to a variable say remoteip. Then use this instruction to set remote video ip address.
        </li>


        <br/><li><b>set-remote-audio-rtp-port &lt;variable&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        set-remote-audio-rtp-port [remote-audio-port]<br/>
        </div>
        <br/>Instruction <b>set-remote-audio-rtp-port</b> is used to set remote audio rtp port. 
        During offer  answer exchange, using template / variable mechanism you can extract 
        remote audio rtp port to a variable say remote-audio-port. Then use this instruction to 
        set remote audio rtp port.
        </li>

        <br/><li><b>set-remote-video-rtp-port &lt;variable&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        set-remote-video-rtp-port [remote-video-port]<br/>
        </div>
        <br/>Instruction <b>set-remote-video-rtp-port</b> is used to set remote video rtp port. 
        During offer  answer exchange, using template / variable mechanism you can extract 
        remote video rtp port to a variable say remote-video-port. Then use this instruction to 
        set remote video rtp port.
        </li>

        </ul>

        <h3>ICE Instructions</h3>
        <ul>
        <li><b>set-turn-user &lt;variable&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        set-turn-user [user]<br/>
        </div>
        <br/>Instruction <b>set-turn-user</b> is used to set the turn user in current session.
        </li>
        
        <br/><li><b>set-turn-password &lt;variable&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        set-turn-password [password]<br/>
        </div>
        <br/>Instruction <b>set-turn-password</b> is used to set the turn user password in current session.
        </li>

        <br/><li><b>ice-agent-init timer &lt;timeout value&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        ice-agent-init timer 60000<br/>
        </div>
        <br/>This instruction <b>ice-agent-init</b> initialises ICE agent. You must mention 
        timeout value in milliseconds, within specified time if it can not initialise ICE 
        agent, it returns fail. As part of ICE agent initialisation, stun binding or turn 
        allocation are done.  Whether to use host / lite / stun / turn, it is already 
        mentioned in config-instance ice-mode configuration.    
        </li>

        <br/><li><b>media-ice-agent-init timer &lt;timeout value&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        media-ice-agent-init timer 60000<br/>
        </div>
        <br/>This instruction <b>media-ice-agent-init</b> initialises ICE agent. You must mention 
        timeout value in milliseconds, within specified time if it can not initialise ICE 
        agent, it returns fail. As part of ICE agent initialisation, stun binding or turn 
        allocation are done.  Whether to use host / lite / stun / turn, it is already 
        mentioned in config-instance ice-mode configuration.    

        <br/><br/>Please note that it is same as ice-agent-init. 
        </li>

        <br/><li><b>audio-ice-agent-init timer &lt;timeout value&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        audio-ice-agent-init timer 60000<br/>
        </div>
        <br/>This instruction <b>audio-ice-agent-init</b> initialises audio ICE agent. You must mention 
        timeout value in milliseconds, within specified time if it can not initialise ICE 
        agent, it returns fail. As part of ICE agent initialisation, stun binding or turn 
        allocation are done.  Whether to use host / lite / stun / turn, it is already 
        mentioned in config-instance ice-mode configuration.    
        </li>

        <br/><li><b>video-ice-agent-init timer &lt;timeout value&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        video-ice-agent-init timer 60000<br/>
        </div>
        <br/>This instruction <b>video-ice-agent-init</b> initialises video ICE agent. You must mention 
        timeout value in milliseconds, within specified time if it can not initialise ICE 
        agent, it returns fail. As part of ICE agent initialisation, stun binding or turn 
        allocation are done.  Whether to use host / lite / stun / turn, it is already 
        mentioned in config-instance ice-mode configuration.    
        </li>
        

        <br/><li><b>ice-agent-con-check timer &lt;timeout value&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        ice-agent-con-check timer 60000<br/>
        </div>
        <br/>The instruction <b>ice-agent-con-check</b> is used for ICE connectivity check. 
        After offer answer exchange over, both parties learnt others candidates, ice ufrag,
        ice password etc, ice connectivity is initiated using this instruction. As part of 
        ice connectivity check, internally ice candidate pairs are formed, ordered, certain 
        candidates are replaced with base candidates, pruned and then ice bind requests are 
        sent for all candidate pairs. The ice bind transaction may succeed for several pairs, 
        priority logic is applied and one pair is chosen as selected pair. Corresponding 
        transport path is chosen for media exchange.    
        </li>


        <br/><li><b>media-ice-agent-con-check timer &lt;timeout value&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        media-ice-agent-con-check timer 60000<br/>
        </div>
        <br/>The instruction <b>media-ice-agent-con-check</b> is used for ICE connectivity check. 
        After offer answer exchange over, both parties learnt others candidates, ice ufrag,
        ice password etc, ice connectivity is initiated using this instruction. As part of 
        ice connectivity check, internally ice candidate pairs are formed, ordered, certain 
        candidates are replaced with base candidates, pruned and then ice bind requests are 
        sent for all candidate pairs. The ice bind transaction may succeed for several pairs, 
        priority logic is applied and one pair is chosen as selected pair. Corresponding 
        transport path is chosen for media exchange.    

        <br/><br/>Please note that media-ice-agent-con-check and ice-agent-con-check are same.
        </li>

        <br/><li><b>audio-ice-agent-con-check timer &lt;timeout value&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        audio-ice-agent-con-check timer 60000<br/>
        </div>
        <br/>The instruction <b>audio-ice-agent-con-check</b> is used for ICE connectivity check for audio channel. 
        After offer answer exchange over, both parties learnt others candidates, ice ufrag,
        ice password etc, ice connectivity is initiated using this instruction. As part of 
        ice connectivity check, internally ice candidate pairs are formed, ordered, certain 
        candidates are replaced with base candidates, pruned and then ice bind requests are 
        sent for all candidate pairs. The ice bind transaction may succeed for several pairs, 
        priority logic is applied and one pair is chosen as selected pair. Corresponding 
        transport path is chosen for media exchange.    
        </li>

        <br/><li><b>video-ice-agent-con-check timer &lt;timeout value&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        video-ice-agent-con-check timer 60000<br/>
        </div>
        <br/>The instruction <b>video-ice-agent-con-check</b> is used for ICE connectivity check for video channel. 
        After offer answer exchange over, both parties learnt others candidates, ice ufrag,
        ice password etc, ice connectivity is initiated using this instruction. As part of 
        ice connectivity check, internally ice candidate pairs are formed, ordered, certain 
        candidates are replaced with base candidates, pruned and then ice bind requests are 
        sent for all candidate pairs. The ice bind transaction may succeed for several pairs, 
        priority logic is applied and one pair is chosen as selected pair. Corresponding 
        transport path is chosen for media exchange.    
        </li>

        <br/><li><b>set-remote-audio-rtp-ufrag &lt;variable&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        set-remote-audio-rtp-ufrag [ufrag]<br/>
        </div>
        <br/>During offer answer exchange, using template / variable mechanism you can extract ICE
        audio ufrag to a variable say ufrag. Then using this instruction set remote audio rtp ice 
        ufrag.
        </li>

        <br/><li><b>set-remote-audio-rtp-icepasswd &lt;variable&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        set-remote-audio-rtp-icepasswd [icepasswd]<br/>
        </div>
        <br/>During offer answer exchange, using template / variable mechanism you can extract ICE
        audio icepasswd to a variable say icepasswd. Then using this instruction set remote audio rtp ice 
        icepasswd.
        </li>

        <br/><li><b>set-remote-audio-rtp-candidate &lt;variable&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        set-remote-audio-rtp-candidate [candidate]<br/>
        </div>
        <br/>During offer answer exchange, using template / variable mechanism you can extract ICE
        audio candidate to a variable say candidate. Then using this instruction set remote audio rtp ice 
        candidate.
        </li>

        <br/><li><b>set-remote-audio-rtcp-ufrag &lt;variable&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        set-remote-audio-rtcp-ufrag [ufrag]<br/>
        </div>
        <br/>During offer answer exchange, using template / variable mechanism you can extract ICE
        audio ufrag to a variable say ufrag. Then using this instruction set remote audio rtcp ice 
        ufrag.
        </li>

        <br/><li><b>set-remote-audio-rtcp-icepasswd &lt;variable&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        set-remote-audio-rtcp-icepasswd [icepasswd]<br/>
        </div>
        <br/>During offer answer exchange, using template / variable mechanism you can extract ICE
        audio icepasswd to a variable say icepasswd. Then using this instruction set remote audio rtcp ice 
        icepasswd.
        </li>

        <br/><li><b>set-remote-audio-rtcp-candidate &lt;variable&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        set-remote-audio-rtcp-candidate [candidate]<br/>
        </div>
        <br/>During offer answer exchange, using template / variable mechanism you can extract ICE
        audio candidate to a variable say candidate. Then using this instruction set remote audio rtcp ice 
        candidate.
        </li>

        <br/><li><b>set-remote-video-rtp-ufrag &lt;variable&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        set-remote-video-rtp-ufrag [ufrag]<br/>
        </div>
        <br/>During offer answer exchange, using template / variable mechanism you can extract ICE
        video ufrag to a variable say ufrag. Then using this instruction set remote video rtp ice 
        ufrag.
        </li>

        <br/><li><b>set-remote-video-rtp-icepasswd &lt;variable&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        set-remote-video-rtp-icepasswd [icepasswd]<br/>
        </div>
        <br/>During offer answer exchange, using template / variable mechanism you can extract ICE
        video icepasswd to a variable say icepasswd. Then using this instruction set remote video rtp ice 
        icepasswd.
        </li>

        <br/><li><b>set-remote-video-rtp-candidate &lt;variable&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        set-remote-video-rtp-candidate [candidate]<br/>
        </div>
        <br/>During offer answer exchange, using template / variable mechanism you can extract ICE
        video candidate to a variable say candidate. Then using this instruction set remote video rtp ice 
        candidate.
        </li>


        <br/><li><b>set-remote-video-rtcp-ufrag &lt;variable&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        set-remote-video-rtcp-ufrag [ufrag]<br/>
        </div>
        <br/>During offer answer exchange, using template / variable mechanism you can extract ICE
        video ufrag to a variable say ufrag. Then using this instruction set remote video rtcp ice 
        ufrag.
        </li>

        <br/><li><b>set-remote-video-rtcp-icepasswd &lt;variable&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        set-remote-video-rtcp-icepasswd [icepasswd]<br/>
        </div>
        <br/>During offer answer exchange, using template / variable mechanism you can extract ICE
        video icepasswd to a variable say icepasswd. Then using this instruction set remote video rtcp ice 
        icepasswd.
        </li>

        <br/><li><b>set-remote-video-rtcp-candidate &lt;variable&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        set-remote-video-rtcp-candidate [candidate]<br/>
        </div>
        <br/>During offer answer exchange, using template / variable mechanism you can extract ICE
        video candidate to a variable say candidate. Then using this instruction set remote video rtcp ice 
        candidate.    
        </li>
        </ul>

        <h3>Send Instructions</h3>
        <ul>
        <li><b>send [t-template]</b>
        <div className="code">
        <b>Examples</b><br/>
        send [t-register]<br/>
        send [t-invite]<br/>
        send [t-bye]<br/>
        </div>
        <br/>Instruction <b>send</b> is used to send a message out. The template given in send instruction
        is formatted, and resultant data is sent to remote.
        </li>
        
        <br/><li><b>send-with-blen [t-template]</b>
        <div className="code">
        <b>Examples</b>
        <br/>send-with-blen [t-invite]
        <br/>send-with-blen [t-invite-200-ok]
        </div>
        <br/>Instruction <b>send-with-blen</b> sends the messages (formatted as per template). This is very similar to 
        <b>send</b> instruction, however it sends content-length header with proper length value. In the template
        make sure, header Content-length is present with empty value.
        </li>

        <br/><li><b>send-with-brange [t-template]</b>
        <div className="code">
        <b>Examples</b>
        <br/>send-with-brange [t-req-template]
        <br/>send-with-brange [t-res-template]
        </div>
        <br/>Instruction <b>send-with-brange</b> sends the messages (formatted as per template). This is very similar to 
        <b>send</b> instruction, however it sends byte-rage header with proper length value. In the template
        make sure, header Byte-Range is present with empty value.
        </li>

        <br/><li><b>send-over-audio-rtp-channel [t-template]</b>
        <div className="code">
        <b>Examples</b>
        <br/>send-over-audio-rtp-channel [t-req-template]
        <br/>send-over-audio-rtp-channel [t-req-template]
        </div>
        <br/>Instruction <b>send-over-audio-rtp-channel</b> sends the messages (formatted as per template). 
        This is very similar to <b>send</b> instruction, however it sends the formatted message over audio 
        channel.
        </li>

        <br/><li><b>send-data [t-template]</b>
        <div className="code">
        <b>Examples</b>
        <br/>send-data [t-req-template]
        <br/>send-data [t-req-template] myconn
        </div>
        <br/>Instruction <b>send-data</b> sends the messages (formatted as per template). 
        This is very similar to <b>send</b> instruction, however it sends the non-standard 
        formatted message over session connection or connection identified by name myconn 
        as shown in example.
        </li>
        </ul>

        <h3>Receive Instructions</h3>
        <ul>
        <li><b>receive [t-template] timer &lt;timeout-value&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        receive [t-register] timer 60000<br/>
        receive [t-invite] timer 60000<br/>
        </div>
        <br/>Instruction <b>receive</b> is used to check if a message is received within specified time. 
        Please note that, received message is not processed in context of receive instruction. This
        instruction is basically to flag the error in case message is not received in given time.
        </li>
        </ul>					


        <h3>File Graphs</h3>
        LM Tools supports file based graphs. Required data, configurations etc will be in files and
        graph is drawn using these data. Database is avoided and most of the gaph draw work is done
        using javascript to make it faster.
        
        Say for example, user wants to draw graph SIP sent invites and received invites. Below instruction
        set exactly does this.

        <div className="code">  
        graph sip1 create sipinvites<br/>
        graph sip1 sipinvites xaxis elements time<br/>
        graph sip1 sipinvites yaxis elements txinvite rxinvite<br/>
        graph sip1 sipinvites yaxis type distinct<br/>
        graph sip1 sipinvites draw type bar<br/>
        graph sip1 sipinvites start<br/>
        </div>
        <br/>
        Lets analyze this example in detail.
        
        <br/><br/><b>graph sip1 create sipinvites</b><br/>
        Here <i>graph</i> is a keyword, <i>sip1</i> is the name of b2ba entity, <i>create</i> is a keyword,
        <i>sipinvites</i> is the graph name. For graph name you can put anything, but space not allowed. Here
        user is creating a graph by name sipinvites.


        <br/><br/><b>graph sip1 sipinvites xaxis elements time</b><br/>
        Here <i>xaxis</i>, <i>elements</i> and <i>time</i> are keywords. Here time is configured for xaxis.

        <br/><br/><b>graph sip1 sipinvites yaxis elements txinvite rxinvite</b><br/>
        Here <i>yaxis</i>, <i>txinvite</i> and <i>rxinvite</i> are keywords. Here txinvite and 
        rxinvite are configured for yaxis. In yaxis, user can configure many items, all possible items
        are listed below.

        <br/><br/><b>graph sip1 sipinvites yaxis type distinct</b><br/>
        Here <i>type</i> and <i>distinct</i> are keywords. Yaxis scale configured distinct for each yaxis item.

        <br/><br/><b>graph sip1 sipinvites draw type line</b><br/>
        Here <i>draw</i> and <i>line</i> are keywords. Draw type is configured as line for each yaxis item.
        Various draw types are
        <ul>
        <li>line</li>
        <li>bar</li>
        <li>pie</li>
        <li>donut</li>
        <li>eclipse</li>
        <li>rectangle</li>
        </ul>

        <b>graph sip1 sipinvites start</b><br/>
        Here <i>start</i> is a keyword. Now the graph is started, and from now on, graph data is captured and communicated to draw engine.
        
        B2BA SIP has many available yaxis graph items as listed in below table.<br/>
        
        {ugc_file_graphs_sip()}
        
        
        <br/>Media related file graph items listed below.<br/>
        
        {ugc_file_graphs_media()}

        <br/>
        
        <h3>Miscellaneous Instructions</h3>
        <ul>

        <li><b>log &lt;string&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        log This is LM Tools<br/>
        log Value of variable string is [string]<br/>
        log Function add(v1, v2) output is [f-add(int1, int2)]<br/>
        log Value [p-random-number] is a digit only string, and [p-random-hex-number:20] is a hexadecimal string of 20 bytes<br/>
        </div>
        <br/>Instruction <b>log</b> is used to log certain data to a log file using this instruction. Just after <i>log</i> you 
        can put constant string, variable, plugin variable or function. During run time multiplier evaluates the entire 
        expression and log it to corresponding log file.
        </li>
        
        <br/><li><b>wait &lt;timeout&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        wait &lt;timeout&gt;<br/>
        wait 5000<br/>
        </div>
        <br/>Instruction <b>wait</b> is used to wait for certain duration asynchronously. The timeout 
        value given is in milliseconds.
        </li>

        <br/><li><b>increment &lt;variable&gt; &lt;value&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        increment cseq 1<br/>
        increment count 5<br/>
        </div>
        <br/>Instruction <b>increment</b> is used to increase a variable value. As you seen in example
        variables are given without square brackets.
        </li>

        <br/><li><b>decrement &lt;variable&gt; &lt;value&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        decrement value 1<br/>
        decrement count 5<br/>
        </div>
        <br/>Instruction <b>decrement</b> is used to decrease a variable value. As you seen in example
        variables are given without square brackets.
        </li>

        <br/><li><b>set variable &lt;value&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        set variable &lt;value&gt;<br/>
        set variable 10<br/>
        </div>
        <br/>Instruction <b>set</b> is used to set value for a variable. As you seen in example
        variables are given without square brackets. Variables added to system using set command
        will be available across sessions.
        </li>

        <br/><li><b>execute [f-luafunction(a, b, c)]</b>
        <div className="code">
        <b>Examples</b>
        <br/>execute [f-luafunction(a, b, c)]
        </div>
        <br/>Instruction <b>execute</b> is used to execute a lua function. You can pass the arguments to
        lua function. You can do all sort of logical executions like determining a random value,
        writing to a file, reading from file, evealuate a complex expression and return a value
        (to be used in templates or otherwise) anything that lua supports.
        
        <br/><br/>
        
        Additionally, you can achieve a logical if/else kind of functionality. Like on certain condition  
        execute IB1, on other condition execute IB2 so on.
        
        <br/><br/>
        
        You can return an instruction block name to execute the same, with 
        <b>executeib</b> as last return value as shown in below example. 
        
        <div className="code">
        function luafunction(a)<br/>
        <tab2></tab2>if(a==0)<br/>
        <tab2></tab2>{lcurly}<br/>
        <tab4 />return "ib-get", "executeib"
        <br/><tab2 />}<br/>
        <tab2 />elseif(a==1)<br/>
        <tab2 />{lcurly}<br/>
        <tab4 />return "ib-post", "executeib"<br/>
        <tab2 />{rcurly}<br/>
        <tab2 />elseif(a==2)<br/>
        <tab2 />{lcurly}<br/>
        <tab4 />return "ib-put", "executeib"<br/>
        <tab2 />{rcurly}<br/>
        <tab2 />elseif(a==3)<br/>
        <tab2 />{lcurly}<br/>
        <tab4 />return "ib-delete", "executeib"<br/>
        <tab2 />{rcurly}<br/>
        <tab2 />else<br/>
        <tab2 />{lcurly}<br/>
        <tab4 />return "ib-invalid", "executeib"<br/>
        <tab2 />}<br/>
        end<br/>
        </div>

        </li>
        </ul>
                
            
        <br/>
        
        <div className="inlinecenter">
          <button onClick={() => window.location.href="/userguide/sipb2basyntax" }>Back</button> 
          <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
          <button onClick={() => window.location.href="/userguide/httpb2bainstructions" }>Next</button>
        </div>
      </p>
    </div>
  );
  
  return element;
}

export {UGSIPB2BAInstructions}

