
import React, {useState} from 'react';
import axios from 'axios';

import {setPageTitle, handleErrorResponse} from './lm.js'

const ChangePasswd = () => {

    var user = null;
    setPageTitle('Change password');
    const [status, setStatus] = useState(null);
    
    const handleSubmit =
    async (currpasswd, newpasswd, cnfpasswd) => {
        
        if(newpasswd !== cnfpasswd) {
            setStatus({
                type: 'error',
                message: `New / confirm password mismatch`,
            });                    
        } else {
            setStatus(null);
            try {
                const res = await axios.post('/api/exec/user/changepassword',
                {uid: user.uid, email: user.email, currpasswd, newpasswd},
                {headers: {
                    'Authorization': user.jwt,
                }}).then (function (res) {
                   
                    console.log(res);
                    
                    if(res.status === 200) {
                        setStatus({
                            type: 'success',
                            message: `Password changed for ${user.name} successfully`,
                        });                    
                    } else {
                        setStatus({
                            type: 'error',
                            message: res.body.message,
                        });                    
                    }
                });           
                
            } catch(err) {
                                
                setStatus({
                    type: 'error',
                    message: err.response.data.message,
                });    
                handleErrorResponse(err);
            }
        }
    }    
    
    const userData = localStorage.getItem('user');
    if(userData === undefined || userData === null) {
        window.location.href = "/authrequired";
    } else {
        user = JSON.parse(userData);
        
        let out =  
        <form onSubmit={(event) => {
            event.preventDefault();
            const [currpasswd, newpasswd, cnfpasswd] = event.target.elements;
            handleSubmit(currpasswd.value, newpasswd.value, cnfpasswd.value); }}>
        
            <div className="medium-text">
                <div className="center">
                    <h2>Change Password</h2>
                    <p>
                        Enter current and new passwords and submit to change the password.<br/><br/>
                    </p>
                    <table id="">
                        <tbody>
                            <tr key="1">
                                <td>
                                    <b>Current password</b><br/>
                                    <input required
                                        type="password"
                                        name = "currpasswd"
                                        id = "currpasswd"
                                        autoComplete = "on"
                                        maxLength = {15}
                                    />
                                </td>
                            </tr>

                            <tr key="2">
                                <td>
                                    <b>New password</b><br/>
                                    <input required
                                        type="password"
                                        name = "newpasswd"
                                        id = "newpasswd"
                                        autoComplete = "on"
                                        maxLength = {15}
                                    />
                                </td>
                            </tr>

                            <tr key="3">
                                <td>
                                    <b>Confirm password</b><br/>
                                    <input required
                                        type="password"
                                        name = "cnfpasswd"
                                        id = "cnfpasswd"
                                        autoComplete = "on"
                                        maxLength = {15}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <br/>
                    <div className="">
                        <button type="submit">Submit</button>
                    </div>

                    <br/>
                    {status && <div className={`alert ${status.type}`}>{status.message}</div>}
        
                </div>
            </div>
        </form>;
        
        return out;
    }    
};

export default ChangePasswd;
