
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGTemplate = () => {

  setPageTitle('Template');
  
  const element = (
    <div className="userguide">
      <h1>LM Tools - Template</h1>
      <br/>
      Template is a message format used to define a message with fixed and variable portions.
      In TX path, before a message is sent, a template is evaluated (variable portions of template is filled in with 
      actual values). 
      <br/><br/>
      Similarly in RX path, when a message is received, if receive syntax specifies a template to be matched,
      then if fixed portions match properly, the variable present in template is set to the received value 
      for the given variable portion in received message.
      
      LM Tools supports two types of templates, text template and binary template. Lets first explore the text template.
      
      <br/><br/>
      <div id="texttemplates">
      <h3>Text templates</h3>
      </div>
      The text template is an important core component in LM Tools environment. 
      As the name suggests it is just a template. The kind of message you want to 
      send or receive will be input to prepare a template. Please note that 
      templates can be provided in three different ways in multiplier.
      
      <ol>
        <li><b>Direct</b> - You can write template directly in test suite as a test case / block.
        Not preferred way as it increases number of test cases unnecessarily and same template 
        user need to write everytime a test suite is written, thereby increasing user effort and 
        its time consuming.
        </li>
        
        <li><b>File system</b> - Here the templates are written as plain text files, and all 
        files are stored in a single folder. Load the folder to LM Tools runtime context using 
        instruction <i>set-text-template /path/to/folder</i>. This is very efficient, the only issue here is 
        how to put or delete CRLF if required, at the line end as these are not visible. 
        You can make use of <i>dos2unix</i> or <i>unix2dos</i> command to work with CRLF.
        Sometimes you need to remove CRLF at the end of file. If this looks to be complex, better go for 
        template interface.
        </li>
        
        <li><b>Template interface</b> - This is the most efficient and easy way to write the template.
        Look at the images below.
        <br/><img className="" src={process.env.PUBLIC_URL + '/userguide/template-head-ui.png'} alt="Template header" width='100%'/>
        <br/><img className="" src={process.env.PUBLIC_URL + '/userguide/template-messages-ui.png'} alt="Template body" width='100%'/>
        <br/><br/>
        As you can see in messages, at the line end \r and \n are written explicitly, when 
        the template is evaluated, \r nad \n is changed to CR and LF. Please note if \r and \n is 
        present in the middle of line, these are not converted to CR and LF, rather it is evaluated 
        as \ r \ n (4 different characters).
        </li>
        
      </ol>
      <br/>
      So comparing all three options above, the best way to write template is #3 option.
      In future versions of LM Tools, the option #1 and #2 may be deprecated. Now lets 
      explore the binary templates. Please note lines starting with '#' is a comment only, it 
      does not affect the text or binary templates.

      <br/><br/>
      <div id="binarytemplates">
      <h3>Binary templates</h3>
      </div>
      A binary template represents format or structure of a binary message. It is 
      fundamentally different from text template. It deals with bits / bytes, padding, 
      putting length at a specific offset etc. Again you have big-endian / 
      little-endian for byte order. In case of text templates, it is a continuous 
      message buffer containing variables, functions, other templates etc. In case of 
      binary template, it is not continuous, it is a fine combination of template 
      format and limited instruction sets combined to prepare a binary message. 
      When you come across a case, where the message contains both binary portion as 
      well as text portion, then go for binary templates, as binary templates can 
      contain text templates as well. Text templates can contain nested text templates, 
      however binary templates are designed to contain both text and binary nested 
      templates.
      <br/><br/>
      Binary templates contains nested text / binary templates, certain numbers in 
      certain number of bytes, strings at certain offsets. In current message buffer 
      you can move forward / backward using advance / back syntax. You can mark a 
      certain offset in buffer using mark syntax, and later you can place a 
      number/string at marked offset. You can make use of variables, functions in a 
      binary template. Additionally you can do some checks / asserts in template 
      itself using verify syntax.
      <br/><br/>
      <h3>Binary Template Examples</h3>
      Below two messages are DNS messages (text + binary messages) shown here for 
      your reference. Using these messages you can simulate DNS NAPTR transactions.
      <br/><br/>

      <b>DNS NAPTR query request template</b>
      <div className="code">

        #DNS NAPTR query
        <br/>#Advance 2 bytes, later we will put length. Required only for tcp case.
        <br/>advance 2 bytes
        <br/>
        <br/>#Put transid variable in 2 byte
        <br/>[transid] in 2 byte
        <br/>
        <br/>#Put flag 0x0120 in 2 byte
        <br/>288 in 2 byte be
        <br/>
        <br/>#Put number of questions in 2 byte
        <br/>1 in 2 byte be
        <br/>
        <br/>#Put Answer RRs in 2 byte
        <br/>0 in 2 byte be
        <br/>
        <br/>#Put Authority RRs in 2 byte
        <br/>0 in 2 byte be
        <br/>
        <br/>#Put Additional RRs in 2 byte
        <br/>1 in 2 byte be
        <br/>
        <br/>#Put name
        <br/>user1 = [p-user]
        <br/>amdn = [f-alter_mdn(user1)]
        <br/>[amdn] in 22 byte
        <br/>
        <br/>#Put first part of domain
        <br/>[l-domain1] in 1 byte
        <br/>[domain1] in [l-domain1] byte
        <br/>
        <br/>#Put second part of domain
        <br/>[l-domain2] in 1 byte
        <br/>[domain2] in [l-domain2] byte
        <br/>
        <br/>#Put third part of domain
        <br/>[l-domain3] in 1 byte
        <br/>[domain3] in [l-domain3] byte
        <br/>
        <br/>#Null terminate
        <br/>0 in 1 byte
        <br/>
        <br/>#Put type
        <br/>35 in 2 byte be
        <br/>
        <br/>#Put class
        <br/>1 in 2 byte be
        <br/>
        <br/>#Now start additional records
        <br/>#root
        <br/>0 in 1 byte be
        <br/>
        <br/>#type OPT
        <br/>41 in 2 byte be
        <br/>
        <br/>#Udp payload size
        <br/>4096 in 2 byte be
        <br/>
        <br/>#Higher bits
        <br/>0 in 1 byte
        <br/>
        <br/>#EDNS0 version
        <br/>0 in 1 byte
        <br/>
        <br/>#Z and data length
        <br/>0 in 4 byte be
        <br/>
        <br/>#Below 3 lines required for tcp only
        <br/>save-length buf_len
        <br/>tlength = [buf_len] - 2
        <br/>[tlength] in 2 bytes at 0 be
        <br/>
      
      </div>
      <br/>
      <b>DNS NAPTR query response template</b>
      <div className="code">
        number [fb] out 1 byte
        <br/>number [sb] out 1 byte
        <br/>advance [sb] byte      
      </div>
      <br/>
      <h3>Binary Templates - Generic Examples </h3>
      This section contains certain examples, just to show possible syntax and 
      techniques. Do not worry in next page all possible syntax and techniques are 
      well documented. 

      <br/><br/>
      <b>Tx Message</b>
      <br/>
      <div className="code">
        #message
        <br/>128 in 1 byte
        <br/>204 in 1 byte
        <br/>mark offset len_offset
        <br/>advance 2 bytes
        <br/>[p-local-audio-ssrc:my_session_id_1] in 4 byte be
        <br/>"Mult" in 4 byte
        <br/>#request encoding
        <br/>102 in 1 byte
        <br/>2 in 1 byte
        <br/>0 in 2 bytes
        <br/>103 in 1 byte
        <br/>8 in 1 byte
        <br/>0 in 1 byte
        <br/>
        <br/>#Add padding
        <br/>save-length buf_len
        <br/>length = [l-sess_id1] + [l-mdn]
        <br/>length = [length] + 2tlength = [length] + [buf_len]pad 0 [tlength] 4
        <br/>
        <br/>#Continue request message
        <br/>[sess_id1] in [l-sess_id1] bytes
        <br/>[l-sess_id1] in 1 byte
        <br/>[mdn] in [l-mdn] bytes
        <br/>[l-mdn] in 1 byte
        <br/>
        <br/>#Add length
        <br/>save-length buf_len
        <br/>tlength = [buf_len] - 4
        <br/>tlength = [tlength] / 4
        <br/>[tlength] in 2 bytes at [len_offset] be
        <br/>      
      </div>

      <br/>
      <b>Rx Message</b>
      <br/>
      <div className="code">
        # message
        <br/>#advance 2 bytes for versio/padding etc, 2 byte for length
        <br/>number [msg_type] out 1 byte
        <br/>verify [msg_type] 129
        <br/>advance 1 bytes
        <br/>number [rlen] out 2 bytes be
        <br/>rlen = [rlen] + 1
        <br/>rlen = [rlen] * 4
        <br/>
        <br/>#Get ssrc value
        <br/>number [rssrc] out 4 bytes le
        <br/>
        <br/>#Get Mult value
        <br/>string [rkey] out 4 bytes le
        <br/>
        <br/># advance
        <br/>advance 8 bytes
        <br/>
        <br/>#Extract mdn length and mdn value
        <br/>mark offset parse_offset
        <br/>dlength = [rlen] - [parse_offset]
        <br/>dlength = [dlength] - 1
        <br/>advance [dlength] bytes
        <br/>number [rmdn_len] out 1 bytes le
        <br/>back 1 bytes
        <br/>back [rmdn_len] bytes
        <br/>string [rmdn] out [rmdn_len] bytes
        <br/>
        <br/>#Extract session id length and session id value
        <br/>back [rmdn_len] bytes
        <br/>back 1 bytes
        <br/>number [rsessid_len] out 1 bytes le
        <br/>back 1 bytes
        <br/>back [rsessid_len] bytes
        <br/>string [rsessionid] out [rsessid_len] bytes
        <br/>advance 1 bytes
        <br/>advance [rmdn_len] bytes
        <br/>advance 1 bytes      
      </div>
      <br/>
      File binary templates are loaded using instruction <i>set-binary-templates</i>, 
      whereas web binary templates are loaded using <i>set-default-binary-templates</i> 
      instruction. It is recommended to use web based binary templates. 

      <br/><br/>
      Now that you have seen couple of examples, and all these are normative in nature, 
      lets see in below section the supported syntax and techniques in binary templates. 
      As you can see it is just about placing the bytes, advance, back, mark, length 
      variable, arithmetic (like +, -) are the basic primitives used in binary templates. 
      All these are explained below.

      <br/><br/>
      <h3>Binary Syntax & Examples </h3>
      Best way to explain binary template syntax is to go with examples. Here each example 
      explains a particular syntax.
      <br/><br/><ol>
        <li><b>data in n byte</b> - This syntax is used to place <b>data</b> in <b>n</b> 
        number of bytes in a binary message starting from current offset. Data could be an 
        integer, string, a variable, a function etc and N is number of bytes it should use 
        to place the data. If you want to place bigger data in a smaller size, behavior is 
        undefined.
        
          <div className="code">
            <b>Examples</b>
            <br/>#Put 1 in 2 bytes in big endian format
            <br/>1 in 2 byte be
            <br/>#Put 1 in 2 bytes in little endian format
            <br/>1 in 2 byte le
            <br/>
            <br/>#Put 1 in 2 bytes in little endian (default little endian)
            <br/>1 in 2 byte
            <br/>
            <br/>#Put a variable in 2 bytes in little endian
            <br/>[var] in 2 byte
            <br/>
            <br/>#Put a hard coded string in 6 byte
            <br/>"String" in 6 byte
            <br/>
            <br/>#Put string variable mdn in length of mdn bytes
            <br/>[mdn] in [l-mdn] byte
            <br/>
            <br/>#Put length of mdn in 1 byte
            <br/>[l-mdn] in 1 byte        
          </div>
        </li>
        <br/>
        <li><b>data in n byte at index</b> - This syntax is used to place <b>data</b> 
          in <b>n</b> number of bytes in a binary message at starting offset index. Typically 
          integer data is put using this syntax (putting the length at marked offset).

          <div className="code">
            <b>Examples</b>
            <br/>#Put length variable in 2 bytes at a give offset as per big endian notion.
            <br/>[length] in 2 byte at [offset] be            
          </div>
        </li>


        <br/>
        <li><b>advance n byte</b> - Advance <b>n</b> bytes from current position.

          <div className="code">
            <b>Examples</b>
            <br/>#Advance by 2 byte from current position
            <br/>advance 2 byte
            <br/>#Advance by [var] byte from current position, where var is a variable
            <br/>advance [var] byte
          </div>
        </li>


        <br/>
        <li><b>back n byte</b> - Back <b>n</b> bytes from current position.

          <div className="code">
            <b>Examples</b>
            <br/>#back by 2 byte from current position
            <br/>back 2 byte
            <br/>#back by [var] byte from current position, where var is a variable
            <br/>back [var] byte
            <br/>
          </div>
        </li>


        <br/>
        <li><b>mark offset identifier</b> - Marks the current cursor position with 
        given identifier. Later you can make use of identifier either to put length 
        or update with any other value in binary message using <b>at offset</b> syntax, 
        see below.

          <div className="code">
            <b>Examples</b>
            <br/>#Marks current position with length_offset identifier
            <br/>mark offset length_offset
            <br/>#Later you can use it, for example to put length variable at length_offset
            <br/>[length] in 2 bytes at [length_offset]
          </div>
        </li>


        <br/>
        <li><b>pad value length word</b> - Pad the buffer starting from cursor 
        position with value. How many bytes to be padded is calculated from length 
        and word like bytes to be padded = length % word.

          <div className="code">
            <b>Examples</b>
            <br/>#Pad the binary message from current cursor position with value 0 for [length] % 8 bytes.
            <br/>pad 0 [length] 8
          </div>
        </li>


        <br/>
        <li><b>Mathematical expressions</b> - You can have simple mathematical 
        expressions like assigment, addition, subtraction, multiplication etc in 
        a binary template.

          <div className="code">
            <b>Examples</b>
            <br/>#Simple mathematical assignments and expressions
            <br/>var1 = [var2]
            <br/>var1 = [var2] + [var3]
            <br/>var1 = [var2] - [var3]
            <br/>var1 = [var2] * [var3]
            <br/>var1 = [var2] / [var3]
            <br/>var1 = [var2] % [var3]
          </div>
          <br/>
          You can see in above examples variable var1 is assigned with var2 or 
          var2 + var3 etc. This is required as in binary message sometimes you 
          need to compute certain expressions and place it in binary message.
        </li>


        <br/>
        <li><b>number [var1] out n byte</b> - From received binary message, at 
        times you need to extract an integer for verification or for future use. 
        This is done using out mechanism. Please refer example below.

          <div className="code">
            <b>Examples</b>
            <br/>#Extract an integer from binary message
            <br/>number [var1] out 4 bytes le
          </div>
        </li>



        <br/>
        <li><b>string [var1] out n byte</b> - From received binary message, at 
        times you need to extract a string for future use. This done using out 
        mechanism. Please refer example below.

          <div className="code">
            <b>Examples</b>
            <br/>#Extract a string from binary message
            <br/>string [var1] out 10 bytes le
          </div>
        </li>



        <br/>
        <li><b>verify [var1] [var2]</b> - Verifies if both var1 and next value are equal or not.

          <div className="code">
            <b>Examples</b>
            <br/>#Verify is var1 and 10 are same or not
            <br/>verify [var1] 10
          </div>
        </li>

        These are the supported syntax and techniques to work with binary templates. 
        Out of box scenarios you can test using binary templates. For example with 
        binary template, you can simulate binary message call flows, text+binary 
        message call flows. Its so efficient you can easily scale more than 100,000 
        endpoints in normal desktop environment.
        
      </ol>
      <br/>
      <h3>Notes on Receive Templates</h3>
      When you are writing templates for received message, you need to be careful. 
      In receive template case, message is not prepared, rather message is 
      available, fixed portions are compared, variables portions are extracted. 
      You should use only normal variables in template. You should not use any special 
      variables like plugin variable (starts with [p-), other party variable (starts 
      with [o-), length variable (starts with [l-) etc. Also you should not use functions 
      (starts with [f-) and templates (starts with [t-). So user is allowed to use only 
      normal variable in receive templates. In some cases, you would like a wild card 
      just to move past certain portion of received data. For wild card, in receive 
      templates you need to use [...]. Now lets take an example to get more clarity on 
      receive templates.
      <br/><br/>
      Lets say you want to write a template for HTTP response 200 OK, and you want to 
      extract Etag header value from HTTP response. The template looks like as below.
      <br/><br/>
      
      <div className="code">
        HTTP/1.1 200 OK[...]<br/>
        Etag: [etag]<br/>
        [...] 
      </div>

      <br/>
      Here the Etag header value is extracted to a variable with name as <i>etag</i>.
      <br/>
      <br/>
      
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/testsuite"}>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/scenario"}>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGTemplate}
