import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import { BrowserRouter } from 'react-router-dom';

// styles
import './App.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  //Kamal: Commenting StrictMode helps to avoid 2 times execution of code

  //<React.StrictMode> 
    
    <BrowserRouter basename="">
      <App />
    </BrowserRouter>
    
  //</React.StrictMode>

);
