
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGVirtualIPConfig = () => {

  setPageTitle('Virtual IP Configuration');
  
  const element = (
    <div className="userguide">
      <h1>Virtual IP Configuration</h1>
      
      <br/>
      Its a common practice to run load testing tools with virtual IP addresses. With one IP address you can make use of maximum 65535 ports. Assuming each endpoint takes 4 ports, maximum you can have around 16383 endpoints. To avoid this limitation it is advised to make use of virtual IP addresses.

      <br/><br/>You can manually configure virtual IP addresses and run Multiplier.

      <br/><br/>In Ubuntu below command is used to configure virtual IP address.
      <div className="code">ifconfig eth0:0 192.168.1.5</div>
      <br/>
      To disable virtual ip, you can use below command.
      <div className="code">ifconfig eth0:0 down</div>
      <br/>
      But it is really cumbersome to do that. What IP addresses you configure manually, what IP addresses Multiplier uses during execcution should be same. Else Multiplier endpoint initialization may fail. 

      <br/><br/>So it is strongly recommended to make use of <b>ipgenerator</b> executable, thats a part of release starting from Multiplier 2.0.

      <br/><br/><b>ipgenerator</b> helps to generate two script files <b>upif.sh</b> and <b>downif.sh</b> that helps user to configure right virtual IP addresses.

      <br/><br/>Please see example towards end.

      <h3>How to Configure Virtual IP in Multiplier</h3>
      You can make use of attribute <b>ip-granularity</b> to control how Multiplier uses IP address(s) of system.
      <br/><b>ip-granularity</b> has 3 possible values
      <ul>
      <li>none
      <br/>If you configure <b>ip-granularity</b> as <b>none</b>, whatever IP is mentioned as local ip address in <b>create-instance</b> command, same IP address is used for all endpoints across all siblings.
      <br/></li>
      <li>siblings
      <br/>If you configure <b>ip-granularity</b> as <b>siblings</b>, all endpoints under one sibling/process will use one virtual Ip address.
      </li>
      <li>endpoints
      <br/>If you configure <b>ip-granularity</b> as <b>endpoints</b>, all endpoints across all siblings will use unique IP address.
      </li></ul>

      So while executing <b>ipgenerator</b> accordingly input ip-granularity.

      <h3>Example</h3>
      Lets execute <b>ipgenerator</b> and first generate two scripts that helps to configure and disable virtual IP addresses.
      <br/>Lets try 4 siblings and total 8 endpoints. Less number of endpoints is used so that output script files can be shown here.

      <br/><br/><b>ipgenerator</b> execution instructions can be followed from below example.
      <br/><img className="borderimg" src={process.env.PUBLIC_URL + '/userguide/ipgen-exec-ui.png'} alt="ipgen" width='100%'/>
      <br/>

      <b>ipgenerator</b> execution output are upif.sh and downif.sh script files. 
      <br/><br/>The upif.sh file is shown as below.
      <div className="code">
      ifconfig eth0:0 192.168.1.6
      <br/>ifconfig eth0:1 192.168.1.7
      <br/>ifconfig eth0:2 192.168.1.8
      <br/>ifconfig eth0:3 192.168.1.9
      <br/>ifconfig eth0:4 192.168.1.10
      <br/>ifconfig eth0:5 192.168.1.11
      <br/>ifconfig eth0:6 192.168.1.12
      <br/>ifconfig eth0:7 192.168.1.13
      <br/>ifconfig eth0:8 192.168.1.14
      <br/>ifconfig eth0:9 192.168.1.15
      <br/>ifconfig eth0:10 192.168.1.16
      <br/>ifconfig eth0:11 192.168.1.17
      <br/>ifconfig eth0:12 192.168.1.18
      <br/>ifconfig eth0:13 192.168.1.19
      <br/>ifconfig eth0:14 192.168.1.20
      <br/>ifconfig eth0:15 192.168.1.21
      </div>

      <br/><br/>The downif.sh file is shown as below.
      <div className="code">
      ifconfig eth0:0 down
      <br/>ifconfig eth0:1 down
      <br/>ifconfig eth0:2 down
      <br/>ifconfig eth0:3 down
      <br/>ifconfig eth0:4 down
      <br/>ifconfig eth0:5 down
      <br/>ifconfig eth0:6 down
      <br/>ifconfig eth0:7 down
      <br/>ifconfig eth0:8 down
      <br/>ifconfig eth0:9 down
      <br/>ifconfig eth0:10 down
      <br/>ifconfig eth0:11 down
      <br/>ifconfig eth0:12 down
      <br/>ifconfig eth0:13 down
      <br/>ifconfig eth0:14 down
      <br/>ifconfig eth0:15 down
      </div>
      <br/>You can generate as many as virtual ip addresses using ipgenerator. Connectivity between multiplier and these new IP must be there. For simplicity, example with less number of endpoints is given.

      <br/><br/>Please note that in <b>ipgenerator</b> execution, it asks for base ip address. You need to input local-ip-address (as configured in create-instance command), this makes sure synchronization between output script and test platform is maintained. 
      <br/><br/>
      <h3>Summary</h3>
      Overall please follow below steps when you want to make use of virtual IP addresses.
      <ul>
      <li>Note down local ip address configured from craete-instance command.</li>
      <li>Note down ip-granularity from craete-instance command.</li>
      <li>Note down threads/siblings, endpoints configured from test bed.</li>
      <li>Now execute <b>ipgenerator</b>, provide all inputs accurately as noted in above steps. 
      It generates <b>upif.sh</b> and <b>downif.sh</b> script files.</li>
      <li>Execute upif.sh to configure required virtual ip addresses.</li>
      <li>Now you can start the test, endpoints will use IP addresses as per ip-granularity (in create-instance) configuration.</li>
      <li>After test execution over, if you want to disable all virtual ip addresses configured, execute downif.sh script.</li>
      </ul>
      One important note is, while executing <b>ipgenerator</b> choosing right base ip address. If you need large number of virtual IP addresses start base IP address as 192.1.1.4, note 2nd and 3rd tokens have 1, that will help you to have large number of virtual IP addresses. Also you should be careful in choosing base IP address in such a way, it should be reachable to other network components as required in test. <b>ipgenerator</b> generates virtual IP addresses with last token as 5 to 254. Assuming base IP address it 192.1.1.4, it will generate below virtual IP addresses. 

      <br/><br/>192.1.1.5
      <br/>192.1.1.6
      <br/>.    .    .    .
      <br/>192.1.1.254
      <br/>192.1.2.5
      <br/>192.1.2.6
      <br/>.    .    .    .
      <br/>192.1.254.254
      <br/>192.2.1.5
      <br/>192.2.1.6
      <br/>.    .    .    .
      <br/>192.254.254.254      
      <br/><br/>
      
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/httpserverinstructions" }>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="" }>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGVirtualIPConfig}