
import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import {checkRecordCounts, createSelectStyle, setPageTitle, getLmPluginId, getLmFrequencyId, getLmBlockTypeId, getLmReportId, handleErrorResponse} from '../../routes/lm.js';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import moment from 'moment';
import axios from 'axios';
import {Line} from 'rc-progress';
import CryptoJS from 'crypto-js';

let myTimeOut = null;

const ScenarioAccessSingleURL = () => 
{
  const [btnState, setBtnState] = useState(false);
  
  const [controllers, setControllers] = useState([{value: 0, label: ""},]);
  const [multipliers, setMultipliers] = useState([{value: 0, label: ""},]);

  const [controller, setController] = useState({value: 0, label: ""});
  const [multiplier, setMultiplier] = useState({value: 0, label: ""});
  
  const [progressBar, setProgressBar] = useState({value: 0});
  const [state, setState] = useState({name: "access-single-url", localip: "", baseid: "0", url: "www.google.com", id: -1});

  const [description, setDescription] = useState("");

  const [status, setStatus] = useState(null);
  
  
  setPageTitle('Access single page scenario');

  const handleInputTextChange=(e)=> {
    const {name,value}=e.target;
    const newState = {...state};
    newState[name]=value;
    setState(newState);
  }

  const handleInputTextNone=(e)=> {
    const {name,value}=e.target;
    e.target.value="";
  }

  const handleCntrollerChange=(e)=> {
    const newController = {...controller};
    newController.value = e.value;
    newController.label = e.label;
    setController(newController);
  }

  const handleMultiplierChange=(e)=> {
    const newMultiplier = {...multiplier};
    newMultiplier.value = e.value;
    newMultiplier.label = e.label;
    setMultiplier(newMultiplier);
  }

  const user = JSON.parse(localStorage.getItem('user'));
 
  const accessSingleURLCreateTP = async (name) => {
    
    let tpRecordId = -1;
    
    const reqdTpHead = {
      tpName: name,
      type: 0, //0 -> text, 1-> binary template
      tpDesc: `Auto generated template for ${state.name} scenario.`,
      tpId: -1, //-1 for new template
    };
    
    try {
      await axios.post(
        '/api/template/data/save', 
        {uid: user.uid, email: user.email, data: JSON.stringify(reqdTpHead)},
        {headers: {Authorization: user.jwt,}},
      ).then( function (res) {
        tpRecordId = res.data.tpId;
        reqdTpHead.tpId = tpRecordId;
        increaseProgressBy(14);
      });
    }catch(err) {
      handleErrorResponse(err);
    }
    
    if(tpRecordId > 0) {
      
      const reqdTpData = [{tpname: 'rx-msg', format: '[...]'}];
      const tpJsonData = JSON.stringify(reqdTpData);
      const tpBodySha256 = CryptoJS.SHA256(tpJsonData).toString();
      
      try {
        await axios.post(
          '/api/templateblocks/data/save',
          {uid: user.uid, email: user.email, tpid: tpRecordId, sha256: tpBodySha256, data: tpJsonData},
          {headers: {Authorization: user.jwt,}},
        ).then ( function (res) {
          if(res.status === 200) {
            
            increaseProgressBy(28);
          
          } else {
            console.log("Template blocks save, got error " + res.status);
          }
        });
      } catch(err) {
        handleErrorResponse(err);
      }            
    }
    
    return tpRecordId;
  }

  const accessSingleURLCreateTS = async (name) => {

    let tsRecordId = -1;
    
    let reqdTsHead = {
      tsName: name, 
      tsDesc: `Auto generated test suite for ${state.name} scenario.`, 
      tsId: tsRecordId
    };

    try {
      await axios.post(
        '/api/testsuite/data/save', 
        {uid: user.uid, email: user.email, data: JSON.stringify(reqdTsHead)},
        {headers: {Authorization: user.jwt,}},
      ).then( function (res) {
        if(res.status === 200) {
          tsRecordId = res.data.tsId;
          reqdTsHead.tsId = tsRecordId;
          increaseProgressBy(42);
        } else {
          console.log("TS save, got error " + res.status);
        }
      })
    }catch(err) {
      handleErrorResponse(err);
    }

    if(tsRecordId > 0) {
      
      let pluginId = getLmPluginId('Apat');
      let nativeBlock = getLmBlockTypeId('Native API');
      let freqOnce = getLmFrequencyId('Once');
      let freqAlways = getLmFrequencyId('Always');
      
      let reportId = getLmReportId('Yes');
      
      const reqdTsBody = [
        {
          tcname:"create-instance",
          startindex:0,
          deltaindex:0,
          endindex:10000,
          timeout: 300000,
          repeat: 1,
          tolerance: 10,
          plugin: pluginId,
          type: getLmBlockTypeId('Initialize'),
          freq: freqOnce,
          report: reportId,
          attribs: `id ${state.baseid}\nlocal-ipaddress ${state.localip}\nrps 10`,
        },
        {
          tcname:"variables",
          startindex:0,
          deltaindex:0,
          endindex:10000,
          timeout: 300000,
          repeat: 1,
          tolerance: 10,
          plugin: pluginId,
          type: getLmBlockTypeId('Variables'),
          freq: freqOnce,
          report: reportId,
          attribs: `global server=${state.url}\nglobal transport="tcp"\nglobal port=80`,
        },
        {
          tcname:"instructions",
          startindex:0,
          deltaindex:0,
          endindex:10000,
          timeout: 300000,
          repeat: 1,
          tolerance: 10,
          plugin: pluginId,
          type: getLmBlockTypeId('Instructions Block'),
          freq: freqOnce,
          report: reportId,
          attribs: `invoke url create ${state.url} GET mycurl1\ninvoke url mycurl1 receive [t-rx-msg]\ninvoke url mycurl1 header Connection: keep-alive\ninvoke url mycurl1 start\nrps 20`,
        },

        {
          tcname:"session-init",
          startindex:0,
          deltaindex:0,
          endindex:10000,
          timeout: 300000,
          repeat: 1,
          tolerance: 10,
          plugin: pluginId,
          type: nativeBlock,
          freq: freqAlways,
          report: reportId,
          attribs: `session-id session1\nrps 10`,
        },

        {
          tcname:"execute-load-templates",
          startindex:0,
          deltaindex:0,
          endindex:10000,
          timeout: 300000,
          repeat: 1,
          tolerance: 10,
          plugin: pluginId,
          type: nativeBlock,
          freq: freqOnce,
          report: reportId,
          attribs: `session-id session1\ninvoke set-default-text-templates ${name}\nrps 10`,
        },
        
        {
          tcname:"execute-instructions",
          startindex:0,
          deltaindex:0,
          endindex:10000,
          timeout: 300000,
          repeat: 1,
          tolerance: 10,
          plugin: pluginId,
          type: nativeBlock,
          freq: freqAlways,
          report: reportId,
          attribs: `session-id session1\nexecute [i-instructions]\nrps 10`,
        },
        
        {
          tcname:"session-deinit",
          startindex:0,
          deltaindex:0,
          endindex:10000,
          timeout: 300000,
          repeat: 1,
          tolerance: 10,
          plugin: pluginId,
          type: nativeBlock,
          freq: freqAlways,
          report: reportId,
          attribs: `rps 10`,
        },
      ];
        
      const tbJsonData = JSON.stringify(reqdTsBody);
      const tsBodySha256 = CryptoJS.SHA256(tbJsonData).toString();
      
      try {
        await axios.post(
          '/api/testblocks/data/save',
          {uid: user.uid, email: user.email, tsid: tsRecordId, sha256: tsBodySha256, data: tbJsonData},
          {headers: {Authorization: user.jwt,}},
        ).then ( function (res2) {
          if(res2.status === 200) {
            increaseProgressBy(56);
          } else {
            console.log("TS blocks save, got error " + res2.status);
          }
        });
      } catch(err2) {
        handleErrorResponse(err2);
      }
    }
    
    return tsRecordId;
  }

  const accessSingleURLCreateTB = async (name, tsRecordId) => {
    let tbRecordId = -1;
    
    let reqdTbHead = {
      name: name,
      
      cid: controller.value,
      controller: controller.label,
      
      loglevel: 0,
      valgrind: 0,
      repeat: 1,
      description: `Auto generated test bed for ${state.name} scenario.`,
      tbId: -1,
    };

    try {
      await axios.post(
        '/api/testbed/data/save', 
        {uid: user.uid, email: user.email, data: JSON.stringify(reqdTbHead)},
        {headers: {Authorization: user.jwt,}},
      ).then( function (res) {
        if(res.status === 200) {
          tbRecordId = Math.round(res.data.tbId);
          increaseProgressBy(70);
        } else {
          console.log("Testbed save, got error " + res.status);
        }
      })
    }catch(err) {
      handleErrorResponse(err);
    }
    
    let reqdTbBody = [{
      tsid: tsRecordId,
      testsuite: name,
      
      mid: multiplier.value,
      multiplier: multiplier.label,
      
      endpoints: 2,
      threads: 1,
      
      state: 1,
      loglevel: 0,
      valgrind: 0,
    }];
    
    if(tbRecordId > 0) {
            
      const tbJsonData = JSON.stringify(reqdTbBody);
      const tbBodySha256 = CryptoJS.SHA256(tbJsonData).toString();
      
      try {
        await axios.post(
          '/api/testbedblocks/data/save',
          {uid: user.uid, email: user.email, tbid: tbRecordId, sha256: tbBodySha256, data: tbJsonData},
          {headers: {Authorization: user.jwt,}},
        ).then ( function (res2) {
          if(res2.status === 200) {
            increaseProgressBy(84);
          } else {
            console.log("Testbed blocks save, got error " + res2.status);
          }
        });
      } catch(err2) {
        handleErrorResponse(err2);
      }
    }
    
    return tbRecordId;
  }

  function increaseProgressBy(delta) {
    let newProgressBar = {...progressBar};
    newProgressBar.value += delta;
    setProgressBar(newProgressBar);
  }

  const saveAccessURLScenarioData = async (name, tps, tss, tbs) => {
    let scRecordId = -1;
    let atps = [tps];
    let atss = [tss];
    let atbs = [tbs];
    
    try {
      await axios.post(
        '/api/scenario/data/save', 
        {
          uid: user.uid, 
          email: user.email, 
          name: name,
          desc: description,
          tparr: JSON.stringify(atps),
          tsarr: JSON.stringify(atss),
          tbarr: JSON.stringify(atbs),
        },
        {headers: {Authorization: user.jwt,}},
      ).then( function (res) {
        if(res.status === 200) {
          scRecordId = Math.round(res.data.scid);
          
          setStatus({
            type: 'success',
            message: `Scenarion ${name} is created successfully.`
          });
          
          increaseProgressBy(100);
          let newBtnState = true;
          setBtnState(newBtnState);              

          let newState = {...state};
          state.id = scRecordId;
          newState.id = scRecordId;
          setState(newState);
          myTimeOut = setTimeout(moveToScenarioPage, 1500);
          
        } else {
          console.log("Scenario save, got error " + res.status);
        }
      })
    }catch(err) {
      handleErrorResponse(err);
    }
  }
  
  function moveToScenarioPage() {
    clearTimeout(myTimeOut);
    window.location.href = `/components/view/the/scenario?id=${state.id}`;
  }
  
  const handleCreateScenario = async (e) => {
    e.preventDefault();
    
    if(multiplier.value == 0) {
      setStatus({type: 'error', message: 'Multiplier not set'});
      return;
    }

    if(controller.value == 0) {
      setStatus({type: 'error', message: 'Controller not set'});
      return;
    }

    let uniqid = user.uid + '-' + moment().format('YYYY-MM-DD-HH-mm-ss-SSS');
    let name = state.name + '-' + uniqid;

    //Record check result
    let rcr = await checkRecordCounts(user);
    
    if(rcr === 'success') {
      const tps = await accessSingleURLCreateTP(name);
      const tss = await accessSingleURLCreateTS(name);
      const tbs = await accessSingleURLCreateTB(name, tss);
      
      //Add entries 
      await saveAccessURLScenarioData(name, tps, tss, tbs);
    } else {
      alert('Scenario creation failed. Error: ' + rcr);
    }
  }

  const handleScenarioDescChange=(value)=> {
    const newDesc = value;
    setDescription(newDesc); 
  }
  
  //Hook to get data from backend server
  useEffect ( () => {
     fetchCMs();
  }, []);    
  
  
  const fetchCMs = async () => {
    setStatus(null);
    
    try {
      const res = await axios.post('/api/fetch/controllers/multipliers/nameids', 
      {uid: user.uid, email: user.email} ,
      {headers: {
        'Authorization': user.jwt,
      }}).then( 
      function (res) {
        if(res.status === 200) {
          
          if(res.data.controllers) {
            setController(res.data.controllers[0]);
            setControllers(res.data.controllers);
          }
          
          if(res.data.multipliers) {
            setMultiplier(res.data.multipliers[0]);
            setMultipliers(res.data.multipliers);
          }
          
        }else {
          setStatus({
            type: 'error',
            message: 'Could not fetch controllers and multipliers',
          });  
        }
      });
    }catch(err) {
      setStatus({
        type: 'error',
        message: err.message,
      });  
      handleErrorResponse(err);
    }
  }
  
  if(user) {
    return (
      <form name="scform" id="scform" onSubmit={handleCreateScenario}>
        <div key="scmain" className="medium-text">
          <div id="scmain2" className="center" >
            <h2>Create access single URL scenario</h2>

            <label>Scenario name</label>
            <input required name="name" value={state.name} style={{width: "100%"}} onChange={(e)=>handleInputTextChange(e)}/>
            
            <label>Scenario description</label>
            <textarea value={description} name="description" value={description}  
              style={{width: "100%"}}
              rows={4} cols={45}
              onChange={(e) => handleScenarioDescChange(e.target.value)}>                                      
            </textarea> 

            <br/><br/><h3>Select controller and multiplier</h3>
            
            <table key="cmtbl">
              <tbody key="cmbd">
                <tr key='cmr1'>
                  <td>
                    Controller<br/>
                    <Select required 
                      name="cid"
                      value={{value: controller.value, label: controller.label}}
                      className="select" 
                      options={controllers} 
                      styles={createSelectStyle()}
                      onChange={(e)=>handleCntrollerChange(e)}
                      components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    />
                  </td>

                  <td>
                    Multiplier<br/>
                    <Select required 
                      name="mid"
                      value={{value: multiplier.value, label: multiplier.label}}
                      className="select" 
                      options={multipliers} 
                      styles={createSelectStyle()}
                      onChange={(e)=>handleMultiplierChange(e)}
                      components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <br/><br/><h3>Test configuration</h3>
            
            <table key='tctbl' style={{maxWidth: "100%"}}>
              <tbody key='tcbd'>
                <tr key='tcr1'>
                  <td>
                    Base Id
                    <Tooltip title="Base Id is starting reference number. Say prefix is user, base id is 10 then user series will be like user10, user11, so on ..." arrow>
                      <span className="round">?</span>
                    </Tooltip><br/>
                    <input required name="baseid" value={state.baseid} style={{width: "100%"}} onChange={(e)=>handleInputTextChange(e)}/>
                  </td>
                  <td>
                    Local IP address
                    <Tooltip title="Enter multiplier machine IP address. Use local IP addresses to reduce bandwidth cost." arrow>
                      <span className="round">?</span>
                    </Tooltip><br/>
                    <input required name="localip" placeholder="Enter machine local ip address" style={{width: "100%"}} onChange={(e)=>handleInputTextChange(e)}/>
                  </td>
                </tr>

                <tr key='tcr2'>
                  <td>
                    URL
                    <Tooltip title="URL that's accessed during test execution." arrow>
                      <span className="round">?</span>
                    </Tooltip><br/>
                    <input required name="url" value={state.url} style={{width: "100%"}} onChange={(e)=>handleInputTextChange(e)}/>
                  </td>

                  <td>
                    <br/>
                    <input disabled name="dummy" style={{width: "100%"}} onChange={(e)=>handleInputTextNone(e)}/>
                  </td>
                </tr>
                                
              </tbody>
            </table>


          <div className="inlinecenter">
            {status && <div className={`alert ${status.type}`}>{status.message}</div>}
            <Line className="progressbar" style={{visibility: (progressBar.value > 0) ? "visible" : "hidden"}} percent={progressBar.value} strokeWidth="1" strokeColor="#009973" />
            <br/>
            <button disabled={btnState}>Create Scenario</button> 
          </div>
          
          </div>
        </div>
      </form>
    );
  }  else { 
    return(
      <div key="scmain" className="medium-text">
        <div id="scmain2" className="center" >
          You are not allowed to access this page.
        </div>
      </div>
    );
  }  
}
  
export {ScenarioAccessSingleURL};
