/*
* Please note the attribs are indented from start of line intentionally.
* Do not do normal formating else sip test blocks will have additional spaces.
*/

import 
  {
    lmTransports, lmURISchemes, 
    lmMediaProfiles, lmCryptoSuites, 
    lmAudioCodecs, lmVideoCodecs,
    getLmBlockTypeId, lmICEModes, 
  } from '../../../routes/lm.js';

export function apatCreateInstanceAttribs(
    userConfig, callConfig, turnConfig, serverConfig,
    pluginId, frequency, reportId
  ) {
  let video = 0;
  
  let transportsObj = lmTransports();
  let transport = transportsObj[userConfig.transport];
  
  let iceModesObj = lmICEModes();
  let icemode = iceModesObj[turnConfig.icemode];

  if(Math.round(callConfig.vcodec) > 0) {
    video = 1;
  }
 
  let attribs = 
`id ${userConfig.baseid}
transport ${transport}
local-ipaddress ${userConfig.localip}
server ${serverConfig.server}
server-port ${serverConfig.port}
user ${userConfig.uprefix}
password ${userConfig.pprefix}
call-participants 2
ws-hs-template ws-handshake
rtcp-mux ${callConfig.rtcpmux}
av-bundle ${callConfig.avbundle}
video ${video}
client-cert-verify 1
media-capture-offset 1
media-cert-verify 1
media-enable-call-offset 1
call-gap-index -1
ice-mode ${icemode}
turn-server ${turnConfig.server}
turn-server-port ${turnConfig.port}
turn-user-prefix ${turnConfig.tuprefix}
turn-password-prefix ${turnConfig.tpprefix}
ep-status details
rps 960`;

  return ({
    tcname:"create-instance",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: getLmBlockTypeId('Initialize'),
    freq: frequency,
    report: reportId,
    attribs: attribs,
  });
}

export function apatVariablesAttribs(userConfig, pluginId, frequency, reportId) {
  let transportsObj = lmTransports();
  let transport = transportsObj[userConfig.transport];
  
  let attribs = 
`global transport=${transport}`;

  return ({
    tcname:"variables",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: getLmBlockTypeId('Variables'),
    freq: frequency,
    report: reportId,
    attribs: attribs,
  });
}

export function apatConfigSDPAttribs(callConfig, pluginId, typeId, frequency, reportId) {

  let videofile = '';
  let acodecnumber = 0;
  let vcodecnumber = 0;

  let audioCodecsObj = lmAudioCodecs();
  let videoCodecsObj = lmVideoCodecs();
  
  let acodec = audioCodecsObj[callConfig.acodec].toUpperCase();
  let vcodec = videoCodecsObj[callConfig.vcodec].toUpperCase();
  
  if(acodec == 'PCMA') {
    acodecnumber = 8;
  } else if(acodec == 'AMR') {
    acodecnumber = 101;
  } else if(acodec == 'OPUS') {
    acodecnumber = 102;
  }
  
  if(vcodec == 'VP8') {
    videofile = 'in-vp8.mlt';
    vcodecnumber = 98;
  } else if(vcodec == 'H264') {
    videofile = 'in-h264.mlt';
    vcodecnumber = 98;
  }
  
  let attribs = 
`session-config start
version 0
origin lmcloud
session lmcloud
audio-codec ${acodec}
audio-ptime 20
audio-codec-number ${acodecnumber}
audio-sampling-rate 8000`;

if(vcodecnumber > 0) {
attribs += `
video-codec ${vcodec}
video-fps 24
video-file ${videofile}
video-sampling-rate 90000
video-codec-number ${vcodecnumber}`;
}

attribs += `
session-config end
rps 960`;

  return ({
    tcname:"config-sdp",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs,
  });
}

export function apatCallOriginatingAttribs(callConfig, pluginId, typeId, frequency, reportId) {

  let attribs = 
`invoke set-media-ep-mode server
invoke set-ice-role controlling
invoke media-init
invoke ice-agent-init timer 20000
send [t-tx-offer]
receive [t-rx-answer] timer 20000

#Audio codec instructions
invoke set-remote-audio-rtp-candidate [remote-audio-rtp-candidate]
invoke set-audio-codec [remote-acodec-name]
invoke set-audio-codec-number [remote-acodec-number]
invoke set-remote-audio-rtp-ufrag [remote-audio-rtp-ufrag]
invoke set-remote-audio-rtp-icepasswd [remote-audio-rtp-icepasswd]
invoke set-remote-audio-rtcp-candidate [remote-audio-rtcp-candidate]
invoke set-remote-audio-rtcp-ufrag [remote-audio-rtcp-ufrag]
invoke set-remote-audio-rtcp-icepasswd [remote-audio-rtcp-icepasswd]
`;
  if(Math.round(callConfig.vcodec) > 0) {
    attribs +=
`
#Video codec instructions
invoke set-remote-video-rtp-candidate [remote-video-rtp-candidate]
invoke set-video-codec [remote-vcodec-name]
invoke set-video-codec-number [remote-vcodec-number]
invoke set-remote-video-rtp-ufrag [remote-video-rtp-ufrag]
invoke set-remote-video-rtp-icepasswd [remote-video-rtp-icepasswd]
invoke set-remote-video-rtcp-candidate [remote-video-rtcp-candidate]
invoke set-remote-video-rtcp-ufrag [remote-video-rtcp-ufrag]
invoke set-remote-video-rtcp-icepasswd [remote-video-rtcp-icepasswd]
`;
  }

  attribs += 
`
#ICE connection check
invoke ice-agent-con-check timer 20000

#Set media ip and ports
invoke set-remote-ip [p-ice-remote-audio-ip:session1]
invoke set-remote-audio-rtp-port [p-ice-remote-audio-rtp-port:session1]
`;

  if(Math.round(callConfig.vcodec) > 0) {
    attribs +=
`invoke set-remote-video-rtp-port [p-ice-remote-video-rtp-port:session1]
`;
  }
  
  attribs +=
`
#DTLS handshake
invoke media-ready timer 60000

#Media start
invoke media-sendrecv timer ${callConfig.mediatime}

#Disconnect
invoke media-inactive
send [t-tx-disconnect]
receive [t-rx-ack-disconnect] timer 20000
invoke media-deinit

rps 960`;

  return ({
    tcname:"call-originating",
    startindex:1,
    deltaindex:1,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs,   
  });
}

export function apatCallTerminatingAttribs(callConfig, pluginId, typeId, frequency, reportId) {

  let attribs = 
`receive [t-rx-offer] timer 20000
invoke set-media-ep-mode client
invoke set-ice-role controlled
invoke media-init
invoke ice-agent-init timer 20000

#Audio codec instructions
invoke set-audio-codec [remote-acodec-name]
invoke set-audio-codec-number [remote-acodec-number]
invoke set-remote-audio-rtp-candidate [remote-audio-rtp-candidate]
invoke set-remote-audio-rtp-ufrag [remote-audio-rtp-ufrag]
invoke set-remote-audio-rtp-icepasswd [remote-audio-rtp-icepasswd]
invoke set-remote-audio-rtcp-candidate [remote-audio-rtcp-candidate]
invoke set-remote-audio-rtcp-ufrag [remote-audio-rtcp-ufrag]
invoke set-remote-audio-rtcp-icepasswd [remote-audio-rtcp-icepasswd]
`;
  if(Math.round(callConfig.vcodec) > 0) {
    attribs +=
`
#Video codec instructions
invoke set-video-codec [remote-vcodec-name]
invoke set-video-codec-number [remote-vcodec-number]
invoke set-remote-video-rtp-candidate [remote-video-rtp-candidate]
invoke set-remote-video-rtp-ufrag [remote-video-rtp-ufrag]
invoke set-remote-video-rtp-icepasswd [remote-video-rtp-icepasswd]
invoke set-remote-video-rtcp-candidate [remote-video-rtcp-candidate]
invoke set-remote-video-rtcp-ufrag [remote-video-rtcp-ufrag]
invoke set-remote-video-rtcp-icepasswd [remote-video-rtcp-icepasswd]
`;
  }

  attribs += 
`
#Send answer
send [t-tx-answer]

#ICE connection check
invoke ice-agent-con-check timer 20000

#Set media ip and ports
invoke set-remote-ip [p-ice-remote-audio-ip:session1]
invoke set-remote-audio-rtp-port [p-ice-remote-audio-rtp-port:session1]
`;

  if(Math.round(callConfig.vcodec) > 0) {
    attribs +=
`invoke set-remote-video-rtp-port [p-ice-remote-video-rtp-port:session1]
`;
  }
  
  attribs +=
`
#Media start
invoke media-ready timer 60000
invoke media-sendrecv timer ${callConfig.mediatime}

#Disconnect
invoke media-inactive
receive [t-rx-disconnect] timer 20000
send [t-tx-ack-disconnect]
invoke media-deinit

rps 960`;

  return ({
    tcname:"call-terminating",
    startindex:0,
    deltaindex:1,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs,   
  });
}

export function apatSessionInitAttribs(userConfig, callConfig, turnConfig, serverConfig, pluginId, typeId, frequency, reportId) {
  let mediaprofilesObj = lmMediaProfiles();
  let cryptoSuitesObj = lmCryptoSuites();
  
  let mediaprofile = mediaprofilesObj[callConfig.mediaprofile];
  let cryptosuite = cryptoSuitesObj[callConfig.cryptosuite].toUpperCase();

  let iceModesObj = lmICEModes();
  let icemode = iceModesObj[turnConfig.icemode];
  
  let attribs = 
`session-id session1
call-gap-index -1
called-party-domain ${serverConfig.server}
called-party-prefix ${userConfig.uprefix}
media-transport ${mediaprofile}`;

  if(mediaprofile === "srtp-dtls") {
    attribs +=
`
crypto-suite ${cryptosuite}`;
  }

  if(icemode === 'turn') {
    attribs += 
`
data-mode data-over-ch`;
  }

  attribs +=
`
rps 960`;

  return ({
    tcname:"session-init",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs, 
  });
}

export function apatLoadTemplateAttribs(tptype, tpname, pluginId, typeId, frequency, reportId) {

  let startindex=0;
  
  if(tptype === "originating") {
    startindex = 1;
  }
  
  let attribs = 
`session-id session1
invoke set-default-text-templates ${tpname}
rps 960`;

  return ({
    tcname:`execute-load-call-${tptype}-templates`,
    startindex:`${startindex}`,
    deltaindex:1,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs, 
  });
}

export function apatExecuteRegisterAttribs(userConfig, pluginId, typeId, frequency, reportId) {

  let attribs = 
`session-id session1
invoke timer 10000
invoke start-transport-connection [p-server] [p-server-port] [transport] my_connection timer 20000
send [t-tx-register]
rps 16
`;

  return ({
    tcname:"execute-register",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs, 
  });
}

export function apatExecuteCallAttribs(pluginId, typeId, frequency, reportId) {

  let attribs = 
`session-id session1
execute [i-call-originating]
execute [i-call-terminating]
rps 16`;
  
  return ({
    tcname:"execute-call",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs, 
  });
}

export function apatExecuteUnregisterAttribs(userConfig, pluginId, typeId, frequency, reportId) {

  let attribs = 
`session-id session1
send [t-tx-unregister]
rps 16
`;
  
  return ({
    tcname:"execute-unregister",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs, 
  });
}

export function apatSessionDeInitAttribs(pluginId, typeId, frequency, reportId) {
  let attribs = `rps 16`;
  return ({
    tcname:"session-deinit",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs, 
  });
}