export const menuItems = 
[
  {
      title: 'User',
      url: '/',
      allowed: 'anonymous',

    submenu: 
    [
      {
          title: 'Home',
          url: '/',
      },

      {
          title: 'User Guide',
          url: 'userguide/tableofcontents',
      },

      {
          title: 'Download Multiplier',
          url: 'userguide/download',
      },

      {
          title: 'My Profile',
          url: 'profile',
      },
      
    ],
      
  },

  {
    title: 'Admin',
    url: '/admin',
    allowed: 'admin',
    submenu: 
    [
      {
          title: 'Users',
          url: 'view/all/users',
      },

      {
          title: 'Drop Tables',
          url: 'exec/drop/alltables',
      },

      {
          title: 'Create Tables',
          url: 'exec/create/alltables',
      },
      
    ],
  },
  
  {
    title: 'Components',
    url: '/components',
    allowed: 'anonymous',
    submenu: 
    [
      {
          title: 'Add Multiplier',
          url: 'components/multiplier?id=-1',
      },
      {
          title: 'Edit / Delete Multiplier',
          url: 'components/editmultipliers',
      },

      {
          title: 'Add Controller',
          url: 'components/controller?id=-1',
      },
      {
          title: 'Edit / Delete Controller',
          url: 'components/editcontrollers',
      },
    ],
  },
  
  {
    title: 'Test Environment',
    url: '/components/testenvironments',
    allowed: 'anonymous',
    submenu: 
    [
          {
        title: 'Scenarios',
        url: 'components/scenarios',

        submenu: [
            {
                title: 'Add Scenario',
                url: 'components/scenarios?id=-1',
            },

            {
                title: 'View / Delete Scenario',
                url: 'components/view/scenarios',
            },
        ],
      },
      
      {
        title: 'Test Suites',
        url: 'components/testsuites',
        
        submenu: [
            {
                title: 'Add Test Suite',
                url: 'components/testsuites?id=-1',
            },

            {
                title: 'Edit / Delete Test Suite',
                url: 'components/edittestsuites',
            },
        ],
      },

      {
        title: 'Templates',
        url: 'components/templates',
        
        submenu: [
            {
                title: 'Add Template',
                url: 'components/templates?id=-1',
            },

            {
                title: 'Edit / Delete Template',
                url: 'components/edittemplates',
            },
        ],
      },

      {
        title: 'Test Beds',
        url: 'components/testbeds',
        
        submenu: [
            {
                title: 'Add Test Bed',
                url: 'components/testbeds?id=-1',
            },

            {
                title: 'Edit / Delete Test Bed',
                url: 'components/edittestbeds',
            },
        ],
      },
    ],
  },

  {
    title: 'Test Execution',
    url: '/components',
    allowed: 'anonymous',
    submenu: 
    [
      {
          title: 'Start Test',
          url: 'components/execute/test',
      },
      {
          title: 'View Test',
          url: 'components/view/test/stats',
      },

      {
          title: 'Test History',
          url: 'components/view/test/history',
      },
      
      {
          title: 'Stop Test',
          url: 'components/view/test/stats',
      },
    
    ],
  },
];

