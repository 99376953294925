
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGMachineInfo = () => {

  setPageTitle('Multiplier Machine Information');
  
  const element = (
    <div className="userguide">
      <h1>Multiplier Machine Specification</h1>
      <br/>
      People ask us what is the machine specification for LM Tools Multiplier component.
      As of May, 2024 our official recommendation for Multiplier machine follows as below.
      <br/><br/>
      <ul>
      <b>RAM</b> - 6GB or higher
      <br/><b>HDD</b> - 20GB or higher
      <br/><b>CPU</b> - 2.4GHz or higher
      <br/><b>LAN</b> - 1Gbps or higher
      <br/><b>Operating system</b> - Ubuntu 22.04 LTS
      </ul>
      <br/>      
      With this machine you can simulate approximately 10,000 subscribers or even more.
      You need to be careful with LAN ratings, if you send/receive audio video packets for 
      higher number of concurrent sessions, you must calculate bandwidth requirement and 
      accordingly you need to choose machine with higher LAN specification. 
      <br/><br/>
      Our recommendation is do not think too much about the hardware requirement. Just go for 
      it even if you have a machine with lower configuration and check how it works out for you.
      At a later stage when you need to go for high load testing by that time you will have sufficient 
      information to choose the right machine. 
      <br/>
      <br/>
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/download"}>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/install/multiplier"}>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGMachineInfo}

