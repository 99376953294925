import React, {useState, useEffect} from 'react';
import {setPageTitle, handleErrorResponse} from '../../routes/lm.js';
import axios from 'axios';

const CreateAllTables = () => {
  const [btnState, setBtnState] = useState(false);
  const [status, setStatus] = useState(null);

  setPageTitle('Create all tables');

  const user = JSON.parse(localStorage.getItem('user'));
  
  const handleCreateAllTablesHit = async (e) => {
    e.preventDefault();
    if (window.confirm("Press OK to confirm for creating all tables in database")) {
      if (window.confirm("Press OK to double confirm for creating all tables in database")) {


        try {
          await axios.post(
            '/api/execute/create/alltables', 
            {uid: user.uid, email: user.email},
            {headers: {Authorization: user.jwt,}},
          ).then( function (res) {
            if(res.status === 200) {
              setStatus({type: 'success', message: "Created all tables except users"});
            } else {
              console.log("Error " + res.status);
            }
          })
        }catch(err) {
          setStatus({type: 'error', message: err.message});
          handleErrorResponse(err);
        }

        
      }
    }
  }
  
  if(user) {
    return(

      <div key="dbadmin" className="medium-text">
        <div id="dbadmin2" className="center" >
          <form name="droptbl" id="droptbl" onSubmit={handleCreateAllTablesHit}>
            <div className="inlinecenter">
              <br/><br/><br/><button disabled={btnState}>Create All Tables</button> 
              <br/><br/><br/>
              {status && <div className={`alert ${status.type}`}>{status.message}</div>}
            </div>
          </form>
        </div>
      </div>
    
    );
  }    
}

const DropAllTables = () => {
  const [btnState, setBtnState] = useState(false);
  const [status, setStatus] = useState(null);

  setPageTitle('Drop all tables');

  const user = JSON.parse(localStorage.getItem('user'));
  
  const handleDropAllTablesHit = async (e) => {
    e.preventDefault();
    if (window.confirm("Press OK to confirm for deleting all tables in database")) {
      if (window.confirm("Press OK to double confirm for deleting all tables in database")) {


        try {
          await axios.post(
            '/api/execute/drop/alltables', 
            {uid: user.uid, email: user.email},
            {headers: {Authorization: user.jwt,}},
          ).then( function (res) {
            if(res.status === 200) {
              setStatus({type: 'success', message: "Deleted all tables except users"});
            } else {
              console.log("Error " + res.status);
            }
          })
        }catch(err) {
          setStatus({type: 'error', message: err.message});
          handleErrorResponse(err);
        }

        
      }
    }
  }
  
  if(user) {
    return(

      <div key="dbadmin" className="medium-text">
        <div id="dbadmin2" className="center" >
          <form name="droptbl" id="droptbl" onSubmit={handleDropAllTablesHit}>
            <div className="inlinecenter">
              <br/><br/><br/><button disabled={btnState}>Drop All Tables</button> 
              <br/><br/><br/>
              {status && <div className={`alert ${status.type}`}>{status.message}</div>}
            </div>
          </form>
        </div>
      </div>
    
    );
  }    
}

export {CreateAllTables, DropAllTables};
