
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGHTTPDB2BAInstructions = () => {

  setPageTitle('HTTPD B2BA Instructions');
  
  let lcurly = '{';
  let rcurly = '{';
  
  const element = (
    <div className="userguide">
      <h1>HTTPD B2BA Instructions</h1>
      <br/>
        
          B2BA HTTPD stands for HTTPD (Hyper Text Transfer Protocol Daemon) back to back agent. 
          Typically there are more than one component
          in B2BA and it interfaces with different products.
          Instructions are means to instruct certain operations to be executed in multiplier 
          test environment. 
          <br/><br/>
          Instructions can be in instruction blocks or in native APIs those 
          starts with <b>execute-</b> tag. Multiplier (B2BA) supports various types of 
          instructions listed below.
          <br/><br/>
          B2BA HHPD behaves like a Web Server. You can configure B2BA HTTPD to server for links,
          download operations, upload operations etc.
          <br/><br/>
          There is another module B2BA HTTP, that acts as a Web / HTTP Client, whereas B2BA HTTPD 
          acts as Web / HTTP Server. Please note B2BA HTTPD and B2BA
          HTTP are two different modules in multiplier environment.
          <br/><br/>
          <h3>B2BA HTTPD Instructions</h3>
          Now lets explore on various instructions supported by B2BA HTTPD module. Each instruction is
          explained in a single block with syntax, example and followed by explanation. Typically HTTPD 
          instructions in B2BA starts with <b>httpd start</b> and ends with <b>httpd end</b> as shown below.
          <br/><br/>
          <div className="code">
          httpd start<br/>
          instruction 1<br/>
          instruction 2<br/>
          ....................<br/>
          instruction n<br/>
          httpd end<br/>
          </div><br/>
          
          
          <h3>Instructions</h3>
          <ul>
          
          <li><b>create &lt;name&gt;</b>
          <div className="code">
          <b>Examples</b>
          <br/>#Here name can be any unique name like url1 mylink1 etc. 
          <br/>create default
          <br/>create response
          </div>
          <br/>Instruction <b>create</b> is used to create an http response with a given name identifier.
          </li>
          
          <br/><li><b>template &lt;msgtemplate&gt;</b>
          <div className="code">
          <b>Examples</b>
          <br/>template [t-headers]
          </div>
          <br/>Instruction <b>template</b> is used to format a HTTP response.
          </li>

          <br/><li><b>attach &lt;filename&gt;</b>
          <div className="code">
          <b>Examples</b>
          <br/>attach /home/user/data.txt
          </div>
          <br/>Instruction <b>attach</b> is used to attach a file in HTTP body.
          </li>

          <br/><li><b>increment &lt;variable&gt; &lt;value&gt;</b>
          <div className="code">
          <b>Examples</b><br/>
          increment cseq 1<br/>
          increment count 5<br/>
          </div>
          <br/>Instruction <b>increment</b> is used to increase a variable value. As you seen in example
          variables are given without square brackets.
          </li>

          <br/><li><b>decrement &lt;variable&gt; &lt;value&gt;</b>
          <div className="code">
          <b>Examples</b><br/>
          decrement value 1<br/>
          decrement count 5<br/>
          </div>
          <br/>Instruction <b>decrement</b> is used to decrease a variable value. As you seen in example
          variables are given without square brackets.
          </li>

          <br/><li><b>set variable &lt;value&gt;</b>
          <div className="code">
          <b>Examples</b><br/>
          set variable &lt;value&gt;<br/>
          set variable 10<br/>
          </div>
          <br/>Instruction <b>set</b> is used to set value for a variable. As you seen in example
          variables are given without square brackets. Variables added to system using set command
          will be available across sessions.
          </li>

          <br/><li><b>save file &lt;filename&gt; &lt;path&gt; &lt;finalfilename&gt;   </b>
          <div className="code">
          <b>Examples</b><br/>
          save file data.txt /home/user mydata.txt<br/>
          </div>
          <br/>When a HTTP client / browser sends a file to HTTPD, it is saved using <b>save</b>
          instruction. 
          <br/><br/>
          In above example, the file name uploaded by HTTP client / browser is data.txt
          and it is saved to path /home/user with a name mydata.txt.
          </li>

          <br/><li><b>endpoint-id &lt;epid&gt;</b>
          <div className="code">
          <b>Examples</b>
          <br/>endpoint-id lm_0
          </div>
          <br/>Instruction <b>endpoint-id</b> is used to set an endpoint as active endpoint for a session.
          </li>

          <br/><li><b>execute [f-luafunction(a, b, c)]</b>
          <div className="code">
          <b>Examples</b>
          <br/>execute [f-luafunction(a, b, c)]
          </div>
          <br/>Instruction <b>execute</b> is used to execute a lua function. You can pass the arguments to
          lua function. You can do all sort of logical executions like determining a random value,
          writing to a file, reading from file, evealuate a complex expression and return a value
          (to be used in templates or otherwise) anything that lua supports.
          
          <br/><br/>
          
          Additionally, you can achieve a logical if/else kind of functionality. Like on certain condition  
          execute IB1, on other condition execute IB2 so on.
          
          <br/><br/>
          
          You can return an instruction block name to execute the same, with 
          <b>executeib</b> as last return value as shown in below example. 
          
          <br/><br/>
          <div className="code">
          function luafunction(a)<br/>
          <tab2></tab2>if(a==0)<br/>
          <tab2></tab2>{lcurly}<br/>
          <tab4 />return "ib-get", "executeib"
          <br/><tab2 />}<br/>
          <tab2 />elseif(a==1)<br/>
          <tab2 />{lcurly}<br/>
          <tab4 />return "ib-post", "executeib"<br/>
          <tab2 />{rcurly}<br/>
          <tab2 />elseif(a==2)<br/>
          <tab2 />{lcurly}<br/>
          <tab4 />return "ib-put", "executeib"<br/>
          <tab2 />{rcurly}<br/>
          <tab2 />elseif(a==3)<br/>
          <tab2 />{lcurly}<br/>
          <tab4 />return "ib-delete", "executeib"<br/>
          <tab2 />{rcurly}<br/>
          <tab2 />else<br/>
          <tab2 />{lcurly}<br/>
          <tab4 />return "ib-invalid", "executeib"<br/>
          <tab2 />}<br/>
          end<br/>

          </div>
          </li>
          
          </ul>        
        
      <br/>
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/httpdb2basyntax" }>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/sipb2bavariables" }>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGHTTPDB2BAInstructions}

