
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGAPATB2BAInstructions = () => {

  setPageTitle('APAT B2BA Instructions');
  
  const element = (
    <div className="userguide">
      <h1>APAT B2BA Instructions</h1>
      <br/>
        
        B2BA APAT stands for APAT (any product any test) back to back agent. 
        Typically there are more than one component
        in B2BA and it interfaces with different products.
        Instructions are means to instruct certain operations to be executed in multiplier 
        test environment. 
        <br/><br/>
        Instructions can be in instruction blocks or in native APIs those 
        starts with <b>execute-</b> key. Multiplier (B2BA) supports various types of 
        instructions listed below.
        <br/><br/>
        <h3>B2BA APAT Instructions</h3>
        Now lets explore on various instructions supported by B2BA APAT module. Each instruction is
        explained in a single block with syntax, example and followed by explanation. Typically APAT
        instructions in B2BA starts with <b>apat start</b> and ends with <b>apat end</b> as shown below.
        <br/><br/>
        <div className="code">
        apat start<br/>
        instruction 1<br/>
        instruction 2<br/>
        ....................<br/>
        instruction n<br/>
        apat end<br/>
        </div><br/>
        
        
        <h3>Transport Instructions</h3>
        <ul>
        <li><b>start-transport-connection &lt;ip&gt; &lt;port&gt; &lt;transport&gt; conn-name &lt;ws-hs-tmplt&gt; timer &lt;timeout-value&gt;</b>
        <div className="code">
        <b>Examples</b>
        <br/>#Here ip, port, transport are variables. 
        <br/>#ws-hs-tmplt is template name used for websocket handshake. It is optional.
        <br/>start-transport-connection &lt;ip&gt; &lt;port&gt; &lt;transport&gt; conn-name &lt;ws-hs-tmplt&gt; timer 60000
        <br/>#For tcp connection, ws-hs-tmplt not required
        <br/>start-transport-connection 192.168.1.10 5060 tcp conn-name timer 60000
        </div>
        <br/>Instruction <b>start-transport-connection</b> is used to start a transport connection to remote.
        It creates connection and connects to remote server unlike create-client-connection instruction. Parameters
        &lt;ip&gt;, &lt;port&gt;, &lt;transport&gt; are used to provide remote server transport ipaddress, port and transport protocol.
        &lt;transport&gt; can have one value out of udp, tcp, tls, sctp, ws and wss. 
        </li>
        
        <br/><li><b>stop-transport-connection &lt;conn-name&gt;</b>
        <div className="code">
        <b>Examples</b>
        <br/>stop-transport-connection conn-name1
        <br/>stop-transport-connection conn-name2
        </div>
        <br/>Instruction <b>stop-transport-connection</b> is used to stop / destroy a transport 
        connection identified by conn-name.
        </li>
        
        </ul>
        
        <h3>Media Instructions</h3>
        <ul>

        <li><b>media-init</b>
        <div className="code">
        <b>Example</b><br/>
        media-init<br/>
        </div>
        <br/>Instruction <b>media-init</b> is used to initialize media session. It always creates audio
        session. If video is enabled, it will create video session as well.
        </li>
        
        <br/><li><b>media-ready timer &lt;timeout-value&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        media-ready timer 60000<br/>
        </div>
        <br/>Previously you have done media-init, with offer answer exchange and ICE connectivity 
        (wherever applicable), you have selected and set appropriate media transport details. 
        In a normal case, it will do everything required to start a media session. In case of 
        SRTP-DTLS call, it will do everything required to start a media session, plus DTLS 
        handshake. You need to mention timeout value as last parameters in millisecond. Within 
        that specified time if all media ready activities are not completed test case returns 
        fail.
        </li>
        
        <br/><li><b>media-sendrecv timer &lt;timeout-value&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        media-sendrecv timer 20000<br/>
        </div>
        <br/>Instruction <b>media-sendrecv</b> is used to exchange media between participants in a call.
        The timer value is given in milliseconds. Media exchange continues till timeout.
        </li>
        
        <br/><li><b>media-inactive</b>
        <div className="code">
        <b>Example</b><br/>
        media-inactive<br/>
        </div>
        <br/>Instruction <b>media-inactive</b> is used to set media mode to inactive. This suspends media
        TX and RX operations.
        </li>
        
        <br/><li><b>media-deinit</b>
        <div className="code">
        <b>Example</b><br/>
        media-deinit<br/>
        </div>
        <br/>Instruction <b>media-deinit</b> is used to de-initialise media sessions. By default
        it destroys audio session. If video session exists, it also destroys video session.
        </li>

        <br/><li><b>audio-init</b>
        <div className="code">
        <b>Example</b><br/>
        audio-init<br/>
        </div>
        <br/>Instruction <b>audio-init</b> is used to initialize audio session. It always creates audio
        session. If video is enabled, it will create video session as well.
        </li>
        
        <br/><li><b>audio-ready timer &lt;timeout-value&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        audio-ready timer 60000<br/>
        </div>
        <br/>Previously you have done audio-int, with offer answer exchange and ICE connectivity 
        (wherever applicable), you have selected and set appropriate audio transport details. 
        In a normal case, it will do everything required to start a audio session. In case of 
        SRTP-DTLS call, it will do everything required to start a audio session, plus DTLS 
        handshake. You need to mention timeout value as last parameters in millisecond. Within 
        that specified time if all audio ready activities are not completed test case returns 
        fail.
        </li>
        
        <br/><li><b>audio-sendrecv timer &lt;timeout-value&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        audio-sendrecv timer 20000<br/>
        </div>
        <br/>Instruction <b>audio-sendrecv</b> is used to exchange audio between participants in a call.
        The timer value is given in milliseconds. audio exchange continues till timeout.
        </li>
        
        <br/><li><b>audio-inactive</b>
        <div className="code">
        <b>Example</b><br/>
        audio-inactive<br/>
        </div>
        <br/>Instruction <b>audio-inactive</b> is used to set audio mode to inactive. This suspends audio
        TX and RX operations.
        </li>
        
        <br/><li><b>video-init</b>
        <div className="code">
        <b>Example</b><br/>
        video-init<br/>
        </div>
        <br/>Instruction <b>video-init</b> is used to initialize video session. It always creates video
        session. If video is enabled, it will create video session as well.
        </li>
        
        <br/><li><b>video-ready timer &lt;timeout-value&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        video-ready timer 60000<br/>
        </div>
        <br/>Previously you have done video-int, with offer answer exchange and ICE connectivity 
        (wherever applicable), you have selected and set appropriate video transport details. 
        In a normal case, it will do everything required to start a video session. In case of 
        SRTP-DTLS call, it will do everything required to start a video session, plus DTLS 
        handshake. You need to mention timeout value as last parameters in millisecond. Within 
        that specified time if all video ready activities are not completed test case returns 
        fail.
        </li>
        
        <br/><li><b>video-sendrecv timer &lt;timeout-value&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        video-sendrecv timer 20000<br/>
        </div>
        <br/>Instruction <b>video-sendrecv</b> is used to exchange video between participants in a call.
        The timer value is given in milliseconds. video exchange continues till timeout.
        </li>
        
        <br/><li><b>video-inactive</b>
        <div className="code">
        <b>Example</b><br/>
        video-inactive<br/>
        </div>
        <br/>Instruction <b>video-inactive</b> is used to set video mode to inactive. This suspends video
        TX and RX operations.
        </li>
        
        <br/><li><b>set-audio-codec &lt;variable&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        set-audio-codec [audio-codec]<br/>
        </div>
        <br/>Instruction <b>set-audio-codec</b> is used to set audio codec name. During offer 
        answer exchange, using template / variable mechanism you can extract audio codec name 
        to a variable say audio-codec. Then use this instruction to set audio codec.
        </li>
        
        <br/><li><b>set-audio-codec-number &lt;variable&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        set-audio-codec-number [audio-codec-number]<br/>
        </div>
        <br/>Instruction <b>set-audio-codec-number</b> is used to set audio codec number. During offer 
        answer exchange, using template / variable mechanism you can extract audio codec number 
        to a variable say audio-codec-number. Then use this instruction to set audio codec number.
        </li>
        
        <br/><li><b>set-dtmf-codec-number &lt;variable&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        set-dtmf-codec-number [dtmf-codec-number]<br/>
        </div>
        <br/>Instruction <b>set-dtmf-codec-number</b> is used to set dtmf codec number. During offer 
        answer exchange, using template / variable mechanism you can extract dtmf codec number 
        to a variable say dtmf-codec-number. Then use this instruction to set dtmf codec number.
        </li>
        
        <br/><li><b>set-remote-ip &lt;variable&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        set-remote-ip [remoteip]<br/>
        </div>
        <br/>Instruction <b>set-remote-ip</b> is used to set remote ip address. During offer 
        answer exchange, using template / variable mechanism you can extract remote ip address 
        to a variable say remoteip. Then use this instruction to set remote ip address.
        </li>

        <br/><li><b>set-remote-audio-rtp-port &lt;variable&gt;</b>
        <div className="code">
        <b>Example</b><br/>
        set-remote-audio-rtp-port [remote-audio-port]<br/>
        </div>
        <br/>Instruction <b>set-remote-audio-rtp-port</b> is used to set remote audio rtp port. 
        During offer  answer exchange, using template / variable mechanism you can extract 
        remote audio rtp port to a variable say remote-audio-port. Then use this instruction to 
        set remote audio rtp port.
        </li>

        </ul>
        <h3>Send Instructions</h3>
        <ul>
        <li><b>send [t-template]</b>
        <div className="code">
        <b>Examples</b><br/>
        send [t-register]<br/>
        send [t-invite]<br/>
        send [t-bye]<br/>
        </div>
        <br/>Instruction <b>send</b> is used to send a message out. The template given in send instruction
        is formatted, and resultant data is sent to remote.
        </li>
        
        <br/><li><b>send-with-blen [t-template]</b>
        <div className="code">
        <b>Examples</b>
        <br/>send-with-blen [t-invite]
        <br/>send-with-blen [t-invite-200-ok]
        </div>
        <br/>Instruction <b>send-with-blen</b> sends the messages (formatted as per template). This is very similar to 
        <b>send</b> instruction, however it sends content-length header with proper length value. In the template
        make sure, header Content-length is present with empty value.
        </li>
        
        <br/><li><b>send-over-audio-rtp-channel [t-template]</b>
        <div className="code">
        <b>Examples</b>
        <br/>send-over-audio-rtp-channel [t-req-template]
        <br/>send-over-audio-rtp-channel [t-req-template]
        </div>
        <br/>Instruction <b>send-over-audio-rtp-channel</b> sends the messages (formatted as per template). 
        This is very similar to <b>send</b> instruction, however it sends the formatted message over audio 
        channel.
        </li>
        
        </ul>

        <h3>Receive Instructions</h3>
        <ul>
        <li><b>receive [t-template] timer &lt;timeout-value&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        receive [t-register] timer 60000<br/>
        receive [t-invite] timer 60000<br/>
        </div>
        <br/>Instruction <b>receive</b> is used to check if a message is received within specified time. 
        Please note that, received message is not processed in context of receive instruction. This
        instruction is basically to flag the error in case message is not received in given time.
        </li>
        </ul>					


        
        
        <h3>Miscellaneous Instructions</h3>
        <ul>

        <li><b>wait &lt;timeout&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        wait &lt;timeout&gt;<br/>
        wait 5000<br/>
        </div>
        <br/>Instruction <b>wait</b> is used to wait for certain duration asynchronously. The timeout 
        value given is in milliseconds.
        </li>
        
        <br/><li><b>increment &lt;variable&gt; &lt;value&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        increment cseq 1<br/>
        increment count 5<br/>
        </div>
        <br/>Instruction <b>increment</b> is used to increase a variable value. As you seen in example
        variables are given without square brackets.
        </li>
        
        <br/><li><b>decrement &lt;variable&gt; &lt;value&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        decrement value 1<br/>
        decrement count 5<br/>
        </div>
        <br/>Instruction <b>decrement</b> is used to decrease a variable value. As you seen in example
        variables are given without square brackets.
        </li>
        
        <br/><li><b>set variable &lt;value&gt;</b>
        <div className="code">
        <b>Examples</b><br/>
        set variable &lt;value&gt;<br/>
        set variable 10<br/>
        </div>
        <br/>Instruction <b>set</b> is used to set value for a variable. As you seen in example
        variables are given without square brackets. Variables added to system using set command
        will be available across sessions.
        </li>
        
        </ul>
        <br/>
        
        <div className="inlinecenter">
          <button onClick={() => window.location.href="/userguide/apatb2basyntax" }>Back</button> 
          <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
          <button onClick={() => window.location.href="/userguide/httpdb2bavariables" }>Next</button>
        </div>
    </div>
  );
  
  return element;
}

export {UGAPATB2BAInstructions}

