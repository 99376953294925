
import React, {useState, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import {Line} from 'rc-progress';
import {getURLQueryVariable, setPageTitle, handleErrorResponse} from '../../routes/lm.js';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useLocation } from 'react-router-dom';

let myTimeOut = null;

const Templates = () => 
{
  const [tpHead, setTpHead] = useState({
    tpName: "",
    type: 0,
    tpDesc: "",
    toggle: false,
    tpId: getURLQueryVariable("id"),
    progress: 0,
  });

  const [tpBody, setTpBody]=useState([
  {
    tpname:"",
    format: "",
    toggle: false, 
    checked: false,
  }]);

  const [status, setStatus] = useState(null);
  const [collapseButtonState, setCollapseButtonState] = useState("Expand All");
  const [selectAllState, setSelectAllState] = useState("Select All");
  
  setPageTitle("Templates");

  var bodyTypesObj = 
  {
    0: 'Text Template' ,
    1: 'Binary Template' ,
  };
  
  const handleChange=(e,i)=> {
    const {name,value}=e.target;
    const onchangeVal = [...tpBody];
    onchangeVal[i][name]=value;
    setTpBody(onchangeVal);
  }

  const handleTextAreaChange=(e,i)=> {
    const {name,value}=e.target;
    const newTpBody = [...tpBody];
    newTpBody[i].format = value;
    setTpBody(newTpBody);
  }

  const insertOneTpBody=(e,i) => {
    e.preventDefault();

    const newTpBody = [...tpBody];
    newTpBody.splice(i, 0, {
      tpname:"",
      format:"",
      toggle: false, 
      checked: false,      
    });
    
    setTpBody(newTpBody);
  }
  
  const handleTpNameChange=(value)=> {
    const newTpHead = {...tpHead};
    newTpHead.tpName = value;
    setTpHead(newTpHead); 
  }


  const handleTpDescChange=(value)=> {
    const newTpHead = {...tpHead};
    newTpHead.tpDesc = value;
    setTpHead(newTpHead); 
  }

  function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  const handleTpTypeChange=(e)=> {
    const key = Object.keys(bodyTypesObj)[e.target.selectedIndex];
    const newTpHead = {...tpHead};
    newTpHead.type = key;
    setTpHead(newTpHead);
  }

  const handleDelClick=(e)=>{
    e.preventDefault();
    
    if(tpBody.length > 0) {
    
      let newTpBody = tpBody.filter(item => (item.checked === false));
      let length = tpBody.length - newTpBody.length;
      if(length > 0) {
        if (window.confirm("Press OK to delete " + length  + " selected item(s)")) {
          setTpBody(newTpBody);
        }
      }
    }
  }
  
  function changeDataToggleTrue(obj) {
    obj.toggle = true;
    return obj;
  }

  function changeDataToggleFalse(obj) {
    obj.toggle = false;
    return obj;
  }
  
  
  const handleExpandCollapseClick=(e)=>{
    e.preventDefault();   
    const newTsHead = {...tpHead};
    
    if(e.target.innerText === "Collapse All") {
      setCollapseButtonState("Expand All");
      let newTpBody = tpBody.map(changeDataToggleFalse);
      setTpBody(newTpBody);
      newTsHead.toggle = false;
    } else {
      setCollapseButtonState("Collapse All");
      let newTpBody = tpBody.map(changeDataToggleTrue);
      setTpBody(newTpBody);
      newTsHead.toggle = true;
    }
    
    setTpHead(newTsHead);
  }

  function changeDataCheckedTrue(obj) {
    obj.checked = true;
    return obj;
  }

  function changeDataCheckedFalse(obj) {
    obj.checked = false;
    return obj;
  }
  
  
  const handleSelectAllClick=(e)=>{
    e.preventDefault();   

    if(tpBody.length > 0) {
      if(e.target.innerText === "Select All") {
        setSelectAllState("Deselect All");
        let newTpBody = tpBody.map(changeDataCheckedTrue);
        setTpBody(newTpBody);
      } else {
        setSelectAllState("Select All");
        let newTpBody = tpBody.map(changeDataCheckedFalse);
        setTpBody(newTpBody);
      }
    }
  }


  const handleAddClick=(e)=>{
    e.preventDefault();
    
    const newTpData = {
      tpname:"",
      format: "",
      toggle: false,
      checked: false,
    };
    
    const newTpBody = [...tpBody];

    //for(let i=0; i<502; i++)
    newTpBody[newTpBody.length] = newTpData;
    setTpBody(newTpBody);
  }
  
  function getUniqueKey(i) {
    let key = "1st" + i;
    return key;
  }
  
  function getTableRowColor(tindex) {
    if((tindex%2) == 0) {
      return "#b3e6ff";
    }
    return "#66ccff";
  }
  
  function tpCellCBClicked(tindex) {    
    const newTpBody = [...tpBody];
    newTpBody[tindex].checked = !(tpBody[tindex].checked);
    setTpBody(newTpBody);
  }
  
  function tpThinClicked() {
    const newTpHead = {...tpHead};
    newTpHead.toggle = !(newTpHead.toggle);
    setTpHead(newTpHead);
  }

  function tpUpperExpand() {
    const newTpHead = {...tpHead};
    newTpHead.toggle = true;
    setTpHead(newTpHead);
  }

  function tpUpperCollapse() {
    const newTpHead = {...tpHead};
    newTpHead.toggle = false;
    setTpHead(newTpHead);
  }
    
  function tpCellClicked(tindex) {
    const newTpBody = [...tpBody];
    newTpBody[tindex].toggle = !(tpBody[tindex].toggle);
    setTpBody(newTpBody);
  }
  
  function getInnerDivKey(tindex) {
    let key = "innerdiv" + tindex;
    return key;
  }

  function setProgressBar(value) {
    tpHead.progress = value;
    const newTpHead = {...tpHead};
    setTpHead(newTpHead);
  }


  function increaseProgress() {
    tpHead.progress = tpHead.progress + 50;
    const newTpHead = {...tpHead};

    if(newTpHead.progress > 100) {
      newTpHead.progress = 100;
      setTpHead(newTpHead);
      myTimeOut = setTimeout(completeProgress, 5000);
    } else {
      setTpHead(newTpHead);
      if(tpBody.length > 0) {
        myTimeOut = setTimeout(increaseProgress, 200);
      } else {
        myTimeOut = setTimeout(increaseProgress, 100);
      }
    }
  }

  function completeProgress() {
    if(tpHead.progress >= 100) {
      tpHead.progress = 0;
      const newTpHead = {...tpHead};
      setTpHead(newTpHead);
      clearTimeout(myTimeOut); 
    }
  }

  var user = JSON.parse(localStorage.getItem('user'));

  const handleSaveAll = async (e) => {

    var recordId = -1;

    let tpName = tpHead.tpName.trim();
    if(tpName === "") {
      if(tpHead.toggle === false) {
        tpUpperExpand();
        e.preventDefault();
        alert("Please enter template name");
        return;
      }
    }

    let index = 0;
    while(index < tpBody.length) {
      if(tpBody[index].tpname.trim() === "") {
        e.preventDefault();
        alert(`Template block ${index+1}, please enter message name`);
        return;
      }
      
      if(tpBody[index].format.trim() === "") {
        e.preventDefault();
        alert(`Template block ${index+1}, please enter format`);
        return;
      }
      
      index++;
    }

    e.preventDefault();

    if(user) {
      if (window.confirm("You are about to save template " + tpHead.tpName  + ", press OK to confirm")) {

        //Create a new object reqdTpHead excluding toggle, progress members 
        const {toggle, progress, ...reqdTpHead} = tpHead;
                
        try {
          await axios.post(
            '/api/template/data/save', 
            {uid: user.uid, email: user.email, data: JSON.stringify(reqdTpHead)},
            {headers: {Authorization: user.jwt,}},
          ).then( function (res) {
            if(res.status === 200) {
              recordId = res.data.tpId;

              tpHead.tpId = recordId;
              const newTpHead = {...tpHead};
              setTpHead(newTpHead);               
              
              setProgressBar(5);
            } else {
              console.log("Template save, got error " + res.status);
            }
          })
        }catch(err) {
          handleErrorResponse(err);
        }

        if(recordId > 0) {
          if(tpBody.length > 0) {
            //Create array of new objects excluding toggle, checked property of array elements (object)
            const reqdTpData = tpBody.map(({toggle, checked, ...rest}) => rest);
            const tpJsonData = JSON.stringify(reqdTpData);
            const tpBodySha256 = CryptoJS.SHA256(tpJsonData).toString();
            
            try {
              await axios.post(
                '/api/templateblocks/data/save',
                {uid: user.uid, email: user.email, tpid: recordId, sha256: tpBodySha256, data: tpJsonData},
                {headers: {Authorization: user.jwt,}},
              ).then ( function (res2) {
                if(res2.status === 200) {
                  //success. here show a dialog or progress bar
                  //setProgressBar(1);
                  myTimeOut = setTimeout(increaseProgress, 200);
                } else {
                  console.log("Template blocks save, got error " + res2.status);
                }
              });
            } catch(err2) {
              handleErrorResponse(err2);
            }
          } else {
            myTimeOut = setTimeout(increaseProgress, 100);
          }
        } else {
          console.log(`Error in template blocks save/update, tpid=${recordId} and test blocks array length=${tpBody.length}`);
        }
      }
    }    
  };
 

  const fetchTemplate = async () => {
    
    if(Math.round(tpHead.tpId) === -1) {
      return;
    }

    var flag = 0;
    
    try {
      await axios.post('/api/template/data/fetch', 
      {uid: user.uid, email: user.email, tpid: tpHead.tpId},
      {headers: {Authorization: user.jwt,}}).then (function (res) {
        if(res.status === 200) {
          if(res.data.length > 0) {
            const newTpHead = {...tpHead};
            
            newTpHead.tpDesc = res.data[0].description;
            newTpHead.tpName = res.data[0].name;
            newTpHead.type = res.data[0].type;
            
            setTpHead(newTpHead);
            flag = 1;
          } else {
              console.log("No templaterecord found");
          }          
        } else {
          console.log("Error in searching template record");
        } 
      });
      
    } catch (err) {
        handleErrorResponse(err);
    }
    
    if(flag === 1) {
      try {
       
        await axios.post('/api/templateblocks/data/fetch',
        {uid: user.uid, email: user.email, tpid: tpHead.tpId},
        {headers: {Authorization: user.jwt,}}).then (function (res) {
          
          if(res.status === 200) {
            
            let index=0;
            const newTpBody = [...tpBody];
            while(index < res.data.length) {
              const newTpData = {};

              newTpData.tpname = res.data[index].name;
              newTpData.format = res.data[index].format;
              newTpData.toggle = false;
              newTpData.checked = false;
              
              newTpBody[index] = newTpData;
              index++;
            }
            
            setTpBody(newTpBody);
            
          } else {
            console.log("No template record found");
          }
        })
      } catch (err) {
        handleErrorResponse(err);
      }    
    }
    
  }

  //Hook to get data from backend server
  useEffect ( () => {
     fetchTemplate();
  }, []);  

  if(user) 
  {
    return (
    <form name="tpform" id="tpform" onSubmit={handleSaveAll}>
      <div key="tpmain" className="medium-text">
        <div id="tpmain2" className="center" >
          {(tpHead.tpName.length == 0) ? 
            <h3><p align="center">Templates</p></h3> : 
            <h3><p align="center">Template [{tpHead.tpName}]</p></h3>}

          { 
            tpHead.toggle ? (

              <div id="tpmain3" className="txupper"> 
                
                <div id="tpmain2" style={{cursor: "zoom-out"}} onClick={()=>{tpThinClicked()}} >
                  <p align="right">
                    <button className="minibuttons" onClick={tpThinClicked}></button>
                  </p>
                </div>
                
                
                <table key={`tpuppertbl`} className="tstbl">
                  <tbody>
                    <tr>
                      <td style={{width: "80%"}}>
                        Name<br/>
                        <input required name="name" style={{width: "100%"}}
                        value={tpHead.tpName}
                        onChange={(e)=>handleTpNameChange(e.target.value)} /> 
                      </td>
                      <td style={{width: "20%"}}>
                      Type<br/>
                        <select style={{width: "100%"}} value={bodyTypesObj[tpHead.type]} onChange={handleTpTypeChange}>
                          {Object.keys(bodyTypesObj).map(item => 
                            <option key={`tptype${item}`}>
                              {bodyTypesObj[item]}
                            </option>
                          )}
                        </select>                        
                      </td>
                    </tr>
                  </tbody>
                </table>
                

                <br/><label>Template description</label>
                <textarea value={tpHead.tpDesc} name="description" 
                  spellcheck="false" 
                  rows={4} cols={5}  style={{width: "100%"}}
                  onChange={(e) => handleTpDescChange(e.target.value)}>                                      
                </textarea> 

                <br/>
              </div>  
              
            ) : (
              <div id="tpmain3" className="txthinner" 
              style={{cursor: "zoom-in", background: getTableRowColor(1)}} onClick={()=>{tpThinClicked()}}
              >
              Template name and type details
              </div>
            ) 
          }

          <br/><br/><h3><p align="center">Template Messages</p></h3>
          
          {
            tpBody.map((val,tindex)=> 
              <div key={getInnerDivKey(tindex)} className="tstbl">
              <table key={`tblmain{tindex}`} >

                <tbody>
                  { val.toggle ? (<tr  key={`tblmainrow{tindex}`}

                    style={{background: getTableRowColor(tindex)}}  >
                    <td style={{cursor: "zoom-out"}} onClick={()=>{tpCellClicked(tindex)}} key={`tblmaintc{tindex}`}> 
                      <b>&nbsp;{tindex+1}</b>&nbsp;&nbsp;
                    </td>

                    <td >
                      <br/>
                      Name<br/>
                      <input required name="tpname" value={val.tpname} 
                      style={{width: "100%"}}
                      onChange={(e)=>handleChange(e,tindex)} />
                      <br/>
                      Format
                      <br/>
                      <textarea required name="format" rows={8} cols={40} 
                        spellcheck="false" 
                        value={val.format}
                        onChange={(e)=>handleTextAreaChange(e,tindex)}                                      
                      />
                    </td>
                    
                    <td key={`tblrowtc{tindex}`} > 
                      <Tooltip title="Insert test block just above this." arrow>
                        <button className="miniimgbtns" onClick={(e)=>insertOneTpBody(e,tindex)}> ++ </button>
                      </Tooltip>
                      &nbsp;<input type="checkbox" id={`chk{tindex}`} 
                        onChange={()=>{tpCellCBClicked(tindex)}} 
                        checked={val.checked}
                      />
                      &nbsp;
                   </td> 
                  </tr>
                  ) : 
                  (
                    <tr key={`tblmainrow{tindex}`} style={{background: getTableRowColor(tindex)}}  >
                    <td style={{cursor: "zoom-in"}} onClick={()=>{tpCellClicked(tindex)}} key={`tblmaintc{tindex}`}> 
                      <b> &nbsp;{tindex+1}</b>&nbsp;&nbsp;
                    </td>                              

                    <td style={{cursor: "zoom-in", width:"600px"}} onClick={()=>{tpCellClicked(tindex)}}>
                      {val.tpname ? val.tpname : "None"}
                    </td>
                    
                    <td key={`tblrowtc{tindex}`}> 
                      <Tooltip title="Insert test block just above this." arrow>
                        <button className="miniimgbtns" onClick={(e)=>insertOneTpBody(e,tindex)}> ++ </button>
                      </Tooltip>
                      &nbsp;<input type="checkbox" id={`chk{tindex}`} 
                      onChange={()=>{tpCellCBClicked(tindex)}} 
                      checked={val.checked} 
                    />
                      &nbsp;
                    </td>
                    
                    </tr>
                  )} 
                </tbody>
              </table>
              </div>
            )
          }




        </div>  

        <div className="inlinecenter">
          <Line className="progressbar" style={{visibility: (tpHead.progress > 0) ? "visible" : "hidden"}} percent={tpHead.progress} strokeWidth="1" strokeColor="#009973" />
          <br/>
          <button onClick={handleAddClick}>Add</button> 
          <button onClick={handleDelClick}>Delete</button>
          <button onClick={handleExpandCollapseClick}>{collapseButtonState}</button>
          <button onClick={handleSelectAllClick}>{selectAllState}</button>
          <button>Save</button>
        </div>
          
      </div>
    </form>);      
  } 
  else 
  {
    return(
      <div key="tpmain" className="medium-text">
        <div id="tpmain2" className="center" >
          You are not allowed to access this page.
        </div>
      </div>
    );
  }
};

const EditSingleTemplates = () => {
  return Templates();
};

const EditTemplates = () => {
  const initState = [{id: 0, name: "", checked: false}];
  const [state, setState] = useState(initState);
  const [status, setStatus] = useState(null);
  const [selectAllBtnText, setSelectAllBtnText] = useState("Select All");

  var user = null;
  const userData = localStorage.getItem('user');

  if(userData != null) {
    user = JSON.parse(userData);
  }
  setPageTitle("Templates");
  const fetchTemplates = async () => {
    setStatus(null);
    if(user != null) {
      try {
        const res = await axios.post('/api/templates/edit', 
        {uid: user.uid, email: user.email} ,
        {headers: {
          'Authorization': user.jwt,
        }}).then( 
        function (res) {
          if(res.status === 200) {
            //This also works, keeping it as reference
            //const newState = res.data.map(obj => ({...obj, checked: false}));
            
            res.data.forEach(element => (element.checked = false));

            setState(res.data);
          }else {
            setStatus({
              type: 'error',
              message: 'Error in displaying data',
            });  
          }
        });
      }catch(err) {
        setStatus({
          type: 'error',
          message: err.message,
        }); 
        handleErrorResponse(err);
      }
    } else {
      setStatus({
        type: 'error',
        message: "User not logged in",
      });  
    }
  }
  
  useEffect ( () => {
   fetchTemplates();
  }, []);
  

  function tpRowCBClicked(tindex) {
    const newState = [...state];
    newState[tindex].checked = !(state[tindex].checked);
    setState(newState);
  }

  const handleEditTpDelete= async (e)=> {
    e.preventDefault();
      
    if(state.length > 0) {

      let delIds = [];
      let newState = [];
      
      state.forEach(function (element) {
        if(element.checked) {
          delIds.push(element.id);
        } else {
          newState.push(element);
        }
      });
      
      if(delIds.length > 0) {
        if (window.confirm("Press OK to delete " + delIds.length  + " selected template(s)")) {
          try {
            const res = await axios.post('/api/templates/data/delete', 
            {uid: user.uid, email: user.email, data: JSON.stringify(delIds) } ,
            {headers: {
              'Authorization': user.jwt,
            }}).then( 
            function (res) {
              if(res.status === 200) {
                setState(newState);
              }else {
                setStatus({
                  type: 'error',
                  message: 'Error in displaying data',
                });  
              }
            });
          }catch(err) {
            setStatus({
              type: 'error',
              message: err.message,
            }); 
            handleErrorResponse(err);
          } 
        }
      }
    }
  }
  
  function makeTSRowChecked(obj) {
    obj.checked = true;
    return obj;
  }

  function makeTSRowUnchecked(obj) {
    obj.checked = false;
    return obj;
  }
  
  const handleEditTpSelectAll=(e)=> {
    e.preventDefault();
    
    if(state.length > 0) {
      if(e.target.innerText === "Select All") {
        setSelectAllBtnText("Deselect All");
        const newState = state.map(makeTSRowChecked);
        setState(newState);
      } else {
        setSelectAllBtnText("Select All");
        const newState = state.map(makeTSRowUnchecked);
        setState(newState);
      }
    } 
  }
  
  if(user === null) {
    window.location.href = "/authrequired";
  } else {
    var tindex = 0;
    return (
      <div className="medium-text">
        <div className="center">
          <h3><p align="center">Templates</p></h3>
          
          <table className="tealtbl">
          
            <thead>
              <tr>
                <th>Serial</th>
                <th>Template</th>
                <th>Select</th>
              </tr>
            </thead>                
            <tbody>
              {state.map((item, index) => (
                <tr key={`row${index}`}>
                  <td>{(++tindex)}</td>
                  <td><a href={`/components/editsingletemplates?id=${item.id}`}>{item.name}</a></td>
                  <td>
                    <input type="checkbox" id={`chk{index}`} 
                      onChange={()=>{tpRowCBClicked(index)}} 
                      checked={item.checked} 
                    />
                  
                  </td>
                </tr>
              ))}
            </tbody>
          </table> 
        </div>


        <div className="inlinecenter small-text">
        {status && <div className={`alert ${status.type}`}>{status.message}</div>}
          <button onClick={handleEditTpDelete}>Delete</button>
          <button onClick={handleEditTpSelectAll}>{selectAllBtnText}</button>
        </div>


      </div>
    );
  }
  
};

  
export {Templates, EditSingleTemplates, EditTemplates};
