
import { lmURISchemes, lmICECandidateType } from '../../../routes/lm.js';

/*
* Please note the formats are indented from start of line intentionally.
* Do not do normal formating else sip messages will have additional spaces.
*/

function sipTxRegister(userConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[userConfig.urischeme];
  
  return {
    tpname: 'tx-register',
    format: 
`REGISTER ${urischeme}:[p-server] SIP/2.0\\r\\n
Via: SIP/2.0/[p-transport] [p-local-ipaddress]:[p-local-client-port];branch=z9hG4bK[p-random-string]\\r\\n
From: <${urischeme}:[p-private-user]@[p-domain]>;tag=[p-random-string]\\r\\n
To: <${urischeme}:[p-private-user]@[p-domain]>\\r\\n
Call-ID: [p-random-string]@[p-domain]\\r\\n
CSeq: 1 REGISTER\\r\\n
Allow: INVITE, ACK, CANCEL, BYE, PRACK, UPDATE, REFER, MESSAGE, OPTIONS, NOTIFY, SUBSCRIBE\\r\\n
Contact: <${urischeme}:[p-private-user]@[p-local-ipaddress]:[p-local-client-port];transport=[p-transport]>\\r\\n
Max-Forwards: 70\\r\\n
Expires: [reg-expire-time]\\r\\n
Content-Length: 0\\r\\n
\\r\\n`
  };
}

function sipRxRegister401() {
  return {
    tpname: 'rx-register-401',
    format: 
`SIP/2.0 401 [...]From:[...]\\r\\n
[...]Call-ID:[...]`  
  }
};

function sipRxRegister200() {
  return {
    tpname: 'rx-register-200',
    format: 
`SIP/2.0 200 [...]From:[...]tag=[reg-from-tag]\\r\\n
[...]Call-ID: [reg-call-id]@[...]`  
  }
};

function sipTxUnRegister(userConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[userConfig.urischeme];
  
  return {
          tpname: 'tx-unregister', 
          format: 
`REGISTER ${urischeme}:[p-server] SIP/2.0\\r\\n
Via: SIP/2.0/[p-transport] [p-local-ipaddress]:[p-local-client-port];branch=z9hG4bK[p-random-string]\\r\\n
From: <${urischeme}:[p-private-user]@[p-domain]>;tag=[p-random-string]\\r\\n
To: <${urischeme}:[p-private-user]@[p-domain]>\\r\\n
Call-ID: [p-random-string]@[p-domain]\\r\\n
CSeq: 2 REGISTER\\r\\n
Allow: INVITE, ACK, CANCEL, BYE, PRACK, UPDATE, REFER, MESSAGE, OPTIONS, NOTIFY, SUBSCRIBE\\r\\n
Contact: *\\r\\n
Max-Forwards: 70\\r\\n
Expires: 0\\r\\n
Content-Length: 0\\r\\n
\\r\\n`
  };
}

function sipRxUnRegister200() {
  return {
          tpname: 'rx-unregister-200', 
          format: 
`SIP/2.0 200 OK\\r\\n
[...]CSeq:[...]REGISTER[...]`,
  };
}

function wsHandshake() {
  return {
    tpname: 'ws-handshake',
    format: 
`GET / HTTP/1.1\\r\\n
Host: [p-server]:[p-server-port]\\r\\n
Connection: Upgrade\\r\\n
Pragma: no-cache\\r\\n
Cache-Control: no-cache\\r\\n
Upgrade: websocket\\r\\n
Origin: [p-local-ipaddress]:[p-local-client-port]\\r\\n
Sec-WebSocket-Version: 13\\r\\n
User-Agent: Multiplier 1.0\\r\\n
Sec-WebSocket-Protocol: sip\\r`
  };
}

function sipWebRTCTxInvite(userConfig, callConfig, turnConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[userConfig.urischeme];
  let ctype = lmICECandidateType(turnConfig.icemode);
  

  let msg =
`INVITE ${urischeme}:[p-called-party-user:session1]@[p-server] SIP/2.0\\r\\n
Via: SIP/2.0/[p-transport] [p-local-ipaddress]:[p-local-client-port];rport;branch=z9hG4bK[p-random-hex-string]\\r\\n
From: <${urischeme}:[p-private-user]@[p-server]>;tag=[p-random-hex-string]\\r\\n
To: <${urischeme}:[p-called-party-user:session1]@[p-server]>\\r\\n
Contact: <${urischeme}:[p-private-user]@[p-local-ipaddress]:[p-local-client-port];transport=[p-transport]>\\r\\n
Call-ID: [p-random-hex-string]\\r\\n
CSeq: 2 INVITE\\r\\n
Max-Forwards: 70\\r\\n
Required: 100rel,precondition\\r\\n
Allow: INVITE, ACK, CANCEL, BYE, REFER, MESSAGE, SUBSCRIBE, NOTIFY, PUBLISH\\r\\n
Content-type: application/sdp\\r\\n
User-Agent: multiplier-v2.0\\r\\n
Organization: Load Multiplier\\r\\n
Content-Length:\\r\\n
\\r\\n
v=0\\r\\n
o=- [sess-id] [sess-version] IN IP4 [p-local-ipaddress]\\r\\n
s=-\\r\\n
t=0 0\\r\\n
a=msid-semantic: WMS [msid]\\r\\n\n`;

  if(callConfig.avbundle === 1) {
    msg += `a=group:BUNDLE audio video\\r\\n\n`;
  }

  msg += 
`m=audio [p-audio-rtp-port:session1] RTP/SAVPF [p-audio-codec-number]\\r\\n
c=IN IP4 [p-local-ipaddress]\\r\\n
a=rtcp:[p-audio-rtp-port:session1] IN IP4 [p-local-ipaddress]\\r\\n
a=candidate:[p-audio-rtp-${ctype}-candidate:session1]\\r\\n
a=ice-ufrag:[p-audio-rtp-ufrag:session1]\\r\\n
a=ice-pwd:[p-audio-rtp-icepasswd:session1]\\r\\n
a=fingerprint:[p-fingerprint:session1]\\r\\n
a=mid:audio\\r\\n
a=sendrecv\\r\\n
a=rtpmap:[p-audio-codec-number] [p-audio-codec]/[p-audio-sampling-rate]\\r\\n
a=rtcp-fb:[p-audio-codec-number] transport-cc\\r\\n
a=ssrc:[p-local-audio-ssrc:session1] cname:Kim35YICmo924Tl2\\r\\n
a=ssrc:[p-local-audio-ssrc:session1] msid:[msid] [label]\\r\\n
a=ssrc:[p-local-audio-ssrc:session1] mslabel:[msid]\\r\\n
a=ssrc:[p-local-audio-ssrc:session1] label:[label]\\r\\n\n`;

  if(callConfig.vcodec > 0) {
    msg += 
`m=video [p-video-rtp-port:session1] RTP/SAVPF [p-video-codec-number]\\r\\n
c=IN IP4 [p-local-ipaddress]\\r\\n
a=rtcp:[p-video-rtp-port:session1] IN IP4 [p-local-ipaddress]\\r\\n
a=candidate:[p-video-rtp-${ctype}-candidate:session1]\\r\\n
a=ice-ufrag:[p-video-rtp-ufrag:session1]\\r\\n
a=ice-pwd:[p-video-rtp-icepasswd:session1]\\r\\n
a=fingerprint:[p-fingerprint:session1]\\r\\n
a=setup:actpass\\r\\n
a=mid:video\\r\\n
a=sendrecv\\r\\n\n`;
  }
  
  if(callConfig.rtcpmux > 0) {
    msg += `a=rtcp-mux\\r\\n\n`;
  }

  if(callConfig.vcodec > 0) {
    msg += 
`a=rtpmap:[p-video-codec-number] [p-video-codec]/[p-video-sampling-rate]\\r\\n
a=rtcp-fb:[p-video-codec-number] transport-cc\\r\\n
a=ssrc:[p-local-video-ssrc:session1] cname:Kim35YICmo924Tl6\\r\\n
a=ssrc:[p-local-video-ssrc:session1] msid:[msid] [label]\\r\\n
a=ssrc:[p-local-video-ssrc:session1] mslabel:[msid]\\r\\n
a=ssrc:[p-local-video-ssrc:session1] label:[label]\\r\\n`; 
  }
  
  return {
    tpname: 'tx-invite',
    format: msg
  };
}

function sipTxInvite(userConfig, callConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[userConfig.urischeme];
  
      let msg = 
`INVITE ${urischeme}:[p-called-party-user:session1]@[p-server] SIP/2.0\\r\\n
Via: SIP/2.0/[p-transport] [p-local-ipaddress]:[p-local-client-port];rport;branch=z9hG4bK[p-random-hex-string]\\r\\n
From: <${urischeme}:[p-private-user]@[p-server]>;tag=[p-random-hex-string]\\r\\n
To: <${urischeme}:[p-called-party-user:session1]@[p-server]>\\r\\n
Contact: <${urischeme}:[p-private-user]@[p-local-ipaddress]:[p-local-client-port];transport=[p-transport]>\\r\\n
Call-ID: [p-random-hex-string]\\r\\n
CSeq: 2 INVITE\\r\\n
Max-Forwards: 70\\r\\n
Required: 100rel,precondition\\r\\n
Allow: INVITE, ACK, CANCEL, BYE, REFER, MESSAGE, SUBSCRIBE, NOTIFY, PUBLISH\\r\\n
Content-type: application/sdp\\r\\n
User-Agent: multiplier-v2.0\\r\\n
Organization: Load Multiplier\\r\\n
Content-Length:\\r\\n
\\r\\n
v=0\\r\\n
o=- [sess-id] [sess-version] IN IP4 [p-local-ipaddress]\\r\\n
s=-\\r\\n
t=0 0\\r\\n\n`;      
      
      if(callConfig.avbundle === 1) {
        msg += "a=group:BUNDLE audio video\\r\\n\n";
      }
      
      msg += 
`m=audio [p-audio-rtp-port:session1] RTP/AVP [p-audio-codec-number]\\r\\n
c=IN IP4 [p-local-ipaddress]\\r\\n
a=rtcp:[p-audio-rtp-port:session1] IN IP4 [p-local-ipaddress]\\r\\n
a=sendrecv\\r\\n
a=rtpmap:[p-audio-codec-number] [p-audio-codec]/[p-audio-sampling-rate]\\r\\n`;
            
      if(callConfig.vcodec !== 0) {
        if(callConfig.webrtc) {
          msg += `\na=rtcp-mux\\r\\n`;
        }
        
        msg += 
`\nm=video [p-video-rtp-port:session1] RTP/AVP [p-video-codec-number]\\r\\n
c=IN IP4 [p-local-ipaddress]\\r\\n
a=rtcp:[p-video-rtp-port:session1] IN IP4 [p-local-ipaddress]\\r\\n
a=sendrecv\\r\\n
a=rtpmap:[p-video-codec-number] [p-video-codec]/[p-video-sampling-rate]\\r\\n`;       
      } 

  return {
    tpname: 'tx-invite',
    format: msg
  };
}

function sipRxInvite100() {
  return {
    tpname: "rx-invite-100",
    format: "SIP/2.0 100[...]"
  };
}

function sipRxInvite180(userConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[userConfig.urischeme];
  
  return {
    tpname: "rx-invite-180",
    format:
`SIP/2.0 180[...]\\r\\n
From:[...]<${urischeme}:[from-user:session1]@[...]tag=[from-tag:session1]\\r\\n
To:[...]<${urischeme}:[to-user:session1]@[...]tag=[to-tag:session1]\\r\\n
[...]Call-ID: [callid_1]\\r\\n
[...]CSeq: [cseq] INVITE\\r\\n
[...]Contact: <[remote-contact]>\\r\\n
[...]`    
  };
}

function sipRxInvite200(userConfig, callConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[userConfig.urischeme];
  
  let msg = 
`SIP/2.0 200 OK[...]\\r\\n
From:[...]<${urischeme}:[from-user:session1]@[...]tag=[from-tag:session1]\\r\\n
To:[...]<${urischeme}:[to-user:session1]@[...]tag=[to-tag:session1]\\r\\n
[...]Call-ID: [callid_1]\\r\\n
[...]CSeq: [cseq] INVITE\\r\\n
[...]Contact: <[remote-contact]>\\r\\n
[...]m=audio [remote-audio-rtp-port] RTP/AVP [remote-acodec-number]\\r\\n
[...]c=IN IP4 [remote-ip]\\r\\n
[...]a=rtpmap:[...] [remote-acodec-name]/[...]\\r\\n`;
        
  if(callConfig.vcodec !== 0) {
    msg += 
`\n[...]m=video [remote-video-rtp-port] RTP/AVP [remote-vcodec-number]\\r\\n
[...]c=IN IP4 [remote-video-ip]\\r\\n
[...]a=rtpmap:[...] [remote-vcodec-name]/[...]`;          
  }
  
  return {
    tpname: 'rx-invite-200',
    format: msg
  };
}

function sipWebRTCRxInvite200(userConfig, callConfig, turnConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[userConfig.urischeme];
  let ctype = lmICECandidateType(turnConfig.icemode);
  
  let msg = 
`SIP/2.0 200 OK[...]\\r\\n
From:[...]<${urischeme}:[from-user:session_id_1]@[...]tag=[from-tag:session_id_1]\\r\\n
To:[...]<${urischeme}:[to-user:session_id_1]@[...]tag=[to-tag:session_id_1]\\r\\n
[...]Call-ID: [callid_1]\\r\\n
[...]CSeq: [cseq] INVITE\\r\\n
[...]Contact: <[remote-contact]>\\r\\n
[...]m=audio [mport] [...]\\r\\n
[...]a=candidate:[remote-audio-rtp-candidate]\\r\\n
[...]a=ice-ufrag:[remote-audio-rtp-ufrag]\\r\\n
[...]a=ice-pwd:[remote-audio-rtp-icepasswd]\\r\\n
[...]a=rtpmap:[remote-acodec-number] [remote-acodec-name]/[...]\\r\\n`;

  if(callConfig.vcodec !== 0) {
    msg += 
`\n[...]m=video [mport] [...]\\r\\n
[...]a=candidate:[remote-video-rtp-candidate]\\r\\n
[...]a=ice-ufrag:[remote-video-rtp-ufrag]\\r\\n
[...]a=ice-pwd:[remote-video-rtp-icepasswd]\\r\\n
[...]a=rtpmap:[remote-vcodec-number] [remote-vcodec-name]/[...]\\r\\n`;
  }

  return {
    tpname: 'rx-invite-200',
    format: msg
  };
}


function sipTxAck(userConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[userConfig.urischeme];
  
  return {
    tpname: 'tx-ack',
    format:
`ACK [remote-contact] SIP/2.0\\r\\n
Via: SIP/2.0/[p-transport] [p-local-ipaddress];branch=z9hG4bK[p-random-hex-string];rport\\r\\n
From: <${urischeme}:[from-user:session1]@[p-server]>;tag=[from-tag:session1]\\r\\n
To: <${urischeme}:[to-user:session1]@[p-server]>;tag=[to-tag:session1]\\r\\n
Contact: <${urischeme}:[from-user:session1]@[p-local-ipaddress];transport=[p-transport]>\\r\\n
Call-ID: [callid_1]\\r\\n
CSeq: [cseq] ACK\\r\\n
Max-Forwards: 70\\r\\n
User-Agent: multiplier-v2.0\\r\\n
Organization: Load Multiplier\\r\\n
Content-Length: 0\\r\\n
\\r\\n`   
  };
}

function sipTxBye(userConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[userConfig.urischeme];
  
  return {
    tpname: 'tx-bye',
  format:
`BYE [remote-contact] SIP/2.0\\r\\n
Via: SIP/2.0/[p-transport] [p-local-ipaddress]:[p-local-client-port];rport;branch=z9hG4bK[p-random-hex-string]\\r\\n
Max-Forwards: 70\\r\\n
From: <${urischeme}:[p-private-user]@[p-server]>;tag=[from-tag:session1]\\r\\n
To: <${urischeme}:[p-called-party-user:session1]@[p-server]>;tag=[to-tag:session1]\\r\\n
Call-ID: [callid_1]\\r\\n
CSeq: 6 BYE\\r\\n
Contact: <${urischeme}:[p-private-user]@[p-local-ipaddress]:[p-local-client-port];transport=[p-transport]>\\r\\n
Content-Length: 0\\r\\n
\\r\\n`   
  };
}

function sipRxBye200() {
  return {
    tpname: 'rx-bye-200',
    format:
`SIP/2.0 200 OK\\r\\n
[...]CSeq: [...]\\r\\n
[...]`
  };
}


function sipRxInvite(userConfig, callConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[userConfig.urischeme];
  
  let msg = 
`INVITE[...]From:[...]${urischeme}:[from-user:session1]@[...]tag=[from-tag:session1]\\r\\n
To:[...]${urischeme}:[to-user:session1]@[...]\\r\\n
[...]Call-ID: [call-id:session1]\\r\\n
[...]CSeq: [cseq] INVITE\\r\\n
[...]m=audio [remote-audio-rtp-port] [...]\\r\\n
[...]c=IN IP4 [remote-ip]\\r\\n
[...]a=rtpmap:[remote-acodec-number] [remote-acodec-name]/[...]\\r\\n
[...]m=video [remote-video-rtp-port] [...]\\r\\n`;
  
  if(callConfig.vcodec !== 0) {
    msg += 
`\n[...]c=IN IP4 [remote-video-ip]\\r\\n
[...]a=rtpmap:[remote-vcodec-number] [remote-vcodec-name]/[...]\\r\\n`;    
  }
  
  return {
    tpname: 'rx-invite',
    format: msg,
  };
}

function sipWebRTCRxInvite(userConfig, callConfig, turnConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[userConfig.urischeme];
  let ctype = lmICECandidateType(turnConfig.icemode);
  
  let msg = 
`INVITE[...]From:[...]${urischeme}:[from-user:session1]@[...]tag=[from-tag:session1]\\r\\n
To:[...]${urischeme}:[to-user:session1]@[...]\\r\\n
[...]Call-ID: [call-id:session1]\\r\\n
[...]CSeq: [cseq] INVITE\\r\\n
[...]m=audio [mport] [...]\\r\\n
[...]c=IN IP4 [remote-ip]\\r\\n
[...]a=candidate:[remote-audio-rtp-candidate]\\r\\n
[...]a=ice-ufrag:[remote-audio-rtp-ufrag]\\r\\n
[...]a=ice-pwd:[remote-audio-rtp-icepasswd]\\r\\n
[...]a=rtpmap:[remote-acodec-number] [remote-acodec-name]/[...]\\r\\n`;

  if(callConfig.vcodec !== 0) {
    msg += 
`\n[...]m=video [...]\\r\\n
[...]c=IN IP4 [remote-video-ip]\\r\\n
[...]a=candidate:[remote-video-rtp-candidate]\\r\\n
[...]a=ice-ufrag:[remote-video-rtp-ufrag]\\r\\n
[...]a=ice-pwd:[remote-video-rtp-icepasswd]\\r\\n
[...]a=rtpmap:[remote-vcodec-number] [remote-vcodec-name]/[...]\\r\\n`;  
  }
  
  return {
    tpname: 'rx-invite',
    format: msg,
  };
}

function sipTxInvite180(userConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[userConfig.urischeme];
  
  return {
    tpname: 'tx-invite-180',
    format:
`SIP/2.0 180 Ringing\\r\\n
From: <${urischeme}:[from-user:session1]@[p-server]>;tag=[from-tag:session1]\\r\\n
To: <${urischeme}:[to-user:session1]@[p-server]>;tag=[totag]\\r\\n
Contact: <${urischeme}:[p-private-user]@[p-local-ipaddress]:[p-local-client-port];transport=[p-transport]>\\r\\n
Call-ID: [call-id:session1]\\r\\n
CSeq: [cseq] INVITE\\r\\n
Content-Type: application/sdp\\r\\n
Allow: ACK, BYE, CANCEL, INVITE, MESSAGE, NOTIFY, OPTIONS, PRACK, REFER, UPDATE\\r\\n
Content-Length:0\\r\\n
\\r\\n`
  };
}

function sipTxInvite200(userConfig, callConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[userConfig.urischeme];
  
  let msg = 
`SIP/2.0 200 OK\\r\\n
From: <${urischeme}:[from-user:session1]@[p-server]>;tag=[from-tag:session1]\\r\\n
To: <${urischeme}:[to-user:session1]@[p-server]>;tag=[totag]\\r\\n
Contact: <${urischeme}:[p-private-user]@[p-local-ipaddress]:[p-local-client-port];transport=[p-transport]>\\r\\n
Call-ID: [call-id:session1]\\r\\n
CSeq: [cseq] INVITE\\r\\n
Content-Type: application/sdp\\r\\n
Allow: ACK, BYE, CANCEL, INVITE, MESSAGE, NOTIFY, OPTIONS, PRACK, REFER, UPDATE\\r\\n
Content-Length:\\r\\n
\\r\\n
v=0\\r\\n
o=- [sess-id] [sess-version] IN IP4 [p-local-ipaddress]\\r\\n
s=-\\r\\n
t=0 0\\r\\n`;

  if(callConfig.avbundle === 1) {
    msg += 
`\na=group:BUNDLE audio video\\r\\n`;    
  }
 
  msg += 
`\nm=audio [p-audio-rtp-port:session1] RTP/AVP [p-audio-codec-number]\\r\\n
c=IN IP4 [p-local-ipaddress]\\r\\n
a=sendrecv\\r\\n
a=rtpmap:[p-audio-codec-number] [p-audio-codec]/[p-audio-sampling-rate]\\r\\n`;
  
  if(callConfig.vcodec !== 0) {
    msg += 
`\nm=video [p-video-rtp-port:session1] RTP/AVP [p-video-codec-number]\\r\\n
c=IN IP4 [p-local-ipaddress]\\r\\n
a=sendrecv\\r\\n
a=rtpmap:[p-video-codec-number] [p-video-codec]/[p-video-sampling-rate]\\r\\n`;
  }
  
  msg += `\na=maxptime:60\\r\\n`;
  
  return {
    tpname: 'tx-invite-200',
    format: msg,
  };
}

function sipWebRTCTxInvite200(userConfig, callConfig, turnConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[userConfig.urischeme];
  let ctype = lmICECandidateType(turnConfig.icemode);
  
  let msg = 
`SIP/2.0 200 OK\\r\\n
From: <${urischeme}:[from-user:session1]@[p-server]>;tag=[from-tag:session1]\\r\\n
To: <${urischeme}:[to-user:session1]@[p-server]>;tag=[p-random-hex-string]\\r\\n
Contact: <${urischeme}:[p-private-user]@[p-local-ipaddress]:[p-local-client-port];transport=[p-transport]>\\r\\n
Call-ID: [call-id:session1]\\r\\n
CSeq: [cseq] INVITE\\r\\n
Content-Type: application/sdp\\r\\n
Allow: ACK, BYE, CANCEL, INVITE, MESSAGE, NOTIFY, OPTIONS, PRACK, REFER, UPDATE\\r\\n
Content-Length:\\r\\n
\\r\\n
v=0\\r\\n
o=- [sess-id] [sess-version] IN IP4 [p-local-ipaddress]\\r\\n
s=ice\\r\\n
t=0 0\\r\\n\n`;

  if(callConfig.avbundle > 0) {
    msg += `a=group:BUNDLE audio video\\r\\n\n`;
  }
  
  msg += 
`m=audio [p-audio-rtp-port:session1] RTP/SAVPF [p-audio-codec-number]\\r\\n
c=IN IP4 [p-local-ipaddress]\\r\\n
a=rtcp:[p-audio-rtp-port:session1] IN IP4 0.0.0.0\\r\\n
a=candidate:[p-audio-rtp-${ctype}-candidate:session1]\\r\\n
a=ice-ufrag:[p-audio-rtp-ufrag:session1]\\r\\n
a=ice-pwd:[p-audio-rtp-icepasswd:session1]\\r\\n
a=fingerprint:[p-fingerprint:session1]\\r\\n
a=setup:active\\r\\n
a=sendrecv\\r\\n
a=rtpmap:[p-audio-codec-number] [p-audio-codec]/[p-audio-sampling-rate]\\r\\n
a=rtcp-fb:[p-audio-codec-number] transport-cc\\r\\n`;

  if(callConfig.vcodec !== 0) {
    msg += 
`\nm=video [p-video-rtp-port:session1] RTP/SAVPF [p-video-codec-number]\\r\\n
c=IN IP4 [p-local-ipaddress]\\r\\n
a=rtcp:[p-video-rtp-port:session1] IN IP4 0.0.0.0\\r\\n
a=candidate:[p-video-rtp-${ctype}-candidate:session1]\\r\\n
a=ice-ufrag:[p-video-rtp-ufrag:session1]\\r\\n
a=ice-pwd:[p-video-rtp-icepasswd:session1]\\r\\n
a=fingerprint:[p-fingerprint:session1]\\r\\n
a=setup:active\\r\\n
a=sendrecv\\r\\n
a=rtpmap:[p-video-codec-number] [p-video-codec]/[p-video-sampling-rate]\\r\\n
a=rtcp-fb:[p-video-codec-number] transport-cc\\r\\n
a=maxptime:70\\r\\n`;  
  }
  
  if(callConfig.rtcpmux) {
    msg += `\na=rtcp-mux\\r\\n`;
  }
  
  return {
    tpname: 'tx-invite-200',
    format: msg,
  };
}

function sipRxAck() {
  return {
    tpname: 'rx-ack',
    format:
`ACK[...]\\r\\n
To: [...]tag=[to-tag:session1]\\r\\n
[...]\\r\\n`
  };
}

function sipRxBye() {
  return {
    tpname: 'rx-bye',
    format:
`BYE [...]\\r\\n
To: [...]tag=[to-tag:session1]\\r\\n
[...]\\r\\n
CSeq: [cseq] BYE[...]`
  };
}

function sipTxBye200(userConfig) {
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[userConfig.urischeme];
  
  return {
    tpname: 'tx-bye-200',
    format:
`SIP/2.0 200 OK\\r\\n
From: <${urischeme}:[from-user:session1]@[p-server]>;tag=[from-tag:session1]\\r\\n
To: <${urischeme}:[to-user:session1]@[p-server]>;tag=[to-tag:session1]\\r\\n
Call-Id: [call-id:session1]\\r\\n
CSeq: [cseq] BYE\\r\\n
Content-Length: 0\\r\\n
\\r\\n`
  };
}

export {
  sipTxRegister, sipRxRegister401, sipRxRegister200, 
  
  sipTxUnRegister, sipRxUnRegister200, wsHandshake,
  
  sipTxInvite, sipRxInvite100, sipRxInvite180, sipRxInvite200,
  sipTxAck, sipTxBye, sipRxBye200,
  
  sipWebRTCTxInvite, sipWebRTCRxInvite200,
  
  
  sipRxInvite, sipTxInvite180, sipTxInvite200,
  sipRxAck, sipRxBye, sipTxBye200,
  
  sipWebRTCRxInvite, sipWebRTCTxInvite200,
}


