
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGTerminologies = () => {

  setPageTitle('Terminologies');
  
  const element = (
    <div className="userguide">
      <h1>LM Tools - Terminologies</h1>
      <br/>
      While working with LM Tools, you will come across various terms like Controller, Multiplier, Testbed, Test Suite,
      Template, Scenario etc. Lets understand these in LM Tools environment.
      <ul>
        <br/><li><b>Controller</b> - 
          This module controls the test execution, collects and aggregates the stats from all the
          modules / machines involved in test and presents data through Webserver in a
          readable format. For details check <a href="/userguide/controller">controller</a> page.
        </li>

        <br/><li><b>Multiplier</b> - 
          This module simulates the required customer components except system under test entity. 
          Lets take an example of URL access. Say you have a webserver and you need to
          access URL using LM Tool. Here tool's Multiplier module simulates a browser. 
          For details check <a href="/userguide/multiplier">multiplier</a> page.
        </li>

        <br/><li><b>Testbed</b> - This combines all like controller and multiplier(s) / testsuite(s) 
        configured. It supports many interesting features like number of threads for a given multiplier,
        serial execution of all testsuites, parallel execution of all testsuites. Checkout 
        all details for testbed <a href="/userguide/testbed">here</a>.
        </li>

        <br/><li><b>Test Suite</b> - This combines several test blocks, each block does certain task like
        creating an endpoint, variable declaration, writing instructions, session creation, executing instructions,
        session delete etc. Checkout all details for test suites <a href="/userguide/testsuite">here</a>.
        </li>

        <br/><li><b>Template</b> - This combines several template blocks, each block 
        signifies one message template. While writing text message templates is easy, 
        its bit tricky to write templates for binary messages, do not worry it will be covered
        later in this user guide. Checkout all details for templates <a href="/userguide/template">here</a>.
        </li>

        <br/><li><b>Scenario</b> - Its a great module that helps new comers to get started and learn 
        LM Tools without external help. At a later stage, when you become an expert in LM Tools,
        this will be just used to create schema for a new test. 
        <br/><br/>
        You can create simple scenarios by just providing minimal information and in the
        process of scenario creation automatically tool creates templates, test suites and 
        testbed required for test execution. Lets explore more on   
        scenarios <a href="/userguide/scenario">here</a>. 
        </li>
      </ul>
      <br/>
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/quickstart"}>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/controller"}>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGTerminologies}

