
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGAPATB2BAVariables = () => {

  setPageTitle('APAT B2BA Variables');
  
  const element = (
    <div className="userguide">
      <h1>APAT B2BA Variables</h1>
      <br/>
          
      Multiplier plugins are dependent on variables to large extent. There are 
      different kind of variables like normal variable, system variable, plugin 
      variable and relative variables. Here normal variables and plugin variables are discussed. 
      
      <br/><br/>
      <h3>B2BA APAT Normal Variables</h3>
      The normal variables starts with <i>[</i>  and ends with <i>]</i>. 
      For example [count] is a normal variable, variable name is count. 
      Each variable has name, type and value. The string type variables has 
      additionally length as well. You can use normal variables to store data, 
      extract data and fetch data.

      <br/><br/>
      <h3>B2BA Plugin Variables</h3>
      B2BA plugin provides internal variables to user known as <i><b>plugin variables</b></i> 
      to enable to fetch plugin internal values. The plugin variables are written in a 
      special way, start with <i>[p-</i> and ends with <i>]</i>. For example <i>[p-random-string]</i>
      is a plugin variable that gives a random digit-only string.
      <br/><br/>
      As of now, there are no specific B2BA apat plugin variables. However the B2BA
      generic plugin variables explained in <a href="/userguide/b2bavariables">B2BA variables page</a>, can 
      be used for B2BA apat, since those are generic plugn varianles across B2BA modules.        
          
          <br/><br/>
      
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/b2bainstructions" }>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/apatb2basyntax" }>Next</button>
      </div>

    </div>
  );
  
  return element;
}

export {UGAPATB2BAVariables}

