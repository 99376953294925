import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import axios from 'axios';
import parse from 'html-react-parser';
import {getURLQueryVariable, setPageTitle, isNumeric, createSelectStyle, handleErrorResponse} from '../../routes/lm.js';

let myTimeOut=null;

const StartTest = () => {

  const [lastExecutedTB, setLastExecutedTB] = useState(null);
  const [testName, setTestName] = useState("");
  
  const [btnState, setBtnState] = useState(false);
  const [status, setStatus] = useState({type: null, message: null});
  const [testBeds, setTestBeds] = useState([
      {label: "", value: 0},
    ]
  );

  let myTid = getURLQueryVariable("tid");
  
  let newTestBeds;
  
  setPageTitle("Start test");

  const fetchTestbeds = async () => {
    setStatus({type: null, message: null});
    if(user != null) {
      try {
        const res = await axios.post('/api/testbeds/get/data', 
        {uid: user.uid, email: user.email} ,
        {headers: {
          'Authorization': user.jwt,
        }}).then( 
        function (res) {
          if(res.status === 200) {

          newTestBeds = res.data.tbdata.map(function(obj) {
            return {label: obj.name, value: obj.id};
          });

          setTestBeds(newTestBeds);
          
          let ltbid = res.data.ltbid;
          if(myTid === false) {
          } else {
            ltbid = Math.round(myTid);
          }
          for(let index=0; index < newTestBeds.length; index++) {
            if(newTestBeds[index].value === ltbid) {
              setLastExecutedTB(newTestBeds[index]);
              break;
            }
          }
          
          }else {
            setStatus({
              type: 'error',
              message: 'Error in displaying data',
            });  
          }
        });
      }catch(err) {
        setStatus({
          type: 'error',
          message: err.message,
        });  
        handleErrorResponse(err);
      }
    } else {
      setStatus({
        type: 'error',
        message: "User not logged in",
      });  
    }
  }

  useEffect ( () => {
   fetchTestbeds();
  }, []);

  const handleInputTextChange=(e)=> {
    const {name,value} = e.target;
    const newTestName = value;
    setTestName(newTestName);
  } 
  
  const handleTestbedChange=(e)=> {
    const newLastExecutedTB = {value: e.value, label: e.label};
    setLastExecutedTB(newLastExecutedTB);
  }

  const moveToTestStatusPage = async (e) => {
    clearTimeout(myTimeOut);
    window.location.href = '/components/view/test/stats';
  }

  const generateTestDatFile = async (e) => {    
    setStatus({type: null, message: null});
    if(user != null) {

      try {
        const res = await axios.post('/api/test/generate/dat/files', 
        {uid: user.uid, email: user.email, tbid: lastExecutedTB.value, tbname: lastExecutedTB.label, testname: testName} ,
        {headers: {
          'Authorization': user.jwt,
        }}).then( 
        function (res) {
          if(res.status === 200) {
              setStatus({
                type: 'success',
                message: res.data.message,
              });  
              
          let newBtnState = true;
          setBtnState(newBtnState);              
             
          myTimeOut = setTimeout(moveToTestStatusPage, 3500);
 
          }else {
            setStatus({
              type: 'error',
              message: 'Error in writing config.dat file',
            });  
          }
        });
      }catch(err) {
        setStatus({
          type: 'error',
          message: err.message,
        });  
        handleErrorResponse(err);
      }              
    } else {
      setStatus({
        type: 'error',
        message: "User not logged in",
      });  
    }
  }

  const handleStartTest = async (e) => {
    e.preventDefault();
    
    if(window.confirm(`You are about to execute the testbed ${testName}, press OK to confirm.`)) {
      generateTestDatFile();
    }
  }
  
  const user = JSON.parse(localStorage.getItem('user'));
  if(user) {
    return (
      <form name="tbexecform" id="tbexecform" onSubmit={handleStartTest}>
        <div key="tbexecmain" className="medium-text">
          <div id="tbexecmain2" className="center" >
            <table key={`tbexectbl`} className="">
              <tbody>
                <tr>
                  <td style={{width: "450px"}}>
                    <a href={`/components/editsingletestbeds?id=${lastExecutedTB ? lastExecutedTB.value : -1}`}>Testbed</a><br/>
                    <Select required value={lastExecutedTB}
                      name="tbid"
                      className="select" 
                      options={testBeds} 
                      styles={createSelectStyle()}
                      onChange={(e)=>handleTestbedChange(e)}
                      components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    />
                  </td>
                </tr>

                <tr>
                  <td style={{width: "450px"}}>
                    Test name<br/>
                    <input name="name"
                    style={{width: "100%"}}
                    placeholder="Enter test name (Optional)"
                    onChange={(e)=>handleInputTextChange(e)} /> 
                  </td>
                </tr>


                <tr>
                  <td style={{width: "100%"}}>
                  </td>
                </tr>

              </tbody>
            </table>
            <br/>
            <div className="inlinecenter">
            {status && <div className={`alert ${status.type}`}>{ 
              
              status.message
              
              }</div>}
              <p align="center">
                <br/><button disabled={btnState} >Start Test</button>
              </p>
            </div>
            
          </div>
        </div>
      </form>
    );
    
  }
}

const StopTest = () => {
  const [stopResult, setStopResult] = useState("");
  const [status, setStatus] = useState({type: null, message: null});

  let tid = getURLQueryVariable("tid");
  
  setPageTitle('Stop test');
  
  const stopActiveTest = async(e) => {
    if(user != null) {
      try {
        const res = await axios.post('/api/test/current/active/stop', 
        {uid: user.uid, email: user.email, tid: tid} ,
        {headers: {
          'Authorization': user.jwt,
        }}).then( 
        function (res) {
          if(res.status === 200) {
            setStopResult(res.data);
          }else {
            setStopResult("Error");
          }
        });
      }catch(err) {
        setStopResult(err.message);
        handleErrorResponse(err);
      }
    } else {
      setStopResult("User not logged in");
    }    
  }
  
  useEffect ( () => {
     stopActiveTest();
  }, []);  

  const user = JSON.parse(localStorage.getItem('user'));
  if(user) {
    return stopResult;
  }
}

const RestartTest = () => {
  const [restartResult, setRestartResult] = useState("");
  const [status, setStatus] = useState({type: null, message: null});

  let tid = getURLQueryVariable("tid");
  
  setPageTitle('Restart test');

  const restartActiveTest = async(e) => {
    if(user != null) {
      try {
        const res = await axios.post('/api/test/current/active/restart', 
        {uid: user.uid, email: user.email, tid: tid} ,
        {headers: {
          'Authorization': user.jwt,
        }}).then( 
        function (res) {
          if(res.status === 200) {
            window.location.href = '/components/view/test/stats';
            //setRestartResult(res.data);
          }else {
            setRestartResult("Error");
          }
        });
      }catch(err) {
        setRestartResult(err.message);
        handleErrorResponse(err);
      }
    } else {
      setRestartResult("User not logged in");
    }    
  }
  
  useEffect ( () => {
     restartActiveTest();
  }, []);  

  const user = JSON.parse(localStorage.getItem('user'));
  if(user) {
    return restartResult;
  }
}

export {StartTest, StopTest, RestartTest};
