
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';
import {flexisipsyntax_table} from './flexisipsyntax.js'

const UGFlexiSIPWebRTCSyntax = () => {

  setPageTitle('FlexiSIP WebRTC Syntax');
  
  const element = (
    <div className="userguide">
      <p>
        <h1>FlexiSIP WebRTC Syntax</h1>
        <br/><br/>
        
        {flexisipsyntax_table("flexisip")}
        
        <div className="inlinecenter">
          <button onClick={() => window.location.href="/userguide/flexisipsyntax" }>Back</button> 
          <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
          <button onClick={() => window.location.href="/userguide/flexisipinstructions" }>Next</button>
        </div>
      </p>
    </div>
  );
  
  return element;
}

export {UGFlexiSIPWebRTCSyntax}

