import { Link } from 'react-router-dom';

const Signup = () => {

  var user;
  const userData = localStorage.getItem("user");

  if(userData !== null) {
    user = JSON.parse(userData);

    return (
      <div className="signup">
        <a href="/profile">{user.name}</a>
        &nbsp;
        &nbsp;
        &nbsp;
        <a href="/logout">Logout</a>
      </div>
    );
  }
  else
  {
    return (
        <div className="signup">
          <a href="/login">Login</a>
          &nbsp;
          &nbsp;
          &nbsp;
          <a href="/register">Register</a>
        </div>
    );
  }
};

export default Signup;
