

//for jwt https://clerk.com/blog/adding-jwt-authentication-to-react

import React, {useState} from 'react';
import axios from 'axios';
import {setPageTitle, handleErrorResponse} from './lm.js'

const Authenticate = async (email, password) => 
{
    try 
    {
      //axios defaults to json
      const res = await axios.post("/api/auth", { email, password });
      if(res.status === 200) 
      {
        var user = {
            uid: res.data.uid,
            name: res.data.firstname,
            jwt: res.data.token,
            email: email,
            role: res.data.role,
            rid: res.data.rid,
        };
        
        localStorage.setItem("user", JSON.stringify(user));
        window.location.href = '/profile';
      }
      else 
      {
        throw new Error("Authentication failed");
      }
    } catch (error) 
    {
      handleErrorResponse(error);
    }
};

const Login = () => 
{
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  setPageTitle('Login');

  const handleSubmit = async (e) => 
  {
    e.preventDefault();
    setError(null);
    try 
    {
      await Authenticate(user, password);
    } 
    catch (err) 
    {
      setError(err.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
        <div className="center">
          <br/>
          <b>Email Id</b>
          <input required 
            type="text"
            id="email"
            name="email"
            placeholder="Email"
            value={user}
            onChange={(e) => setUser(e.target.value)}
          />
          <b>Password</b>
          <input required 
            type="password"
            id="password"
            name="password"
            placeholder="Password"
            autoComplete="on"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="">
            <button type="submit">Submit</button>
          </div>

          <br />
          
          <b>Note</b>
          <span>If you have forgotten the password, please send us an email from your registered email id to reset it.</span>

          <br />
          {error && <div id="error">{error}</div>}
        </div>
    </form>
  );
};

export default Login;
