
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

import 
  {
    ugc_variables_syntax, ugc_variables_syntax_desc,
    ugc_plugin_syntax, ugc_plugin_syntax_desc, 
    ugc_instructions_syntax, ugc_instructions_syntax_desc,
    ugc_text_template_syntax, ugc_text_template_syntax_desc,
    ugc_binary_template_syntax, ugc_binary_template_syntax_desc
  } from './ugcommon.js'


function httpdb2ba_createinstance_syntax()
{
  return (
    <>
    <b>create-instance</b><br/>
    httpd 192.168.1.216 5060 udp httpd1<br/>
    http 192.168.1.216 http1<br/>
    max-endpoints 1000<br/>
    max-sessions 1000<br/>
    max-session-time 10<br/>
    ipversion 6<br/>
    automation 1<br/>
    </>
  );
}

function httpdb2ba_createinstance_syntax_desc(etype)
{
  return (
    <>
    The <b>create-instance</b> command creates {etype}. This test case name is 
    fixed, you can not use any arbitrary name. Various attributes used in create 
    instance are explained below.
    <br/><br/>
    <b>httpd</b> - HTTPD entity is created using <b>httpd</b> synntax. See in left column
    for example. User need to mention IP address, port and transport protocol for HTTPD
    entity. The last parameter is name of HTTPD entity.
    
    <br/><br/>
    <b>http</b> - User can associate a HTTP client with {etype} HTTPD entity using 
    <b>http</b> syntax. Just IP address and a name for HTTP client needs to be
    given for HTTP client.
    
    <br/><br/>
    <b>max-endpoints</b> - You can specify how many maximum endpoints can be handled 
    by {etype} HTTPD using <b>max-endpoints</b>.
    
    <br/><br/>
    <b>max-sessions</b> - You can specify how many maximum sessions can be handled 
    by {etype} HTTPD using <b>max-sessions</b>.
    
    <br/><br/>
    <b>max-session-time</b> - You can specify maximum session time for a session in 
    {etype} HTTPD using <b>max-session-time</b>.

    <br/><br/>
    <b>ipversion</b> - Set the ipversion preference IPv6 / IPv4 by setting this to 6 / 4.

    <br/><br/>
    <b>automation</b> - To view the test result (Overall stats) on basis of number of test cases instead of number of virtual users set it to 1.
    
    <br/><br/>
    <b>ep-status</b> - Endpoint status is written into log file in every few 
    seconds. Three possible value are given below.
    
    <ul>
    
    <br/><li><b>none</b> - Endpoint status is not be written into log file. 
    During heavy load testing this is the preferred option.
    </li>
    
    <br/><li><b>resource</b> - Endpoint status, only resource usage portion 
    (like size of linked lists, timer counts etc) is written to log file.
    </li>
    
    <br/><li><b>details</b> -  Endpoint status in full is written into log file. 
    This helps to debug which particular endpoint is not returned from current 
    command execution. Once you know which endpoint is not returned, filter log 
    for that endpoint and it helps to analyze the issue further.
    </li>
    </ul>
    
    </>
  );
}

function httpdb2ba_configinstance_syntax()
{
  return (
    <>
    <b>config-instance</b><br/>    
    httpd start<br/>
    proxy 1<br/>
    root www.somedomain.com<br/>
    default-ib [i-default]<br/>
    httpd end<br/>    
    </>
  );
}


function httpdb2ba_configinstance_syntax_desc()
{
  return (
    <>

    The <b>config-instance</b> command is used to configure the entity. This test case name is 
    fixed, you can not use any arbitrary test case name for this. 
    Various attributes used in config instance are explained below.
    <br/><br/>
    <b>proxy</b> - You can enable / disable proxy mode be setting <b>proxy</b> value to 1 or 0. 

    <br/><br/>
    <b>root</b> - You can set the root domain using <b>root</b> configuration. By default
    when any HTTP request is received, it is forwarded to root domain.

    <br/><br/>
    <b>default-ib</b> - You can specify default instruction block using <b>default-ib</b>. When 
    a HTTP request is received, and does not match with any <b>on / ondata</b> instruction, 
    that time default instruction block is invoked.
          
    </>
  );
}

function httpdb2ba_configsdp_syntax()
{
  return (
    <>
    <b>config-sdp</b><br/>
    session-config start<br/>
    version 0<br/>
    origin lm<br/>
    session lm<br/>
    audio-codec PCMU<br/>
    video-codec VP8<br/>
    audio-ptime 20<br/>
    video-fps 24<br/>
    video-file in-vp8.mlt<br/>
    audio-samplingrate 8000<br/>
    video-samplingrate 90000<br/>
    session-config end

    </>
  );
}


function httpdb2ba_configsdp_syntax_desc()
{
  return (
    <>
    <b>config-sdp</b> is used to configure sdp parameters. 
    This test case name is fixed, you can not use any arbitrary name. 
    Various attributes are explained below.
    
    <br/><br/><b>session-config start</b> - Indicates start of session level 
    configuration.
    
    <br/><br/><b>version</b> - SDP version. Typically set to 0
    <br/><br/><b>origin</b> - SDP origin. You can put any token say multiplier
    <br/><br/><b>session</b> - Session. You can put any token say multiplier
    <br/><br/><b>audio-codec</b> - Audio codec name. Possible values are <i>PCMA, PCMU, AMR, OPUS</i>
    <br/><br/><b>video-codec</b> - Video codec name. Possible values are <i>VP8</i>, <i>H.264</i>
    <br/><br/><b>audio-ptime</b> - Audio packetization time in millisecond
    <br/><br/><b>video-fps</b> -Video frames per second
    <br/><br/><b>audio-file</b> - Audio input file 
    <br/><br/><b>video-file</b> - Video input file 
    <br/><br/><b>audio-samplingrate</b> - Audio sampling rate 
    <br/><br/><b>video-samplingrate</b> - Video sampling rate 
    
    <br/><br/><b>session-config end</b> - Indicates end of session level 
    configuration.    
    </>
  );
}

const UGHTTPDB2BASyntax = () => {

  setPageTitle('HTTPD B2BA Syntax');
  
  let index = 0;
  let modulename = "httpdb2ba";
  
  const element = (
    <div className="userguide">
      <h1>HTTPD B2BA Syntax</h1>
      <br/>
        
      <table className="statstbl">
        <thead>
          <tr>
            <th style={{width: "5%"}}>
              Sl
            </th>
            
            <th style={{width: "40%"}}>
              Test block
            </th>
            
            <th style={{width: "55%"}}>
              Syntax
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              {++index} 
            </td>
            
            <td>
              {httpdb2ba_createinstance_syntax()}
            </td>
            
            <td>
              {httpdb2ba_createinstance_syntax_desc("B2BA")}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {httpdb2ba_configinstance_syntax()}
            </td>
            
            <td>
              {httpdb2ba_configinstance_syntax_desc()}
            </td>
          </tr>

          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {httpdb2ba_configsdp_syntax()}
            </td>
            
            <td>
              {httpdb2ba_configsdp_syntax_desc()}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {ugc_variables_syntax()}
            </td>
            
            <td>
              {ugc_variables_syntax_desc(modulename)}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {ugc_plugin_syntax()}
            </td>
            
            <td>
              {ugc_plugin_syntax_desc(modulename)}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {ugc_instructions_syntax()}
            </td>
            
            <td>
              {ugc_instructions_syntax_desc(modulename)}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {ugc_text_template_syntax()}
            </td>
            
            <td>
              {ugc_text_template_syntax_desc()}
            </td>
          </tr>


          <tr>
            <td>
              {++index}
            </td>
            
            <td>
              {ugc_binary_template_syntax()}
            </td>
            
            <td>
              {ugc_binary_template_syntax_desc()}
            </td>
          </tr>


        </tbody>
      </table>
        
        
      <br/>
      
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/httpdb2bavariables" }>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/httpdb2bainstructions" }>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export 
  {
    UGHTTPDB2BASyntax,
    httpdb2ba_createinstance_syntax, httpdb2ba_createinstance_syntax_desc,
    httpdb2ba_configinstance_syntax, httpdb2ba_configinstance_syntax_desc,
    httpdb2ba_configsdp_syntax, httpdb2ba_configsdp_syntax_desc,
  }

