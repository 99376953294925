
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGTableOfContents = () => {

  setPageTitle('Table of Contents');
  
  const element = (
    <div className="userguide">
      <h1>Table of Contents</h1>
      <br/>
      <ol>
      <li><a href="/userguide/introduction">Introduction</a></li>
      <li><a href="/userguide/download">Download multiplier</a></li>
      <li><a href="/userguide/multiplier/machineinfo">Multiplier machine specification</a></li>
      <li><a href="/userguide/install/multiplier">Multiplier installation</a></li>
      <b><li><a href="/userguide/quickstart">Quick Start - Everything in single page</a></li></b>
      <li><a href="/userguide/terminologies">LM Tools - Terminologies</a></li>
      <li><a href="/userguide/controller">LM Tools - Controller</a></li>
      <li><a href="/userguide/multiplier">LM Tools - Multiplier</a></li>
      <li><a href="/userguide/testbed">LM Tools - Testbed</a></li>
      <li><a href="/userguide/testsuite">LM Tools - Test Suite</a></li>
      <li><a href="/userguide/template">LM Tools - Template</a></li>
      <li><a href="/userguide/scenario">LM Tools - Scenario</a></li>

      <li><a href="/userguide/multiplier/threadsendpointsrps">Threads, Endpoints & RPS</a></li>
      <li><a href="/userguide/variables">LM Tools - Variables</a></li>
      <li><a href="/userguide/userseries">User Series</a></li>
      

      <li><a href="/userguide/apatvariables">APAT Variables</a></li>
      <li><a href="/userguide/apatsyntax">APAT Syntax</a></li>
      <li><a href="/userguide/apatwebrtcsyntax">APAT WebRTC Syntax</a></li>
      <li><a href="/userguide/apatinstructions">APAT Instructions</a></li>

      <li><a href="/userguide/flexisipvariables">Flexi SIP Variables</a></li>
      <li><a href="/userguide/flexisipsyntax">Flexi SIP Syntax</a></li>
      <li><a href="/userguide/flexisipwebrtcsyntax">Flexi SIP WebRTC Syntax</a></li>
      <li><a href="/userguide/flexisipinstructions">Flexi SIP Instructions</a></li>

      <li><a href="/userguide/b2bavariables">B2BA Variables</a></li>
      <li><a href="/userguide/b2basyntax">B2BA Syntax</a></li>
      <li><a href="/userguide/b2bainstructions">B2BA Instructions</a></li>


      <li><a href="/userguide/apatb2bavariables">APAT B2BA Variables</a></li>
      <li><a href="/userguide/apatb2basyntax">APAT B2BA Syntax</a></li>
      <li><a href="/userguide/apatb2bainstructions">APAT B2BA Instructions</a></li>

      <li><a href="/userguide/httpdb2bavariables">HTTPD B2BA Variables</a></li>
      <li><a href="/userguide/httpdb2basyntax">HTTPD B2BA Syntax</a></li>
      <li><a href="/userguide/httpdb2bainstructions">HTTPD B2BA Instructions</a></li>

      <li><a href="/userguide/sipb2bavariables">SIP B2BA Variables</a></li>
      <li><a href="/userguide/sipb2basyntax">SIP B2BA Syntax</a></li>
      <li><a href="/userguide/sipb2bainstructions">SIP B2BA Instructions</a></li>

      <li><a href="/userguide/httpb2bainstructions">HTTP B2BA Instructions</a></li>


      <li><a href="/userguide/servervariables">Server Variables</a></li>
      <li><a href="/userguide/serversyntax">Server Syntax</a></li>
      <li><a href="/userguide/serverinstructions">Server Instructions</a></li>

      <li><a href="/userguide/apatservervariables">APAT Server Variables</a></li>
      <li><a href="/userguide/apatserversyntax">APAT Server Syntax</a></li>
      <li><a href="/userguide/apatserverinstructions">APAT Server Instructions</a></li>

      <li><a href="/userguide/httpdservervariables">HTTPD Server Variables</a></li>
      <li><a href="/userguide/httpdserversyntax">HTTPD Server Syntax</a></li>
      <li><a href="/userguide/httpdserverinstructions">HTTPD Server Instructions</a></li>

      <li><a href="/userguide/sipservervariables">SIP Server Variables</a></li>
      <li><a href="/userguide/sipserversyntax">SIP Server Syntax</a></li>
      <li><a href="/userguide/sipserverinstructions">SIP Server Instructions</a></li>

      <li><a href="/userguide/httpserverinstructions">HTTP Server Instructions</a></li>
      
      <li><a href="/userguide/multiplier/virtualipconfig">Virtual IP Address Configuration</a></li>
      
      </ol>
      
      <br/>
      <div className="inlinecenter">
        <button >Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/introduction" }>Next</button>
      </div>
      
    </div>
  );
  
  return element;
}

export {UGTableOfContents}

