import React, {useState, useEffect} from 'react';
import axios from 'axios';
import moment from 'moment';
import {setPageTitle, getURLQueryVariable, handleErrorResponse, getISOFormatedLocaleDate} from '../../routes/lm.js';

import * as echarts from 'echarts';
import ReactEcharts from "echarts-for-react"; 

export const CreateCharts = () => {

  const [options, setOptions] = useState(null);
  const [height, setHeight] = useState(500);
  
  const chartType = ['line', 'bar', 'pie']; 

  var option2 = {
    xAxis: {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: [820, 932, 901, 934, 1290, 1330, 1320],
        type: 'line',
        smooth: true
      }
    ]
  };
  
  var options2 = [option2];
  
   //Hook to get data from backend server
  useEffect ( () => {
     fetchTestChartData();
  }, []);    
  

  const user = JSON.parse(localStorage.getItem('user'));
  
  function lmchart_get_fg(fgarr) {
    if(fgarr) {
      for(let index=0; index < fgarr.length; index++) {
        if(fgarr[index].filename === "fg.txt") {
          return fgarr[index];
        }
      }
    }
    
    return null;
  }

  function lmchart_get_fge(fgarr) {
    if(fgarr) {
      for(let index=0; index < fgarr.length; index++) {
        if(fgarr[index].filename === "fgelements.txt") {
          return fgarr[index];
        }
      }
    }
    
    return null;
  }

  function lmchart_get_fgdp(fgarr) {
    if(fgarr) {
      for(let index=0; index < fgarr.length; index++) {
        if(fgarr[index].filename === "fgdatapoints.txt") {
          return fgarr[index];
        }
      }
    }
    
    return null;
  }

  function lmchart_skeleton(chartLine) {
    
    let chartArr = chartLine.split("|");
    let ctype = Math.round(chartArr[3]);
    
    //line or bart chart
    if(ctype < 2) {
      return (
        {
          title: {
            text: chartArr[0],
            left: 'left'
          },

          tooltip: {
            trigger: 'item',
            formatter: function (value) {
              let xvalue = moment(Math.round(value.name)).format('YYYY-MM-DD-HH-mm-ss');
              return `${value.seriesName} <b>${value.value}</b><br/>time ${xvalue}`;
            }
          },
  
          legend: {
            left: 'right',
            data: [],
          },          

          grid: {
            containLabel: true,
          },
          
          xAxis: {
            type: 'category',
            data: [],
            
            axisLabel: {
              show: true,
              interval: 0,
              rotate: 30,
              showMinLabel: true,
              showMaxLabel: true,
              formatter: (function(value){
                return moment(Math.round(value)).format('YYYY-MM-DD-HH-mm-ss');
              }),

            },
            
            axisTick: {
              show: true,
              interval: 0
            },
          },
          
          yAxis: {
            type: 'value',
            style: {
              height: '500px',
            }
          },
          
          series: [
            
          ]          
        }
      );
    }
    
    return null;
  }
  
  const fetchTestChartData = async () => {
    
    if(user != null) {
      try {
        let tid = getURLQueryVariable("tid");
        const res = await axios.post('/api/test/chart/stats/data', 
        {uid: user.uid, email: user.email, tid: tid} ,
        {headers: {
          'Authorization': user.jwt,
        }}).then( 
        function (res) {
          if(res.status === 200) {
            /*
            * fg.txt
            * line example: mygraph|0|1|0
            * graph-name | graph-index | separate-y-axis | line(0), bar(1), pie (2), donut(3), eclipse (4), rectangle (5)
            * In fg.txt file can have more than 1 graphs. Per graph one line will be there.
            *
            * fgelements.txt
            * line example: txinvite|0|0|0|0
            *               rxinvite|1|0|1|0
            * 
            * element-name | element-index | graph-index | graph-relative-index | graph-axis (x -> 1, y -> 0)
            * It can contain more than one graph elements, hence graph index is part of line
            * 
            */
            let fg = lmchart_get_fg(res.data.data);
            let fge = lmchart_get_fge(res.data.data);
            let fgdp = lmchart_get_fgdp(res.data.data);
            
            if(fg) {
              let fgarr = fg.data.split("\n");
              let fgearr = fge.data.split("\n");
              let fgdparr = fgdp.data.split("\n");
                                          
              //Now prepare the options and use it to plot the graph.
              var newOptions = [];
              
              fgarr.forEach(line => {
                line = line.trim();
                if(line === "") {
                  
                } else {
                  let option = lmchart_skeleton(line);
                  newOptions.push(option);
                }
              });

              let fgeElements = [];
              fgearr.forEach(line => {
                line = line.trim();
                if(line === "") {
                  
                } else {
                  let fgeElement = line.split("|"); 
                  fgeElements.push(fgeElement);
                  
                  let cindex = Math.round(fgeElement[2]);
                  let option = newOptions[cindex];
                  
  ;                //Set element specific data
                  option.legend.data.push(fgeElement[0]);
                  
                  //Set series skeleton now
                  option.series.push({name: fgeElement[0], type: 'line', smooth: true, data: []});
                }
              });

              let interval = 0;
              
              if(fgdparr.length > 15) {
                interval = Math.round(fgdparr.length / 15);
              }
              
              fgdparr.forEach(line => {
                
                line = line.trim();
                if(line === "") {
                  
                } else {
                  let fgdpElement = line.split("|");
                  
                  //Now the data is like element-index | value pair
                  let index=1;
                  let length = fgdpElement.length;
                  
                  while(index < length) {
                    let eindex = Math.round(fgdpElement[index]);
                    let fgeElement = fgeElements[eindex];
                    let cindex = Math.round(fgeElement[2]); //graph or chart index
                    let option = newOptions[cindex];
                    index++;
                    let rindex = Math.round(fgeElement[3]);//element relative index in graph

                    if(rindex === 0) {
                      let isodate = new Date(Math.round(fgdpElement[0])).toISOString();
                      option.xAxis.data.push(fgdpElement[0]);
                      option.xAxis.axisLabel.interval = interval;
                      option.xAxis.axisTick.interval = interval;
                    }

                    option.series[rindex].data.push(fgdpElement[index]);
                    index++;
                  }
                }
              });

              setOptions(newOptions);
            }else {
              alert('Error in displaying data');
            }
          }
        });
      }catch(err) {
        alert(err.message);
        handleErrorResponse(err);
      }
    } else {
      alert('User not logged in');
    }
  }
    
  return (
    options &&  
      <div key="mycharts" >
        {
          options.map((option, index) => (
            <ReactEcharts style={{height:`${height}px`,width:'800px'}} key={`rechart${index}`} option={option} />
          ))
        }
      </div>
  );
}
