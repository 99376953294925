
import React from 'react';
import {setPageTitle} from '../../routes/lm.js';

const UGTestSuite = () => {

  setPageTitle('Test Suite');
  
  const element = (
    <div className="userguide">
      <h1>LM Tools - Test Suite</h1>
      <br/>
      Test suite consists of several test blocks, each block is configured for certain task like
      create-instance for a creating virtual user, variables for variable declarations etc, and these are 
      discussed in detail in this page below.
      
      A typeical test suite looks as below.
      
      <br/>
      <br/><img className="" src={process.env.PUBLIC_URL + '/userguide/ts-ui-head.png'} alt="Test suite header" width='100%'/>
      <br/><img className="" src={process.env.PUBLIC_URL + '/userguide/ts-ui-body.png'} alt="Test suite body" width='100%'/>
      <br/>
      <br/>
      In upper section, you can configure test suite name and description. In below section
      you can see only one test block is in expnded form, and other test blocks 
      are in collpased form. In the expanded test block / case, there are few configuration 
      parameters and those are explained below.
      
      <ul>
        <li>
          <b>Test case name</b> - You can enter a test case name in this field. For some test blocks
          name is fixed. For some test blocks, initial name is fixed. And for remainig test blocks
          you can use any name. Check below table for more details.
          
          <div>
            <table className="tealtbl" style={{width: "100%"}}>
              <tr>
                <th style={{width: "10%"}}>Serial</th>
                <th style={{width: "25%"}}>Test block name</th>
                <th style={{width: "15%"}}>Type</th>
                <th style={{width: "15%"}}>Property</th>
                <th style={{width: "35%"}}>Description</th>
              </tr>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>create-instance</td>
                  <td>Initialize</td>
                  <td>Fixed</td>
                  <td>Creates virtual user.</td>
                </tr>

                <tr>
                  <td>2</td>
                  <td>config-instance</td>
                  <td>Native API</td>
                  <td>Fixed</td>
                  <td>Configures virtual user.</td>
                </tr>

                <tr>
                  <td>3</td>
                  <td>config-sdp</td>
                  <td>Native API</td>
                  <td>Fixed</td>
                  <td>Configures session sdp attributes.</td>
                </tr>

                <tr>
                  <td>4</td>
                  <td>session-init</td>
                  <td>Native API</td>
                  <td>Fixed</td>
                  <td>Creates session.</td>
                </tr>

                <tr>
                  <td>5</td>
                  <td>session-deinit</td>
                  <td>Native API</td>
                  <td>Fixed</td>
                  <td>Destroys session.</td>
                </tr>

                <tr>
                  <td>6</td>
                  <td>execute-<i>anything</i></td>
                  <td>Native API</td>
                  <td>Semi fixed</td>
                  <td>Executes instructions. In name after <b>execute-</b> you can put anything (space and special characters not allowed). This is applicable for Native API type test blocks.</td>
                </tr>

                <tr>
                  <td>7</td>
                  <td><i>anything</i></td>
                  <td>Variables<br/>Instructions<br/>Functions</td>
                  <td>Semi fixed</td>
                  <td>In name you can put anything (space and special characters not allowed). This is applicable for Variables, Instruction Blocks and Functions type test blocks.</td>
                </tr>

              </tbody>
            </table>
          </div>
          
        </li>

        <li>
          <b>Start index</b> - Each virtual user is identified by an index. Start index indicates
          starting virtual user index. 
        </li>

        <li>
          <b>Delta index</b> - Delta index determines the next virtual user index.
          For example, if start index is 0, delta index is 0, end index is 10, then
          the given test block is executed for virtual users 0,1,2... till 9. 
          Similarly say start index is 1, delta index is 1, end index is 10, then the 
          given test block is executed for virtual users identified by indexes 1,3,5,7,9.
        </li>

        <li>
          <b>End index</b> - End index determines the last virtual user. Always the last 
          virtual user index is smaller than or equal to end index.
        </li>

        <li>
          <b>Timeout</b> - Timeout is value is set in milliseconds. During test execution, if a test block 
          takes more time than timeout value configured, then the given test block / case is 
          marked as failed and test execution again starts from next test block / case.
        </li>

        <li>
          <b>Repeat</b> - How many times this test case should repeat. 
          After completion of each round, stats counters are updated in front end.
        </li>

        <li>
          <b>Tolerance</b> - Allowed error percentage. If number of errors are more (beyond the tolerance limit), 
          the test case is treated as failed.
        </li>

        <li>
          <b>Plugin</b> - Select a particular plugin. If you want to simulate sip 
          call flow, select FlexiSip plugin. If you want to simulate sip webrtc 
          call flow, select FlexiSipWebrtc plugin. Similarly you can select APAT 
          (any product any test) or APAT WebRTC plugin when appropriate. There are 
          several plugins and you need to choose the right one.
        </li>

        <li>
          <b>Type</b> - Type basically indicates a test block / case type. 
          There are various types like Initialize, Variables, Function, 
          Instruction blocks, and Native API.
          

          <div>
            <table className="tealtbl" style={{width: "100%"}}>
              <tr>
                <th style={{width: "10%"}}>Serial</th>
                <th style={{width: "20%"}}>Type</th>
                <th style={{width: "70%"}}>Description</th>
              </tr>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Initialize</td>
                  <td>
                    Creates an virtual user. When a test suite is executed in a loop,
                    this needs to be executed only once. So frequency for this test block
                    must be set to "Once".
                  </td>
                </tr>

                <tr>
                  <td>2</td>
                  <td>Variables</td>
                  <td>User can declare variables using <b>Varibales</b> test block.</td>
                </tr>

                <tr>
                  <td>3</td>
                  <td>Function</td>
                  <td>When user wants to perform something and that is not supported in LM Tools,
                  that time user can write those stuff in lua script. Or you can you can write the logic 
                  in any other scripting language and call it from lua script.   
                  In <b>Function</b> test blocks, you can write lua function code. Please note that when 
                  this test block is executed, Multiplier only remembers the lua function code, later when 
                  this lua function is invoked from a <b>Native API</b> test block, that time only 
                  lua function code is executed.
                  <br/><br/>
                  A simple lua function looks as below.
                  <br/><br/>
                  <i>
                  function random_number_func()<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;math.randomseed(os.time())<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;x=math.random(100000000,999999999)<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;return x<br/>
                  end 
                  </i>
                  <br/>
                  <br/>

                  When a lua function is used in template, it starts with <b>[f-</b>,
                  for example above function is referred as <b>[f-random_number_func()]</b>.
                  It is possible to use function arguments in lua functions in LM Tools environment.
                 
                  </td>
                </tr>

                <tr>
                  <td>4</td>
                  <td>Text Template</td>
                  <td>User can write text template inline using <b>Text Template</b> test blocks. This may not be the 
                  preferred way to write the templates, details please check in <a href="/userguide/template">template</a> page.
                  </td>
                </tr>

                <tr>
                  <td>5</td>
                  <td>Binary Template</td>
                  <td>User can write binary template inline using <b>Binary Template</b> test blocks. This may not be the 
                  preferred way to write the templates, details please check in <a href="/userguide/template">template</a> page.
                  </td>
                </tr>

                <tr>
                  <td>6</td>
                  <td>Instructions Block</td>
                  <td>
                  User can write the instructions in <b>Instructions Block</b> test block.

                  Please note that when 
                  this test block is executed, Multiplier only remembers the instructions present in this test block, later when 
                  this instructions block is invoked from a <b>Native API</b> test block, that time only 
                  instructions present in this test block are executed.
                  </td>
                </tr>

                <tr>
                  <td>7</td>
                  <td>Native API</td>
                  <td>This test block <b>Native API</b> typically execute instructions directly,
                  instructions through Instructions Block test block, lua fucntions.
                  </td>
                </tr>

              </tbody>
            </table>
          </div>          
          
        </li>

        <li>
          <b>Frequency</b> - You can choose how often you want to execute a test block.
          You can select Never, Once or Always. When user sets repeat count in testbed 
          higher than 1, depending on frequency set, test block is executed never, once or 
          always.
        </li>

        <li>
          <b>Report</b> - If set to Yes, execution result of the test block is stored 
          internally and shown to user in details summary page.
        </li>

      </ul>
      <br/>
      
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/testbed"}>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/template"}>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGTestSuite}

