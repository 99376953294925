
import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import {Line} from 'rc-progress';
import {lmLogLevels, lmStates, getURLQueryVariable, setPageTitle, isNumeric, createSelectStyle, handleErrorResponse} from '../../routes/lm.js';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

//npm config set legacy-peer-deps true helped to install TextField and Autocomplete
//import TextField from '@material-ui/core/TextField'; 
//import Autocomplete from '@material-ui/lab/Autocomplete';

let myTimeOut = null;

const Testbeds = () => 
{
  const [tbHead, setTbHead] = useState({
    name: "",
    cid: 0,
    controller: "",
    loglevel: 0,
    valgrind: 0,
    repeat: 1,
    validRepeat: true,
    description: "",
    toggle: false,
    tbId: getURLQueryVariable("id"),
    progress: 0,
  });

  const [tbControls, setTbControls] = useState({
    state: 1,
    logLevel: 0,
    valgrind: 0,
  });

  const [testSuites, setTestSuites] = useState([
      {label: "", value: 0},
    ]
  );

  const [multipliers, setMultipliers] = useState([
      {label: "", value: 0},
    ]
  );

  const [controllers, setControllers] = useState([
      {label: "", value: 0},
    ]
  );
  
  const [tbBody, setTbBody]=useState([
  {
    tsid: 0,
    testsuite: "",
    
    mid: 0,
    multiplier:"",
    
    endpoints: 2,
    threads: 1,
    
    state: 1,
    loglevel: 0,
    valgrind: 0,
       
    toggle: false, 
    checked: false,
  }]);

  const [status, setStatus] = useState(null);
  const [collapseButtonState, setCollapseButtonState] = useState("Expand All");
  const [selectAllState, setSelectAllState] = useState("Select All");
  
  setPageTitle("Testbeds");

  const logLevelObj = lmLogLevels();
  const stateObj = lmStates();
  
  const handleChange=(e,i)=> {
    const {name,value}=e.target;
    const onchangeVal = [...tbBody];
    onchangeVal[i][name]=value;
    setTbBody(onchangeVal);
  }

  const handleTsChange=(e,i)=> {
    const newTbBody = [...tbBody];
    newTbBody[i].tsid = e.value;
    newTbBody[i].testsuite = e.label;
    setTbBody(newTbBody);
  }

  const handleMultChange=(e,i)=> {
    const newTbBody = [...tbBody];
    newTbBody[i].mid = e.value;
    newTbBody[i].multiplier = e.label;
    setTbBody(newTbBody);
  }
  
  const handleCntrollerChange=(e)=> {
    const newTbHead = {...tbHead};
    newTbHead.cid = e.value;
    newTbHead.controller = e.label;
    setTbHead(newTbHead);
  }
  
  const insertOneTbBody=(e,i) => {
    e.preventDefault();

    const newTbBody = [...tbBody];
    newTbBody.splice(i, 0, {
      tsid: 0,
      testsuite: ``,
      
      mid: 0,
      multiplier:"",
      
      endpoints: 2,
      threads: 1,
      
      state: 1,
      loglevel: 0,
      valgrind: 0,
            
      toggle: false, 
      checked: false,
    });
        
    setTbBody(newTbBody);
  }

  const headInputTextChange=(e)=> {
    const {name,value} = e.target;
    const newTbHead = {...tbHead};
    newTbHead[name] = value;
    setTbHead(newTbHead);
  }  

  const bodyInputTextChange=(e)=> {
    const {name,value} = e.target;
    const newTbBody = [...tbBody];
    newTbBody[name] = value;
    setTbBody(newTbBody);
  }  

  const handleTbDescChange=(value)=> {
    const newTbHead = {...tbHead};
    newTbHead.description = value;
    setTbHead(newTbHead); 
  }

  const handleTbLogLevelChange=(e)=> {
    const key = Object.keys(logLevelObj)[e.target.selectedIndex];
    const newTbHead = {...tbHead};
    newTbHead.loglevel = key;
    setTbHead(newTbHead);
  }

  const handleTbControlValgrindChange=(e)=> {
    const key = Object.keys(stateObj)[e.target.selectedIndex];
    const newTbControls = {...tbControls};
    newTbControls.valgrind = key;
    setTbControls(newTbControls);
    
    const newTbBody = [...tbBody];
    newTbBody.forEach(element => (element.valgrind = key));
    setTbBody(newTbBody);
  }

  const handleTbControlLogLevelChange=(e)=> {
    const key = Object.keys(logLevelObj)[e.target.selectedIndex];
    const newTbControls = {...tbControls};
    newTbControls.logLevel = key;
    setTbControls(newTbControls);
    
    const newTbBody = [...tbBody];
    newTbBody.forEach(element => (element.loglevel = key));
    setTbBody(newTbBody);
  }

  const handleTbControlStateChange=(e)=> {
    const key = Object.keys(stateObj)[e.target.selectedIndex];
    const newTbControls = {...tbControls};
    newTbControls.state = key;
    setTbControls(newTbControls);
    
    const newTbBody = [...tbBody];
    newTbBody.forEach(element => (element.state = key));
    setTbBody(newTbBody);
  }
  
  const handleTbTsLogLevelChange=(e,i)=> {
    const key = Object.keys(logLevelObj)[e.target.selectedIndex];
    const newTbBody = [...tbBody];
    newTbBody[i].loglevel = key;
    setTbBody(newTbBody);
  }
  
  const handleTbTsStateChange=(e,i)=> {
    const key = Object.keys(stateObj)[e.target.selectedIndex];
    const newTbBody = [...tbBody];
    newTbBody[i].state = key;
    setTbBody(newTbBody);
  }

  const handleTbTsValgrindChange=(e,i)=> {
    const key = Object.keys(stateObj)[e.target.selectedIndex];
    const newTbBody = [...tbBody];
    newTbBody[i].valgrind = key;
    setTbBody(newTbBody);
  }

  const handleTbValgrindChange=(e)=> {
    const key = Object.keys(stateObj)[e.target.selectedIndex];
    const newTbHead = {...tbHead};
    newTbHead.valgrind = key;
    setTbHead(newTbHead);
  }

  const handleDelClick=(e)=>{
    e.preventDefault();
    
    if(tbBody.length > 0) {
    
      let newTbBody = tbBody.filter(item => (item.checked === false));
      let length = tbBody.length - newTbBody.length;
      if(length > 0) {
        if (window.confirm("Press OK to delete " + length  + " selected item(s)")) {
          setTbBody(newTbBody);
        }
      }
    }
  }
  
  const handleExecuteClick=(e)=>{
    e.preventDefault();
    window.location.href = '/components/execute/test?tid='+tbHead.tbId;
  }
  
  function changeDataToggleTrue(obj) {
    obj.toggle = true;
    return obj;
  }

  function changeDataToggleFalse(obj) {
    obj.toggle = false;
    return obj;
  }
  
  
  const handleExpandCollapseClick=(e)=>{
    e.preventDefault();   
    const newTsHead = {...tbHead};
    
    if(e.target.innerText === "Collapse All") {
      setCollapseButtonState("Expand All");
      let newTbBody = tbBody.map(changeDataToggleFalse);
      setTbBody(newTbBody);
      newTsHead.toggle = false;
    } else {
      setCollapseButtonState("Collapse All");
      let newTbBody = tbBody.map(changeDataToggleTrue);
      setTbBody(newTbBody);
      newTsHead.toggle = true;
    }
    
    setTbHead(newTsHead);
  }

  function changeDataCheckedTrue(obj) {
    obj.checked = true;
    return obj;
  }

  function changeDataCheckedFalse(obj) {
    obj.checked = false;
    return obj;
  }
  
  const handleSelectAllClick=(e)=>{
    e.preventDefault();   

    if(tbBody.length > 0) {
      if(e.target.innerText === "Select All") {
        setSelectAllState("Deselect All");
        let newTbBody = tbBody.map(changeDataCheckedTrue);
        setTbBody(newTbBody);
      } else {
        setSelectAllState("Select All");
        let newTbBody = tbBody.map(changeDataCheckedFalse);
        setTbBody(newTbBody);
      }
    }
  }

  const handleAddClick=(e)=>{
    e.preventDefault();
    
    const newTbData = {
      tsid: 0,
      testsuite: ``,
      
      mid: 0,
      multiplier:"",
      
      endpoints: 2,
      threads: 1,
      
      state: 1,
      loglevel: 0,
      valgrind: 0,
            
      toggle: false, 
      checked: false,
    };
    
    const newTbBody = [...tbBody];

    //for(let i=0; i<502; i++)
    newTbBody[newTbBody.length] = newTbData;
    setTbBody(newTbBody);
  }
  
  function getUniqueKey(i) {
    let key = "1st" + i;
    return key;
  }
  
  function getTableRowColor(tindex) {
    if((tindex%2) == 0) {
      return "#b3e6ff";
    }
    return "#66ccff";
  }
  
  function tbCellCBClicked(tindex) {    
    const newTbBody = [...tbBody];
    newTbBody[tindex].checked = !(tbBody[tindex].checked);
    setTbBody(newTbBody);
  }
  
  function tbThinClicked() {
    const newTbHead = {...tbHead};
    newTbHead.toggle = !(newTbHead.toggle);
    setTbHead(newTbHead);
  }

  function tbUpperExpand() {
    const newTbHead = {...tbHead};
    newTbHead.toggle = true;
    setTbHead(newTbHead);
  }

  function tbUpperCollapse() {
    const newTbHead = {...tbHead};
    newTbHead.toggle = false;
    setTbHead(newTbHead);
  }
    
  function tbCellClicked(tindex) {
    const newTbBody = [...tbBody];
    newTbBody[tindex].toggle = !(tbBody[tindex].toggle);
    setTbBody(newTbBody);
  }
  
  function getInnerDivKey(tindex) {
    let key = "innerdiv" + tindex;
    return key;
  }

  function setProgressBar(value) {
    tbHead.progress = value;
    const newTbHead = {...tbHead};
    setTbHead(newTbHead);
  }

  function increaseProgress() {
    tbHead.progress = tbHead.progress + 50;
    const newTbHead = {...tbHead};

    if(newTbHead.progress > 100) {
      newTbHead.progress = 100;
      setTbHead(newTbHead);
      myTimeOut = setTimeout(completeProgress, 5000);
    } else {
      setTbHead(newTbHead);
      if(tbBody.length > 0) {
        myTimeOut = setTimeout(increaseProgress, 200);
      } else {
        myTimeOut = setTimeout(increaseProgress, 100);
      }
    }
  }

  function completeProgress() {
    if(tbHead.progress >= 100) {
      tbHead.progress = 0;
      const newTbHead = {...tbHead};
      setTbHead(newTbHead);
      clearTimeout(myTimeOut); 
    }
  }

  const user = JSON.parse(localStorage.getItem('user'));

  const handleSaveAll = async (e) => {

    e.preventDefault();
    
    let recordId = -1;

    let tbName = tbHead.name.trim();
    if(tbName === "") {
      if(tbHead.toggle === false) {
        tbUpperExpand();
        //e.preventDefault();
        alert("Please enter testbed name");
        return;
      }
    }

    let tbController = tbHead.controller.trim();
    if(tbController === "") {
      if(tbHead.toggle === false) {
        tbUpperExpand();
        //e.preventDefault();
        alert("Please enter testbed controller");
        return;
      }
    }

    let tbRepeat = tbHead.repeat;
    
    if(tbRepeat === "") {
      if(tbHead.toggle === false) {
        tbUpperExpand();
      }
      return;
    } else if(!isNumeric(tbRepeat)) {
      if(tbHead.toggle === false) {
        tbUpperExpand();
      }
      
      const newTbHead = {...tbHead};
      newTbHead.validRepeat = false;
      setTbHead(newTbHead);
      
      alert("Please enter valid repeat value");
      return;
    }
    
    
    let index = 0;
    while(index < tbBody.length) {
      if(tbBody[index].testsuite.trim() === "") {
        //e.preventDefault();
        alert(`Testbed block ${index+1}, please enter testsuite`);
        return;
      }
      
      if(tbBody[index].multiplier.trim() === "") {
        //e.preventDefault();
        alert(`Testbed block ${index+1}, please enter multiplier`);
        return;
      }
      
      index++;
    }

    //e.preventDefault();

    if(user) {

      /* Reset form error flags */
      const newTbHead = {...tbHead};
      newTbHead.validRepeat = true;
      setTbHead(newTbHead);
      
      if (window.confirm("You are about to save testbed " + tbHead.name  + ", press OK to confirm")) {

        //Create a new object reqdTbHead excluding plugin, type, freq, report, toggle members 
        const {toggle, validRepeat, progress, ...reqdTbHead} = tbHead;
        
        try {
          await axios.post(
            '/api/testbed/data/save', 
            {uid: user.uid, email: user.email, data: JSON.stringify(reqdTbHead)},
            {headers: {Authorization: user.jwt,}},
          ).then( function (res) {
            if(res.status === 200) {
              recordId = Math.round(res.data.tbId);
              setProgressBar(5);
            } else {
              console.log("Testbed save, got error " + res.status);
            }
          })
        }catch(err) {
          handleErrorResponse(err);
        }

        if(recordId > 0) {
          if(tbBody.length > 0) {
            //Create array of new objects excluding toggle, checked property of array elements (object)
            const reqdTbData = tbBody.map(({toggle, checked, ...rest}) => rest);
            const tbJsonData = JSON.stringify(reqdTbData);
            const tbBodySha256 = CryptoJS.SHA256(tbJsonData).toString();
            
            try {
              await axios.post(
                '/api/testbedblocks/data/save',
                {uid: user.uid, email: user.email, tbid: recordId, sha256: tbBodySha256, data: tbJsonData},
                {headers: {Authorization: user.jwt,}},
              ).then ( function (res2) {
                if(res2.status === 200) {
                  //success. here show a dialog or progress bar
                  //setProgressBar(1);
                  myTimeOut = setTimeout(increaseProgress, 200);
                } else {
                  console.log("Testbed blocks save, got error " + res2.status);
                }
              });
            } catch(err2) {
              handleErrorResponse(err2);
            }
          } else {
            myTimeOut = setTimeout(increaseProgress, 100);
          }
        } else {
          console.log(`Error in testbed blocks save/update, tbid=${recordId} and test blocks array length=${tbBody.length}`);
        }
      }
    }    
  };
 
  function getTestSuiteName(tsid) {
    let index = 0;
    
    while(index < testSuites.length) {
      if(testSuites[index].value === tsid) {
        return testSuites[index].label;
      }
      index++;
    }
    
    return "";
  }

  function getMultiplierName(mid) {
    let index = 0;
    while(index < multipliers.length) {
      if(multipliers[index].value === mid) {
        return multipliers[index].label;
      }
      index++;
    }
    
    return "";
  }
 
  const fetchTestbed = async () => {
    let newTestSuites;
    let newMultipliers;
    let newControllers;
    
    //Fetch controllers
    try {
      await axios.post('/api/fetch/controllers/nameids', 
        {uid: user.uid, email: user.email},
        {headers: {Authorization: user.jwt,}}).then (function (res) {
          if(res.status === 200) {
            
            if(res.data.length > 0) {
              newControllers = res.data.map(function(obj) {
                return {label: obj.name, value: obj.id};
              });
              
              setControllers(newControllers);
            }
            
          } else {

            setStatus({
              type: 'error',
              message: 'Error in fetching controllers',
            });  
          }
        }
      );
    } catch(err) {
      setStatus({
        type: 'error',
        message: 'Exception in fetching controllers',
      }); 
      handleErrorResponse(err);
    }
    
    
    //Fetch multipliers
    try {
      await axios.post('/api/fetch/multipliers', 
        {uid: user.uid, email: user.email},
        {headers: {Authorization: user.jwt,}}).then (function (res) {
          if(res.status === 200) {
            
            if(res.data.length > 0) {
              newMultipliers = res.data.map(function(obj) {
                return {label: obj.name, value: obj.id};
              });
              
              setMultipliers(newMultipliers);
            }
            
          } else {

            setStatus({
              type: 'error',
              message: 'Error in fetching multipliers',
            });  
          }
        }
      );
    } catch(err) {
      setStatus({
        type: 'error',
        message: 'Exception in fetching multipliers',
      });  
      handleErrorResponse(err);
    }

    //Fetch test suites
    try {
      await axios.post('/api/testsuites/edit', 
        {uid: user.uid, email: user.email},
        {headers: {Authorization: user.jwt,}}).then (function (res) {
          if(res.status === 200) {
            
            if(res.data.length > 0) {
              newTestSuites = res.data.map(function(obj) {
                return {label: obj.name, value: obj.id};
              });
              
              setTestSuites(newTestSuites);
            }
            
          } else {

            setStatus({
              type: 'error',
              message: 'Error in fetching test suites',
            });  
          }
        }
      );
    } catch(err) {
      setStatus({
        type: 'error',
        message: 'Exception in fetching test suites',
      });  
      handleErrorResponse(err);
    }

    if(Math.round(tbHead.tbId) === -1) {
      return;
    }

    let flag = 0;
    
    try {
      await axios.post('/api/testbed/data/fetch', 
      {uid: user.uid, email: user.email, tbid: tbHead.tbId},
      {headers: {Authorization: user.jwt,}}).then (function (res) {
        if(res.status === 200) {
          if(res.data.length > 0) {
            res.data[0].controller = newControllers.filter(obj => obj.value === res.data[0].cid)[0].label;
            res.data[0].validRepeat = tbHead.validRepeat;
            res.data[0].toggle = tbHead.toggle;
            res.data[0].tbId = tbHead.tbId;
            res.data[0].progress = tbHead.progress;
            setTbHead(res.data[0]);
            
            flag = 1;
          } else {
              console.log("No testbed record found");
          }          
        } else {
          console.log("Error in searching testbed record");
        } 
      });
      
    } catch (err) {
        handleErrorResponse(err);
    }
    
    if(flag === 1) {
      try {
       
        await axios.post('/api/testbedblocks/data/fetch',
        {uid: user.uid, email: user.email, tbid: tbHead.tbId},
        {headers: {Authorization: user.jwt,}}).then (function (res) {
          
          if(res.status === 200) {
            res.data.forEach(element => (
              element.checked = false,
              element.toggle = false,
              element.testsuite = newTestSuites.filter(obj => obj.value === element.tsid)[0].label,
              element.multiplier = newMultipliers.filter(obj => obj.value === element.mid)[0].label
            ));
            setTbBody(res.data);
          } else {
            console.log("No testbed record found");
          }
        })
      } catch (err) {
        handleErrorResponse(err);
      }    
    }
    
  }

  //Hook to get data from backend server
  useEffect ( () => {
     fetchTestbed();
  }, []);  

    
  if(user) 
  {
    return (
    <form name="tbform" id="tbform" onSubmit={handleSaveAll}>
      <div key="tbmain" className="medium-text">
        <div id="tbmain2" className="center" >
          {(tbHead.name.length == 0) ? 
            <h3><p align="center">Testbeds</p></h3> : 
            <h3><p align="center">Testbed [{tbHead.name}]</p></h3>}
            
          { 
            tbHead.toggle ? (

              <div id="tbmain3" className="txupper"> 
                
                <div id="tbmain2" style={{cursor: "zoom-out"}} onClick={()=>{tbThinClicked()}} >
                  <p align="right">
                    <button className="minibuttons" onClick={tbThinClicked}></button>
                  </p>
                </div>
                
                
                
                <table key={`tbuppertbl`} className="tstbl">
                  <tbody>
                    <tr>
                      <td style={{width: "25%"}}>
                        Name<br/>
                        <input required name="name"
                        style={{width: "100%"}}
                        value={tbHead.name}
                        onChange={(e)=>headInputTextChange(e)} /> 
                      </td>

                      <td style={{width: "25%"}}>
                        Controller<br/>
                        <Select 
                          name="cid"
                          value={{value: tbHead.cid, label: tbHead.controller}}
                          className="select" 
                          options={controllers} 
                          styles={createSelectStyle()}
                          onChange={(e)=>handleCntrollerChange(e)}
                          components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                        />
                      
                      </td>
                      
                      <td style={{width: "25%"}}>
                        Log level<br/>
                        <select value={logLevelObj[tbHead.loglevel]} 
                          style={{width: "100%"}}
                          onChange={handleTbLogLevelChange}>
                          {Object.keys(logLevelObj).map(item => 
                            <option key={`tbtype${item}`}>
                              {logLevelObj[item]}
                            </option>
                          )}
                        </select>                        
                      </td>
                      
                      <td style={{width: "25%"}}>
                        Repeat<br/>
                        <input required name="repeat"
                        style={{width: "100%"}}
                        value={tbHead.repeat} 
                        className={tbHead.validRepeat ? '' : 'error'} 
                        onChange={(e)=>headInputTextChange(e)} /> 
                      
                      </td>
                      
                    </tr>
                    <tr>
                      
                      <td style={{width: "25%"}}>
                        Valgrind<br/>
                        <select value={stateObj[tbHead.valgrind]} 
                          style={{width: "100%"}}
                          onChange={handleTbValgrindChange}>
                          {Object.keys(stateObj).map(item => 
                            <option key={`vg${item}`}>
                              {stateObj[item]}
                            </option>
                          )}
                        </select>  
                      </td>
                    </tr>
                  </tbody>
                </table>
                

                <br/><label>Testbed description</label><br/>
                <textarea value={tbHead.description} name="description" 
                  style={{width: "100%"}}
                  rows={4} cols={45}
                  onChange={(e) => handleTbDescChange(e.target.value)}>                                      
                </textarea> 
                
                <br/>
                <h3><p align="center" >Controls</p></h3>
                
                <table key={`tbcntltbl`} className="tstbl" style={{width: "100%"}}>
                  <tbody>
                    <tr>

                      <td style={{width: "33%"}}>
                        
                        <Tooltip title="Select state to change in all blocks below." arrow>
                          <label>State</label>
                        </Tooltip>
                        <br/>
                        
                        <select value={stateObj[tbControls.state]} 
                          style={{width: "100%"}}
                          onChange={(e)=>handleTbControlStateChange(e)}>
                          {Object.keys(stateObj).map(item => 
                            <option key={`mstate${item}`}>
                              {stateObj[item]}
                            </option>
                          )}
                        </select>                        
                      </td>
                    
                      <td style={{width: "33%"}}>
                        Log level<br/>
                        <select value={logLevelObj[tbControls.logLevel]} 
                          style={{width: "100%"}}
                          onChange={handleTbControlLogLevelChange}>
                          {Object.keys(logLevelObj).map(item => 
                            <option key={`tbtype${item}`}>
                              {logLevelObj[item]}
                            </option>
                          )}
                        </select>                        
                      </td>
                      
                      <td style={{width: "33%"}}>
                        Valgrind<br/>
                        <select value={stateObj[tbControls.valgrind]} 
                          style={{width: "100%"}}
                          onChange={handleTbControlValgrindChange}>
                          {Object.keys(stateObj).map(item => 
                            <option key={`vg${item}`}>
                              {stateObj[item]}
                            </option>
                          )}
                        </select>  
                      </td>
                      
                    </tr>
                  </tbody>
                </table>
              </div>  
              
            ) : (
              <div id="tbmain3" className="txthinner" 
              style={{cursor: "zoom-in", background: getTableRowColor(1)}} onClick={()=>{tbThinClicked()}}
              >
              Testbed details
              </div>
            ) 
          }

          
          <h3><p align="center"><br/><br/>Test Suites / Multipliers</p></h3>
          
          {
            tbBody.map((val,tindex)=> 
              <div key={getInnerDivKey(tindex)} className="tstbl">
              <table key={`tblmain{tindex}`} >

                <tbody>
                  { val.toggle ? (<tr  key={`tblmainrow{tindex}`}

                    style={{background: getTableRowColor(tindex)}}  >
                    <td style={{cursor: "zoom-out", width: "10%"}} onClick={()=>{tbCellClicked(tindex)}} key={`tblmaintc{tindex}`}> 
                      <b>&nbsp;{tindex+1}</b>&nbsp;&nbsp;
                    </td>

                    <td style={{width: "80%"}}>
                      
                      <table key={`mtbl{tindex}`}>
                        <tbody>
                          <tr key={`tsrow1{tindex}`}>
                            <td style={{width: "250px"}}>
                              <br/><a href={'/components/editsingletestsuites?id='+tbBody[tindex].tsid}>Test suite</a><br/>
                              
                              <Select 
                                name="tsid"
                                className="select" 
                                defaultValue={{value: tbBody[tindex].tsid, label: tbBody[tindex].testsuite}}
                                options={testSuites} 
                                styles={createSelectStyle()}
                                onChange={(e)=>handleTsChange(e,tindex)}
                                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                              />

                            </td>
                            <td style={{width: "150px"}}>
                              <br/>Multiplier<br/>

                              <Select 
                                name="mid"
                                /*menuIsOpen*/
                                value={{value: tbBody[tindex].mid, label: tbBody[tindex].multiplier}}
                                className="select" 
                                options={multipliers} 
                                styles={createSelectStyle()}
                                onChange={(e)=>handleMultChange(e,tindex)}
                                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                              />
                            </td>
                            
                            <td style={{width: "100px"}}>
                              <br/>Threads<br/>
                              <input required name="threads" value={val.threads} 
                              style={{width: "100%"}}
                              onChange={(e)=>handleChange(e,tindex)} />
                            </td>

                            <td style={{width: "100px"}}>
                              <br/>Endpoints<br/>
                              <input required name="endpoints" value={val.endpoints} 
                              style={{width: "100%"}}
                              onChange={(e)=>handleChange(e,tindex)} />
                            </td>
                          </tr>
                          <tr key={`tsrow2{tindex}`}>
                            <td>
                              State<br/>
                              <select value={stateObj[val.state]} 
                                style={{width: "100%"}}
                                onChange={(e)=>handleTbTsStateChange(e,tindex)}>
                                {Object.keys(stateObj).map(item => 
                                  <option key={`mstate${item}`}>
                                    {stateObj[item]}
                                  </option>
                                )}
                              </select>                        
                            </td>

                            <td>
                              Log level<br/>
                              <select value={logLevelObj[val.loglevel]} 
                                style={{width: "100%"}}
                                onChange={(e)=>handleTbTsLogLevelChange(e,tindex)}>
                                {Object.keys(logLevelObj).map(item => 
                                  <option key={`tbtsll${item}`}>
                                    {logLevelObj[item]}
                                  </option>
                                )}
                              </select>                        
                            </td>

                            <td>
                              Valgrind<br/>
                              <select value={stateObj[val.valgrind]} 
                                style={{width: "100%"}}
                                onChange={(e)=>handleTbTsValgrindChange(e,tindex)}>
                                {Object.keys(stateObj).map(item => 
                                  <option key={`mvg${item}`}>
                                    {stateObj[item]}
                                  </option>
                                )}
                              </select>                        
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    
                    <td key={`tblrowtc{tindex}`} tyle={{width: "10%"}}> 
                      <Tooltip title="Insert test block just above this." arrow>
                        <button className="miniimgbtns" onClick={(e)=>insertOneTbBody(e,tindex)}> ++ </button>
                      </Tooltip>
                      &nbsp;<input type="checkbox" id={`chk{tindex}`} 
                        onChange={()=>{tbCellCBClicked(tindex)}} 
                        checked={val.checked}
                      />
                      &nbsp;
                   </td> 
                  </tr>
                  ) : 
                  (
                    <tr key={`tblmainrow{tindex}`} style={{background: getTableRowColor(tindex)}}  >
                    <td style={{cursor: "zoom-in", width: "10%"}} onClick={()=>{tbCellClicked(tindex)}} key={`tblmaintc{tindex}`}> 
                      <b> &nbsp;{tindex+1}</b>&nbsp;&nbsp;
                    </td>                              

                    <td style={{cursor: "zoom-in", width:"600px"}} onClick={()=>{tbCellClicked(tindex)}}>
                      {val.tsid ? `${val.testsuite}` : "None"}
                    </td>
                    
                    <td key={`tblrowtc{tindex}`} style={{width:"10%"}}> 
                      <Tooltip title="Insert test block just above this." arrow>
                        <button className="miniimgbtns" onClick={(e)=>insertOneTbBody(e,tindex)}> ++ </button>
                      </Tooltip>
                      &nbsp;<input type="checkbox" id={`chk{tindex}`} 
                      onChange={()=>{tbCellCBClicked(tindex)}} 
                      checked={val.checked} 
                    />
                      &nbsp;
                    </td>
                    
                    </tr>
                  )} 
                </tbody>
              </table>
              </div>
            )
          }




        </div>  

        <div className="inlinecenter">
          <Line className="progressbar" style={{visibility: (tbHead.progress > 0) ? "visible" : "hidden"}} percent={tbHead.progress} strokeWidth="1" strokeColor="#009973"/>
          <br/>
          <button onClick={handleAddClick}>Add</button> 
          <button onClick={handleDelClick}>Delete</button>
          <button onClick={handleExpandCollapseClick}>{collapseButtonState}</button>
          <button onClick={handleSelectAllClick}>{selectAllState}</button>
          <button>Save</button>
          <button onClick={handleExecuteClick}>Execute</button>
        </div>
          
      </div>
    </form>);      
  } 
  else 
  {
    return(
      <div key="tbmain" className="medium-text">
        <div id="tbmain2" className="center" >
          You are not allowed to access this page.
        </div>
      </div>
    );
  }
};

const EditSingleTestbeds = () => {
  return Testbeds();
};

const EditTestbeds = () => {
  const initState = [{id: 0, name: "", checked: false}];
  const [state, setState] = useState(initState);
  const [status, setStatus] = useState(null);
  const [selectAllBtnText, setSelectAllBtnText] = useState("Select All");

  const user = JSON.parse(localStorage.getItem('user'));
  setPageTitle("Testbeds");
  const fetchTestbeds = async () => {
    setStatus(null);
    if(user != null) {
      try {
        const res = await axios.post('/api/testbeds/edit', 
        {uid: user.uid, email: user.email} ,
        {headers: {
          'Authorization': user.jwt,
        }}).then( 
        function (res) {
          if(res.status === 200) {
            //This also works, keeping it as reference
            //const newState = res.data.map(obj => ({...obj, checked: false}));
            
            res.data.forEach(element => (element.checked = false));

            setState(res.data);
          }else {
            setStatus({
              type: 'error',
              message: 'Error in displaying data',
            });  
          }
        });
      }catch(err) {
        setStatus({
          type: 'error',
          message: err.message,
        });
        handleErrorResponse(err);
      }
    } else {
      setStatus({
        type: 'error',
        message: "User not logged in",
      });  
    }
  }
  
  useEffect ( () => {
   fetchTestbeds();
  }, []);
  

  function tbRowCBClicked(tindex) {
    const newState = [...state];
    newState[tindex].checked = !(state[tindex].checked);
    setState(newState);
  }

  const handleEditTbDelete= async (e)=> {
    e.preventDefault();
      
    if(state.length > 0) {

      let delIds = [];
      let newState = [];
      
      state.forEach(function (element) {
        if(element.checked) {
          delIds.push(element.id);
        } else {
          newState.push(element);
        }
      });
      
      if(delIds.length > 0) {
        if (window.confirm("Press OK to delete " + delIds.length  + " selected testbed(s)")) {
          try {
            const res = await axios.post('/api/testbeds/data/delete', 
            {uid: user.uid, email: user.email, data: JSON.stringify(delIds) } ,
            {headers: {
              'Authorization': user.jwt,
            }}).then( 
            function (res) {
              if(res.status === 200) {
                setState(newState);
              }else {
                setStatus({
                  type: 'error',
                  message: 'Error in displaying data',
                });  
              }
            });
          }catch(err) {
            setStatus({
              type: 'error',
              message: err.message,
            });
            handleErrorResponse(err);
          } 
        }
      }
    }
  }
  
  function makeTSRowChecked(obj) {
    obj.checked = true;
    return obj;
  }

  function makeTSRowUnchecked(obj) {
    obj.checked = false;
    return obj;
  }
  
  const handleEditTbSelectAll=(e)=> {
    e.preventDefault();
    
    if(state.length > 0) {
      if(e.target.innerText === "Select All") {
        setSelectAllBtnText("Deselect All");
        const newState = state.map(makeTSRowChecked);
        setState(newState);
      } else {
        setSelectAllBtnText("Select All");
        const newState = state.map(makeTSRowUnchecked);
        setState(newState);
      }
    } 
  }
  
  if(user === null) {
    window.location.href = "/authrequired";
  } else {
    let tindex = 0;
    return (
      <div className="medium-text">
        <div className="center">
          <h3><p align="center">Testbeds</p></h3>
          
          <table className="tealtbl">
          
            <thead>
              <tr>
                <th>Serial</th>
                <th>Testbed</th>
                <th>Execute</th>
                <th>Select</th>
              </tr>
            </thead>                
            <tbody>
              {state.map((item, index) => (
                <tr key={`row${index}`}>
                  <td>{(++tindex)}</td>
                  <td><a href={`/components/editsingletestbeds?id=${item.id}`}>{item.name}</a></td>
                  <td>&nbsp;&nbsp;<a href={'/components/execute/test?tid='+item.id}><img src={process.env.PUBLIC_URL + '/s_icon_start.png'} alt="System Diagram" width='36%'/></a> </td>
                  <td>
                    <input type="checkbox" id={`chk{index}`} 
                      onChange={()=>{tbRowCBClicked(index)}} 
                      checked={item.checked} 
                    />
                  
                  </td>
                </tr>
              ))}
            </tbody>
          </table> 
        </div>

        <br/>
        <div className="inlinecenter small-text">
        {status && <div className={`alert ${status.type}`}>{status.message}</div>}
          <button onClick={handleEditTbDelete}>Delete</button>
          <button onClick={handleEditTbSelectAll}>{selectAllBtnText}</button>
        </div>

      </div>
    );
  }
  
};

export {Testbeds, EditSingleTestbeds, EditTestbeds};
