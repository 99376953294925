
import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import axios from 'axios';
import {createSelectStyle, handleErrorResponse} from '../../../routes/lm.js';

export const ScenarioHeader = ({scenarioName, onConfigChange}) =>  
{ 
  const [controllers, setControllers] = useState([{value: 0, label: ""}]);
  const [multipliers, setMultipliers] = useState([{value: 0, label: ""}]);
  const [state, setState] = useState(
    {
      cvalue: 0, clabel: '', 
      mvalue: 0, mlabel: '',
      name: scenarioName, desc: `Auto generated for ${scenarioName} scenario`,
    }
  );

  const user = JSON.parse(localStorage.getItem('user'));

  const fetchCMs = async () => {
    
    try {
      const res = await axios.post('/api/fetch/controllers/multipliers/nameids', 
      {uid: user.uid, email: user.email} ,
      {headers: {
        'Authorization': user.jwt,
      }}).then( 
      function (res) {
        if(res.status === 200) {

          let newState = {...state};
          
          if(res.data.controllers) {
            setControllers(res.data.controllers);
            newState.clabel = res.data.controllers[0].label;
            newState.cvalue = res.data.controllers[0].value;
          }
          
          if(res.data.multipliers) {
            setMultipliers(res.data.multipliers);
            newState.mlabel = res.data.multipliers[0].label;
            newState.mvalue = res.data.multipliers[0].value;
          }

          setState(newState);
          onConfigChange(newState);
          
        }else {
          alert('Error: Could not fetch controllers and multipliers');
        }
      });
    }catch(err) {
      alert(err.message);
      handleErrorResponse(err);
    }
  }

  const handleCntrollerChange=(e)=> {
    let newState = {...state};
    newState.cvalue = e.value;
    newState.clabel = e.label;
    setState(newState);
    onConfigChange(newState);
  }

  const handleMultiplierChange=(e)=> {
    let newState = {...state};
    newState.mvalue = e.value;
    newState.mlabel = e.label;
    setState(newState);
    onConfigChange(newState);
  }

  const handleInputTextChange=(e)=> {
    const {name,value}=e.target;
    let newState = {...state};
    newState.name = value;
    setState(newState);
    onConfigChange(newState);
  }

  const handleScenarioDescChange=(value)=> {
    let newState = {...state};
    newState.desc = value;
    setState(newState);
    onConfigChange(newState);
  }

  //Hook to get data from backend server
  useEffect ( () => {
     fetchCMs();
  }, []);    

  if(user) {
    
    let modifiedName = state.name.replaceAll(' ', '-').toLowerCase();
    
    return (
      <div key="cmentities">
        <br/><br/><h2>Create {scenarioName} scenario</h2>
        <div id="cmentities2" className="center" >
        
          <label>Name</label>
          <input required name="name" value={modifiedName} style={{width: "100%"}} onChange={(e)=>handleInputTextChange(e)}/>
          
          <label>Description</label>
          <textarea name="description" value={state.desc}  
            style={{width: "100%"}}
            rows={4} cols={45}
            onChange={(e) => handleScenarioDescChange(e.target.value)}>                                      
          </textarea> 

          <br/>
          <h3>Select controller and multiplier</h3>
      
          <table key="cmtbl" className="statstbl">
            <tbody key="cmbd">
              <tr key='cmr1'>
                <td>
                  Controller<br/>
                  <Select required 
                    name="cid"
                    value={{value: state.cvalue, label: state.clabel}}
                    className="select" 
                    options={controllers} 
                    styles={createSelectStyle()}
                    onChange={(e)=>handleCntrollerChange(e)}
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  />
                </td>

                <td>
                  Multiplier<br/>
                  <Select required 
                    name="mid"
                    value={{value: state.mvalue, label: state.mlabel}}
                    className="select" 
                    options={multipliers} 
                    styles={createSelectStyle()}
                    onChange={(e)=>handleMultiplierChange(e)}
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  />
                </td>
                
                
              </tr>
            </tbody>
          </table>
          
        </div>
      </div>
    );
  } else {
    return(
      <div key="cmentities" className="medium-text">
        <div id="cmentities2" className="center" >
          You are not allowed to access this page.
        </div>
      </div>
    );
  }
};
