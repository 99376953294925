/*
* Please note the attribs are indented from start of line intentionally.
* Do not do normal formating else sip test blocks will have additional spaces.
*/

import 
  {
    lmTransports, lmURISchemes, 
    lmMediaProfiles, lmCryptoSuites, 
    lmAudioCodecs, lmVideoCodecs,
    getLmBlockTypeId, lmICEModes, 
  } from '../../../routes/lm.js';

export function sipCreateInstanceAttribs(
    userConfig, callConfig, serverConfig, 
    turnConfig, pluginId, frequency, reportId
  ) {
  let video = 0;
  
  let transportsObj = lmTransports();
  let transport = transportsObj[userConfig.transport];
  
  let uriSchemesObj = lmURISchemes();
  let urischeme = uriSchemesObj[userConfig.urischeme];

  let icemode = '';
  let calltype = 'sip';
  
  if(turnConfig) {
    let iceModesObj = lmICEModes();
    icemode = iceModesObj[turnConfig.icemode];
    calltype = 'webrtc-sip';
  }
  
  if(callConfig.vcodec > 0) {
    video = 1;
  }
  
  let attribs = 
`id ${userConfig.baseid}
transport ${transport}
uri-scheme ${urischeme}
call-type ${calltype}
public-user ${userConfig.uprefix}
private-user ${userConfig.uprefix}
private-password-prefix ${userConfig.pprefix}
local-ipaddress ${userConfig.localip}
user-agent LM SIP UA 1.0.1
expire 3600
server ${serverConfig.server}
domain ${serverConfig.server}
realm ${serverConfig.server}
server-port ${serverConfig.port}
rtcp-mux ${callConfig.rtcpmux}
av-bundle ${callConfig.avbundle}
video ${video}
client-cert-verify 1
call-participants 2
media-capture-offset 1
media-cert-verify 1
media-enable-call-offset 1`;

  if(turnConfig) {
attribs +=
`
ice-mode ${icemode}
turn-server ${turnConfig.server}
turn-server-port ${turnConfig.port}
turn-user-prefix ${turnConfig.tuprefix}
turn-password-prefix ${turnConfig.tpprefix}`;
  }

attribs +=
`
ep-status details
rps 960`;

  return ({
    tcname:"create-instance",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: getLmBlockTypeId('Initialize'),
    freq: frequency,
    report: reportId,
    attribs: attribs,
  });
}

export function sipVariablesAttribs(pluginId, frequency, reportId) {
  let attribs = 
`global reg-expire-time=86400
global sess-id=[p-random-number-u16]
global sess-version=[p-random-number-u16]
global msid=[p-random-hex-string:36]
global label=[p-random-hex-string]
global rseq=1
global rseq2=2
global totag=[p-random-hex-string]`;

  return ({
    tcname:"variables",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: getLmBlockTypeId('Variables'),
    freq: frequency,
    report: reportId,
    attribs: attribs,
  });
}

export function sipConfigSDPAttribs(callConfig, pluginId, typeId, frequency, reportId) {

  let videofile = '';
  let acodecnumber = 0;
  let vcodecnumber = 98;

  let audioCodecsObj = lmAudioCodecs();
  let videoCodecsObj = lmVideoCodecs();
  
  let acodec = audioCodecsObj[callConfig.acodec].toUpperCase();
  let vcodec = videoCodecsObj[callConfig.vcodec].toUpperCase();
  
  if(acodec == 'PCMA') {
    acodecnumber = 8;
  } else if(acodec == 'AMR') {
    acodecnumber = 101;
  } else if(acodec == 'OPUS') {
    acodecnumber = 102;
  }
  
  if(vcodec == 'VP8') {
    videofile = 'in-vp8.mlt';
  } else if(vcodec == 'H264') {
    videofile = 'in-h264.mlt';
  }
  
  let attribs = 
`session-config start
version 0
origin lmcloud
session lmcloud
audio-codec ${acodec}
audio-ptime 20
audio-codec-number ${acodecnumber}
audio-sampling-rate 8000`;

if(vcodecnumber > 0) {
attribs += `
video-codec ${vcodec}
video-fps 24
video-file ${videofile}
video-sampling-rate 90000
video-codec-number ${vcodecnumber}`;
}

attribs += `
session-config end
rps 960`;

  return ({
    tcname:"config-sdp",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs,
  });
}

export function sipCallOriginatingAttribs(callConfig, pluginId, typeId, frequency, reportId) {
  let attribs = 
`invoke set-media-ep-mode server
invoke media-init
format [t-tx-invite] buffer
send-with-blength Content-Length [p-blank-line] [buffer] conn-name
receive [t-rx-invite-180] timer 60000
receive [t-rx-invite-200] timer 60000
send [t-tx-ack]

#Audio codec instructions
invoke set-audio-codec [remote-acodec-name]
invoke set-audio-codec-number [remote-acodec-number]
invoke set-remote-audio-rtp-port [remote-audio-rtp-port]`;

  if(Math.round(callConfig.vcodec) > 0) {
    attribs += 
`

#Video codec instructions
invoke set-video-codec [remote-vcodec-name]
invoke set-video-codec-number [remote-vcodec-number]
invoke set-remote-video-rtp-port [remote-video-rtp-port]`;
  }

  attribs += 
`

#Set remote ip
invoke set-remote-ip [remote-ip]

#Media instructions
invoke media-ready timer 60000
invoke media-sendrecv timer ${callConfig.mediatime}

#Disconnect
invoke media-inactive
send [t-tx-bye]
receive [t-rx-bye-200] timer 60000

rps 960`;

  return ({
    tcname:"call-originating",
    startindex:1,
    deltaindex:1,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs,   
  });
}

export function sipCallTerminatingAttribs(callConfig, pluginId, typeId, frequency, reportId) {
  let attribs = 
`invoke set-media-ep-mode client
invoke media-init
receive [t-rx-invite] timer 60000

#Audio codec instructions
invoke set-audio-codec [remote-acodec-name]
invoke set-audio-codec-number [remote-acodec-number]`;

  if(Math.round(callConfig.vcodec) > 0) {
    attribs += 
`

#Video codec instructions
invoke set-video-codec [remote-vcodec-name]
invoke set-video-codec-number [remote-vcodec-number]`;
  }

    attribs += 
`

#Invite response and ack
send [t-tx-invite-180]
invoke timer 500
format [t-tx-invite-200] buffer
send-with-blength Content-Length [p-blank-line] [buffer] conn-handle
receive [t-rx-ack] timer 60000`;

    attribs += 
`

#Set remote ip
invoke set-remote-ip [remote-ip]

#Set audio port
invoke set-remote-audio-rtp-port [remote-audio-rtp-port]`;

  if(Math.round(callConfig.vcodec) > 0) {
    attribs += 
`

#Set video port
invoke set-remote-video-rtp-port [remote-video-rtp-port]`;
  }

    attribs += 
`

#Media instructions
invoke media-ready timer 60000
invoke media-sendrecv timer ${callConfig.mediatime}

#Disconnect
invoke media-inactive
receive [t-rx-bye] timer 60000
send [t-tx-bye-200]

rps 960`;

  return ({
    tcname:"call-terminating",
    startindex:0,
    deltaindex:1,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs,   
  });
}


export function sipWebRTCCallOriginatingAttribs(callConfig, pluginId, typeId, frequency, reportId) {
  let mediaprofilesObj = lmMediaProfiles();
  let mediaprofile = mediaprofilesObj[callConfig.mediaprofile];

  let attribs = 
`invoke set-media-ep-mode server
invoke set-ice-role controlling
invoke media-init
invoke ice-agent-init timer 60000

format [t-tx-invite] buffer
send-with-blength Content-Length [p-blank-line] [buffer] conn-name
receive [t-rx-invite-180] timer 60000
receive [t-rx-invite-200] timer 60000
send [t-tx-ack]

#Audio codec instructions
invoke set-audio-codec [remote-acodec-name]
invoke set-audio-codec-number [remote-acodec-number]
invoke set-remote-audio-rtp-candidate [remote-audio-rtp-candidate]
invoke set-remote-audio-rtp-ufrag [remote-audio-rtp-ufrag]
invoke set-remote-audio-rtp-icepasswd [remote-audio-rtp-icepasswd]`;


  if(Math.round(callConfig.vcodec) > 0) {
    attribs += 
`

#Video codec instructions
invoke set-video-codec [remote-vcodec-name]
invoke set-video-codec-number [remote-vcodec-number]
invoke set-remote-video-rtp-candidate [remote-video-rtp-candidate]
invoke set-remote-video-rtp-ufrag [remote-video-rtp-ufrag]
invoke set-remote-video-rtp-icepasswd [remote-video-rtp-icepasswd]`;
  }

  attribs += 
`

#Set premedia params
invoke ice-agent-con-check timer 60000
invoke set-remote-ip [p-ice-remote-audio-ip:session1]
invoke set-remote-audio-rtp-port [p-ice-remote-audio-rtp-port:session1]`;

  if(Math.round(callConfig.vcodec) > 0) {
    attribs += 
`
invoke set-remote-video-rtp-port [p-ice-remote-video-rtp-port:session1]`;
  }

    attribs += 
`

#Media instructions
invoke media-ready timer 60000
invoke media-sendrecv timer ${callConfig.mediatime}
invoke media-inactive

#Disconnect`;

  if(mediaprofile === 'srtp-dtls') {
    attribs += 
`
invoke dtls-close`;
  }

    attribs += 
`
send [t-tx-bye]
receive [t-rx-bye-200] timer 60000

rps 960`;

  return ({
    tcname:"call-originating",
    startindex:1,
    deltaindex:1,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs,   
  });
}

export function sipWebRTCCallTerminatingAttribs(callConfig, pluginId, typeId, frequency, reportId) {
  let mediaprofilesObj = lmMediaProfiles();
  let mediaprofile = mediaprofilesObj[callConfig.mediaprofile];

  let attribs = 
`invoke set-media-ep-mode client
invoke set-ice-role controlled
invoke media-init
receive [t-rx-invite] timer 60000
invoke ice-agent-init timer 60000

#Audio codec instructions
invoke set-audio-codec [remote-acodec-name]
invoke set-audio-codec-number [remote-acodec-number]
invoke set-remote-audio-rtp-candidate [remote-audio-rtp-candidate]
invoke set-remote-audio-rtp-ufrag [remote-audio-rtp-ufrag]
invoke set-remote-audio-rtp-icepasswd [remote-audio-rtp-icepasswd]`;

  if(Math.round(callConfig.vcodec) > 0) {
    attribs += 
`

#Video codec instructions
invoke set-video-codec [remote-vcodec-name]
invoke set-video-codec-number [remote-vcodec-number]
invoke set-remote-video-rtp-candidate [remote-video-rtp-candidate]
invoke set-remote-video-rtp-ufrag [remote-video-rtp-ufrag]
invoke set-remote-video-rtp-icepasswd [remote-video-rtp-icepasswd]`;
  }

    attribs += 
`

#Invite response and ack
send [t-tx-invite-180]
invoke timer 500
format [t-tx-invite-200] buffer
send-with-blength Content-Length [p-blank-line] [buffer] conn-handle
receive [t-rx-ack] timer 60000`;



    attribs += 
`

#Set premedia params
invoke ice-agent-con-check timer 60000
invoke set-remote-ip [p-ice-remote-audio-ip:session1]
invoke set-remote-audio-rtp-port [p-ice-remote-audio-rtp-port:session1]`;

  if(Math.round(callConfig.vcodec) > 0) {
    attribs += 
`
invoke set-remote-video-rtp-port [p-ice-remote-video-rtp-port:session1]`;
  }

    attribs += 
`

#Media instructions
invoke media-ready timer 60000
invoke media-sendrecv timer ${callConfig.mediatime}
invoke media-inactive

#Disconnect`;

  if(mediaprofile === 'srtp-dtls') {
    attribs += 
`
invoke dtls-close`;    
  }

    attribs += 
`
receive [t-rx-bye] timer 60000
send [t-tx-bye-200]

rps 960`;

  return ({
    tcname:"call-terminating",
    startindex:0,
    deltaindex:1,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs,   
  });
}

export function sipSessionInitAttribs(userConfig, callConfig, serverConfig, turnConfig, pluginId, typeId, frequency, reportId) {
  let mediaprofilesObj = lmMediaProfiles();
  let cryptoSuitesObj = lmCryptoSuites();
  
  let mediaprofile = mediaprofilesObj[callConfig.mediaprofile];
  let cryptosuite = cryptoSuitesObj[callConfig.cryptosuite].toUpperCase();


  let attribs = 
`session-id session1
call-gap-index -1
called-party-domain ${serverConfig.server}
called-party-prefix ${userConfig.uprefix}
media-transport ${mediaprofile}`;

  if(mediaprofile === "srtp-dtls") {
    attribs +=
`
crypto-suite ${cryptosuite}`;
  }
  
  if(turnConfig) {
    let iceModesObj = lmICEModes();
    let icemode = iceModesObj[turnConfig.icemode];
    
    if(icemode === 'turn') {
    attribs += 
`
data-mode data-over-ch`;
    }
  }
  
  attribs += 
`
rps 960`;

  return ({
    tcname:"session-init",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs, 
  });
}

export function sipLoadTemplateAttribs(tptype, tpname, pluginId, typeId, frequency, reportId) {
  let startindex=0;
  
  if(tptype === "originating") {
    startindex = 1;
  }
  
  let attribs = 
`session-id session1
invoke set-default-text-templates ${tpname}
rps 960`;

  return ({
    tcname:`execute-load-call-${tptype}-templates`,
    startindex:`${startindex}`,
    deltaindex:1,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs, 
  });
}

export function sipExecuteRegisterAttribs(userConfig, pluginId, typeId, frequency, reportId) {

  let attribs = 
`session-id session1
invoke timer 10000
invoke init-transport timer 120000
send [t-tx-register]
`;

  if(userConfig.auth > 0) {
    attribs += 
`receive [t-rx-register-401] timer 120000
send auth-request
`;    
  }
  
  attribs += 
`receive [t-rx-register-200] timer 120000
rps 16`;
  
  return ({
    tcname:"execute-register",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs, 
  });
}

export function sipExecuteCallAttribs(pluginId, typeId, frequency, reportId) {

  let attribs = 
`session-id session1
execute [i-call-originating]
execute [i-call-terminating]
rps 16`;
  
  return ({
    tcname:"execute-call",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs, 
  });
}

export function sipExecuteUnregisterAttribs(userConfig, pluginId, typeId, frequency, reportId) {

  let attribs = 
`session-id session1
send [t-tx-unregister]
`;

  if(userConfig.auth > 0) {
    attribs += 
`receive [t-rx-register-401] timer 120000
send auth-request
`;    
  }
  
  attribs += 
`receive [t-rx-unregister-200] timer 120000
rps 16`;
  
  return ({
    tcname:"execute-unregister",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs, 
  });
}

export function sipSessionDeInitAttribs(pluginId, typeId, frequency, reportId) {
  let attribs = `rps 16`;
  return ({
    tcname:"session-deinit",
    startindex:0,
    deltaindex:0,
    endindex:10000,
    timeout: 3000000,
    repeat: 1,
    tolerance: 10,
    plugin: pluginId,
    type: typeId,
    freq: frequency,
    report: reportId,
    attribs: attribs, 
  });
}
