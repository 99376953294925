
import React from 'react';
import { HashLink } from 'react-router-hash-link'
import {setPageTitle} from '../../routes/lm.js';

function apatsyntax_table() {
  return (
      <table className="statstbl">
        <thead>
          <tr>
            <th style={{width: "5%"}}>
              Sl
            </th>
            
            <th style={{width: "40%"}}>
              Test block
            </th>
            
            <th style={{width: "55%"}}>
              Syntax
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              1
            </td>
            <td>
              <b>create-instance</b>
              <br/>id 0
              <br/>transport ws
              <br/>local-ipaddress 192.168.1.4
              <br/>server 192.168.1.116
              <br/>server-port 8080
              <br/>user lm_
              <br/>password lm_
              <br/>call-participants 2
              <br/>video 1
              <br/>media-capture-offset 200
              <br/>media-enable-call-offset 1
              <br/>media-cert-verify 1
              <br/>client-cert-verify 1
              <br/>call-gap-index -1
              <br/>rtcp-mux 1
              <br/>av-bundle 1
              <br/>ipversion 6
              <br/>network-log 1
              <br/>javascript 1
              <br/>automation 1
              <br/>headless 1
              <br/>ice-mode turn
              <br/>turn-server 192.168.1.187
              <br/>turn-server-port 3478
              <br/>turn-user-prefix lm_
              <br/>turn-password-prefix lm_
              <br/>#ip-granularity siblings
              <br/>gnutls-log 99
              <br/>#gnutls-audit-log 99
              <br/>ep-status none
              <br/>rps 1000            
            </td>
            
            <td>
              The <b>create-instance</b> command creates endpoint. This test case name is fixed, you can not use any arbitrary name. Various attributes used in create instance are explained below.

              <br/><br/>
              <b>id</b> - The id indicates base index. If you want the endpoints to start from 1000, you may like to enter 1000 for id.

              <br/><br/>
              <b>transport</b> - The transport type. Possible values are udp, tcp, ws, wss, sctp, tls etc.

              <br/><br/>
              <b>local-ipaddress</b> The ip address that will be used by multiplier.

              <br/><br/>
              <b>server</b> - The server ip address. You can also provide server FQDN.

              <br/><br/>
              <b>server-port</b> - The server port.

              <br/><br/>
              <b>user</b> - The user prefix. The MDN (or index) is appended to compute endpoint id.

              <br/><br/>
              <b>password</b> - The password prefix. The MDN (or index) is appended to compute endpoint password.

              <br/><br/>
              <b>call-participants</b> - Total number of participants in a call. For basic call, it should be 2. For transfer case it should be 3.

              <br/><br/>
              <b>video</b> - To disable / enable video set it to 0 / 1.

              <br/><br/>
              <b>media-capture-offset</b> - If user wants to capture all audio / video data and write to a file for all endpoints, set media-capture-offset to 1. If you want to capture for certain endpoints then provide the offset value accordingly. Possible values for this attributes are 1, 2, ... n etc. If you have set it to 10, then media is captured for endpoint 0, 10, 20 so on ...

              <br/><br/>
              <b>media-enable-call-offset</b> - This enables users to have calls with media disabled. Assuming you have set this value to 10, then 0th, 10th, 20th etc.. calls will have signalling and media, other calls will have just signalling without.

              <br/><br/>
              <b>media-cert-verify</b> - If you are making call (see session-init syntax) with srtp-dtls and you want to verify certificates in media channel set it to 1, else 0.

              <br/><br/>
              <b>client-cert-verify</b> - If you have selected signalling transport as TLS,WSS and you want to present the client certificates to server in TLS connection then client-cert-verify flag need to be enabled (i.e. 1) else set it to 0.

              <br/><br/>
              <b>call-gap-index</b> - The call gap index is used by calling party to determine called party index. Either a positive value or negative value you can specify here. If your test involves hundreds or thousands of endpoints for basic call, it is strongly recommended to set this as -1. You should think of a call model where endpoint 1 calls endpoint 0, endpoint 3 calls endpoint 2 so on. This model ensures, first called party is scheduled and then calling party, hence chance of succeeding calls is more.

              <br/><br/>
              <b>rtcp-mux</b> - To disable / enable rtcp mux set it to 0 / 1.

              <br/><br/>
              <b>av-bundle</b> - To disable / enable audio video bundle set it to 0 / 1.

              <br/><br/>
              <b>ipversion</b> - To set the ip version 6 or 4 set it to 6 / 4.

              <br/><br/>
              <b>network-log</b> - To log both the TX and RX network messages (HTTP, HTTPS, Websocket, Secured websocket etc) to a separate file, set it to 1.

              <br/><br/>
              <b>javascript</b> - To initialize the javascript runtime engine set it to 1.

              <br/><br/>
              <b>automation</b> - To view the test result (Overall stats) on basis of number of test cases instead of number of virtual users set it to 1.

              <br/><br/>
              <b>headless</b> - Enable headless browser module by setting it to 1.

              <br/><br/>
              <b>ws-hs-template</b> - The template that is used for webseocket handshake. During websocket handshake, multiplier sends handshake (http GET) message. You can customise the handshake message using this template.

              <br/><br/>
              <b>ws-req-uri</b> - Request URI in websocket handsake (HTTP GET) message.

              <br/><br/>
              <b>ws-ahh</b> - Websocket additional http headers. You can send additional http headers in websocket handsake (HTTP GET) message.

              <br/><br/>
              <b>ice-mode</b> - You can specify ice mode here. Possible values are host / lite / stun / turn.

              <br/><br/>
              <b>turn-server</b> - The turn server ip address or FQDN.

              <br/><br/>
              <b>turn-server-port</b> - The turn server port.

              <br/><br/>
              <b>turn-user-prefix</b> - The turn user prefix. The MDN (or index) is appended to compute turn user for endpoint.

              <br/><br/>
              <b>turn-password-prefix</b> - The turn password prefix. The MDN (or index) is appended to compute turn password for endpoint.

              <br/><br/>
              <b>ip-granularity</b> - Possible values are none, siblings, endpoints. None indicates local ip address is used for multiplier, and endpoints under all siblings. Siblings indicates local ip address is used for multiplier, next valid ip address used for all endpoints under first sibling, then next valid ip address used for all endpoints under second sibling and so on. Endpoints indicates local ip address is used for multiplier, and all endpoints will have unique ip address. Please check this for more information.

              <br/><br/>
              <b>gnutls-log</b> - Only if you want to see GNU TLS log set this log level. For all logs set it to 99. Only developers should use it.

              <br/><br/>
              <b>ep-status</b> - Endpoint status is written into log file in every few seconds. Three possible value are given below.

              <ul>
              <li>none - Endpoint status is not be written into log file. During heavy load testing this is the preferred option.</li>

              <li>resource - Endpoint status, only resource usage portion (like size of linked lists, timer counts etc) is written to log file.</li>

              <li>details - Endpoint status in full is written into log file. This helps to debug which particular endpoint is not returned from current command execution. Once you know which endpoint is not returned, filter log for that endpoint and it helps to analyze the issue further.</li>
              </ul>

              <b>rps</b> - The rates per second. You can mention any positive value here. Please check this to know more on rps.
            </td>
            
          </tr>

          <tr>
            <td>
              2
            </td>
            
            <td>
              <b>config-sdp</b>
              <br/>session-config start
              <br/>version 0
              <br/>origin multiplier
              <br/>session multiplier
              <br/>audio-codec OPUS
              <br/>video-codec VP8
              <br/>audio-ptime 20
              <br/>video-ptime 60
              <br/>video-fps 24
              <br/>audio-samplingrate 8000
              <br/>video-samplingrate 90000
              <br/>audio-file g711a.wav
              <br/>video-file in-vp8.mlt
              <br/>session-config end
              <br/>session-attributes start
              <br/>t 10101 2222233
              <br/>session-attributes end
              <br/>#Start of audio codecs details
              <br/>audio-codecs start
              <br/>codecs 0 8 99 101 102
              <br/>a rtpmap:0 PCMU/8000
              <br/>a rtpmap:8 PCMA/8000
              <br/>a rtpmap:99 AMR/8000
              <br/>a rtpmap:101 telephone-event/8000
              <br/>a fmtp:101 0-11
              <br/>a rtpmap:102 OPUS/8000
              <br/>b AS 64
              <br/>a ptime:25
              <br/>a curr:qos local none
              <br/>a curr:qos remote none
              <br/>a des:qos mandatory local sendrecv
              <br/>a des:qos mandatory remote sendrecv
              <br/>audio-codecs end
              <br/>#Start of video codecs details
              <br/>video-codecs start
              <br/>codecs 107 108
              <br/>a rtpmap:107 VP8/40000
              <br/>a rtpmap:108 VP9/40000
              <br/>b AS 128
              <br/>a ptime:70
              <br/>a curr:qos local none
              <br/>a curr:qos remote none
              <br/>a des:qos mandatory local sendrecv
              <br/>a des:qos mandatory remote sendrecv
              <br/>video-codecs end            
            </td>
            
            <td>
              <b>config-sdp</b> is used to configure sdp parameters. This test case name is fixed, you can not use any arbitrary name. Various attributes are explained below.

              <br/><br/><b>session-config start</b> - Indicates start of session level configuration.

              <br/><br/><b>version</b> - SDP version. Typically set to 0.

              <br/><br/><b>origin</b> - SDP origin. You can put any token say multiplier.

              <br/><br/><b>session</b> - Session. You can put any token say multiplier.

              <br/><br/><b>audio-codec</b> - Audio codec names. Possible values are PCMA, PCMU, AMR, OPUS.

              <br/><br/><b>video-codec</b> - Video codec name. Possible value is VP8.

              <br/><br/><b>audio-ptime</b> - Audio packetization time in millisecond.

              <br/><br/><b>video-ptime</b> - Video packetization time in millisecond. With mlt file introduction (since multiplier 3.03), this parameter is not required.

              <br/><br/><b>video-fps</b> - Video frames per second. Introduced in multiplier 3.03.

              <br/><br/><b>audio-samplingrate</b> - Audio sampling in Hz.

              <br/><br/><b>video-samplingrate</b> - Video sampling in Hz.

              <br/><br/><b>audio-file</b> - Audio input file name. The output file takes a format like out-multiplierid-infilename.

              <br/><br/><b>video-file</b> - Video input file name. The output file takes a format like out-multiplierid-infilename. Starting from Multiplier version 3.03, mlt video files are introduced. This is multiplier proprietary video format file. This file is not playable, however you can use utility mfmedia to to convert mlt file to mkv file.

              <br/><br/><b>session-config end</b> - Indicates end of session level configuration.
              
              <br/><br/><b>session-attributes start</b> - Indicates start of session attributes.

              <br/><br/><b>t</b> - Time stamp. You can put start and end values. Example given below.



              <br/><br/><b>t</b> 10101 2222233.

              <br/><br/><b>a</b> - You can put any attribute. Some examples given below.<br/>

              <br/>a group:BUNDLE audio<br/>

              <br/>a msid-semantic: WMS<br/>FUNgvHSv45OGnJA8ikuFBKVOBekWCfkM3xNZ

              <br/><br/><b>session-attributes end</b> - Session level attribute end.

              <br/><br/><b>audio-codecs start</b> - Audio codec configuration start.

              <br/><br/><b>codecs</b> - You need to set all audio codecs here as space separated values. Example given below.

              <br/><br/>0 8 99 101 111

              <br/><br/><b>a rtpmap:</b> - RTP map for audio codec

              <br/><br/><b>a fmtp:</b> - fmtp attribute.

              <br/><br/><b>a ptime:</b>- Packetization time in millisecond.

              <br/><br/><b>b AS</b> - Specify bandwidth here.

              <br/><br/><b>a curr:</b> - Current qos attribute, used in precondition case.




              <br/><br/><b>a des:</b> - Desired qos attribute, used in precondition case.

              <br/><br/><b>a cnf:</b> - Confirmed qos attribute, used in precondition case.

              <br/><br/><b>audio-codecs end</b> - Audio codec configuration end.

              
              <br/><br/><b>video-codecs start</b> - Video codec configuration start.

              <br/><br/><b>codecs</b> - You need to set all video codecs here as space separated values.

              <br/><br/><b>a rtpmap:</b> - RTP map for video codec

              <br/><br/><b>a ptime:</b> - Packetization time in millisecond.

              <br/><br/><b>b AS</b> - Specify bandwidth here.

              <br/><br/><b>a curr:</b> - Current qos attribute, used in precondition case.

              <br/><br/><b>a des:</b> - Desired qos attribute, used in precondition case.

              <br/><br/><b>a cnf:</b> - Confirmed qos attribute, used in precondition case.

              <br/><br/><b>video-codecs end</b> - Video codec configuration end.            
            </td>
          </tr>
          
          <tr>
            <td>
              3
            </td>
            
            <td>
              <b>variables</b>
              <br/>global msid=[p-random-hex-number:36]
              <br/>global label=[p-random-hex-number]
              <br/>global server-port=5060
              <br/>global entity-id=[p-random-number]
              <br/>global sess_id=[p-random-number]
              <br/>global sess_id="kodiaknetworks"
              <br/>global mdn=[p-public-user]
              <br/>global sess-version=[p-random-number]            
            </td>
            
            <td>
            This test case is of type variable. This test case name is not fixed, you can use any test case name as you like. You can declare strings, integers. Even you can declare a variable with random values. Lets see some examples.
            <br/><br/>
            <ol>
              <li>
                <i>global msid=[p-random-hex-number:36]</i><br/>
                In above example, msid is a string variable initialised with a hex-string of size 36 bytes. Please note that length is mentioned here as 36 bytes.
              </li>


              <li>
                <i>global label=[p-random-hex-number]</i><br/>
                Here label is a string variable, initialised to a 7 byte random hex-string. If length is not mentioned by default length is 7 bytes.
              </li>

              <li>
                <i>global server-port=5060</i><br/>
                Here server-port is a integer variable initialised to value 5060.
              </li>

              <li>
                <i>global entity-id=[p-random-number]</i><br/>
                Here label is a string variable, initialised to a 7 byte random digit string. If length is not mentioned by default length is 7 bytes.
              </li>

              <li>
                <i>global sess_id=[p-random-number]</i><br/>
                Here label is a string variable, initialised to a 7 byte random digit string. If length is not mentioned by default length is 7 bytes.
              </li>

              <li>
                <i>global str1="LM Tools"</i><br/>
                Here str1 is a string variable initialised with value LM Tools.
              </li>

              <li>
                <i>global mdn=[p-public-user]</i><br/>
                Here mdn is a string variable, initialised to a plugin variable public-user. Plugin variables are variables provided by multiplier plugins. In create-instance you have configured public-user, same is stored in multiplier plugin, and here that same value is provided. Sometimes even if you have not configured certain things, you will be able to extract certain system internal values as plugin variables. More of this you will see in instruction blocks down.
              </li>

            </ol>
            </td>            
          </tr>

          <tr>
            <td>
              4
            </td>
            
            <td>
              <b>random_number_func</b><br/>
              For example, please refer right side.            
            </td>
            
            <td>
              <i>
              function random_number_func()<br/>
              &nbsp;&nbsp;&nbsp;&nbsp;math.randomseed(os.time())<br/>
              &nbsp;&nbsp;&nbsp;&nbsp;x=math.random(100000000,999999999)<br/>
              return x<br/>
              end<br/>
              </i><br/><br/>
              
              This is a lua function to generate a random number. You can define lua functions as test cases, and use the functions in template / instructions like [f-func()]. This is bit interesting, you can add more intelligence to test execution using lua functions.            
            </td>            
          </tr>

          <tr>
            <td>
              5
            </td>
            
            <td>
              <b>rxpkt_expected_length</b><br/>
              This lua function helps to calculate incoming message length. APAT being generic, it does not know whats the message length. It takes a lua function rxpkt_expected_length from user to calculate message length.            
            </td>
            
            <td>
              function rxpkt_expected_length(s, num2)
              <br/><sp1>i = 0;</sp1>
              <br/><sp1>j = 0;</sp1>
              <br/><sp1>len = 0;</sp1>
              <br/><sp1>ret_value = 0;</sp1>
              <br/><sp1>header_length = 0;</sp1>
              <br/><sp1>i, j = string.find(s, "\r\n\r\n")</sp1>
              <br/><sp1></sp1>
              <br/><sp1>if(i ~= nil)then</sp1>
              <br/><tab2>header_length = j</tab2>
              <br/><tab2>i, j = string.find(s, "Content%-Length:")</tab2>
              <br/><tab2>if(j ~= nil) then</tab2>
              <br/><tab3>si, li = string.find(s, "\r\n", j)</tab3>
              <br/><tab3>j = j+1</tab3>
              <br/><tab3>si = si-1</tab3>
              <br/><tab3></tab3>
              <br/><tab3>new_sting1 = string.sub(s, j, si)</tab3>
              <br/><tab3>if(new_sting1 ~= nil)then</tab3>
              <br/><tab4>local l = 1</tab4>
              <br/><tab4>while string.sub(new_sting1,l,l) == ' ' do</tab4>
              <br/><tab5>l = l+1</tab5>
              <br/><tab4>end</tab4>
              <br/><tab4></tab4>
              <br/><tab4>local r = string.len(new_sting1)</tab4>
              <br/><tab4></tab4>
              <br/><tab4>while string.sub(new_sting1,r,r) == ' ' do</tab4>
              <br/><tab5>r = r-1</tab5>
              <br/><tab4>end</tab4>
              <br/><tab4></tab4>
              <br/><tab4>new_sting = string.sub(new_sting1,l,r)</tab4>
              <br/><tab3>else</tab3>
              <br/><tab4>new_sting = new_string1</tab4>
              <br/><tab3>end</tab3>
              <br/><tab3></tab3>
              
              <br/><tab3>temp = string.len(new_sting)</tab3>
              <br/><tab3>if(temp &lt; 1) then</tab3>
              <br/><tab4>len = string.len(s)</tab4>
              <br/><tab4>return len</tab4>
              <br/><tab3>end</tab3>
              <br/><tab3></tab3>
              <br/><tab3>len = tonumber(new_sting)</tab3>
              <br/><tab3>if(len >= 0)then</tab3>
              <br/><tab4>len = header_length+len</tab4>
              <br/><tab3>else</tab3>
              <br/><tab4>len = string.len(s)</tab4>
              <br/><tab3>end</tab3>
              <br/><tab2>else</tab2>
              <br/><tab3>len = string.len(s)</tab3>
              <br/><tab2>end</tab2>
              
              <br/><sp1>else</sp1>
              <br/><tab2>len = 0;</tab2>
              <br/><sp1>end</sp1>
              <br/><sp1>return len</sp1>
              <br/>end
            </td>            
          </tr>
        </tbody>
        
        <tr>
          <td>
            6
          </td>
          
          <td>
            <b>plugin variables</b><br/>
            [p-varname]          
          </td>
          
          <td>
            Plugin variables are provided to access internal value of 
            a plugin. You may like to check all 
            available <a href="/userguide/apatvariables">plugin variables</a>.            
          </td>
        </tr>

        <tr>
          <td>
            7
          </td>
          
          <td>
            <b>instructions</b><br/>
            invoke &lt;command&gt; &lt;token1&gt; &lt;token2&gt; &lt;token3&gt; ... &lt;tokenN&gt;
          </td>
          
          <td>
            Instructions are means to execute certain operations in 
            multiplier. Multiplier offers instructions set for each and every 
            operation possible. It is strongly recommended to 
            check <a href="/userguide/apatinstructions">instructions page</a>.
          </td>
        </tr>

        <tr>
          <td>
            8
          </td>
          
          <td>
            <b>text templates</b><br/>
            text templates
          </td>
          
          <td>
            The text templates are message formats used by multiplier to prepare a message. Templates definition can be set through a test case, or can be written directly in a file, and that file can be loaded to memory using set-text-templates instruction. Actually set-text-templates instruction loads all templates in a directory to memory. Before instructions are executed templates must be loaded, so that templates referred in instructions are valid and available.
            <br/><br/>
            When you encode or format a message, if a variable / function found in template, variable / function is replaced with corresponding value.
            <br/><br/>
            When you receive and match an incoming message against a template, if variable is found, corresponding value is extracted to variable and stored for future use.
            <br/><br/>
            For more details please visit <HashLink to='/userguide/template#texttemplates'>text template page</HashLink>.
          </td>
        </tr>

        <tr>
          <td>
            9
          </td>
          
          <td>
            <b>binary templates</b><br/>
            binary templates
          </td>
          
          <td>
            Just like text templates, binary templates are message formats. Text templates are used for text messages and binary templates are used for binary messages. Binary templates can be set through text case or one can write in file and load to memory using set-binary-templates instruction.
            <br/><br/>
            In binary templates you can specify fix bytes, you can do simple arithmetic and use result, you can advance offset, back offset, add padding bytes, kind of provides complete features to prepare a binary message as per your requirement.
            <br/><br/>
            For more information you may like to 
            visit <HashLink to='/userguide/template#binarytemplates'>binary template page</HashLink>
          </td>
        </tr>

        <tr>
          <td>
            10
          </td>
          
          <td>
            <b>session-init</b>
            <br/>session-id session1
            <br/>call-gap-index -1
            <br/>called-party-domain 192.168.1.116
            <br/>called-party-prefix lm_
            <br/>media-transport rtp-udp
            <br/>#permission-mode create-permission
            <br/>
            <br/>#For SRTP UDP below two lines are required
            <br/>#crypto-suite AES_CM_128_HMAC_SHA1_80
            <br/>#sec-service confidential-authentication
            <br/>
            <br/>#For SRTP DTLS only below line required
            <br/>#crypto-suite SRTP_AES128_CM_HMAC_SHA1_80
            <br/>#crypto-suite SRTP_AES128_CM_HMAC_SHA1_32
            <br/>
            <br/>rps 500          
          </td>
          
          <td>
            <b>session-id</b> - Each session posses unique session key. You can specify session key using this parameter. Later this session is referred using this key.
            <br/><br/>
            <b>call-gap-index</b> - The call gap index is used by calling party to determine called party index. Either a positive value or negative value you can specify here. If your test involves hundreds or thousands of endpoints for basic call, it is strongly recommended to set this as -1. You should think of a call model where endpoint 1 calls endpoint 0, endpoint 3 calls endpoint 2 so on. This model ensures, first called party is scheduled and then calling party, hence chance of succeeding calls is more.
            <br/><br/>
            <b>called-party-domain</b> - You can set the domain IP address or domain name using this parameter.
            <br/><br/>
            <b>called-party-prefix</b> - You can set called party user prefix using this parameter.
            <br/><br/>
            <b>media-transport</b> - Media transport profiles. Possible values are rtp-udp, rtp-tcp, rtp-tls, srtp-udp and srtp-dtls.
            <ul>
            <li><b>rtp-udp</b>: RTP packets exchange over UDP.</li>
            <li><b>rtp-tcp</b>: RTP packets exchange over TCP.</li>
            <li><b>rtp-tls</b>: RTP packets exchange over TLS.</li>
            <li><b>srtp-udp</b>: SRTP packets exchange over UDP.</li>
            <li><b>srtp-dtls</b>: SRTP packets exchange over UDP, key is extracted from DTLS handshake.</li>
            </ul>
            
            <b>data-mode</b> - You can specify data mode here in a ICE associated call. Possible values are plain-data / data-over-ch.
            <br/><br/>
            <b>crypto-suite</b> - Crypto-suite type based on security service.
            Possible values are -
            <br/><br/>
            <ul>
            <li>SRTP_AES128_CM_HMAC_SHA1_80</li>
            <li>SRTP_AES128_CM_HMAC_SHA1_32</li>
            <li>SRTP_NULL_HMAC_SHA1_80</li>
            <li>SRTP_NULL_SHA1_32</li>
            </ul>
            
            <b>sec-service</b> - Security services. Possible values are
            <ul>
            <li>confidential-authentication</li>
            <li>authentication</li>
            <li>confidential</li>
            <li>none</li>
            </ul>

            This attribute is only applicable when media-transport is srtp-udp.          
          </td>
        </tr>

        <tr>
          <td>
            11
          </td>
          
          <td>
            <b>session-deinit</b><br/>
            rps 512
          </td>
          
          <td>
            It de-initialises session that was created in session-init test case.
          </td>
        </tr>

        <tr>
          <td>
            12
          </td>
          
          <td>
            <b>sleep</b><br/>
            sleep 1000
          </td>
          
          <td>
            You can specify sleep time in milliseconds here.
          </td>
        </tr>
        
      </table>
  
  );
}

const UGAPATSyntax = () => {

  setPageTitle('APAT Syntax');
  
  const element = (
    <div className="userguide">
      <h1>APAT Syntax</h1>
      <br/>
      {apatsyntax_table()}
      <br/> <br/>
      <div className="inlinecenter">
        <button onClick={() => window.location.href="/userguide/apatvariables" }>Back</button> 
        <button onClick={() => window.location.href="/userguide/tableofcontents" }>Table of Contents</button>
        <button onClick={() => window.location.href="/userguide/apatwebrtcsyntax" }>Next</button>
      </div>
    </div>
  );
  
  return element;
}

export {UGAPATSyntax, apatsyntax_table}

