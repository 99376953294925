import { Link } from 'react-router-dom';
import {setPageTitle} from './lm.js';

const Components = () => {

  setPageTitle('System Components');
  
  return (
    <div>
        <h2>Add / Edit System Components</h2>
        <Link to="/components/addcontroller">Add Controller</Link><br/>
        <Link to="/components/editcontroller">Edit Controller</Link><br/>
        <Link to="/components/multiplier">Add Multiplier</Link><br/>
        <Link to="/components/editmultipliers">Edit Multiplier</Link><br/>
    </div>
    );
};

export default Components;
