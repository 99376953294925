import 
  {
    lmICECandidateType,
  } from '../../../routes/lm.js';

/*
* Please note the formats are indented from start of line intentionally.
* Do not do normal formating else apat messages will have additional spaces.
*/

export function wsHandshake() {
  return {
    tpname: 'tx-wshandshake',
    format: 
`GET / HTTP/1.1\\r\\n
Host: [p-server]:[p-server-port]\\r\\n
Connection: Upgrade\\r\\n
Pragma: no-cache\\r\\n
Cache-Control: no-cache\\r\\n
Upgrade: websocket\\r\\n
Sec-WebSocket-Version: 13\\r\\n
User-Agent: Multiplier 1.0\\r\\n
Sec-WebSocket-Protocol: apat\\r`
  };
}

export function apatTxRegister(userConfig) {
  return {
    tpname: 'tx-register',
    format: 
`{"register":{"username":"${userConfig.uprefix}[p-client-index]","url":"[p-local-ipaddress]","contact":"${userConfig.uprefix}[p-client-index]@[p-local-ipaddress]:[p-local-client-port: session1:my_connection]","serverip":"[p-server]","serverport":"[p-server-port]"}}`
  };
}

export function apatTxUnRegister(userConfig) {
  return {
    tpname: 'tx-unregister',
    format: 
`{"unregister":{"username":"${userConfig.uprefix}[p-client-index]"}}`
  };
}

export function apatTxOffer(userConfig, callConfig, turnConfig) {
  let ctype = lmICECandidateType(turnConfig.icemode);
  let audiopart = `{"offer":{"from":"${userConfig.uprefix}[p-client-index]","to":"${userConfig.uprefix}[p-called-party-index]","local-ip":"[p-local-ipaddress]","audio-codec":"[p-audio-codec]","audio-codec-number":"[p-audio-codec-number]","audio-media-rtp-port":"[p-audio-rtp-port: session1]","audio-rtp-ufrag":"[p-audio-rtp-ufrag: session1]","audio-rtp-icepasswd":"[p-audio-rtp-icepasswd: session1]","fingerprint":"[p-fingerprint: session1]","audio-rtp-candidate":"[p-audio-rtp-${ctype}-candidate: session1]"`;
  let videopart = `",video-codec":"[p-video-codec]","video-codec-number":"[p-video-codec-number]","video-media-rtp-port":"[p-video-rtp-port: session1]","video-rtp-ufrag":"[p-video-rtp-ufrag: session1]","video-rtp-icepasswd":"[p-video-rtp-icepasswd: session1]","fingerprint":"[p-fingerprint: session1]","video-rtp-candidate":"[p-video-rtp-${ctype}-candidate: session1]"`;
  let ending = "}}";
  
  let offer = audiopart;
  if(callConfig.vcodec > 0) {
    offer += videopart;
  }
  offer += ending;
  
  return {
    tpname: 'tx-offer',
    format: offer,
  };
}

export function apatRxOffer(callConfig) {
  let audiopart = `{"offer":{"from":[...],"to":[...],"local-ip":"[remote-ip]","audio-codec":"[remote-acodec-name]","audio-codec-number":"[remote-acodec-number]","audio-media-rtp-port":"[remote-audio-rtp-port]","audio-rtp-ufrag":"[remote-audio-rtp-ufrag]","audio-rtp-icepasswd":"[remote-audio-rtp-icepasswd]","audio-rtp-candidate":"[remote-audio-rtp-candidate]"`;
  let videopart = `,"video-codec":"[remote-vcodec-name]","video-codec-number":"[remote-vcodec-number]","video-media-rtp-port":"[remote-video-rtp-port]","video-rtp-ufrag":"[remote-video-rtp-ufrag]","video-rtp-icepasswd":"[remote-video-rtp-icepasswd]","video-rtp-candidate":"[remote-video-rtp-candidate]"`;
  let ending = "}}";
  
  let offer = audiopart;
  if(callConfig.vcodec > 0) {
    offer += videopart;
  }
  offer += ending;
  
  return {
    tpname: 'rx-offer',
    format: offer,
  };  
}


export function apatTxAnswer(userConfig, callConfig, turnConfig) {

  let ctype = lmICECandidateType(turnConfig.icemode);
  let audiopart = `{"answer":{"from":"${userConfig.uprefix}[p-client-index]","to":"${userConfig.uprefix}[p-calling-party-index]","local-ip":"[p-local-ipaddress]","audio-codec":"[p-audio-codec]","audio-codec-number":"[p-audio-codec-number]","audio-media-rtp-port":"[p-audio-rtp-port: session1]","audio-rtp-ufrag":"[p-audio-rtp-ufrag:session1]","audio-rtp-icepasswd":"[p-audio-rtp-icepasswd:session1]","fingerprint":"[p-fingerprint:session1]","audio-rtp-candidate":"[p-audio-rtp-${ctype}-candidate:session1]"`;
  let videopart = `",video-codec":"[p-video-codec]","video-codec-number":"[p-video-codec-number]","video-media-rtp-port":"[p-video-rtp-port: session1]","video-rtp-ufrag":"[p-video-rtp-ufrag: session1]","video-rtp-icepasswd":"[p-video-rtp-icepasswd: session1]","fingerprint":"[p-fingerprint: session1]","video-rtp-candidate":"[p-video-rtp-${ctype}-candidate: session1]"`;
  let ending = "}}";
  
  let answer = audiopart;
  if(callConfig.vcodec > 0) {
    answer += videopart;
  }
  answer += ending;
  
  return {
    tpname: 'tx-answer',
    format: answer,
  };
}

export function apatRxAnswer(callConfig) {
  let audiopart = `{"answer":{"from":[...],"to":[...],"local-ip":"[remote-ip]","audio-codec":"[remote-acodec-name]","audio-codec-number":"[remote-acodec-number]","audio-media-rtp-port":"[remote-audio-rtp-port]","audio-rtp-ufrag":"[remote-audio-rtp-ufrag]","audio-rtp-icepasswd":"[remote-audio-rtp-icepasswd]","audio-rtp-candidate":"[remote-audio-rtp-candidate]"`;
  let videopart = `,"video-codec":"[remote-vcodec-name]","video-codec-number":"[remote-vcodec-number]","video-media-rtp-port":"[remote-video-rtp-port]","video-rtp-ufrag":"[remote-video-rtp-ufrag]","video-rtp-icepasswd":"[remote-video-rtp-icepasswd]","video-rtp-candidate":"[remote-video-rtp-candidate]"`;
  let ending = "}}";
  
  let answer = audiopart;
  if(callConfig.vcodec > 0) {
    answer += videopart;
  }
  answer += ending;
  
  return {
    tpname: 'rx-answer',
    format: answer,
  };  
}

export function apatTxDisconnect(userConfig) {
  return {
    tpname: 'tx-disconnect',
    format: 
`{"disconnect":{"from":"${userConfig.uprefix}[p-client-index]","to":"${userConfig.uprefix}[p-called-party-index]","action":"call-disconnect"}}`
  };
}

export function apatRxDisconnect() {
  return {
    tpname: 'rx-disconnect',
    format: 
`{"disconnect"[...]`
  };
}


export function apatRxAckDisconnect() {
  return {
    tpname: 'rx-ack-disconnect',
    format: 
`{"ack-disconnect"[...]`
  };
}

export function apatTxAckDisconnect(userConfig) {
  return {
    tpname: 'rx-ack-disconnect',
    format: 
`{"ack-disconnect":{"from":"${userConfig.uprefix}[p-client-index]","to":"${userConfig.uprefix}[p-calling-party-index]"}}`
  };
}