
import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import {setPageTitle, getURLQueryVariable, handleErrorResponse} from '../../routes/lm.js';

const Controller = () => {
  
  const initState = {name: "", ipaddr: "", atoken: "", cid: getURLQueryVariable("id")};
  const [state, setState] = useState(initState);
  const [status, setStatus] = useState(null);

  setPageTitle("Controller");
  
  var user = JSON.parse(localStorage.getItem('user'));
  
  /* Handle Submit */
  const handleSave = async (e) => {
    e.preventDefault();
    setStatus(null);

    try {
      const res = await axios.post('/api/save/controller', 
        {uid: user.uid, email: user.email, data: JSON.stringify(state)},
        {headers: {
            'Authorization': user.jwt,
        }}
      ).then( function (res) {
        
        if(res.status === 200) {
          setStatus({
            type: 'success',
            message: `Controller ${state.name} saved successfully`,
          });                    
        } else {
          setStatus({
            type: 'error',
            message: res.data.message,
          });                    
        }
      });
    }catch(err) {
      
      setStatus({
        type: 'error',
        message: err.response.data.message,
      }); 

      handleErrorResponse(err);
    }    
  }
  
  /* Other Handlers */
  const inputTextChange=(e)=> {
    const {name,value}=e.target;
    const newState = {...state};
    newState[name] = value;
    setState(newState);
  }  

  const fetchSingleController = async () => {
    setStatus(null);
    
    if(Math.round(state.cid) === -1) {
      return;
    }
    
    if(user != null) {
      
      try {
        const res = await axios.post('/api/fetch/singlecontroller', 
        {uid: user.uid, email: user.email, cid: state.cid} ,
        {headers: {
          'Authorization': user.jwt,
        }}).then( 
        function (res) {
          if(res.status === 200) {
            setState(res.data[0]);
          }else {
            setStatus({
              type: 'error',
              message: 'Error in displaying data',
            });  
          }
        });
      }catch(err) {
        setStatus({
          type: 'error',
          message: err.message,
        });  
        handleErrorResponse(err);
      }
    } else {
      setStatus({
        type: 'error',
        message: "User not logged in",
      });  
    }
  }

  /* Fetch Data */
  useEffect ( () => {
     fetchSingleController();
  }, []);  

  
  /* User Interface */
  if(user) {
    return (
      <form onSubmit={(event) => {
        event.preventDefault();
        handleSave(event);
      }}>
      
        <div key="multmain" className="medium-text">
          <div id="multmain2" className="center" >
            {(Math.round(state.cid) === -1) ? 
              <h2>Add Controller</h2> : 
              <h3><p align="center">Edit Controller [{state.name}]</p></h3>
            }
            <br/>
            <table key="tbl1" className="tbl" >
              <tbody>
                <tr>
                  <td>
                    <b>Name</b><br/>
                    <input type="text" required 
                    maxLength={15}
                    name="name"
                    value={state.name}
                    onChange={(e)=>inputTextChange(e)}
                    placeholder="Unique name"/>
                  </td>

                  <td>
                    <b>IP address</b><br/>
                    <input required 
                    type="text"
                    maxLength={15}
                    name="ipaddr"
                    value={state.ipaddr}
                    onChange={(e)=>inputTextChange(e)}
                    placeholder="IP address"/>
                  </td>

                  <td>
                    <b>Access token</b><br/>
                    <input required 
                    type="text"
                    maxLength={32}
                    name="atoken"
                    value={state.atoken}
                    onChange={(e)=>inputTextChange(e)}
                    placeholder="Access token"/>
                  </td>
                  
                </tr>
              </tbody>
            </table>

          <br/>
          {status && <div className={`alert ${status.type}`}>{status.message}</div>}
            
          </div>
          
          <div className="inlinecenter">
            <button type="submit">Save</button>
          </div>

          
        </div>
      </form>
    );
  } else {
    return(
      <div key="id1" className="medium-text">
        <div key="id2" className="center" >
          You are not allowed to access this page.
          <br/><br/><br/><br/><br/>
        </div>
      </div>
    );
  }
};

const EditSingleController = () => {
  return Controller();
};

const EditControllers = () => {
  const initState = [{cid: 0, name: "", ipaddr: "", checked: false}];
  const [state, setState] = useState(initState);
  const [status, setStatus] = useState(null);
  const [selectAllBtnText, setSelectAllBtnText] = useState("Select All");

  var user = null;
  const userData = localStorage.getItem('user');
  setPageTitle("Edit controllers");

  if(userData != null) {
    user = JSON.parse(userData);
  }

  const fetchControllers = async () => {
    setStatus(null);
    if(user != null) {
      try {
        const res = await axios.post('/api/fetch/controllers', 
        {uid: user.uid, email: user.email} ,
        {headers: {
          'Authorization': user.jwt,
        }}).then( 
        function (res) {
          if(res.status === 200) {
            //This also works, keeping it as reference
            //const newState = res.data.map(obj => ({...obj, checked: false}));
            //console.log(newState);
            
            res.data.forEach(element => (element.checked = false));
            //console.log(res.data);

            setState(res.data);
          }else {
            setStatus({
              type: 'error',
              message: 'Error in displaying data',
            });  
          }
        });
      }catch(err) {
        setStatus({
          type: 'error',
          message: err.message,
        }); 
        handleErrorResponse(err);
      }
    } else {
      setStatus({
        type: 'error',
        message: "User not logged in",
      });  
    }
  }
  
  useEffect ( () => {
   fetchControllers()
  }, []);
  

  function multRowCBClicked(tindex) {
    const newState = [...state];
    newState[tindex].checked = !(state[tindex].checked);
    setState(newState);
  }

  const handleEditControllerDelete= async (e)=> {
    e.preventDefault();

    setStatus(null);
    if(user != null) {
      
      let delIds = [];
      let newState = [];
    
      if(state.length > 0) {
        
        state.forEach(function (element) {
          if(element.checked) {
            delIds.push(element.id);
          } else {
            newState.push(element);
          }
        });
        
        if(delIds.length > 0) {
          if (window.confirm("Press OK to delete " + delIds.length  + " selected controller(s)")) {
            try {
              const res = await axios.post('/api/delete/controllers', 
              {uid: user.uid, email: user.email, data: JSON.stringify(delIds) } ,
              {headers: {
                'Authorization': user.jwt,
              }}).then( 
              function (res) {
                if(res.status === 200) {
                  setState(newState);
                }else {
                  setStatus({
                    type: 'error',
                    message: 'Error in displaying data',
                  });  
                }
              });
            }catch(err) {
              setStatus({
                type: 'error',
                message: err.message,
              }); 
              handleErrorResponse(err);
            }            
          }
        }
      }      
    } else {
      setStatus({
        type: 'error',
        message: "User not logged in",
      });  
    }
  }
  
  function makeControllerRowChecked(obj) {
    obj.checked = true;
    return obj;
  }

  function makeControllerRowUnchecked(obj) {
    obj.checked = false;
    return obj;
  }
  
  const handleEditControllerSelectAll=(e)=> {
    e.preventDefault();
    
    if(state.length > 0) {
      if(e.target.innerText === "Select All") {
        setSelectAllBtnText("Deselect All");
        const newState = state.map(makeControllerRowChecked);
        setState(newState);
      } else {
        setSelectAllBtnText("Select All");
        const newState = state.map(makeControllerRowUnchecked);
        setState(newState);
      }
    } 
  }
  
  if(user === null) {
    window.location.href = "/authrequired";
  } else {
    var tindex = 0;
    return (
      <div className="medium-text">
        <div className="center">
          <h3><p align="center">Controllers</p></h3>
          
          <table className="tealtbl">
          
            <thead>
              <tr>
                <th>Serial</th>
                <th>Controller</th>
                <th>IP address</th>
                <th>Select</th>
              </tr>
            </thead>                
            <tbody>
              {state.map((item, index) => (
                <tr key={`row${index}`}>
                  <td>{(++tindex)}</td>
                  <td><a href={`/components/editsinglecontroller?id=${item.id}`}>{item.name}</a></td>
                  <td>{item.ipaddr}</td>
                  <td>
                    <input type="checkbox" id={`chk{index}`} 
                      onChange={()=>{multRowCBClicked(index)}} 
                      checked={item.checked} 
                    />
                  
                  </td>
                </tr>
              ))}
            </tbody>
          </table> 

        <br/>

        </div>

        <div className="inlinecenter small-text">
          {status && <div className={`alert ${status.type}`}>{status.message}</div>}
          <button onClick={handleEditControllerDelete}>Delete</button>
          <button onClick={handleEditControllerSelectAll}>{selectAllBtnText}</button>
        </div>


      </div>



    );
  }
  
};

export {Controller, EditSingleController, EditControllers};
