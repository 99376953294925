import {setPageTitle} from './lm.js'
import React from 'react';

const Home = () => {
  setPageTitle('Home');
  const myElement = (
    <div>
      <p><br/>
        <b><span className="blue">LM Tools</span></b> is a <b>Load</b> testing tool with <b>Automation</b> framework. 
        It offers highly scalable testing solutions, catering to needs 
        of global customers in areas of Web, Custom protocols, HTTP, 
        Websocket, WebRTC, VoIP, SIP, RTP, SRTP, JSON, XML, STUN / TURN 
        / ICE and few more. And it supports any <b>text / binary</b> based protocol 
        testing which in turn helps our clients to test <b>custom / proprietary</b> products as well.
                 
      </p>
      <br></br><img className="borderimg" src={process.env.PUBLIC_URL + '/lmtools-systems.png'} alt="LM Tools Deployment" width='85%'/>
      <br/><br/>
      <b>LM Tools</b> as you can see in above diagram consists of three components
      and the components are - 
      
      <ul>
        <li><b>Multiplier</b> - 
        This machine is provided by customer either from cloud or from lab network.
        LM Tools multiplier package must be installed in this machine to simulate
        virtual subscribers or gateways or proxies or any other component.
        </li>
        
        <li><b>Controller</b> - 
        This is provied by service provider from it's cloud setup. This 
        is responsible to collect stats from N number of Multiplier machines in network, 
        aggregate those and serve the data to Web server for displaying the same to user in a
        readable format.
        </li>
        
        <li><b>Web Server</b> - This is provied by service provider from it's cloud setup. 
        It provides an easy to use front end interface for LM Tools.
        </li>
        
      </ul>
      
      The <b>System Under Test</b> is the one, that is to be tested for its standard compatibility,
      stability under heavy load, long hours testing with varied load using LM Tools.
      <br/><br/>
      Cloud version of LM Tools is completely free. For more details  
      please <a href="/contact">contact us</a>. To know more 
      on license please visit <a href="/license">license</a> page.
      
    </div>
  );
  
  return myElement;
};

export default Home;
